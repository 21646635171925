/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  ButtonGroup,
  Fab,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import { ReactComponent as QuotesIcon } from '../../assets/quotes-icon.svg';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import CircleIcon from '@mui/icons-material/Circle';
// import { ReactComponent as FiltersIcon } from '../../assets/filters-icon.svg';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
// import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import { ReactComponent as ServiceMediaIcon } from '../../assets/service-media-icon.svg';
// import { ReactComponent as ProductMediaIcon2 } from '../../assets/product-media-icon2.svg';
// import { ReactComponent as ProductMediaIcon3 } from '../../assets/product-media-icon3.svg';
import { ReactComponent as ServiceImg } from '../../assets/service-image.svg';
// import Rating from '@mui/material/Rating';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import {
  getElynkerToolsData,
  setElynkerToolsData,
} from '../Homepage/redux/actions/homepage-tools-action';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
// import { setToolDataSerices } from './redux/actions/elynker-tools-action';
// import { Carousel } from 'react-responsive-carousel';
// import PropTypes from 'prop-types';
import {
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
} from '../Subscription/redux/action/susbcription-action';
import moment from 'moment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ConfirmationDialog } from '../../components/ActionPopup';
import { DIALOG_NOT_LOGIN_MSG, DIALOG_TITLE } from '../../utils/constants';
// import PropTypes from 'prop-types';

const theme = createTheme();

const ElynkerServicesForm = ({
  getElynkerToolsData,
  tools,
  // setToolDataSerices,
  userData,
  orderData,
  subscriptionDetail,
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
  setElynkerToolsData,
  userType,
}) => {
  // function handleClick(event) {
  //     event.preventDefault();
  //     console.info('You clicked a breadcrumb.');
  // }

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOnly = useMediaQuery({
    query: '(min-width: 768px) and (max-width: 1023px)',
  });
  const isMobileOnly = useMediaQuery({ query: '(max-width: 600px)' });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const history = useHistory();

  // const [toolItem, setToolItem] = useState({});

  useEffect(() => {
    getElynkerToolsData();
  }, []);

  useEffect(() => {
    console.log('tools_n_services data :', tools);
  }, [tools]);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // const carouselItems = [
  //   {
  //     id: 1,
  //     paragraph:
  //       'Access a range of digital solutions designed to improve your operations!',
  //     subtitle: 'Establish a strong online presence with Elynker.',
  //   },
  //   // {
  //   //     id: 2,
  //   //     paragraph:
  //   //         "Your customer care team impressed me with their professionalism and friendliness. They made me feel valued and ensured all my concerns were addressed promptly. Thank you for the exceptional service!",
  //   //     text1: 'Vijay Nair',
  //   //     text2: 'Kerala',
  //   // },
  //   // {
  //   //     id: 3,
  //   //     paragraph:
  //   //         "My experience with Elynker was top-notch. The service was flawless, and every aspect was handled with utmost professionalism. I will definitely recommend you to others. Thank you!",
  //   //     text1: 'Sohail Shaikh',
  //   //     text2: 'Mumbai',
  //   // },
  //   // Add more items as needed
  // ];

  // Checkbox

  // Filters section starts

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const [isBuyNowDrawerOpen, setIsBuyNowDrawerOpen] = useState(false);
  const toggleBuyNowDrawer = () => {
    setIsBuyNowDrawerOpen(!isBuyNowDrawerOpen);
  };

  // const handleClickDetail = (tool) => {
  // 	setToolDataSerices(tool);
  // 	history.push('/gbp-landing');
  // };

  // const [openModal, setOpenModal] = useState(false);

  // const handleOpenModal = (tool) => {
  //     setOpenModal(true);
  //     setToolItem(tool)
  // };

  // const handleCloseModal = () => {
  //     setOpenModal(false);
  // };

  // const [ratingValue, setRatingValue] = React.useState(3);

  // Filter section ends

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      onClick={() => {
        history.replace('/homepage');
      }}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      onClick={() => {
        // if (userData) {
        history.replace('/elynker-services');
        // } else {
        // }
      }}
    >
      Elynker Tools
    </Link>,
  ];

  useEffect(() => {
    if (userData)
      getSubscriptionDetails('registration_id', userData?.registration?.id);
    if (userData)
      getSubscriptionOrderBySearch(
        userData?.registration?.id,
        'registration_id'
      );
  }, [userData]);

  function truncateText(text = '', tool, length = 100) {
    if (text.length <= length) {
      return text;
    }

    return text?.length < length
      ? text
      : tool?.viewMore
        ? text
        : text.substr(0, length) + '\u2026';
  }

  function isBeforeDate() {
    if (subscriptionDetail && orderData) {
      const data = moment().isSameOrBefore(subscriptionDetail?.valid_to);
      if (
        subscriptionDetail?.status == true &&
        data &&
        orderData[orderData?.length - 1]?.orders_status == 'success'
      ) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  }
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  const handleConfirm = () => {
    if (userType !== 1) {
      history.push('/login');
    } else {
      history.push('/complete-profile', {
        fromHomePage: true,
      });
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <ConfirmationDialog
          open={openModal}
          handleClose={handleClose}
          title={DIALOG_TITLE}
          content={
            userType !== 1
              ? DIALOG_NOT_LOGIN_MSG
              : 'Please Login as Seller to use this feature'
          }
          onConfirm={handleConfirm}
          confirmText="Yes"
          cancelText="No"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  history.goBack();
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mt-2 mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '20px' }}>
              {isDesktopOrLaptop && (
                <>
                  <Card className="elynker-services-web-card mb-3">
                    <CardContent className="elynker-services-web-content">
                      <div className="elynker-services-tint" />
                      <div
                        style={{
                          zIndex: 2,
                          position: 'relative',
                        }}
                        className="elynker-services-web-text"
                      >
                        
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Access a range of digital solutions designed to improve your operations!
                        </Typography>
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Establish a strong online presence with Elynker.
                        </Typography>

                      </div>
                    </CardContent>
                  </Card>

                  <div
                    className="elynker-services"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >

                  </div>


                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <Breadcrumbs
                      className=""
                      separator={
                        <NavigateNextIcon
                          fontSize="small"
                          className="text-gray"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      {breadcrumbs}
                    </Breadcrumbs>
                  </div>

                  <div className=" mt-5 mb-4">
                    <Typography
                      variant="h4"
                      align="left"
                      alignItems="center"
                      className="fs-30 text-black fw-bold font-skmodernist"
                    >
                      Select From Below Tools
                    </Typography>
                  </div>
                </>
              )}

              {isTabletOnly && (
                <>
                  <Card className="elynker-services-card mb-3">
                    <CardContent className="elynker-services-content">
                      <div className="elynker-services-tint" />
                      <div
                        style={{
                          zIndex: 2,
                          position: 'relative',
                        }}
                        className="elynker-services-text"
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px',
                          }}
                        >
                        </div>
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Access a range of digital solutions designed to improve your operations!
                        </Typography>
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Establish a strong online presence with Elynker.
                        </Typography>

                      </div>
                    </CardContent>
                  </Card>

                  <Breadcrumbs
                    className="mt-3"
                    separator={
                      <NavigateNextIcon
                        fontSize="small"
                        className="text-gray"
                      />
                    }
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>

                  <div className=" mt-4 mb-3">
                    <Typography
                      variant="h4"
                      align="left"
                      alignItems="center"
                      className="fs-30 text-black fw-bold font-skmodernist"
                    >
                      Select From Below Services
                    </Typography>
                  </div>
                </>
              )}

              {isMobileOnly && (
                <>
                  <Card className="elynker-services-card mb-3">
                    <CardContent className="elynker-services-content">
                      <div className="elynker-services-tint" />
                      <div
                        style={{
                          zIndex: 2,
                          position: 'relative',
                        }}
                      >
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Access a range of digital solutions designed to improve your operations!
                        </Typography>
                        <Typography
                          align="left"
                          variant="h4"
                          alignItems="center"
                          className="fs-20 text-white fw-bold font-skmodernist mb-3 text-center"
                        >
                          Establish a strong online presence with Elynker.
                        </Typography>

                      </div>
                    </CardContent>
                  </Card>

                  <Breadcrumbs
                    className="mb-3 mt-3"
                    separator={
                      <NavigateNextIcon
                        fontSize="small"
                        className="text-gray"
                      />
                    }
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs}
                  </Breadcrumbs>

                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                  >
                    Select From Below Tools
                  </Typography>

                  {/* <div className="my-4 product-filter-section">
                                    <Stack className="product-filter-slider" direction="row" spacing={1.5} useFlexGap flexWrap="nowrap" overflow={'auto'}>
                                        <Chip icon={<FiltersIcon />} label="Filters" clickable onClick={toggleFilterDrawer} />
                                        <Chip label="B2B"
                                            variant="outlined"
                                            onClick={() => handleChipClick("B2B")}
                                            style={{
                                                backgroundColor: selectedChips.includes("B2B") ? '#1843a0' : '#fff',
                                                color: selectedChips.includes("B2B") ? '#fff' : '#1843a0'
                                            }} />
                                        <Chip label="Freelancer" variant="outlined"
                                            onClick={() => handleChipClick("Freelancer")}
                                            style={{
                                                backgroundColor: selectedChips.includes("Freelancer") ? '#1843a0' : '#fff',
                                                color: selectedChips.includes("Freelancer") ? '#fff' : '#1843a0'
                                            }} />

                                    </Stack>
                                </div> */}

                  {/* <div className="my-4 b2b-business">
                                    <Stack className="b2b-business-slider" direction="row" spacing={1.5} useFlexGap flexWrap="nowrap" overflow={'auto'}>
                                        <Chip label="25 Mn+ Business" />
                                        <Chip label="30 Cr Business Done" />
                                        <Chip label="4.5 Star" />
                                    </Stack>
                                </div> */}
                </>
              )}

              <Grid container spacing={2} rowSpacing={2}>
                {tools.map((tool) => (
                  <>
                    {tool?.service_type === 'Tools' && (
                      <Grid item xs={12} md={6} key={tool.id}>
                        <ListItem className="product-tools-list-item">
                          <Card className="card">
                            <div className="d-flex">
                              <CardMedia>
                                <img
                                  src={tool.cover_image}
                                  className="category-media"
                                />
                              </CardMedia>
                              <CardContent className="content p-0">
                                <div className="w-100">
                                  <Typography
                                    variant="h5"
                                    className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                    component="div"
                                  >
                                    {tool.title}
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    className="fs-14 text-gray mb-1 fw-normal font-gotham"
                                    component="div"
                                  >
                                    {truncateText(tool.description, tool)}{' '}
                                    <Button
                                      onClick={() => {
                                        let idx = tools?.findIndex(
                                          (tl) => tl.id === tool.id
                                        );
                                        if (idx !== -1) {
                                          let newTools = [...tools];
                                          newTools[idx].viewMore =
                                            !tool.viewMore;
                                          setElynkerToolsData(newTools);
                                        }
                                      }}
                                      sx={{
                                        textTransform: 'capitalize',
                                        color: '#864ffd',
                                        padding: '0px',
                                      }}
                                    >
                                      {tool?.viewMore
                                        ? 'view less'
                                        : 'view more'}
                                    </Button>
                                  </Typography>
                                  {/* <Box>
                                                                    <Rating name="read-only" value={ratingValue} onChange={(event, newValue) => {
                                                                        setRatingValue(newValue);
                                                                    }} size="small" readOnly />
                                                                </Box> */}
                                </div>
                              </CardContent>
                            </div>
                            {/* <Divider
                                                        component="div"
                                                        className="mt-3 mb-3"
                                                        role="presentation"
                                                    /> */}

                            {/* <CardContent className="p-0 d-flex justify-content-end align-items-center">
                                                        <div> */}
                            {/* {isDesktopOrLaptop && (<>

                                                        <Button variant="contained" className="subscribe-btn me-3" onClick={() => handleOpenModal(tool)}>
                                                            Buy Now
                                                        </Button>
                                                        <AdServicesModal open={openModal} onClose={handleCloseModal} />
                                                    </>
                                                    )
                                                    }
                                                    {isTabletOrMobile && (<>
                                                        <Button variant="contained" className="subscribe-btn me-3" onClick={toggleBuyNowDrawer}>
                                                            Buy Now
                                                        </Button>
                                                    </>
                                                    )
                                                    } */}

                            {/* <Button variant="contained" className="view-details-btn" onClick={() => { handleClickDetail(tool) }}>
                                                                View Details
                                                            </Button>

                                                        </div>
                                                    </CardContent> */}
                          </Card>
                        </ListItem>
                      </Grid>
                    )}
                  </>
                ))}
                {/* <AdServicesModal open={openModal} onClose={handleCloseModal} toolSet={toolItem} /> */}

                {/* <Grid item xs={12} md={6} >
                                    <ListItem className="product-tools-list-item">
                                        <Card className="card">
                                            <div className="d-flex">
                                                <CardMedia
                                                ><ProductMediaIcon2 className="category-media" /></CardMedia>
                                                <CardContent className="content p-0">
                                                    <div className="w-100">
                                                        <Typography
                                                            variant="h5"
                                                            className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                                            component="div"
                                                        >
                                                            Google Business Profile
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            className="fs-14 text-gray mb-1 fw-normal font-gotham"
                                                            component="div"
                                                        >
                                                            20k+ used this
                                                        </Typography>
                                                        <Box>
                                                            <Rating name="read-only" value={ratingValue} onChange={(event, newValue) => {
                                                                setRatingValue(newValue);
                                                            }} size="small" readOnly />
                                                        </Box>
                                                    </div>
                                                </CardContent>
                                            </div>
                                            <Divider
                                                component="div"
                                                className="mt-3 mb-3"
                                                role="presentation"
                                            />

                                            <CardContent className="p-0 d-flex justify-content-end align-items-center">
                                                <div>
                                                    <Button variant="contained" className="view-details-btn">
                                                        View Details
                                                    </Button>
                                                </div>
                                            </CardContent>

                                        </Card>
                                    </ListItem>
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <ListItem className="product-tools-list-item">
                                        <Card className="card">
                                            <div className="d-flex">
                                                <CardMedia
                                                ><ProductMediaIcon3 className="category-media" /></CardMedia>
                                                <CardContent className="content p-0">
                                                    <div className="w-100">
                                                        <Typography
                                                            variant="h5"
                                                            className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                                            component="div"
                                                        >
                                                            Startup Services
                                                        </Typography>
                                                        <Typography
                                                            variant="h5"
                                                            className="fs-14 text-gray mb-1 fw-normal font-gotham"
                                                            component="div"
                                                        >
                                                            20k+ used this
                                                        </Typography>
                                                        <Box>
                                                            <Rating name="read-only" value={ratingValue} onChange={(event, newValue) => {
                                                                setRatingValue(newValue);
                                                            }} size="small" readOnly />
                                                        </Box>
                                                    </div>
                                                </CardContent>
                                            </div>
                                            <Divider
                                                component="div"
                                                className="mt-3 mb-3"
                                                role="presentation"
                                            />

                                            <CardContent className="p-0 d-flex justify-content-end align-items-center">
                                                <div>
                                                    <Button variant="contained" className="view-details-btn">
                                                        View Details
                                                    </Button>
                                                </div>
                                            </CardContent>

                                        </Card>
                                    </ListItem>
                                </Grid> */}
              </Grid>

              {/* Buy Now Drawer Starts*/}
              <Drawer
                xs={12}
                anchor="bottom"
                open={isBuyNowDrawerOpen}
                onClose={toggleBuyNowDrawer}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <Box className="position-relative">
                  <Box width={'100%'}>
                    <ServiceImg />
                  </Box>
                  <Box
                    style={{
                      position: 'absolute',
                      top: '20px',
                      right: '15px',
                    }}
                  >
                    <CloseIcon onClick={toggleBuyNowDrawer} />
                  </Box>
                </Box>
                <div className="p-3">
                  <Box className="d-flex justify-content-between mb-2">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-22 text-black  fw-bold font-skmodernist "
                      gutterBottom
                    >
                      Get Facebook Ad <br />
                      Services
                    </Typography>
                    {/* <Rating name="read-only" className="mt-1" value={ratingValue} onChange={(event, newValue) => {
                                            setRatingValue(newValue);
                                        }} size="medium" readOnly /> */}
                  </Box>

                  <Typography
                    variant="h5"
                    align="left"
                    alignItems="center"
                    className="fs-14 text-grey mb-2 fw-normal font-gotham "
                    gutterBottom
                  >
                    Ea ea occaecat labore labore proident fugiat <br />
                    sunt do in magna
                  </Typography>

                  <Box className="d-flex justify-content-between mb-2">
                    {/* <Typography
                                            variant="h5"
                                            align="left"
                                            alignItems="center"
                                            className="fs-14 text-secondary  fw-normal font-skmodernist "
                                            gutterBottom >
                                            20k+ used this
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            align="left"
                                            alignItems="center"
                                            className="fs-16 text-black fw-bold font-skmodernist "
                                            gutterBottom >
                                            ₹ 5.000
                                        </Typography> */}
                  </Box>

                  <ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
                    <Button variant="contained" className="services-btn">
                      Buy Now
                    </Button>
                    <Button variant="contained" className="services-btn">
                      Subscribe
                    </Button>
                  </ButtonGroup>
                </div>
              </Drawer>

              {/* But Now Drawer Ends */}
            </form>
          </Grid>
          {/* {subscriptionDetail?.status === true && orderData && ( */}

          {isBeforeDate() && (
            <Fab
              onClick={() => {
                if (userData && userType !== 1) {
                  history.push('/subscription');
                } else {
                  handleOpen();
                }
              }}
              variant="extended"
              size="medium"
              color="primary"
              sx={{ position: 'fixed', bottom: '50px', right: '25px' }}
            >
              Subscribe
            </Fab>
          )}
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

ElynkerServicesForm.propTypes = {
  getElynkerToolsData: PropTypes.func,
  tools: PropTypes.array,
  setToolDataSerices: PropTypes.func,
  userData: PropTypes.any,
  orderData: PropTypes.any,
  subscriptionDetail: PropTypes.any,
  premiumPlan: PropTypes.any,
  getSubscriptionDetails: PropTypes.func,
  getSubscriptionOrderBySearch: PropTypes.func,
  setElynkerToolsData: PropTypes.func,
  userType: PropTypes.any,
};

const mapStateToProps = (state) => {
  const { tools } = state.homepageTools;
  const { userData } = state.signin;
  const { orderData, subscriptionDetail, premiumPlan } = state.Subscription;
  const userType = userData?.registration?.registration_type;
  console.log('userData elynker userType', userType);

  return {
    tools,
    userData,
    orderData,
    subscriptionDetail,
    premiumPlan,
    userType,
  };
};

export default connect(mapStateToProps, {
  getElynkerToolsData,
  // setToolDataSerices,
  setElynkerToolsData,
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
})(ElynkerServicesForm);
