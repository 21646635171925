import React from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SuccessImage } from '../../assets/success-img.svg';
import Divider from '@mui/material/Divider';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery } from 'react-responsive';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';

const theme = createTheme();

const CongratulationsForm = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)', });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const history = useHistory();
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2">
              <ElynkerLogoComponent>
                <ElynkerLogo width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            ></Divider>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                history.replace('/homepage');
              }}
              style={{ marginTop: '45px' }}
            >
              <div className="mb-4 text-center">
                <SuccessImage />
              </div>
              <Typography
                variant="h4"
                align="center"
                alignItems="center"
                className="fs-28 mb-3  fw-bold text-black font-skmodernist"
                gutterBottom
              >
                Congratulations!!
              </Typography>

              <Typography className="fs-16 text-center font-gotham text-grey mb-2">
                Your seller registration is complete and is under review
              </Typography>

              {isTabletOrMobile && (<>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                  sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                >
                  Start Selling
                </Button>

              </>)}

              {isDesktopOrLaptop && (<>
                <Box className="text-center my-5">

                  <Button
                    type="submit"
                    variant="contained"
                    className="btn-web-next"
                  >
                    Start Selling
                  </Button>
                </Box>

              </>)}


            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default CongratulationsForm;
