import React, { useEffect, useRef, useState } from 'react';
import '../../../common/fontsize.css';
// import './complete-profile.css';
import '../../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	FormControlLabel,
	RadioGroup,
	FormLabel,
	Radio,
	TextareaAutosize,
	Autocomplete,
	TextField,
	FormHelperText,
	IconButton,
	InputAdornment,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import IconButton from '@mui/material/IconButton';
import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import CustomStepper from '../../components/CustomStepper/CustomStepper';
import Divider from '@mui/material/Divider';
// import LocationLogo from '../../../assets/location-icon.svg';
import { useMediaQuery } from 'react-responsive';
// import ElynkerLogoComponent from '../../../components/ElynkerLogo/ElynkerLogo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import {
	setRequestBType,
	setRequestMOQ,
	setRequestName,
	setRequestDesc,
	setRequestLocation,
	getProductCategories,
	setRequestCategory,
	setBudget,
	setPageActionType,
	resetRequirementData,
	setReqSubCategory,
} from './redux/actions/b2b-submit-requirement-action';
import { number, func, object, string, array, bool } from 'prop-types';
import axios from 'axios';
import { BUSINESS, FREELANCER } from '../../../utils/constants';
import useValidation from '../../../hooks/useValidation';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomizedTreeView from '../../../components/TreeDropDown/TreeDropDown';
import { setProductSubCategory } from '../../AddCatalog/redux/actions/add-catalog-action';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import CloseIcon from '@mui/icons-material/Close';
import { searchInArray } from '../../../utils/helper-funtions';
import { POSTOFFICE_CITY } from '../../../utils/APIs/api';

import { stickyButtonStyle } from '../../../utils/sx-styles';
import { sendMobForOTP } from '../../Login/redux/actions/submit-mobileno-action';
import { loading } from '../../../store/actions/rootActions';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';

// import { hasAnyErrorPresent } from '../../../utils/validation';
// import PropTypes from 'prop-types';
const theme = createTheme();

// const handleBTypeChange = (event) => {
//     setBusinessType(event.target.value);
//   };
export function filterData(data, filterValues) {
	return data.filter((value) => {
		return filterValues
			.trim()
			.split(', ')
			.every((filterValue) => checkValue(value, filterValue));
	});
}

function checkValue(value, filterValue) {
	if (typeof value === 'string') {
		return value.toLowerCase().includes(filterValue.toLowerCase());
	} else if (
		typeof value === 'object' &&
		value !== null &&
		Object.keys(value).length > 0
	) {
		if (Array.isArray(value)) {
			return value.some((v) => checkValue(v, filterValue));
		} else {
			return Object.values(value).some((v) => checkValue(v, filterValue));
		}
	} else {
		return false;
	}
}

export function list_to_tree(list) {
	let map = {},
		node,
		roots = [],
		i;

	for (i = 0; i < list.length; i += 1) {
		map[list[i].id] = i; // initialize the map
		list[i].children = []; // initialize the children
	}

	for (i = 0; i < list.length; i += 1) {
		node = list[i];
		if (node.parent_id !== null) {
			// if you have dangling branches check that map[node.parent_id] exists
			list[map[node.parent_id]]?.children.push(node);
		} else {
			roots.push(node);
		}
	}
	return roots;
}

const B2BRequirementProductCategoryForm = ({
	moq,
	setRequestMOQ,
	businessType,
	name,
	category,
	location,
	description,
	setRequestBType,
	setRequestName,
	setRequestDesc,
	setRequestLocation,
	b2bCategory,
	flCategory,
	setRequestCategory,
	getProductCategories,
	budget,
	setBudget,
	pageActionType,
	singleRequirement,
	resetRequirementData,
	// setProductSubCategory,
	setReqSubCategory,
	selectedLvl1Categories,
	// setPageActionType
	sendMobForOTP,
	userData,
	loading,
	pushMessage,
	isLoading
}) => {
	// const [singleCategory, setSingleCategory] = useState(null);

	const searchLocInpRef = React.useRef(null);
	const [mapOtions, setMapOtions] = useState([]);
	const [searchLoad, setSearchLoad] = useState(false);
	const [locSearchVal, setLocSearchVal] = useState('');
	const [categories, setCategory] = useState([]);
	const [open, setOpen] = useState(false);
	const closePopper = () => setOpen(false);
	const openPopper = () => setOpen(true);
	const inputProductName = useRef(null);
	// const handleChangeCategory = (e) => {
	//     // console.log("category: ", e.target.value);
	//     setSingleCategory(e.target.value);
	//     setRequestCategory(e.target.value);
	// };
	const { validateInput, errors } = useValidation();

	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
	const [count, setCount] = useState(description | 0);
	const history = useHistory();

	const [categoriesTreeFilter, setCategoriesTreeFilter] = useState([]);
	const [categorySearch, setCategorySearch] = useState('');
	const [filteredCategory, setFilteredCategory] = useState([]);
	const [categoriesTree, setCategoriesTree] = useState([]);
	const [openCate, setOpenCate] = useState(false);

	let categoryText =
		pageActionType === undefined
			? 'Post a Requirement'
			: pageActionType + ' a Requirement';

	function getTitleById(data, title) {
		for (let i = 0; i < data.length; i++) {
			if (data[i].title === title) {
				return data[i];
			}
		}
		return null; // Return null if ID is not found
	}

	const handleNameChange = (e) => {
		// console.log(e);
		setRequestName(e.target.value);
		validateInput('productName', e.target.value);
	};
	// const handleCategoryChange = (e) => {
	//     console.log(e);
	// }
	const onChangeProdMoq = (e) => {
		const input = e.target.value;
		const regex = /^[0-9]{0,5}$/;
		if (regex.test(input)) {
			//   console.log("B2B Req", Number(input));
			setRequestMOQ(Number(input));
			validateInput('orderQuantity', Number(input));
			//   validateInput("product_orderQuantity", Number(input));
		} else {
			console.log('B2B Req else', input);
		}
	};

	useEffect(() => {
		console.log('b2bCategory_requirement :', b2bCategory);
		console.log('fl-Category_requirement :', flCategory);
		console.log('normal category : ', category);
		//focus
		if(inputProductName){
			inputProductName.current.focus();
		}
	}, []);

	const successOTPCallback = () => {
        loading(false);
		history.push('/b2b-requirement-verify');
    }
    const errorOTPCallback = () => {
        loading(false);
        pushMessage('Network Error while sending OTP.');
    }

	const onPasteDescription = event => {
		const input = (event.clipboardData || event.window.clipboardData).getData("text");
		const newInput = description ? description + input : input;
		// const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~-]*$/;
		const regex = /^[\s\S]*$/;

		if (regex.test(newInput)) {
			if(newInput?.length > 500){
				const text = newInput.substr(0, 500);
				setRequestDesc(text);
				setCount(text?.length);
			} else{
				setRequestDesc(newInput);
				setCount(newInput?.length);
			}

			//   validateInput("product_description", input)
		}
	};
	const onChangeDescription = (e) => {
		const input = e.target.value;
		// const regex = /^[a-zA-Z0-9\s!@#$%^&*()_+=[\]{};':"\\|,.<>/?`~-]*$/;
		const regex = /^[\s\S]*$/;
		if (regex.test(input)) {
			if(input?.length > 500){
				const text = input.substr(0, 500);
				setRequestDesc(text);
				setCount(text?.length);
				//   validateInput("product_description", input)
			} else{
				setRequestDesc(input);
				setCount(input?.length);
			}
		}
	};

	const handleSubCategoryChange = (e) => {
		console.log('==handleSubCategoryChange 67', e.target.value);
		if (!openCate) {
			setCategorySearch('');
			setOpenCate(true);
		}

		setCategorySearch(e.target.value);
		const filteredCategories = filterCategoriesInDeep(e.target.value);
		console.log('filteredCategories0', filteredCategories?.length);
		setFilteredCategory(filteredCategories);

		const searchResults = searchInArray(categoriesTree, e.target.value);

		console.log('Search results:', searchResults);
		setFilteredCategory(searchResults);
	};

	const filterCategoriesInDeep = (searchText) => {
		const filterCategorySearch = filterData(categoriesTreeFilter, searchText);
		if (filterCategorySearch.length > 0) {
			return checkChildrenAndLoop(filterCategorySearch, searchText);
		} else {
			return filterCategorySearch;
		}
	};

	const checkChildrenAndLoop = (childCategories, searchText) => {
		let newFilteredCategories = [];
		for (const category of childCategories) {
			if (category.children.length > 0) {
				const filterLvl2 = filterData(category.children, searchText);
				const filterLvl2Child = filterLvl2.findIndex(
					(child) => child.children.length > 0
				);
				if (filterLvl2Child !== -1) {
					const childArr = checkChildrenAndLoop(filterLvl2, searchText);
					newFilteredCategories.push({
						...category,
						children: childArr,
					});
				} else {
					newFilteredCategories.push({ ...category, children: filterLvl2 });
				}
			} else {
				newFilteredCategories.push(category);
			}
		}
		return newFilteredCategories;
	};

	const getLocationString = (option) => {
		return option;
	};

	const handleClick = () => {
		setOpenCate(!openCate);
	};

	const successCallback = () => {
		console.log('Category success is fetched');
	};

	const errorCallback = () => {
		console.log('Category Error');
	};

	const getAddressDefaultVal = () => {
		let addressVal;
		if(singleRequirement){
			addressVal = singleRequirement?.location;
		}else {
			addressVal = location;
		}

		if (addressVal) {
			return addressVal;
		} else {
			return '';
		}
	};

	useEffect(() => {
		// setPageActionType("Post");
		// get All business categories
		getProductCategories(BUSINESS, successCallback, errorCallback);

		// get All freelancer categories
		getProductCategories(FREELANCER, successCallback, errorCallback);

		if (singleRequirement) {
			if (singleRequirement?.product_type == 1) {
				setRequestBType('1');
				setRequestMOQ(Number(singleRequirement?.order_quantity) || 0);
			} else if(singleRequirement?.product_type == 2){
				setRequestBType('2');
				setBudget(singleRequirement?.budget || 0);
			} else {
				setRequestBType('1');
				setRequestMOQ(Number(singleRequirement?.order_quantity) || 0);
			}

			setRequestName(singleRequirement?.product_service_name);

			//category
			let cateObj = categories?.find(
				(item) => item?.title === singleRequirement?.category
			);
			if (cateObj !== null) {
				setRequestCategory(cateObj);
				setCategorySearch(cateObj?.title);
			}

			// location
			setRequestLocation(singleRequirement?.location || '');
			// description
			setRequestDesc(singleRequirement?.description || '');
			setCount(singleRequirement?.description?.length || 0);
		}
		return () => setPageActionType(undefined);
	}, []);

	useEffect(() => {
		if (businessType == '1') {
			setCategory(b2bCategory);
		} else {
			setCategory(flCategory);
		}
		if (singleRequirement) {
			let selCategory = categories?.filter((item) => {
				if (item?.title === singleRequirement?.category) {
					return item;
				}
			});

			if (selCategory?.length > 0) {
				// setSingleCategory(selCategory[0]);
				setRequestCategory(selCategory[0]);
				setCategorySearch(selCategory[0]?.title);
			} else {
				// setSingleCategory(null);
				setRequestCategory(null);
				setCategorySearch('');
			}
		}
	}, [b2bCategory, flCategory, businessType, categories]);

	useEffect(() => {
		// const jsonString = CircularJSON.stringify(b2bprodCategories);
		// console.log("===b2bprodCategories678", jsonString);
		// console.log('====b2bprodCategories', categories);
		if (singleRequirement && categories && categories.length > 0) {
			let singleProductCategory;
			//singleProduct.category_id
			if (singleRequirement?.category_id && categories.length > 0) {
				singleProductCategory = getTitleById(
					categories,
					singleRequirement?.category
				);
			}

			setCategorySearch(singleProductCategory);
			if (singleRequirement?.category !== '') {
				setRequestCategory(singleProductCategory);
				setReqSubCategory(singleRequirement?.category_id);
				setRequestCategory(singleProductCategory?.category);
			} else {
				setRequestCategory(null);
				setReqSubCategory(singleRequirement?.category_id);
				setRequestCategory(singleProductCategory?.category);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories]);

	useEffect(() => {
		// console.log('selectedLvl1Categories', selectedLvl1Categories);
		let filteredLvl1 = [];
		selectedLvl1Categories.forEach((category) => {
			let selectedIdx = categoriesTree.findIndex(
				(itm) => itm.id === category.id
			);
			if (selectedIdx !== -1) {
				filteredLvl1.push(categoriesTree[selectedIdx]);
			}
		});
		setCategoriesTreeFilter(filteredLvl1);
	}, [categoriesTree, selectedLvl1Categories]);

	useEffect(() => {
		if (categories?.length > 0) {
			const categoryTree = list_to_tree(categories);
			// console.log('CATEGORY TREE', categoryTree);
			setCategoriesTree(categoryTree);
		}
	}, [categories]);

	useEffect(() => {
		const getData = setTimeout(async () => {
			if (locSearchVal && searchLoad) {
				try {
					const response = await axios.get(POSTOFFICE_CITY + locSearchVal);
					if (response.status === 200) {
						if (response?.data?.status_code === 200) {
							console.log('SEARCH RESULT', response.data);
							setMapOtions(response.data?.data);
							setSearchLoad(false);
						} else {
							setMapOtions([]);
							setSearchLoad(false);
						}
					} else {
						console.log('No City Found!!');
					}
				} catch (err) {
					console.log('Network Error!!');
				}
			}
		}, 250);

		return () => clearTimeout(getData);
	}, [locSearchVal, searchLoad]);

	const [, setValue] = React.useState('female');

	const handleBTypeChange = (event) => {
		setValue(event.target.value);
		setRequestBType(event.target.value);
		if (businessType == '1') {
			setCategory(b2bCategory);
		} else {
			setCategory(flCategory);
		}
	};

	const handleBudget = (e) => {
		const input = e.target.value;
		const regex = /^\s*(\d{0,7}(\.\d{1,2})?)?\s*$/;
		if (regex.test(input)) {
			setBudget(Number(e.target.value));
			validateInput('budget', e.target.value);
			// validateInput("product_price", e.target.value)
		}
	};

	useEffect(() => {
		// const jsonString = CircularJSON.stringify(b2bprodCategories);
		// console.log("===b2bprodCategories678", jsonString);
		// console.log('====b2bprodCategories', categories);
		if (singleRequirement && categories && categories.length > 0) {
			let singleProductCategory;
			//singleProduct.category_id
			if (singleRequirement?.category_id && categories.length > 0) {
				singleProductCategory = getTitleById(
					categories,
					singleRequirement?.category
				);
			}

			setCategorySearch(singleProductCategory);
			if (singleRequirement?.category !== '') {
				setRequestCategory(singleProductCategory);
				setReqSubCategory(singleRequirement?.category_id);
				setRequestCategory(singleProductCategory?.category);
			} else {
				setRequestCategory(null);
				setReqSubCategory(singleRequirement?.category_id);
				setRequestCategory(singleProductCategory?.category);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categories]);

	useEffect(() => {
		// console.log('selectedLvl1Categories', selectedLvl1Categories);
		let filteredLvl1 = [];
		selectedLvl1Categories.forEach((category) => {
			let selectedIdx = categoriesTree.findIndex(
				(itm) => itm.id === category.id
			);
			if (selectedIdx !== -1) {
				filteredLvl1.push(categoriesTree[selectedIdx]);
			}
		});
		setCategoriesTreeFilter(filteredLvl1);
	}, [categoriesTree, selectedLvl1Categories]);

	useEffect(() => {
		if (categories?.length > 0) {
			const categoryTree = list_to_tree(categories);
			// console.log('CATEGORY TREE', categoryTree);
			setCategoriesTree(categoryTree);
		}
	}, [categories]);

	// const handleSubmit = (e) => {
	//     e.preventDefault();
	//     //validation

	//     //APi call
	// }

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					{isLoading && (
						<Backdrop sx={{ color: '#fff', zIndex: 10000 }} open={isLoading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					)}
					<Grid item xs={12}>
						<div className="my-3">
							<ArrowBackIosIcon
								onClick={() => {
									resetRequirementData();
									history.push('active-requirements');
								}}
							/>
							<ElynkerLogo
								className="ms-3"
								width={'100px'}
								height={'30px'}
								onClick={() => {
									history.push('/homepage');
								}}
							/>
						</div>
						<Divider
							component="div"
							className="main-divider mt-1"
							role="presentation"
						/>

						{/* Include Custom Stepper here * /}
                        //Stepper Labels -> Details - Product Category - Verify (if radio selected is product)
                        //Stepper Labels -> Details - Service Category - Verify (if radio selected is service)
                        {/* <CustomStepper  /> */}

						<form style={{ marginTop: '40px' }}>
							<Typography
								variant="h4"
								align="start"
								alignItems="center"
								className="fs-22 mt-4 mb-4 fw-bold font-skmodernist"
								gutterBottom
							>
								{categoryText}
							</Typography>
							<FormControl fullWidth>
								<FormLabel
									id="business-type-radio"
									className="fs-15 fw-normal font-skmodernist text-black"
								>
									What is your Requirement Type{' '}
									<span className="text-mandatory">*</span>
								</FormLabel>
								<Divider
									component="div"
									className="divider mt-2 mb-1"
									role="presentation"
								></Divider>
								<RadioGroup
									row
									aria-labelledby="business-type-radio"
									name="business-type-radio"
									defaultValue={'1'}
									value={businessType}
									onChange={handleBTypeChange}
								>
									<FormControlLabel
										disabled={singleRequirement?.status === 'fulfilled'}
										value="1"
										control={<Radio />}
										label="Product"
									/>
									<FormControlLabel
										disabled={singleRequirement?.status === 'fulfilled'}
										value="2"
										control={<Radio />}
										label="Service"
									/>
								</RadioGroup>
								<Divider
									component="div"
									className="divider mb-3 mt-1"
									role="presentation"
								></Divider>
							</FormControl>
							<Grid container rowSpacing={1} columnSpacing={10}>
								<Grid item md={6} lg={6} xs={12} className="">
									<InputLabel
										htmlFor="inputCompanyName"
										className="text-start fs-14 text-black font-skmodernist"
									>
										{businessType == '1' ? 'Product' : 'Service'} Name{' '}
										<span className="text-mandatory">*</span>
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputProductName"
											ref={inputProductName}
											// label="Mobile Number"
											type="text"
											placeholder={
												businessType == '1'
													? 'Enter product name'
													: 'Enter service name'
											}
											required
											autoFocus
											fullWidth
											margin="normal"
											onChange={handleNameChange}
											disabled={singleRequirement?.status === 'fulfilled'}
											value={name}
											autoComplete="off"
											inputProps={{
												maxLength: 100,
											}}
										/>
									</FormControl>
									{errors.productName && (
										<FormHelperText
											sx={{ color: 'red' }}
											id="product-name-helper-text"
										>
											{businessType == '1' ? 'Product' : 'Service'}
											{errors.productName}
										</FormHelperText>
									)}
								</Grid>

								{/* <Grid item md={6} lg={6} xs={12} className="">
                                    <InputLabel
                                        htmlFor="inputCIN"
                                        className="text-start fs-14 text-black font-skmodernist">
                                        Category
                                    </InputLabel>
                                    <FormControl fullWidth sx={{ my: 1 }}>
                                        <Select
                                            value={singleCategory}
                                            onChange={handleChangeCategory}
                                            disabled={singleRequirement?.status === 'fulfilled'}
                                            placeholder='Select Category'
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            renderValue={() => {
                                                if (singleCategory == null) {
                                                    return <em>Select Category</em>;
                                                }
                                                return singleCategory?.title;
                                            }}
                                        >   */}
								{/* <MenuItem disabled value="">
                                                <em>Placeholder</em>
                                            </MenuItem> */}
								{/*  {

                                                categories && categories.map(c => (
                                                    <MenuItem key={c.id} value={c}>{c?.title}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid> */}
								<Grid item md={6} lg={6} xs={12} className=" mb-3">
									<InputLabel
										htmlFor="subCategoryInput"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Sub Category <span className="text-mandatory">*</span>
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="subCategoryInput"
											type="text"
											placeholder="Select Sub-Category"
											// required
											// disabled={categoriesTreeFilter.length === 0}
											autoComplete="off"
											fullWidth
											margin="none"
											value={categorySearch}
											onChange={handleSubCategoryChange}
											onClick={() => {
												console.log('===OutlinedInput onClick');
												handleClick();
											}}
											// onFocus={handleClick}
											endAdornment={
												<InputAdornment
													position="end"
													style={{
														flexDirection: 'row',
														alignItems: 'center',
														border: 'black',
													}}
												>
													{categorySearch && (
														<IconButton
															aria-label="toggle password visibility"
															edge="end"
															onClick={() => {
																validateInput('product_subCategory', '');
																console.log('=====on close');
																setCategorySearch('');
																const filteredCategories =
																	filterCategoriesInDeep('');
																setFilteredCategory(filteredCategories);
															}}
														>
															<CloseIcon
																style={{
																	color: '#727272',
																	width: 16,
																	height: 16,
																	cursor: 'pointer',
																	marginLeft: 4,
																}}
															/>
														</IconButton>
													)}
													<IconButton
														aria-label="toggle password visibility"
														edge="end"
													>
														<ArrowDropDownIcon className="arrow-icon" />
													</IconButton>
												</InputAdornment>
											}
										/>
										{openCate && (
											<div
												style={{
													position: 'absolute',
													boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
													top: '50px',
													background: 'white',
													zIndex: 1,
													borderRadius: 4,
													width: '100%',
												}}
											>
												<CustomizedTreeView
													categoriesTree={
														filteredCategory.length > 0
															? filteredCategory
															: categoriesTreeFilter.length > 0
																? categoriesTreeFilter
																: categoriesTree
													}
													selectedCategory={(value) => {
														console.log('selectedCategory', value);
														validateInput('product_subCategory', value?.title);
														setOpenCate(!openCate);
														setCategorySearch(value.title);

														setReqSubCategory(value.id);
														setRequestCategory(value);
														setFilteredCategory([]);
													}}
												/>
											</div>
										)}
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{errors.product_subCategory}
									</FormHelperText>
								</Grid>

								{businessType == '1' ? (
									<Grid item md={6} lg={6} xs={12} className=" mb-3">
										<InputLabel
											htmlFor="inputCompanyName"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Order Quantity <span className="text-mandatory">*</span>
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<OutlinedInput
												id="inputCompanyName"
												// label="Mobile Number"
												type="text"
												placeholder="Enter order quantity"
												fullWidth
												margin="normal"
												disabled={singleRequirement?.status === 'fulfilled'}
												onKeyDown={(evt) =>
													['e', 'E', '+', '-'].includes(evt.key) &&
													evt.preventDefault()
												}
												inputProps={{
													maxLength: 10,
												}}
												onChange={onChangeProdMoq}
												value={moq === 0 ? '' : moq}
												autoComplete='off'
											/>
										</FormControl>
										<FormHelperText
											sx={{ color: 'red' }}
											id="order-name-helper-text"
										>
											{errors.orderQuantity}
										</FormHelperText>
									</Grid>
								) : (
									<Grid item md={6} lg={6} xs={12} className=" mb-3">
										<InputLabel
											htmlFor="inputCompanyName"
											className="text-start fs-14 text-black font-skmodernist"
										>
											Share Estimated Budget{' '}
											<span className="text-mandatory">*</span>
										</InputLabel>
										<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
											<OutlinedInput
												id="inputCompanyName"
												// label="Mobile Number"
												type="text"
												placeholder="Enter estimated budget"
												fullWidth
												margin="normal"
												onChange={handleBudget}
												disabled={singleRequirement?.status === 'fulfilled'}
												value={isNaN(budget) || budget === 0 ? '' : budget}
												onKeyDown={(evt) =>
													['e', 'E', '+', '-'].includes(evt.key) &&
													evt.preventDefault()
												}
											/>
										</FormControl>
										<FormHelperText
											sx={{ color: 'red' }}
											id="order-name-helper-text"
										>
											{errors.budget}
										</FormHelperText>
									</Grid>
								)}

								<Grid item md={6} lg={6} xs={12} className="">
									<InputLabel
										htmlFor="inputCompanyName"
										className="text-start fs-14 text-black font-skmodernist"
									>
										City
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										{/* <OutlinedInput
                        id="inputLocation"
                        // label="Mobile Number"
                        type="text"
                        placeholder="Enter your location"
                        autoFocus
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <img
                                    src={LocationLogo}
                                    alt="location-logo"
                                    className="location-logo"
                                />
                            </InputAdornment>
                        }
                        inputProps={{
                            maxLength: 200,
                        }}

                    />
                      */}

										<Autocomplete
											// disablePortal
											ref={searchLocInpRef}
											id={'SearchLocation'}
											options={mapOtions}
											fullWidth
											inputValue={location}
											disabled={singleRequirement?.status === 'fulfilled'}
											onInputChange={(e, value) => {
												setLocSearchVal(value);
												setRequestLocation(value !== null ? value : '');
												setSearchLoad(true);
											}}
											onChange={(e, value) => {
												console.log('Value_data on b2b requiremnet :', value);
												setRequestLocation(value !== null ? value : '');
												setSearchLoad(false);
											}}
											open={open}
											onOpen={openPopper}
											onClose={closePopper}
											getOptionLabel={getLocationString}
											defaultValue={getAddressDefaultVal()}
											isOptionEqualToValue={(option, value) => option === value}
											sx={{
												'.MuiAutocomplete-input': {
													paddingLeft: '14px !important',
												},
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="outlined"
													placeholder={
														location != '' ? location : 'Search City'
													}
													inputProps={{
														...params.inputProps,
														maxLength: 200,
													}}
												/>
											)}
										/>
									</FormControl>
								</Grid>
							</Grid>

							<Grid md={12} lg={12} xs={12} className="mb-3">
								<div className="d-flex justify-content-between">
									<InputLabel
										htmlFor="inputCompanyName"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Description
									</InputLabel>
									<Typography
										variant="body1"
										align="left"
										className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
										gutterBottom
									>
										{500 - count} Characters Left
									</Typography>
								</div>
								<FormControl
									fullWidth
									sx={{ my: 1, marginBottom: isTabletOrMobile ? 15 : 0 }}
									variant="outlined"
								>
									<TextareaAutosize
										id="inputComment"
										placeholder="Enter description"
										minRows={3} // You can adjust the number of rows as needed
										maxRows={3} // You can adjust the number of rows as needed
										onChange={onChangeDescription}
										disabled={singleRequirement?.status === 'fulfilled'}
										onPaste={onPasteDescription}
										inputprops={{
											maxLength: 500,
										}}
										value={description}
										autocomplete='off'
									/>
								</FormControl>
							</Grid>

							{isTabletOrMobile && (
								<Box sx={{ width: '100%' }}>
									<Box sx={[stickyButtonStyle]}>
										{(singleRequirement?.status === 'pending' ||
											singleRequirement?.status === 'hold' ||
											!singleRequirement?.status) && (
											<Button
												type="button"
												fullWidth
												variant="contained"
												className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
												sx={{
													borderRadius: '6px',
													textTransform: 'capitalize',
												}}
												onClick={() => {
													if (businessType == 1) {
														validateInput(
															'orderQuantity',
															moq === 0 ? '' : moq
														);
														validateInput('productName', name);
														validateInput('product_subCategory', category?.title);
														if (!name) {
															return;
														}

														if (!moq) {
															return;
														}
														if (!category) {
															return;
														}
													} else {
														validateInput('productName', name);
														validateInput('budget', budget === 0 ? '' : budget);
														validateInput('product_subCategory', category?.title);
														if (!name) {
															return;
														}
														if (!budget) {
															return;
														}
														if (!category) {
															return;
														}
													}
													try{
														var data = { "phone" : userData?.user?.mobile_number};
														sendMobForOTP(data, successOTPCallback, errorOTPCallback);
													} catch(err){
														errorOTPCallback();
													}
												}}
											>
												Verify
											</Button>
										)}

										{/* <Button
                                    // onClick={(e) => handleBackButton(e)}
                                    variant="text"
                                    type="button"
                                    fullWidth
                                    className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                                >
                                    Back
                                </Button> */}
									</Box>
								</Box>
							)}

							{isDesktopOrLaptop && (
								<>
									<Box className="d-flex justify-content-between align-items-center my-4">
										<Button
											// onClick={(e) => handleBackButton(e)}
											variant="text"
											type="button"
											className="btn-web-back"
											onClick={() => {
												resetRequirementData();
												history.push('/active-requirements');
											}}
										>
											Back
										</Button>
										{(singleRequirement?.status === 'pending' ||
											singleRequirement?.status === 'hold' ||
											!singleRequirement?.status) && (
											<Button
												type="button"
												variant="contained"
												className="btn-web-next"
												onClick={() => {
													if (businessType == 1) {
														validateInput(
															'orderQuantity',
															moq === 0 ? '' : moq
														);
														validateInput('productName', name);
														validateInput('product_subCategory', category?.title);
														if (!name) {
															return;
														}

														if (!moq) {
															return;
														}
														if (!category) {
															return;
														}
													} else {
														validateInput('productName', name);
														validateInput('budget', budget === 0 ? '' : budget);
														validateInput('product_subCategory', category?.title);
														if (!name) {
															return;
														}
														if (!budget) {
															return;
														}
														if (!category) {
															return;
														}
													}
													try{
														var data = { "phone" : userData?.user?.mobile_number};
														sendMobForOTP(data, successOTPCallback, errorOTPCallback);
													} catch(err){
														errorOTPCallback();
													}
												}}
											>
												Verify
											</Button>
										)}
									</Box>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

B2BRequirementProductCategoryForm.propTypes = {
	moq: number,
	setRequestMOQ: func,
	businessType: number,
	name: string,
	category: object,
	location: string,
	description: string,
	setRequestBType: func,
	setRequestName: func,
	setRequestDesc: func,
	setRequestLocation: func,
	flCategory: array,
	b2bCategory: array,
	setRequestCategory: func,
	getProductCategories: func,
	setBudget: func,
	budget: number,
	pageActionType: string,
	setPageActionType: func,
	singleRequirement: object,
	resetRequirementData: func,
	setProductSubCategory: func,
	setReqSubCategory: func,
	selectedLvl1Categories: array,
	sendMobForOTP: func,
	userData: object,
	loading: func,
	pushMessage: func,
	isLoading: bool
};

const mapStateToProps = (state) => {
	const {
		moq,
		businessType,
		name,
		categoryull,
		location,
		description,
		flCategory,
		b2bCategory,
		budget,
		pageActionType,
		singleRequirement,
		category_id,
		subCategoryId,
		category,
	} = state.b2bRequirement;
	const { selectedLvl1Categories } = state.offeredServices;
	const { userData } = state.signin;
	const { isLoading } = state.root;
	return {
		moq,
		businessType,
		name,
		categoryull,
		location,
		description,
		flCategory,
		b2bCategory,
		budget,
		pageActionType,
		singleRequirement,
		category_id,
		subCategoryId,
		selectedLvl1Categories,
		category,
		userData,
		isLoading
	};
};
export default connect(mapStateToProps, {
	setRequestMOQ,
	setRequestBType,
	setRequestName,
	setRequestDesc,
	setRequestLocation,
	setRequestCategory,
	getProductCategories,
	setBudget,
	setPageActionType,
	resetRequirementData,
	setProductSubCategory,
	setReqSubCategory,
	sendMobForOTP,
	loading,
	pushMessage
})(B2BRequirementProductCategoryForm);
