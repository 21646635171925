import React, { useEffect, useState } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	Backdrop,
	CircularProgress,
	FormHelperText,
	Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
// import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { loading } from '../../../store/actions/rootActions';
import { setFrlHourlyRate } from './redux/actions/fl-set-rate-action';
import useValidation from '../../../hooks/useValidation';
import { hasAnyErrorPresent } from '../../../utils/validation';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import { useMediaQuery } from 'react-responsive';

const theme = createTheme();
const categoryText = 'Set your Basic Rate';

const FLSetRateForm = ({
	onNextHandler,
	onBackHandler,
	updateUserData,
	setFrlHourlyRate,
	hourlyRate,
	userData,
	isLoading,
	pushMessage,
}) => {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	const [hrRate, setHrRate] = useState(userData?.registration?.hourly_rate);
	const { errors, validateInput } = useValidation();

	const submitRate = async () => {
		if (hrRate == null || hrRate.length === 0) {
			// alert("Please fill Hourly Rate.");
			validateInput('hrRate', '');
			return;
		}
		if (!hasAnyErrorPresent(errors)) {
			loading(true);
			let newUserData = {
				active_steps: 4,
				steps_completed: false,
				hourly_rate: hourlyRate,
			};
			console.log('==userData+newUserData', newUserData);
			await updateUserData(
				userData,
				newUserData,
				userData.registration.id,
				userData.accessToken,
				nextSuccessCallback,
				nextErrorCallback
			);
			onNextHandler();
		} else {
			validateInput('hrRate', hrRate);
		}
	};

	const onHourRateChange = (e) => {
		const input = e.target.value;
		console.log('Hourly rate : ', input);
		const regex = /^\s*-?(\d{1,7}(\.\d{1,2})?)\s*$/;
		if (input === '' || regex.test(input)) {
			setFrlHourlyRate(input);
			setHrRate(input);
			validateInput('hrRate', input);
		}
		// validateInput('hrRate', input);
	};

	/* eslint-disable no-unused-vars */
	function nextSuccessCallback(res) {
		//clear data
		loading(false);
		onNextHandler();
	}

	function nextErrorCallback(res) {
		pushMessage('Please try again, Error while saving data.');
	}
	/* eslint-enable no-unused-vars */
	useEffect(() => {
		setFrlHourlyRate(userData?.registration?.hourly_rate);
	}, []);

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 4,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						{/* <div className='my-3 ms-2'>
                            <ElynkerLogo width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" /> */}
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
							style={{ whiteSpace: 'pre-line' }}
						>
							{categoryText}
						</Typography>

						<Typography
							variant="body1"
							align="left"
							className="fs-14 text-grey fw-normal font-gotham"
							gutterBottom
						>
							Clients will see this rate on your profile and in search results
							once you publish your profile. You can adjust your rate every time
							you submit a proposal.
						</Typography>

						<form
							style={{ marginTop: '40px' }}
							onSubmit={(e) => {
								e.preventDefault();
								submitRate();
							}}
						>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<InputLabel
									htmlFor="inputCompanyName"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Hourly Base Rate <span className="text-mandatory">*</span>
								</InputLabel>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="inputCompanyName"
										// label="Mobile Number"
										type="number"
										placeholder="Enter hourly rate"
										autoFocus
										fullWidth
										margin="none"
										onChange={onHourRateChange}
										value={hrRate}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.hrRate}
								</FormHelperText>
							</Grid>

							<div style={{ marginTop: '150px' }}>
								{isTabletOrMobile && (
									<>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
											sx={{
												borderRadius: '6px',
												textTransform: 'capitalize',
											}}
											onClick={() => {}}
										>
											Next Step
										</Button>
										<Button
											variant="text"
											type="button"
											fullWidth
											className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
											onClick={onBackHandler}
										>
											Back
										</Button>
										<Button
											onClick={handleSkipButton}
											variant="text"
											type="button"
											fullWidth
											className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
										>
											Skip for Now
										</Button>
									</>
								)}

								{isDesktopOrLaptop && (
									<>
										<Box className="d-flex justify-content-between align-items-center my-4">
											<Button
												variant="text"
												type="button"
												className="btn-web-back"
												onClick={onBackHandler}
											>
												Back
											</Button>
											<Button
												onClick={handleSkipButton}
												variant="text"
												type="button"
												className="btn-web-back"
											>
												Skip for Now
											</Button>
											<Button
												type="submit"
												variant="contained"
												className="btn-web-next"
											>
												Next Step
											</Button>
										</Box>
									</>
								)}
							</div>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FLSetRateForm.propTypes = {
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	onNextHandler: PropTypes.func,
	onBackHandler: PropTypes.func,
	updateUserData: PropTypes.func,
	hourlyRate: PropTypes.string,
	setFrlHourlyRate: PropTypes.func,
	userData: PropTypes.object,
	pushMessage: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { hourlyRate } = state.flHourlyRate;

	// console.log("State :", state);
	return {
		isLoading,
		userData,
		hourlyRate,
	};
};
export default connect(mapStateToProps, {
	updateUserData,
	loading,
	setFrlHourlyRate,
	pushMessage,
})(FLSetRateForm);

