import { LOADING } from '../../../Login/redux/types';
import {
	SAVE_OTP,
	SAVE_SIGNIN_DATA,
	SET_POST_LOGIN_ROUTE_NAME,
	LOGOUT_USER,
	UPDATE_USER_DETAILS,
	RESET_OTP_DATA,
	UPDATE_SIGNIN_DATA,
	RESET_UPDATED_NEW_DATA,
	COPY_NEW_USER_DATA,
	UPDATE_REG_DATA,
} from '../types';

const INITIAL_STATE = {
	otp: '',
	loading: false,
	error: '',
	userData: null,
	userDataNew: null,
	regDetails: null,
	postLoginRouteName: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOADING:
			return {
				...state,
				loading: action.payload,
				error: '',
			};
		case SAVE_OTP:
			return { ...state, otp: action.payload };

		case SAVE_SIGNIN_DATA: {
			console.log('==SAVE_SIGNIN_DATA', action);
			const retData = { ...state, userData: action.payload };
			console.log('==SAVE_SIGNIN_DATA after save', retData);
			return { ...state, userData: action.payload };
		}
		case LOGOUT_USER: {
			localStorage.clear();
			return INITIAL_STATE;
		}
		case UPDATE_SIGNIN_DATA: {
			console.log('PAYLOAD', action.payload);

			if (state.userDataNew == null) {
				state.userDataNew = { ...state.userData };
			}
			if (state.userDataNew && action.payload) {
				const newState = { ...state };
				const newUser = { ...newState.userDataNew };
				const registration = { ...state?.userDataNew?.registration };
				newUser.registration = { ...registration, ...action.payload };
				newState.userDataNew = newUser;
				return newState;
			} else {
				const newState = { ...state };
				const newUser = { ...newState.userData };
				const registration = { ...newUser.registration };
				newUser.registration = { ...registration, ...action.payload };
				newState.userDataNew = newUser;
				return newState;
			}
		}
		case UPDATE_USER_DETAILS: {
			if (state.userDataNew == null) {
				state.userDataNew = { ...state.userData };
			}
			// Check if state.user.signin exists and action.payload contains registration data
			console.log('==UPDATE_SIGNIN_DATA');
			if (state.userDataNew && action.payload) {
				const newState = { ...state };
				const newUser = { ...newState.userDataNew }; // Access state.user instead of state.userData
				const user = { ...newUser.user }; // Access state.user.signin.registration
				// Update registration data within newUser
				newUser.user = { ...user, ...action.payload };
				// Update newState with newUser
				newState.userDataNew = newUser;
				console.log('==UPDATE_USER_DETAILS newState', newState);
				// Return the updated state
				return newState;
			} else {
				// Create copies of state to maintain immutability
				const newState = { ...state };
				const newUser = { ...newState.userData }; // Access state.user instead of state.userData
				const user = { ...newUser.user }; // Access state.user.signin.registration
				// Update registration data within newUser
				newUser.user = { ...user, ...action.payload };
				// Update newState with newUser
				newState.userDataNew = newUser;
				console.log('==UPDATE_USER_DETAILS newState', newState);
				// Return the updated state
				return newState;
			}
		}
		case SET_POST_LOGIN_ROUTE_NAME:
			return { ...state, postLoginRouteName: action.payload };
		case RESET_OTP_DATA:
			return INITIAL_STATE;
		case RESET_UPDATED_NEW_DATA:
			return { ...state, userDataNew: null };
		case COPY_NEW_USER_DATA: {
			// const newState = { ...state };
			// newState.userDataNew = { ...state.userData }
			return { ...state, userDataNew: state.userData };
		}
		case UPDATE_REG_DATA:
			return {
				...state,
				userData: { ...state.userData, registration: action.payload },
			};

		default:
			return state;
	}
};

