import { applyMiddleware, createStore } from 'redux';
import { thunk } from 'redux-thunk';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import storage from 'redux-persist/lib/storage';
// import reducers from './reducers';
import createRootReducer from './reducers';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import migrations from './migrations';

export const history = createBrowserHistory();

const persistConfig = {
	key: 'root',
	storage,
	version: parseInt(process.env.REACT_APP_REDUX_VERSION), // Sequential Versioning (previous version +1)
	migrate: createMigrate(migrations, { debug: false }),
	whitelist: [
		'root',
		'auth',
		'mobile',
		'signin',
		'b2bOnboardStep',
		'companyDetails',
		'offeredServices',
		'product',
		'mycatalog',
		'profileDetails',
		'INBusinessDoc',
		'AUBusinessDoc',
		'b2bVendorlisting',
		'b2bBusinessReducer',
		'flOnboardingStep',
		'flUploadResume',
		'flAddProfRole',
		'flAddBio',
		'flHourlyRate',
		'flProfileDetails',
		'flUploadService',
		'userProfile',
		'updatedProfile',
		'flService',
		'b2cData',
		'b2bVendorlisting',
		'b2bBusinessReducer',
		'b2bRequirement',
		'b2bRequirementList',
		'template',
		'b2bRequirement',
		'solrSearch',
		'homepageTools',
		'createJobReducer',
		'jobListReducer',
		'jobSearchList',
		'elynkerTools',
		'creditVas',
		'personalService',
		'jobDetail',
		'jobPostApply',
		'jobApplication',
		'Subscription',
	], // eg. ['demo', ...]
};

// const persistedReducer = persistReducer(persistConfig, reducers);
const persistedReducer = persistReducer(
	persistConfig,
	createRootReducer(history)
);

// BELOW FUNCTION FOR WHEN USING CONNECTED REACT ROUTER WITH REDUX, REDUX PERSIST, REDUX THUNK
export default () => {
	const store = createStore(
		persistedReducer, // root reducer with router state
		applyMiddleware(
			routerMiddleware(history), // for dispatching history actions
			thunk
		)
	);
	let persistor = persistStore(store);

	return { store, persistor };
};

// BELOW FUNCTION FOR WHEN USING REDUX WITH REDUX PERSIST, REDUX THUNK
// const configStore = () => {
//   let store = createStore(persistedReducer, applyMiddleware(ReduxThunk));
//   let persistor = persistStore(store);
//   return {store, persistor};
// };

// BELOW FUNCTION FOR WHEN USING REDUX WITH REDUX THUNK
// const configStore = () => {
//   return createStore(reducers, {}, applyMiddleware(ReduxThunk));
// };

// export default configStore;

