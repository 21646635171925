/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ReactComponent as ResumeIcon } from '../../assets/resume-icon.svg';
// import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loading } from '../../store/actions/rootActions';
import {
  postJobApply,
  resetJobApplyData,
  resetJobFormApplyData,
  setApplicantEmail,
  setApplicantMobileNo,
  setApplicantName,
} from './redux/actions/job-post-apply-action';
import useValidation from '../../hooks/useValidation';
import { compressFile } from '../../utils/compressFile';
import {
  SAVE_JOB_COVERLETTER_DATA,
  UPDATE_JOB_UPLOADED_RESUME,
} from './redux/types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import Responsive from '../../utils/responsive';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import LoadingButton from '@mui/lab/LoadingButton';
import { ConfirmationDialog } from '../../components/ActionPopup';

const theme = createTheme();
// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };

const JobPostApplyForm = ({
  postJobApply,
  loading,
  // isLoading,
  setApplicantName,
  name,
  phoneNumber,
  emailId,
  resume,
  coverLetter,
  setApplicantEmail,
  setApplicantMobileNo,
  resetJobApplyData,
  jobId,
  resetJobFormApplyData,
  userData,
  pushMessage,
}) => {
  const { errors, validateInput } = useValidation();
  const dispatch = useDispatch();
  const [openReupload, setOpenReupload] = useState(false);
  const handleOpenReupload = () => setOpenReupload(true);
  const handleCloseReupload = () => setOpenReupload(false);

  const [openReuploadCL, setOpenReuploadCL] = useState(false);
  const handleOpenReuploadCL = () => setOpenReuploadCL(true);
  const handleCloseReuploadCL = () => setOpenReuploadCL(false);
  const [open, setOpen] = useState(false);
  const [openOnSucc, setOpenOnSucc] = useState(false);
  const [openOnErr, setOpenOnErr] = useState(false);
  const [openCLDel, setOpenCLDel] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    // setOpen(false);
    // setresDelOpen(false);
    // setcertDelOpen(false);
    // setprojDelOpen(false);
    setOpen(false);
    setOpenCLDel(false);
    setOpenOnSucc(false);
    setOpenOnErr(false);
  };
  const resumeUploadRef = useRef(null);
  const clUploadRef = useRef(null);

  const onChangeName = (e) => {
    const input = e.target.value;
    const regex = /^(?!\s)[a-zA-Z0-9_\s-]*$/;
    // /^[a-zA-Z ]{0,10}$/
    // ^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$
    console.log('===onChangeProdDetail', input, errors);
    if (regex.test(input)) {
      setApplicantName(String(input));
      validateInput('jobApplicantName', input);
    }
  };

  console.log('jobID data on :', jobId);

  const handleEmailChange = (e) => {
    const { value, id } = e.target;
    console.log('====handleEmailChange', value, id);
    // validateInput('jobEmail', value);
    setApplicantEmail(value);
  };

  const handleBlurEmail = (e) => {
    const { value, id } = e.target;
    validateInput('jobEmail', value);
  };

  const handleMobileNoChange = (e) => {
    const { value, id } = e.target;
    const mobileNumberRegex = /^\d{0,10}$/;
    if (mobileNumberRegex.test(value)) {
      setApplicantMobileNo(value);
    }
    console.log('====handleMobileChange', value, id);
    validateInput('jobMobileNo', value);
  };

  const successCallback = (data) => {
    resetJobApplyData();
    setOpenOnSucc(true);
    setLoader(false);
    history.replace('/job-listing');
    pushMessage('Job Applied Successfully');
    console.log('You have successfully applied for job.');
  };
  const errorCallback = () => {
    setOpenOnErr(true);
    console.log('There is some issue while applying this job.');
  };

  const handleResumeUploadFile = async (e) => {
    const selectedFile = e.target.files[0];
    console.log('Selected File on Resume :', selectedFile);
    if (
      selectedFile?.type.includes('.document') ||
      selectedFile?.type.includes('pdf') ||
      selectedFile?.type.includes('msword')
    ) {
      if (selectedFile?.size > 2000000) {
        validateInput('flResumeUpload', selectedFile?.size);
        return;
      } else {
        validateInput('flResumeUpload', 0);
      }
      selectedFile.isLocal = true;
      console.log('=====compressedFile', selectedFile);
      dispatch({ type: UPDATE_JOB_UPLOADED_RESUME, payload: selectedFile });
      e.target.value = null;
    } else {
      pushMessage('Invalid File Type');
    }
  };

  const handleResumeUploadClick = () => {
    if (resume && resume.length >= 1) {
      // show alert message
      handleOpenReupload();
    } else {
      resumeUploadRef.current.click();
    }
  };
  const handleProjectUploadClick = () => {
    clUploadRef.current.click();
  };
  const handleProjectUploadFile = async (e) => {
    let selectedFile = e?.target?.files[0];
    // .doc,.docx,.pdf,.png,.jpeg,.jpg
    console.log('Selected-file cover letter: ', selectedFile);
    if (
      selectedFile?.type.includes('.document') ||
      selectedFile?.type.includes('pdf') ||
      selectedFile?.type.includes('msword') ||
      selectedFile?.type.includes('jpeg') ||
      selectedFile?.type.includes('jpg') ||
      selectedFile?.type.includes('png')
    ) {
      if (selectedFile?.size > 2000000) {
        validateInput('flWorkUpload', selectedFile?.size);
        return;
      } else {
        validateInput('flWorkUpload', 0);
      }
      selectedFile.isLocal = true;
      dispatch({ type: SAVE_JOB_COVERLETTER_DATA, payload: selectedFile });
      console.log('Resume File:', selectedFile);
      e.target.value = null;
    } else {
      pushMessage('Invalid File type');
    }
  };
  const handleJobApply = async (e) => {
    e.preventDefault();
    setLoader(true);
    // if(!resume){

    //     return;
    // }
    if (!name) {
      validateInput('jobApplicantName', '');
    }
    if (!phoneNumber) {
      validateInput('jobMobileNo', '');
    }
    if (!emailId) {
      validateInput('jobEmail', '');
    }
    if (!resume) {
      validateInput('flResumeUpload', 0);
    } else {
      // console.log('===handleSubmit Add catalog errors', errors);
      loading(true);
      // console.log('compressedFile Resume File:', compressedFile);

      await postJobApply(
        name,
        phoneNumber,
        emailId,
        resume,
        coverLetter,
        jobId,
        successCallback,
        errorCallback
      );
      loading(false);
    }
    e.target.value = null;
  };

  useEffect(() => {
    console.log('jobID', jobId);
    // return () => resetJobFormApplyData();
  }, []);

  const handleOk = () => {
    if (userData?.registration?.registration_type === 2) {
      history.push('/job-posted');
    } else {
      history.push('/job-listing');
    }
  };
  const handleConfirm = () => {
    dispatch({ type: 'UPDATE_JOB_UPLOADED_RESUME', payload: null });
  };
  const handleFlConfirm = () => {
    validateInput('flWorkUpload', 0); // Example function, replace with actual
    dispatch({ type: 'SAVE_JOB_COVERLETTER_DATA', payload: null });
  };
  //   const handleAdditionalAction = () => {
  //     resumeUploadRef.current.click();
  //   };

  const handleAdditionalConfirm = () => {
    // Any additional actions on confirmation can be placed here
    dispatch({ type: 'UPDATE_RESUME' }); // Example action, replace with actual
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <ConfirmationDialog
          open={openOnSucc}
          handleClose={handleClose}
          title="Elynker"
          content="Job applied successfully!!"
          onConfirm={handleOk}
        />

        <ConfirmationDialog
          open={openOnErr}
          handleClose={handleClose}
          title="Elynker"
          content="Error during applying Job, Please try again."
        />

        <ConfirmationDialog
          open={open}
          handleClose={handleClose}
          title="Elynker"
          content="Are you sure you want to delete the Resume?"
          onConfirm={handleConfirm}
          confirmText="Yes"
          cancelText="No"
        />
        <ConfirmationDialog
          open={openCLDel}
          handleClose={handleClose}
          title="Elynker"
          content="Are you sure you want to delete the Cover Letter?"
          onConfirm={handleFlConfirm}
        />
        <ConfirmationDialog
          open={openReupload}
          handleClose={handleCloseReupload}
          title="Elynker"
          content="Are you sure you want to replace the Existing Resume?"
          confirmText="Yes"
          cancelText="No"
          additionalAction
          onConfirm={handleAdditionalConfirm}
        />
        <ConfirmationDialog
          open={openReupload}
          handleClose={handleCloseReupload}
          title="Elynker"
          content="Are you sure you want to replace the Existing Cover Letter?"
          confirmText="Yes"
          cancelText="No"
          onConfirm={handleConfirm}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  history.push('/job-listing');
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-20  mb-2 fw-bold font-skmodernist"
              gutterBottom
              style={{ whiteSpace: 'pre-line' }}
            >
              Apply Now
            </Typography>

            <form
              style={{ marginTop: '20px' }}
              onSubmit={(e) => handleJobApply(e)}
            >
              <Grid container rowSpacing={0} columnSpacing={10} className="">
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Name <span className="text-danger">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter name"
                      // required
                      fullWidth
                      margin="none"
                      inputProps={{
                        maxLength: 50,
                      }}
                      value={name}
                      onChange={onChangeName}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors.jobApplicantName}
                  </FormHelperText>
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Phone Number <span className="text-danger">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter phone no."
                      // required
                      fullWidth
                      margin="none"
                      value={phoneNumber}
                      onChange={handleMobileNoChange}
                      onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      inputProps={{
                        maxLength: 100,
                      }}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors?.jobMobileNo}
                  </FormHelperText>
                </Grid>
                <Grid item md={6} lg={6} xs={12}>
                  <InputLabel
                    htmlFor="inputCIN"
                    className="text-start fs-14 text-black font-skmodernist"
                  >
                    Email ID <span className="text-danger">*</span>
                  </InputLabel>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="inputCIN"
                      // label="Mobile Number"
                      type="text"
                      placeholder="Enter email id"
                      // required
                      inputProps={{ maxLength: 50 }}
                      fullWidth
                      margin="none"
                      value={emailId}
                      onChange={handleEmailChange}
                      onBlur={handleBlurEmail}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
                    {errors?.jobEmail}
                  </FormHelperText>
                </Grid>
              </Grid>

              <Grid container rowSpacing={0} columnSpacing={10} className="">
                <Grid item md={6} lg={6} xs={12}>
                  <div className="mb-3">
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={resumeUploadRef}
                      onChange={handleResumeUploadFile}
                      id="file"
                      accept=".doc,.docx,.pdf"
                    />
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                      onClick={handleResumeUploadClick}
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      <img
                        src={AddBtnIcon}
                        alt="add-btn-icon"
                        className="user-logo"
                      />
                      <Typography className="ms-2 text-primary-1">
                        Resume Upload <span className="text-danger">*</span>
                      </Typography>
                    </Button>
                    <Typography class="text-muted fs-12">
                      (PDF or DOCX files only, maximum size: 2 MB)
                    </Typography>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors?.flResumeUpload}
                    </FormHelperText>
                  </div>

                  {resume && (
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="d-flex align-items-center">
                        <ResumeIcon />
                        <div>
                          <Typography
                            variant="body1"
                            align="left"
                            className="fs-14 text-black fw-normal mb-0 ms-3 font-skmodernist"
                            gutterBottom
                          >
                            {resume?.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            className="fs-13 text-grey fw-normal mb-0 ms-3 font-gotham"
                            gutterBottom
                          >
                            {''}
                          </Typography>
                        </div>
                      </div>
                      <div className="d-flex">
                        {/* <IconButton
                                    className="cat-edit-icon"
                                >
                                    <DownloadIcon />
                                </IconButton> */}
                        <IconButton
                          className="cat-delete-icon"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </Grid>

                <Grid item md={6} lg={6} xs={12}>
                  <div className="mb-3">
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={clUploadRef}
                      onChange={handleProjectUploadFile}
                      id="file"
                      accept=".doc,.docx,.pdf,.png,.jpeg,.jpg"
                    />
                    <Button
                      type="button"
                      fullWidth
                      variant="outlined"
                      className="add-button mt-2 fs-15 font-skmodernist fw-bold"
                      onClick={handleProjectUploadClick}
                      sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                    >
                      <img
                        src={AddBtnIcon}
                        alt="add-btn-icon"
                        className="user-logo"
                      />
                      <Typography className="ms-2 text-primary-1">
                        Cover Letter Upload
                      </Typography>
                    </Button>
                    <Typography class="text-muted fs-12">
                      (PDF or DOCX or JPEG files only, maximum size: 2 MB)
                    </Typography>
                    <FormHelperText
                      sx={{ color: 'red' }}
                      id="email-helper-text"
                    >
                      {errors?.flWorkUpload}
                    </FormHelperText>
                  </div>

                  {coverLetter && (
                    <div className="d-flex justify-content-between align-items-center my-3">
                      <div className="d-flex align-items-center">
                        <ResumeIcon />
                        <div>
                          <Typography
                            variant="body1"
                            align="left"
                            className="fs-14 text-black fw-normal mb-0 ms-3 font-skmodernist"
                            gutterBottom
                          >
                            {coverLetter?.name}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="left"
                            className="fs-13 text-grey fw-normal mb-0 ms-3 font-gotham"
                            gutterBottom
                          >
                            {''}
                          </Typography>
                        </div>
                      </div>
                      <div className="d-flex">
                        {/* <IconButton
                                    className="cat-edit-icon"
                                >
                                    <DownloadIcon />
                                </IconButton> */}
                        <IconButton
                          className="cat-delete-icon"
                          onClick={() => {
                            setOpenCLDel(true);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>

              {Responsive(
                <>
                  <LoadingButton
                    loading={loader}
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="my-4 apply-btn fs-15 font-skmodernist fw-bold"
                    sx={{
                      borderRadius: '6px',
                      textTransform: 'capitalize',
                      color: 'white',
                    }}
                  >
                    Apply Now
                  </LoadingButton>
                </>,
                <>
                  <div className="d-flex justify-content-end">
                    <LoadingButton
                      type="submit"
                      loading={loader}
                      variant="contained"
                      className="my-4 apply-btn fs-15 font-skmodernist fw-bold"
                      sx={{
                        borderRadius: '6px',
                        textTransform: 'capitalize',
                        color: 'white',
                      }}
                    >
                      Apply Now
                    </LoadingButton>
                  </div>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

JobPostApplyForm.propTypes = {
  isLoading: PropTypes.bool,
  loading: PropTypes.func,
  postJobApply: PropTypes.func,
  setApplicantName: PropTypes.func,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  emailId: PropTypes.string,
  resume: PropTypes.object,
  coverLetter: PropTypes.object,
  setApplicantEmail: PropTypes.func,
  setApplicantMobileNo: PropTypes.func,
  resetJobApplyData: PropTypes.func,
  jobId: PropTypes.number,
  resetJobFormApplyData: PropTypes.func,
  userData: PropTypes.object,
  pushMessage: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.root;
  const { userData } = state.signin;
  const { name, phoneNumber, emailId, resume, coverLetter, jobId } =
    state.jobPostApply;

  return {
    isLoading,
    name,
    phoneNumber,
    emailId,
    resume,
    coverLetter,
    jobId,
    userData,
  };
};

export default connect(mapStateToProps, {
  postJobApply,
  loading,
  setApplicantName,
  setApplicantEmail,
  setApplicantMobileNo,
  resetJobApplyData,
  resetJobFormApplyData,
  pushMessage,
})(JobPostApplyForm);
