/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import '../../common/fontsize.css';
// import './complete-profile.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  IconButton,
  Box,
  ListItemText,
  ListItem,
  List,
  InputAdornment,
  Button,
  Modal,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import CallLogo from '../../assets/mobile-icon.svg';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as ProfileIcon } from '../../assets/user-black-icon.svg';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import CustomStepper from '../../components/CustomStepper/CustomStepper';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import { ReactComponent as MenuDesktopIcon } from '../../assets/menu-dektop-icon.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { useMediaQuery } from 'react-responsive';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  postMemberContacted,
  getVendorDetails,
  resetAllVendorData,
} from '../B2BCategory/redux/actions/b2b-category-action';
import { ConfirmationDialog } from '../../components/ActionPopup';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import {
  resetSearchData,
  resetSearchVendors,
  saveSearchText,
  searchVendors,
  getTredningProductsCategory,
  postTredningProductsCategory,
  getTredningJobCategory,
  postTredningJobCategory,
} from './redux/actions/search-action';
import moment from 'moment';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
  CATEGORY_LOGO_BASE_URL,
  COMPANY_LOGO_BASE_URL,
  COVER_IMAGE_BASE_URL,
} from '../../utils/APIs/api';
import CardContent from '@mui/material/CardContent';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import Rating from '@mui/material/Rating';
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { DIALOG_NOT_LOGIN_MSG, DIALOG_TITLE, ModalStyle } from '../../utils/constants';
import { saveJobSearchText } from '../JobListing/redux/actions/job-listing-action';
import { saveJobData } from '../JobPostDetail/redux/actions/job-post-details-action';
import { setJobID } from '../JobPostApply/redux/actions/job-post-apply-action';
import { setSelectedVCategory } from '../FLFreelance/redux/actions/fl-business-action';

const theme = createTheme();

// const handleBTypeChange = (event) => {
//     setBusinessType(event.target.value);
//   };
// const customBlueColor = '#416AD4';

const options = [
  { label1: 'Graphic Designer', label2: '[Job requirement]' },
  { label1: 'Granite Slabs', label2: '[Products and services]' },
  { label1: 'Plastic Granules', label2: '[Products and services]' },
  { label1: 'Grapes', label2: '[Products and services]' },
];

const SearchForm = ({
  searchVendors,
  searchVendor,
  userData,
  pushMessage,
  postMemberContacted,
  getVendorDetails,
  resetSearchVendors,
  saveJobSearchText,
  getTredningProductsCategory,
  tredningProductCategories,
  setSelectedVCategory,
  resetAllVendorData,
  getTredningJobCategory,
  tredningJobCategories,
  // saveJobData,
  // setJobID,
  //   postTredningProductsCategory,
}) => {
  // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)', });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const history = useHistory();
  const [searches, setSearches] = useState([
    'Tea',
    'Coffee',
    'Designers',
    'Developers',
    'New Plastic Granules',
  ]); // Example initial searches
  const [searchOptions, setSearchOptions] = useState([]);

  const handleRemoveSearch = (index) => {
    setSearches((prevSearches) => prevSearches.filter((_, i) => i !== index));
  };

  React.useEffect(() => {
    getTredningJobCategory();
    getTredningProductsCategory();
  }, [getTredningJobCategory, getTredningProductsCategory]);
  const [vendorSearchVal, setVendorSearchVal] = useState('');
  const [open, setOpen] = useState(false);
  // Slider

  const sliderRef = useRef(null);
  // const scrollAmount = 100;

  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);

  const handleCallNow = (event, phoneNumber, countryCode, id) => {
    event.stopPropagation(); // Prevents the click of card
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.location.href = `tel:${countryCode}${phoneNumber}`;
      } else {
        pushMessage('Phone number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };

  const handleWhatsapp = (event, phoneNumber, countryCode, id) => {
    event.stopPropagation();
    if (userData?.registration?.id !== undefined) {
      if (
        phoneNumber !== undefined &&
        phoneNumber !== null &&
        phoneNumber !== ''
      ) {
        postMemberContacted(id, phoneNumber);
        window.open(
          `https://api.whatsapp.com/send?phone=${countryCode}${phoneNumber}`,
          '_blank'
        );
      } else {
        pushMessage('WhatsApp number not found.');
      }
    } else {
      // show dialog msg and navigate to login screen
      handleOpenCallModal();
    }
  };

  //  useEffect(()=>{

  //   return () => resetSearchData();
  // },[]);

  // useEffect(() => {
  //   var newData = [];
  //   const vpList = searchVendor?.map((item) => {
  //     let vp = item?.vendor_products?.map((p) => {
  //       let splicedata = p.split('::')[0];
  //       // return {"label": splicedata, "value": splicedata};
  //       return splicedata;
  //     });
  //     return vp;
  //     // newData.concate(vp);
  //     // return obj;
  //   });
  //   // let data = vpList?.flat(1) || [];
  //   console.log('vpList : ', vpList);
  //   var flat = [];

  //   for (let i = 0; i < vpList.length; i++) {
  //     console.log('data at i: ', vpList[i]);
  //     flat = flat.concat(vpList[i]);
  //   }

  //   console.log('flat list is : ', flat);

  //   function isempty(x) {
  //     if (x !== '') return true;
  //   }
  //   var resData = flat.filter(isempty);
  //   var res = resData.filter(function (element) {
  //     return element !== undefined;
  //   });
  //   console.log('Res : ', res);

  //   if (res !== undefined && res !== null) {
  //     setSearchOptions(res);
  //   } else {
  //     setSearchOptions([]);
  //   }
  // }, [searchVendor]);

  useEffect(() => {
    const vCardList = searchVendor?.map((item) => {
      item['card_type'] = 'VendorCard';
      return item;
    });
    console.log('res : ', vCardList);
    if (vCardList !== undefined && vCardList?.length > 0) {
      setSearchOptions(vCardList);
    } else {
      console.log('called empty search options');
      setSearchOptions([]);
    }

    return () => {};
  }, [searchVendor]);

  const goToPreviousPath = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const handleVendorSearchInput = (e) => {
    if (!open) {
      setOpen(true);
    }
    setVendorSearchVal(e.target.value);
    searchVendors(e.target.value);
  };
  const handleClick = () => {
    setOpen(!open);
  };
  const handleLogin = () => {
    history.push('/login');
  };

  const handleCancel = () => {
    // Define a fallback route or action if necessary
    // Example: history.push('/home');
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <ConfirmationDialog
          open={openCallModal}
          handleClose={handleCloseCallModal}
          title={DIALOG_TITLE}
          content={DIALOG_NOT_LOGIN_MSG}
          confirmText="Yes"
          cancelText="No"
          onConfirm={handleLogin}
          onCancel={handleCancel}
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  setSearchOptions([]);
                  resetSearchVendors();
                  history.goBack();
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mt-1"
              role="presentation"
            />

            {/* Include Custom Stepper here * /}
                        //Stepper Labels -> Details - Product Category - Verify (if radio selected is product)
                        //Stepper Labels -> Details - Service Category - Verify (if radio selected is service)
                        {/* <CustomStepper  /> */}

            <form style={{ marginTop: '20px' }}>
              {/* <Autocomplete
                                options={options}
                                getOptionLabel={(option) => option.label1}
                                renderOption={(props, option) => (
                                    <div {...props} style={{ display: "block", borderBottom: "1px solid #E3E3E3", justifyContent: "flex-start" }}>
                                        <Typography className="fs-15 text-grey font-gotham text-start">{option.label1}</Typography>
                                        <Typography className="fs-11 text-grey font-gotham text-start">{option.label2}</Typography>
                                    </div>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='outlined'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <SearchIcon color="action" style={{ marginRight: 8 }} />
                                            ),
                                        }}
                                    />
                                )}
                            /> */}
              {/* <Autocomplete
                id="free-solo-demo"
                // getOptionLabel={(option) => option.label}
                // getOptionValue={(option) => option.value}
                // options={vendors !== null ? onVendorProdFilter() : []}
                freeSolo
                options={searchOptions}
                inputValue={searchText}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <SearchIcon color="action" style={{ marginRight: 8 }} />
                      ),
                    }}
                  />
                )}
                filterOptions={(x) => x}
                onChange={(e, value) => {
                  console.log('value of e', e);
                  console.log('value of value :', value);
                  saveSearchText(value);
                  searchVendors(value);
                }}
                onInputChange={(e, v, r) => {
                  console.log('onInput change : ', v);
                  console.log('onInput event change : ', e.target.value);
                  saveSearchText(v);
                  searchVendors(v); //e.target.value
                }}
              /> */}
              <div>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                  <OutlinedInput
                    id="subCategoryInput"
                    type="text"
                    placeholder="Search here..."
                    // required
                    // disabled={categoriesTreeFilter.length === 0}
                    fullWidth
                    margin="none"
                    value={vendorSearchVal}
                    onChange={handleVendorSearchInput}
                    onClick={handleClick}
                    sx={{
                      backgroundColor: 'white',
                    }}
                    // onFocus={handleClick}
                    startAdornment={
                      <SearchIcon style={{ margin: '8px', width: '30px' }} />
                    }
                  />
                  {open && (
                    <div
                      style={{
                        position: 'absolute',
                        boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                        top: '50px',
                        background: 'white',
                        zIndex: 1,
                        borderRadius: 4,
                        width: '100%',
                      }}
                    >
                      <List>
                        {searchVendor &&
                          searchVendor?.map((item, index) => {
                            console.log('searchVendor item', item);
                            var date = new Date(1546108200 * 1000);
                            console.log(date.toUTCString());
                            let last_update = moment(
                              item?.last_login
                            )?.fromNow();
                            if ('salary_offered' in item) {
                              // Jobs
                              return (
                                <ListItem className="b2b-list-item" key={index}>
                                  <Card
                                    className="card"
                                    onClick={() => {
                                      saveJobSearchText(item?.title);
                                      history.push('/job-listing');
                                    }}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <CardMedia
                                        className="category-media"
                                        image={
                                          item?.image_path
                                            ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                            : require('../../assets/elynker_default_image.jpg')
                                        }
                                        sx={{
                                          padding: '1em 1em 0 1em',
                                          objectFit: 'contain',
                                          height: '50px !important',
                                          width: '50px !important',
                                        }}
                                      />
                                      <CardContent className="content p-0">
                                        <div className="d-flex justify-content-between align-items-start w-100">
                                          <Typography
                                            variant="h5"
                                            className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                            component="div"
                                          >
                                            Job: {item?.title}
                                          </Typography>
                                          <div
                                            onClick={() => {
                                              saveJobSearchText(item?.title);
                                              postTredningJobCategory(item.id);
                                              history.push('/job-listing');
                                            }}
                                          >
                                            <RightArrowIcon />
                                          </div>
                                        </div>

                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-grey mb-1 font-gotham"
                                        >
                                          {item?.business_description || ''}
                                        </Typography>

                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-secondary mb-1 font-gotham"
                                        >
                                          {/* Owned by {item?.name} */}
                                          City : {item?.job_location}
                                        </Typography>
                                        {/* <Box>
                                    <Rating
                                      name="read-only"
                                      value={item?.rating}
                                      size="small"
                                      precision={0.5}
                                      readOnly
                                    />
                                  </Box> */}
                                      </CardContent>
                                    </div>
                                  </Card>
                                </ListItem>
                              );
                            } else {
                              // Vendors
                              return (
                                <ListItem className="b2b-list-item" key={index}>
                                  <Card
                                    className="card"
                                    onClick={() => {
                                      if (item?.registration_type[0] == 2) {
                                        console.log('renderOption clicked b2b');
                                        getVendorDetails(item.id);
                                        setSearchOptions([]);
                                        resetSearchVendors();
                                        postTredningJobCategory(item.id);
                                        history.push(`/b2b-detail/${item.id}`);
                                      } else {
                                        //registration_type == 2
                                        console.log('renderOption clicked FL');
                                        getVendorDetails(item.id);
                                        setSearchOptions([]);
                                        resetSearchVendors();
                                        postTredningProductsCategory(item.id);
                                        history.push(
                                          `/freelancer-detail/${item.id}`
                                        );
                                      }
                                    }}
                                  >
                                    <div className="d-flex justify-content-between">
                                      <CardMedia
                                        className="category-media"
                                        image={
                                          item?.image_path
                                            ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                            : require('../../assets/elynker_default_image.jpg')
                                        }
                                        sx={{
                                          padding: '1em 1em 0 1em',
                                          objectFit: 'contain',
                                          height: '50px !important',
                                          width: '50px !important',
                                        }}
                                      />
                                      <CardContent className="content p-0">
                                        <div className="d-flex justify-content-between align-items-start w-100">
                                          <Typography
                                            variant="h5"
                                            className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                            component="div"
                                          >
                                            {item?.company_name
                                              ? item?.company_name
                                              : item?.name}
                                          </Typography>
                                          <div
                                            onClick={() => {
                                              postTredningProductsCategory(
                                                item.id
                                              );
                                              if (
                                                item?.registration_type[0] == 2
                                              ) {
                                                console.log(
                                                  'renderOption clicked b2b'
                                                );
                                                getVendorDetails(item.id);
                                                setSearchOptions([]);
                                                resetSearchVendors();
                                                postTredningJobCategory(
                                                  item.id
                                                );
                                                history.push(
                                                  `/b2b-detail/${item.id}`
                                                );
                                              } else {
                                                //registration_type == 2
                                                console.log(
                                                  'renderOption clicked FL'
                                                );
                                                getVendorDetails(item.id);
                                                setSearchOptions([]);
                                                resetSearchVendors();
                                                postTredningProductsCategory(
                                                  item.id
                                                );
                                                history.push(
                                                  `/freelancer-detail/${item.id}`
                                                );
                                              }
                                            }}
                                          >
                                            <RightArrowIcon />
                                          </div>
                                        </div>

                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-grey mb-1 font-gotham"
                                        >
                                          {item?.business_description || ''}
                                        </Typography>

                                        <Typography
                                          variant="body2"
                                          className="fs-13 text-secondary mb-1 font-gotham"
                                        >
                                          {/* Owned by {item?.name} */}
                                          City : {item?.city}
                                        </Typography>
                                        <Box>
                                          <Rating
                                            name="read-only"
                                            value={item?.rating}
                                            size="small"
                                            precision={0.1}
                                            readOnly
                                          />
                                        </Box>
                                      </CardContent>
                                    </div>
                                  </Card>
                                </ListItem>
                              );
                            }
                          })}
                      </List>
                      {/* {searchOptions?.length > 0 &&
											searchOptions?.map((option, vnIdx) => {
												return (
													<Box
                          style={{
                           marginRight:'10px',
                           marginLeft: '10px'
                          }}
														key={vnIdx}
														onClick={() => {
															if (option?.registration_type[0] == 2) {
																console.log('renderOption clicked b2b');
                                getVendorDetails(option.id);
                                setSearchOptions([]);
                                resetSearchVendors();
																history.push('/b2b-detail');
															} else {
																//registration_type == 2
																console.log('renderOption clicked FL');
																getVendorDetails(option.id);
                                setSearchOptions([]);
                                resetSearchVendors();
																history.push('/freelancer-detail');
															}
														}}
													>
														<div>
															{option?.registration_type[0] == 2
																? option?.company_name
																: option?.name}
														</div>
														<div>{option?.city ? option.city : ''}</div>
														<br />
													</Box>
												);
											})} */}
                    </div>
                  )}
                </FormControl>
              </div>

              {/* <Typography
                variant="h4"
                align="start"
                alignItems="center"
                className="fs-20 mt-3 fw-bold font-skmodernist"
                gutterBottom
              >
                Recent Searches
              </Typography> */}

              {/* <List dense>
                {searches.map((search, index) => (
                  <ListItem
                    key={index}
                    className="d-flex justify-content-between"
                    sx={{
                      padding: '4px 15px 6px 5px',
                      borderBottom: '1px solid #E3E3E3',
                    }}
                  >
                    <ListItemText
                      primary={search}
                      className="fs-14 text-darkgrey font-gotham"
                    />
                    <IconButton
                      edge="end"
                      className="p-0"
                      onClick={() => handleRemoveSearch(index)}
                    >
                      <ClearIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </ListItem>
                ))}
              </List> */}

              <Box className="mt-4">
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                >
                  Trending Products
                </Typography>
                <div className="elynker-services-slider mb-4" ref={sliderRef}>
                  {tredningProductCategories &&
                    tredningProductCategories.slice(0, 4).map((prodData) => {
                      return (
                        <div
                          className="images-container"
                          key={prodData?.id}
                          onClick={() => {
                            if (
                              prodData.category.category_type === 'Business'
                            ) {
                              resetAllVendorData();
                              setSelectedVCategory(prodData.category);
                              history.push('/b2b-category');
                            } else {
                              resetAllVendorData();
                              setSelectedVCategory(prodData.category);
                              history.push('/fl-freelance-category');
                            }
                          }}
                        >
                          <img
                            className="image"
                            alt="sliderImage"
                            src={
                              CATEGORY_LOGO_BASE_URL +
                              prodData.category.banner_image
                            }
                          />
                          <Typography className="image-text">
                            {prodData?.category.title}
                          </Typography>
                        </div>
                      );
                    })}
                </div>
              </Box>

              <Box className="mt-4">
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-20 text-black mb-3 fw-bold font-skmodernist"
                >
                  Trending Job Requirements
                </Typography>
                <div className="elynker-services-slider mb-4" ref={sliderRef}>
                  {tredningJobCategories &&
                    tredningJobCategories.slice(0, 4).map((jobData) => {
                      return (
                        <div
                          className="images-container"
                          key={jobData.id}
                          onClick={() => {
                            history.replace('/job-listing', {
                              from: '/search',
                              data: jobData?.category,
                            });
                          }}
                        >
                          <img
                            className="image"
                            alt="sliderImage"
                            src={
                              CATEGORY_LOGO_BASE_URL +
                              jobData.category.banner_image
                            }
                            // src={'https://picsum.photos/200'}
                          />
                          <Typography className="image-text">
                            {jobData?.category.title}
                          </Typography>
                        </div>
                      );
                    })}
                </div>
              </Box>


              {isTabletOrMobile && (
                <>
                  {/* <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                                >
                                    Next
                                </Button> */}

                  {/* <Button
                                    // onClick={(e) => handleBackButton(e)}
                                    variant="text"
                                    type="button"
                                    fullWidth
                                    className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
                                >
                                    Back
                                </Button> */}
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

SearchForm.propTypes = {
  userData: PropTypes.object,
  searchVendor: PropTypes.array,
  getVendorList: PropTypes.func,
  loading: PropTypes.func,
  isLoading: PropTypes.bool,
  enquiry: PropTypes.array,
  pushMessage: PropTypes.func,
  searchVendors: PropTypes.func,
  postMemberContacted: PropTypes.func,
  getVendorDetails: PropTypes.func,
  searchText: PropTypes.string,
  tredningProductCategories: PropTypes.any,
  resetSearchData: PropTypes.func,
  saveSearchText: PropTypes.func,
  resetSearchVendors: PropTypes.func,
  saveJobSearchText: PropTypes.func,
  getTredningProductsCategory: PropTypes.func,
  postTredningProductsCategory: PropTypes.func,
  getTredningJobCategory: PropTypes.func,
  tredningJobCategories: PropTypes.any,
  saveJobData: PropTypes.func,
  setJobID: PropTypes.func,
  setSelectedVCategory: PropTypes.func,
  resetAllVendorData: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { enquiry } = state.b2bVendorlisting;
  const { isLoading } = state.root;
  const {
    searchVendor,
    searchText,
    tredningProductCategories,
    tredningJobCategories,
  } = state.solrSearch;
  console.log('tredningProductCategories', tredningProductCategories);
  return {
    userData,
    searchVendor,
    tredningProductCategories,
    tredningJobCategories,
    isLoading,
    enquiry,
    searchText,
  };
};

export default connect(mapStateToProps, {
  searchVendors,
  getVendorDetails,
  pushMessage,
  postMemberContacted,
  resetSearchData,
  saveSearchText,
  resetSearchVendors,
  saveJobSearchText,
  getTredningProductsCategory,
  postTredningProductsCategory,
  getTredningJobCategory,
  postTredningJobCategory,
  saveJobData,
  setJobID,
  setSelectedVCategory,
  resetAllVendorData
})(SearchForm);
