import {
  RESET_PROFILE_DETAILS_DATA,
  SET_COMPANY_LOGO,
  SET_COMPANY_LOGO_UPLOAD_PROGRESS,
  SET_COVER_IMG_UPLOAD_PROGRESS,
  SET_LOCATION_COORDS,
  SET_PROFILE_DETAILS_ADDRESS_1,
  SET_PROFILE_DETAILS_ADDRESS_2,
  SET_PROFILE_DETAILS_CITY,
  SET_PROFILE_DETAILS_STATE,
  SET_SELECTED_COVER_IMAGE,
  SET_SELECTED_WHATSAPP,
  SET_SOCIAL_DATA_OBJECT,
  SET_SOCIAL_LINKS,
  SET_SOCIAL_NAME,
  SET_ALL_CITY,
  SET_ALL_STATE,
  SET_SOCIAL_MEDIA_MASTER,
  FILTERED_CITY_DATA,
  SET_PROFILE_DETAILS_AREANAME,
  SET_PROFILE_DETAILS_PINCODE,
  SET_DELETED_SOCIAL_LINK,
} from '../types';
import {
  getSocialLinksByUser,
  postSocialLinksByUser,
  putCompanyLogo,
  putCoverPhoto,
  getCityMasterApi,
  getStateMasterApi,
  putSocialLinksBySocialId,
  getSocialMediaMaster,
  deleteSocialLinksBySocialId
} from '../../../../utils/APIs/endpoints';
// import {  } from '../../../../utils/APIs/endpoints/authApi';

export const setCompanyLogo = (imageData) =>
  function (dispatch) {
    dispatch({ type: SET_COMPANY_LOGO, payload: imageData });
  };

export const setAddress1 = (address1) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_ADDRESS_1, payload: address1 });
  };

export const setAddress2 = (address2) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_ADDRESS_2, payload: address2 });
  };

export const setCity = (city) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_CITY, payload: city });
  };

export const setState = (state) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_STATE, payload: state });
  };

export const setLocationCoords = (locationCoord) =>
  function (dispatch) {
    dispatch({
      type: SET_LOCATION_COORDS,
      payload: locationCoord,
    });
  };

export const setSocialName = (socialName) =>
  function (dispatch) {
    dispatch({
      type: SET_SOCIAL_NAME,
      payload: socialName,
    });
  };

export const setSocialLinks = (socialLinks) =>
  function (dispatch) {
    dispatch({
      type: SET_SOCIAL_LINKS,
      payload: socialLinks,
    });
  };

export const uploadCompanyLogoImage = (
  imageData,
  userId,
  pushMessage,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    // if (imageData[0]?.name) {
    //   console.log(
    //     '[profile-details-form-action] ABORTING... No Image Selected uploadCompanyLogoImage'
    //   );
    //   errroCallback();
    //   return;
    // }
    try {
      const formdata = new FormData();
      formdata.append('images', imageData[0]);
      const response = await putCompanyLogo(
        userId,
        formdata,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Cover Photo Upload Progress - ', percentCompleted);
          dispatch({
            type: SET_COMPANY_LOGO_UPLOAD_PROGRESS,
            payload: percentCompleted,
          });
        }
      );
      if (response.status === 200) {
        console.log(
          '[profile-details-form-action] uploadCompanyLogoImage Success',
          response.data
        );
        successCallback();
      } else if (response.status === 413) {
        pushMessage('Image too Large');
      }
    } catch (error) {
      console.log(
        '[profile-details-form-action] uploadCompanyLogoImage Error',
        error
      );
      errroCallback();
    }
  };

export const uploadCoverImage = (
  imageData,
  userId,
  pushMessage,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const formdata = new FormData();
      formdata.append('images', imageData[0]);
      const response = await putCoverPhoto(
        userId,
        formdata,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log('Cover Photo Upload Progress - ', percentCompleted);
          dispatch({
            type: SET_COVER_IMG_UPLOAD_PROGRESS,
            payload: percentCompleted,
          });
        }
      );
      if (response.status === 200) {
        console.log(
          '[profile-details-form-action] uploadCoverImage Success',
          response.data
        );
        successCallback();
      } else if (response.status === 413) {
        pushMessage('Image too Large');
      }
    } catch (error) {
      console.log(
        '[profile-details-form-action] uploadCoverImage Error',
        error
      );
      errroCallback();
    }
  };

export const setSelectedCoverImage = (selectedImage) =>
  function (dispatch) {
    dispatch({ type: SET_SELECTED_COVER_IMAGE, payload: selectedImage });
  };
export const setWhatsappNumber = (Whatsapp) =>
  function (dispatch) {
    console.log('===setWhatsappNumber', Whatsapp);
    dispatch({ type: SET_SELECTED_WHATSAPP, payload: Whatsapp });
  };

export const setSocialData = (socialData) =>
  function (dispatch) {
    dispatch({ type: SET_SOCIAL_DATA_OBJECT, payload: socialData });
  };

export const getSocialLinks = (userId) =>
  async function (dispatch) {
    try {
      const response = await getSocialLinksByUser(userId);
      if (response.status === 200) {
        dispatch({
          type: SET_SOCIAL_LINKS,
          payload: response.data?.data || [],
        });
      }
    } catch (error) {
      console.log('[profile-details-form-action] getSocialLinks Error', error);
    }
  };

export const postSocialLinks = (userId, accessToken, socialLinks) =>
  async function (dispatch) {
    try {
      // console.log(
      //   '[profile-details-form-action] postSocialLinks called',
      //   socialLinks
      // );
      const response = await postSocialLinksByUser(userId, socialLinks);
      if (response.status === 200 || response.status === 201) {
        console.log(
          '[profile-details-form-action] postSocialLinks response',
          response.data
        );
        dispatch({
          type: SET_SOCIAL_LINKS,
          payload: response.data?.data,
        });
      }
    } catch (error) {
      console.log('[profile-details-form-action] postSocialLinks Error', error);
    }
  };

export const putSocialLinks = (socialid, socialLink) =>
  async function () {
    try {
      const response = await putSocialLinksBySocialId(socialid, socialLink);
      if (response.status === 200) {
        console.log(
          '[profile-details-form-action] postSocialLinks response',
          response.data
        );
        // dispatch({
        //   type: SET_SOCIAL_LINKS,
        //   payload: response.data,
        // });
      }
    } catch (error) {
      console.log('[profile-details-form-action] postSocialLinks Error', error);
    }
  };
  export const deleteSocialLinksData = (socialid) =>
  async function () {
    try {
      const response = await deleteSocialLinksBySocialId(socialid);
      if (response.status === 200) {
        console.log(
          '[profile-details-form-action] deleteSocialLinks response',
          response.data
        );
        // dispatch({
        //   type: SET_SOCIAL_LINKS,
        //   payload: response.data,
        // });
      }
    } catch (error) {
      console.log('[profile-details-form-action] postSocialLinks Error', error);
    }
  };
export const resetProfileData = () =>
  function (dispatch) {
    dispatch({ type: RESET_PROFILE_DETAILS_DATA });
  };

export const getCityMaster = () => async (dispatch) => {
  try {
    const allCity = await getCityMasterApi();
    dispatch({ type: FILTERED_CITY_DATA, payload: allCity });
    dispatch({ type: SET_ALL_CITY, payload: allCity });
  } catch (error) {
    dispatch({ type: SET_ALL_CITY, payload: [] });
  }
};

export const getStateMaster = () => async (dispatch) => {
  try {
    const allState = await getStateMasterApi();
    dispatch({ type: SET_ALL_STATE, payload: allState });
  } catch (error) {
    dispatch({ type: SET_ALL_STATE, payload: [] });
  }
};

export const setFilterCity = (city) => async (dispatch) => {
  dispatch({ type: FILTERED_CITY_DATA, payload: city });
};

export const getSocialMediaMasterData = () =>
  async function (dispatch) {
    try {
      const response = await getSocialMediaMaster();
      if (response.status === 200) {
        if (response.data?.data?.length > 0) {
          dispatch({
            type: SET_SOCIAL_MEDIA_MASTER,
            payload: response.data?.data,
          });
        }
      }
    } catch (error) {
      console.log(
        '[profile-details-form-action] getSocialMediaMaster Error',
        error
      );
    }
  };

export const setAreaName = (areaname) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_AREANAME, payload: areaname });
  };
export const setPincode = (pincode) =>
  function (dispatch) {
    dispatch({ type: SET_PROFILE_DETAILS_PINCODE, payload: pincode });
  };

export const delSocialLinkArr = (id) => 
  function (dispatch) {
    dispatch({ type: SET_DELETED_SOCIAL_LINK, payload: id})
  };