import { SET_SELECTED_VCATEGORY } from "../types";


/* eslint-disable no-unused-vars */
export const setSelectedVCategory = (cate) => async (dispatch) => {
    try {
        dispatch({ type: SET_SELECTED_VCATEGORY, payload: cate });
    } catch (error) {
      dispatch({ type: SET_SELECTED_VCATEGORY, payload: null });
    }
  };