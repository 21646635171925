/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
	Container,
	Grid,
	Typography,
	Divider,
	Box,
	Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/system';
import { connect } from 'react-redux';
import { getProductCategories } from '../OfferedServices/redux/actions/offered-services-form-action';
import { func, number, object, oneOfType, bool, array } from 'prop-types';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { loading } from '../../store/actions/rootActions';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { setSelectedVCategory } from './redux/actions/b2b-business-action';
import { CATEGORY_LOGO_BASE_URL } from '../../utils/APIs/api';
import ImageProfile from '../../components/CustomImageView/ImgProfile';

import e_business_bg from '../../assets/elynker_app_icon.svg';
import elynker_default_image from '../../assets/elynker_default_image.jpg';
import {
	BUSINESS,
	PRIV_POL_SLUG,
	PRIVACY_POLICY,
	T_AND_C_SLUG,
	TERMS,
	CANCELLATION_REFUND,
	CONTACT_US,
	SHIPPING_DELIVERY,
	SHIP_DEL_SLUG,
	CAN_REF_SLUG,
	CON_US_SLUG,
} from '../../utils/constants';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import {
	resetAllVendorData,
	resetVendorFilterCity,
} from '../B2BCategory/redux/actions/b2b-category-action';
import {
	resetTemplateData,
	setTemplateRender,
} from '../Templates/redux/action.js/template-action';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const theme = createTheme();

export function filterAllCategory(list) {
	let roots = list?.filter((item) => {
		return item.parent_id === null;
	});

	console.log('Roots list', roots);
	return roots;
}

const B2BBusinessForm = ({
	getProductCategories,
	b2bLvl1Categories,
	setSelectedVCategory,
	resetVendorFilterCity,
	resetAllVendorData,
	// loading,
	// isLoading,
	// pushMessage,
	// userData,
	setTemplateRender,
	resetTemplateData,
}) => {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	let history = useHistory();
	const [categoriesTree, setCategoriesTree] = useState([]);

	function handleClick(event) {
		event.preventDefault();
		console.info('You clicked a breadcrumb.');
	}

	function handleClickHome(event) {
		event.preventDefault();
		history.replace('/homepage');
	}

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			className="fs-14 text-gray font-gotham"
			href="/"
			onClick={handleClickHome}
		>
			Elynker
		</Link>,
		// <Link underline="hover" key="2" className="fs-14 text-gray font-gotham">
		// 	Business
		// </Link>,
		<Link
			underline="none"
			key="3"
			className="fs-14 text-breadcrumbs-active font-gotham"
		>
			All Categories
		</Link>,
	];

	const StyledImageContainer = styled('div')({
		border: '1px solid #E3E3E3',
		padding: '10px',
		textAlign: 'left',
		borderRadius: '8px',
		height: '100%',
	});

	// const filterCategoriesInDeep = (searchText) => {
	//     const filterCategorySearch = filterData(categoriesTreeFilter, searchText);
	//     if (filterCategorySearch.length > 0) {
	//         return checkChildrenAndLoop(filterCategorySearch, searchText);
	//     } else {
	//         return filterCategorySearch;
	//     }
	// };

	// const checkChildrenAndLoop = (childCategories, searchText) => {
	// let newFilteredCategories = [];
	// for (const category of childCategories) {
	//     if (category.children.length > 0) {
	//     const filterLvl2 = filterData(category.children, searchText);
	//     const filterLvl2Child = filterLvl2.findIndex(
	//         (child) => child.children.length > 0
	//     );
	//     if (filterLvl2Child !== -1) {
	//         const childArr = checkChildrenAndLoop(filterLvl2, searchText);
	//         newFilteredCategories.push({
	//         ...category,
	//         children: childArr,
	//         });
	//     } else {
	//         newFilteredCategories.push({ ...category, children: filterLvl2 });
	//     }
	//     } else {
	//     newFilteredCategories.push(category);
	//     }
	// }
	// return newFilteredCategories;
	// };

	const openInNewTab = (url) => {
		window.open(url, '_blank', 'noreferrer');
	};

	const handleShippingDelivery = () => {
		setTemplateRender(SHIPPING_DELIVERY);
		history.push(`/${SHIP_DEL_SLUG}`);
		resetTemplateData();
	};

	const handleTerms = () => {
		setTemplateRender(TERMS);
		history.push(`/${T_AND_C_SLUG}`);
		resetTemplateData();
	};

	const handlePrivacy = () => {
		setTemplateRender(PRIVACY_POLICY);
		history.push(`/${PRIV_POL_SLUG}`);
		resetTemplateData();
	};

	const handleCancellationRefund = () => {
		setTemplateRender(CANCELLATION_REFUND);
		history.push(`/${CAN_REF_SLUG}`);
		resetTemplateData();
	};

	const handleContactUs = () => {
		setTemplateRender(CONTACT_US);
		history.push(`/${CON_US_SLUG}`);
		resetTemplateData();
	};

	useEffect(() => {
		getProductCategories(BUSINESS);
	}, []);
	useEffect(() => {
		if (b2bLvl1Categories?.length > 0) {
			const categoryList = filterAllCategory(b2bLvl1Categories);
			setCategoriesTree(categoryList);
			console.log('LT : ', categoryList);
		}
	}, [b2bLvl1Categories]);

	const sortedArray = categoriesTree?.sort((a,b)=> a.rank - b.rank)

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="xl">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<div className="my-3 ms-2 d-flex align-items-center">
							<ArrowBackIosIcon
								onClick={() => {
									history.goBack();
								}}
							/>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									alignItems: 'center',
								}}
							>
								<ElynkerLogoComponent>
									<ElynkerLogo
										className="ms-3"
										width={'100px'}
										height={'30px'}
									/>
								</ElynkerLogoComponent>
								<div>
									{/* <IconButton
										className="p-0"
										size="medium"
										aria-label="user icon"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="inherit"
										onClick={() => {
											//   setShowSearch((val) => !val);
											history.push('/search');
										}}
									>
										<SearchIcon />
									</IconButton> */}
								</div>
							</div>
						</div>
						<Divider
							component="div"
							className="main-divider mt-2 mb-3"
							role="presentation"
						/>

						<form style={{ marginTop: '0px' }}>
							{isDesktopOrLaptop && (
								<>
									<Card className="business-testimonial-web-card mb-3">
										<CardContent className="business-testimonial-web-content">
											<div className="business-testimonial-web-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className=" text-white mb-2 fw-bold font-skmodernist business-testimonial-web-text"
													component="div"
												>
													Expand your reach by
													connecting with potential partners
													and suppliers.
												</Typography>
											</div>
										</CardContent>
									</Card>

									{/* <div className="d-flex justify-content-between align-items-center mt-5 mb-4"> */}

									{/* <div className=" b2b-business">
											<Stack
												direction="row"
												spacing={1.5}
												useFlexGap
												flexWrap="wrap"
											>
												<Chip label="25 Mn+ Business" />
												<Chip label="30 Cr Business Done" />
												<Chip label="4.5 Star" />
											</Stack>
										</div> */}
									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>

									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Select From Below Services
									</Typography>
									{/* </div> */}
								</>
							)}

							{isTabletOrMobile && (
								<>
									<Card className="business-testimonial-card mb-3">
										<CardContent className="business-testimonial-content">
											<div className="business-testimonial-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className=" text-white mb-2 fw-bold font-skmodernist business-testimonial-text"
													component="div"
												>
													Expand your reach by
													connecting with potential partners
													and suppliers.
												</Typography>
											</div>
										</CardContent>
									</Card>

									{/* <div className="my-4 b2b-business">
                                    <Stack direction="row" spacing={1.5} useFlexGap flexWrap="wrap">
                                        <Chip label="25 Mn+ Business" />
                                        <Chip label="30 Cr Business Done" />
                                        <Chip label="4.5 Star" />
                                    </Stack>
                                </div> */}
									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>
									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Select From Below Services
									</Typography>
								</>
							)}

							<Grid container spacing={2} className="mb-4">
								{sortedArray?.map((item) => (
									<Grid item xs={6} md={3} key={item.img}>
										<StyledImageContainer
											onClick={() => {
												resetVendorFilterCity();
												resetAllVendorData();
												setSelectedVCategory(item);
												history.push('/b2b-category');
											}}
										>
											{/* <img
                                                srcSet={`${CATEGORY_LOGO_BASE_URL}${item.banner_image}`}
                                                src={ `${CATEGORY_LOGO_BASE_URL}${item.banner_image}`}
                                                alt={item?.title}
                                                loading="lazy"
                                                className="b2b-services-img"
                                                style={{ marginBottom: '8px', width: '100%', height: '130px', borderRadius: '8px' }}
                                            /> */}

											<ImageProfile
												path={CATEGORY_LOGO_BASE_URL + item.banner_image}
												placeholder={e_business_bg}
												loadError={elynker_default_image}
												style={{
													marginBottom: '8px',
													width: '100%',
													height: '130px',
													borderRadius: '8px',
													objectFit: 'contain',
												}}
												loading="lazy"
											/>
											<Typography className="fs-14 text-darkgrey b2b-servies text-center">
												{item.title}
											</Typography>
										</StyledImageContainer>
									</Grid>
								))}
							</Grid>
						</form>
						{isDesktopOrLaptop && (
							<>
								<div className="homepage-footer">
									<Grid
										container
										className="homepage-footer-content mb-4"
										sx={{
											width: '70%',
											margin: '0 auto',
											justifyContent: 'space-evenly',
										}}
									>
										<Box sx={{ marginTop: '4px', marginBottom: '10px' }}>
											<Typography
												variant="body1"
												className="fs-20 text-white font-skmodernist fw-bold mb-4"
											>
												Get In Touch
											</Typography>

											<Link
												className="support-link"
												// href="https://support.elynker.com"
												underline="always"
												color="#FFF"
											>
												{'support@elynker.com'}
											</Link>
											<Typography className="fs-14 font-gotham text-light-white mt-2">
												+91 9820596537
											</Typography>
											<Typography className="fs-14 font-gotham text-light-white">
												{'Remember us with ease -  '}
												<Link
													component="button"
													underline="none"
													variant="body2"
													color="#FFF"
													onClick={() => {
														window.location.href = 'tel:+919820596537';
													}}
												>
													9820 LYNKER
												</Link>
											</Typography>
										</Box>

										<Box className="usefullink-container">
											<Typography
												variant="body1"
												className="fs-20 text-white font-skmodernist fw-bold mb-4"
											>
												Useful Links
											</Typography>

											<Box className="link-container"></Box>
											<Box>
												<Typography
													className="fs-14 font-gotham text-light-white mt-3 mb-1"
													onClick={handleShippingDelivery}
													sx={{ cursor: 'pointer' }}
												>
													Shipping Delivery
												</Typography>
											</Box>
											<Box>
												<Typography
													className="fs-14 font-gotham text-light-white mt-3 mb-1"
													onClick={handlePrivacy}
													sx={{ cursor: 'pointer' }}
												>
													Privacy Policy
												</Typography>
											</Box>
											<Box>
												<Typography
													className="fs-14 font-gotham text-light-white mt-3 mb-1"
													onClick={handleTerms}
													sx={{ cursor: 'pointer' }}
												>
													Terms of Use
												</Typography>
											</Box>
											<Box>
												<Typography
													className="fs-14 font-gotham text-light-white mt-3 mb-1"
													onClick={handleCancellationRefund}
													sx={{ cursor: 'pointer' }}
												>
													Cancellation and Refund
												</Typography>
											</Box>
											<Box>
												<Typography
													className="fs-14 font-gotham text-light-white mt-3 mb-1"
													onClick={handleContactUs}
													sx={{ cursor: 'pointer' }}
												>
													Contact Us
												</Typography>
											</Box>
										</Box>
									</Grid>

									<Box className="d-flex justify-content-around">
										<Typography
											className="fs-12 font-gotham text-light-white mb-4"
											sx={{ marginTop: '30px', cursor: 'pointer' }}
											onClick={() => openInNewTab('https://www.elgraces.com/')}
										>
											© El Graces Aggregators Private Limited, 2024
										</Typography>
									</Box>
								</div>
							</>
						)}
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

B2BBusinessForm.propTypes = {
	getProductCategories: func,
	isLoading: bool,
	loading: func,
	userData: oneOfType([object, number]),
	pushMessage: func,
	b2bLvl1Categories: array,
	setSelectedVCategory: func,
	resetVendorFilterCity: func,
	resetAllVendorData: func,
	setTemplateRender: func,
	resetTemplateData: func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { b2bLvl1Categories } = state.offeredServices;
	return {
		userData,
		isLoading,
		b2bLvl1Categories,
	};
};
export default connect(mapStateToProps, {
	getProductCategories,
	loading,
	pushMessage,
	setSelectedVCategory,
	resetVendorFilterCity,
	resetAllVendorData,
	setTemplateRender,
	resetTemplateData,
})(B2BBusinessForm);


