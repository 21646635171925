import {
  GET_PRODUCT_CATEGORIES,
  SET_BUSINESS_TYPE,
  SET_LVL1_CATEGORIES,
  SET_SELECTED_LVL1_CATEGORIES,
  GET_ALL_PRODUCTS,
  SET_WEB_ADD_PRODUCT_TYPE,
  RESET_OFFERED_SERVICE_DATA,
  SET_FLANCER_LVL1_CATEGORIES,
  SET_BULK_PRODUCT,
} from '../types';
import {
  bulkUploadProduct,
  getAllCatalogProducts,
  getAllProdCategories,
  getProdCategories,
} from '../../../../utils/APIs/endpoints';
import { BUSINESS, FREELANCER } from '../../../../utils/constants';
import { setLoading } from '../../../../store/actions/rootActions';

export const getProductCategories = (type = BUSINESS) =>
  async function (dispatch) {
    try {
      const response = await getProdCategories(type);
      if (response.status === 200) {
        const b2bCategories = await response.data.data.filter(
          (category) =>
            category.category_type.toLowerCase() == type.toLowerCase()
        );
        const lvl1Categories = b2bCategories.filter(
          (category) => category.parent_id === null
        );
        await dispatch({
          type: GET_PRODUCT_CATEGORIES,
          payload: b2bCategories,
        });

        if (type === FREELANCER) {
          await dispatch({
            type: SET_FLANCER_LVL1_CATEGORIES,
            payload: lvl1Categories,
          });
        } else {
          await dispatch({
            type: SET_LVL1_CATEGORIES,
            payload: lvl1Categories,
          });
        }
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
    }
  };

export const setSelectedLvl1Categories = (selectedLvl1Categories) =>
  function (dispatch) {
    dispatch({
      type: SET_SELECTED_LVL1_CATEGORIES,
      payload: selectedLvl1Categories,
    });
  };

export const setBusinessType = (businessType) =>
  function (dispatch) {
    dispatch({ type: SET_BUSINESS_TYPE, payload: businessType });
  };

export const setWebAddProductType = (addProductType) =>
  function (dispatch) {
    dispatch({ type: SET_WEB_ADD_PRODUCT_TYPE, payload: addProductType });
  };
export const setBulkProduct = (product) =>
  function (dispatch) {
    dispatch({ type: SET_BULK_PRODUCT, payload: product });
  };

export const getAllProducts = (id) =>
  async function (dispatch) {
    try {
      const response = await getAllCatalogProducts(id);
      if (response.status === 200) {
        // console.log('Products ' + response.data);

        // dispatch data into state
        dispatch({ type: GET_ALL_PRODUCTS, payload: response.data });
      }
    } catch (error) {
      console.log('[offered-services-form-action] getAllProducts Error', error);
      dispatch({ type: GET_ALL_PRODUCTS, payload: [] });
    }
  };

export const resetOfferedServiceData = () =>
  function (dispatch) {
    dispatch({ type: RESET_OFFERED_SERVICE_DATA });
  };

export const getAllProductCategories = () =>
  async function (dispatch) {
    try {
      const response = await getAllProdCategories();
      if (response.status === 200) {
        const b2bCategories = await response?.data.data;
        console.log('====getAllProductCategories b2bCategories', b2bCategories);
        const lvl1Categories = b2bCategories.filter(
          (category) => category.parent_id === null
        );
        await dispatch({
          type: GET_PRODUCT_CATEGORIES,
          payload: b2bCategories,
        });
        await dispatch({
          type: SET_LVL1_CATEGORIES,
          payload: lvl1Categories,
        });
      }
    } catch (error) {
      console.log(
        '[offered-services-form-action] getProductCategories Error',
        error
      );
    }
  };

export const uploadBulkProduct = (formData, sucessCallBack, failureCallBack) =>
  async function (dispatch) {
    try {
      dispatch(setLoading(true));
      const response = await bulkUploadProduct(formData);
      if (response.status === 200) {
        const bulkProductData = await response?.data;
        sucessCallBack(bulkProductData?.message);
        console.log('====bulkProductData', bulkProductData.message);
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      failureCallBack(error.response.data.error);
      console.log(
        '[uploadBulkProduct-action]  Error',
        error.response.data.error
      );
    } finally {
      dispatch(setLoading(false));
    }
  };
