import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import { stickyButtonStyle } from '../../utils/sx-styles';
import { Container, Grid, Divider, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Card, CardContent, Backdrop, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import SubscriptionBanner from '../../assets/subscription-banner.png';
import { ReactComponent as CheckCircleOutline } from '../../assets/checkmark-circle-sharp.svg';
import { ReactComponent as CheckCircleGreenIcon } from '../../assets/check-outline-green-icon.svg';
import { ReactComponent as WhatsappBusinessIcon } from '../../assets/package-whatsapp-business-icon.svg';
import { ReactComponent as GoogleBusinessIcon } from '../../assets/package-google-business-icon.svg';
import { ReactComponent as SinglePageIcon } from '../../assets/package-single-page-icon.svg';
import { ReactComponent as InstaIcon } from '../../assets/package-instagram-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/package-facebook-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/package-linkedin-icon.svg';
// import BasicSubscription from '../../assets/basic-subscription-img.png';
// import EssentialSubscription from '../../assets/essential-subscription-img.png';
// import EstarterSubscription from '../../assets/estarter-subscription-img.png';
import LimitedOFferImg from '../../assets/limited-offer-img.png';
import { connect, useDispatch } from 'react-redux';
import DrawerForm from '../../components/DrawerForm';
import PropTypes from 'prop-types';

import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import useValidation from '../../hooks/useValidation';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { getEnquiryById, postEnquiry } from '../B2BCategory/redux/actions/b2b-category-action';
import { useEffect } from 'react';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { resetTemplateData, setTemplateRender } from '../Templates/redux/action.js/template-action';
import { B2C_ID, CALLBACK_TYPE_SUBSCRIPTION, DIALOG_NOT_LOGIN_MSG, DIALOG_SUB_REQ_ERR_MSG, DIALOG_SUB_REQ_SUCC_MSG, DIALOG_TITLE, SUBSCRIPTION_TERMS, T_AND_C_SUB_SLUG } from '../../utils/constants';
import { loading } from '../../store/actions/rootActions';

import { useMediaQuery } from 'react-responsive';
import { isMoreThan24HoursAgo } from '../../utils/helper-funtions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';


const theme = createTheme();
const SubscriptionnForm = ({
    userData,
    postEnquiry,
    pushMessage,
    enquiry,
    isLoading,
    setTemplateRender,
    resetTemplateData,
    getEnquiryById
}) => {
    let location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()
    const { user } = userData || {};
    const { mobile_number = '', name = '', email = '' } = user || {};
    const { validateInput, errors } = useValidation();
    const [openCallModal, setOpenCallModal] = useState(false);
    const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
    const handleOpenCallModal = () => setOpenCallModal(true);
    const handleCloseCallModal = () => setOpenCallModal(false);

    const [openSuccCallModal, setOpenSuccCallModal] = useState(false);
    const handleOpenSuccCallModal = () => setOpenSuccCallModal(true);
    const handleCloseSuccCallModal = () => setOpenSuccCallModal(false);

    const [openErrCallModal, setOpenErrCallModal] = useState(false);
    const handleOpenErrCallModal = () => setOpenErrCallModal(true);
    const handleCloseErrCallModal = () => setOpenErrCallModal(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    let userType;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const toggleCallBackDrawer = () => {
        userType = userData?.registration?.registration_type || 0;
        if (isMoreThan24HoursAgo(enquiry?.createdAt)) {
            if (!userData) {
                dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                });
                history.push('/login');
            } else {
                setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
            }
        } else if (enquiry && Object.keys(enquiry)?.length == 0) {
            if (!userData) {
                dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                });
                handleOpenCallModal();
                // history.push('/login');
            } else {
                if(userType !== 1){
                    setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
                }else {
                    dispatch({
                        type: SET_ENQUIRY,
                        payload: { isTrue: true, redirect_to: '/subscription' },
                    });
                    handleOpenCallModal();
                }
            }
        } else if(userData) {
            if(userData !== 1){
                if(!isMoreThan24HoursAgo(enquiry?.createdAt)){
                    pushMessage(
                        'We already received your enquiry. Admin will get back to you soon'
                    );
                } else{
                    setIsCallBackDrawerOpen(!isCallBackDrawerOpen);

                }
            } else{
                dispatch({
                    type: SET_ENQUIRY,
                    payload: { isTrue: true, redirect_to: '/subscription' },
                });
                handleOpenCallModal();
            }
        } else{
            pushMessage(
                'We already received your enquiry. Admin will get back to you soon'
            );
        }
    };

    useEffect(() => {
        const regId = userData?.registration.id;
        getEnquiryById(regId, CALLBACK_TYPE_SUBSCRIPTION);
        // if(enquiry)
        if (enquiry?.isTrue == true) {
            setIsCallBackDrawerOpen(true);
        }
    }, []);

    useEffect(() => {
        setIsCallBackDrawerOpen(false);
    }, []);

    const requestCallBackSubmit = async (formData) => {
        // const{email,name,mobile_number}=userData
        loading(true);
        const registrationId = userData?.registration?.id;
        if (registrationId) {
            const { mobileNumber, comment, email, name, ...rest } = formData;
            let sEmail = email ? email : "";
            let sname = name ? name : "";
            const details = {
                ...rest,
                name: sname,
                email:sEmail,
                comments: comment,
                type: CALLBACK_TYPE_SUBSCRIPTION,
                phone_number: mobileNumber,
                registrationId,
            };

            // const postResponse = await postEnquiry(registrationId, details);
            const postResponse = await postEnquiry(registrationId, details);
            console.log("enquiry response ", postResponse);
            loading(false);
            if(postResponse?.message === "Record Created"){
                handleCloseErrCallModal();
                handleOpenSuccCallModal();
            }else{
                handleCloseSuccCallModal();
                handleOpenErrCallModal();
            }
            // pushMessage(postResponse.message);
            dispatch({
                type: SET_ENQUIRY,
                payload: { isTrue: false, redirect_to: '/' },
            });
        }
        setIsCallBackDrawerOpen(false);
    };

    const handleSubTermsCondition = () => {
        setTemplateRender(SUBSCRIPTION_TERMS);
        history.push(`/${T_AND_C_SUB_SLUG}`);
        resetTemplateData();
    };

    return (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xl">
            {isLoading && (
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={isLoading}
                >
                <CircularProgress color="inherit" />
              </Backdrop>
              )}
                <DrawerForm
                    title="Request a Call Back"
                    isOpen={isCallBackDrawerOpen}
                    onClose={() => {
                        dispatch({
                            type: SET_ENQUIRY,
                            payload: { isTrue: false, redirect_to: '/' },
                        });
                        setIsCallBackDrawerOpen(false);
                    }}
                    mobile_number={mobile_number}
                    name={name}
                    email={email}
                    validateInput={validateInput}
                    onSubmit={requestCallBackSubmit}
                    errors={errors}
                    BuisnessType={false}
                />
                <Dialog
                    open={openCallModal}
                    onClose={handleCloseCallModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                {DIALOG_TITLE}
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseCallModal();
                                        // history.push('/login');
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        {userData?.registration?.registration_type !== B2C_ID
                            ? DIALOG_NOT_LOGIN_MSG
                            : 'Please Login as Seller to use this feature'
                        }
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button className="btn-cancel me-2" onClick={handleCloseCallModal}>
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                handleCloseCallModal();
                                if(userData){
                                    if(userData?.registration?.registration_type == B2C_ID){
                                        dispatch({
                                            type: SET_ENQUIRY,
                                            payload: { isTrue: true, redirect_to: '/subscription' },
                                        })
                                        history.push('/complete-profile')
                                    } else {
                                        toggleCallBackDrawer();
                                    }
                                } else{
                                    dispatch({
                                        type: SET_ENQUIRY,
                                        payload: { isTrue: true, redirect_to: '/subscription' },
                                    })
                                    history.push('/login')
                                }
                            }}
                            className="btn-save"
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openSuccCallModal}
                    onClose={handleCloseSuccCallModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                {DIALOG_TITLE}
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseSuccCallModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {DIALOG_SUB_REQ_SUCC_MSG}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button
                            onClick={() => {
                                handleCloseSuccCallModal();
                            }}
                            className="btn-save"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openErrCallModal}
                    onClose={handleCloseErrCallModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    maxWidth="sm"
                    fullWidth
                >
                    {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
                    <DialogTitle className="custom-border-header pb-0">
                        <Box className="position-relative d-flex justify-content-between">
                            <Typography
                                className="fs-22 fw-bold font-skmodernist"
                                id="alert-dialog-title"
                            >
                                {DIALOG_TITLE}
                            </Typography>
                            <Box>
                                <CloseIcon
                                    onClick={() => {
                                        handleCloseErrCallModal();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {DIALOG_SUB_REQ_ERR_MSG}
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions className="modal-footer">
                        <Button
                            onClick={() => {
                                handleCloseErrCallModal();
                            }}
                            className="btn-save"
                        >
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 'auto' }}
                >
                    <Grid item xs={12}>
                        {/* <div >
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        aria-label="menu"
                                        sx={{ mr: 2 }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <div style={{ flexGrow: 1 }}>
                                        <ElynkerLogoComponent>
                                        <ElynkerLogo width={'100px'} height={'30px'} />
                                        </ElynkerLogoComponent>
                                    </div>
                                    {
                                        <div>

                                            <IconButton
                                                size="medium"
                                                aria-label="user icon"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                color="inherit"
                                            >
                                                <SearchIcon />
                                            </IconButton>


                                        </div>
                                    }
                                </Toolbar>
                            </AppBar>
                        </div> */}
                        <div className="my-3 ms-2 d-flex align-items-center">
                            <ArrowBackIosIcon onClick={() => {
                                // history.goBack()
                                history.replace(location?.state?.from ?? '/homepage');
                            }} />
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                <ElynkerLogoComponent>
                                    <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
                                </ElynkerLogoComponent>

                            </div>
                        </div>
                        <Divider
                            component="div"
                            className="main-divider mt-2 mb-3"
                            role="presentation"
                        />

                        <form style={{ marginTop: '40px' }}>
                            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                                {/* <img src={SubscriptionBanner} style={{ width: '100%' }}></img> */}

                                <Card className="subscription-banner-web-card mb-3">
                                    <CardContent className="subscription-banner-web-content">
                                        <div className="subscription-banner-web-content-tint" />
                                        <div style={{ zIndex: 2, position: 'absolute', top: '35%', left: '10%' }}>
                                            <Typography
                                                variant="h5"
                                                className="subscription-banner-web-text"
                                                component="div"
                                            >
                                                Subscribe to elynker
                                            </Typography>

                                            <Typography className="subscription-banner-web-subtext">Plans Starting From INR 0</Typography>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Box>

                            

                            <Grid container spacing={4} justifyContent="space-between" className='mb-4'>
                                {/* Basic Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="package-box" sx={{ flexGrow: 1 }}>
                                        <Box className="inner-box position-relative">
                                            <Typography className='plan-type'>Basic</Typography>
                                            <Typography className='plan-info'>Our Basic Free Package</Typography>
                                            <div className='d-flex justify-content-end'>
                                                <CheckCircleOutline />
                                            </div>
                                        </Box>

                                        <Box className="benefits-services">
                                            <Typography className='header-title'>Benefits & Services</Typography>
                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Marketplace Listing</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Requirement posting</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Unlimited Connects</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Unlimited Inquiries</Typography>
                                                </li>

                                            </ul>
                                        </Box>
                                        <Divider className='divider' />
                                        <Box className="go-digital">
                                            <Typography className='header-title'>Go Digital</Typography>
                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Digital Business Card</Typography>
                                                </li>
                                            </ul>
                                        </Box>

                                        <Box className="note-box">
                                            <Typography className="note">
                                                <span className='fw-bold'>Please Note:</span> All users will avail of the basic package on completion of their registration and onboarding formalities. For any further queries contact elynker at support@elynker.com
                                            </Typography>
                                        </Box>

                                        <Typography className="caption">Conditions apply*</Typography>
                                    </Box>
                                </Grid>

                                {/* Estarter Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="package-box position-relative" sx={{ flexGrow: 1 }}>
                                        <Box className="inner-box">
                                            <Typography className='plan-type'>eStarter</Typography>
                                            <Typography className='plan-info'> <span className='strike-through'>₹ 6,500</span> <span className='now-text'>Now</span> FREE!!</Typography>
                                            <Typography>Come Experience elynker</Typography>
                                        </Box>

                                        <Box className="benefits-services">
                                            <Typography className='header-title'>Benefits & Services</Typography>

                                            <ul className='package-list'>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>All benefits from Basic</Typography>
                                                </li>
                                                <li>
                                                    <CheckCircleGreenIcon />
                                                    <Typography className='list-text'>Digital Catalog</Typography>
                                                </li>
                                            </ul>
                                        </Box>
                                        <Divider className='divider' />
                                        <Box className="profile-creation">
                                            <Typography className='header-title'>Profile Creation</Typography>
                                            <ul className='package-list'>
                                                <li>
                                                    <WhatsappBusinessIcon />
                                                    <Typography className='list-text'>WhatsApp Business Profile*</Typography>
                                                </li>
                                                <li>
                                                    <GoogleBusinessIcon />
                                                    <Typography className='list-text'>Google Business Profile*</Typography>
                                                </li>
                                                <li>
                                                    <SinglePageIcon />
                                                    <Typography className='list-text'>Single Pager Website*</Typography>
                                                </li>
                                            </ul>
                                        </Box>
                                        <Divider className='divider' />
                                        <Box className="social-media">
                                            <Typography className='header-title' style={{ marginBottom: '0px' }}>Social Media Profile Creation</Typography>
                                            <Typography className='header-subtitle'>(Profile creation with one template post*)</Typography>
                                            <ul className='package-list' >
                                                <li style={{ alignItems: 'center', gap: '2px', marginBottom: '10px' }}>
                                                    <InstaIcon />
                                                    <Typography className='list-text'>Instagram</Typography>
                                                </li>
                                                <li style={{ alignItems: 'center', gap: '2px', marginBottom: '10px' }}>
                                                    <FacebookIcon />
                                                    <Typography className='list-text'>Facebook</Typography>
                                                </li>

                                            </ul>
                                        </Box>
                                        <Typography className="caption">Conditions apply*</Typography>
                                        
                                        <img src={LimitedOFferImg} style={{ position: 'absolute', top: '-24px', right: '0px' }}></img>
                                    </Box>
                                </Grid>

                                {/* Essential Package */}
                                <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                                    <Box className="essential-package-box " sx={{ flexGrow: 1 }}>
                                        <Box className="package-box position-relative blur-content">
                                            <Box className="inner-box essential-inner-box">
                                                <Typography variant="h6">Essential</Typography>
                                                <Typography>Coming Soon!</Typography>
                                            </Box>

                                            <Box className="benefits-services">
                                                <Typography className='header-title'>Benefits & Services</Typography>

                                                <ul className='package-list'>
                                                    <li>
                                                        <CheckCircleGreenIcon />
                                                        <Typography className='list-text'>All services from e-Starter</Typography>
                                                    </li>
                                                    <li>
                                                        <CheckCircleGreenIcon />
                                                        <Typography className='list-text'>Dedicated Helpline</Typography>
                                                    </li>
                                                    <li>
                                                        <CheckCircleGreenIcon />
                                                        <Typography className='list-text'>Advisory Services</Typography>
                                                    </li>
                                                    <li>
                                                        <CheckCircleGreenIcon />
                                                        <Typography className='list-text'>Email Marketing*</Typography>
                                                    </li>
                                                    <li>
                                                        <CheckCircleGreenIcon />
                                                        <Typography className='list-text'>Digital Catalog (Upto 100 products/services)</Typography>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Divider className='divider' />
                                            <Box className="profile-creation">
                                                <Typography className='header-title'>Profile Creation</Typography>
                                                <ul className='package-list'>
                                                    <li>
                                                        <WhatsappBusinessIcon />
                                                        <Typography className='list-text'>WhatsApp Business Profile*</Typography>
                                                    </li>
                                                    <li>
                                                        <GoogleBusinessIcon />
                                                        <Typography className='list-text'>Google Business Profile*</Typography>
                                                    </li>
                                                    <li>
                                                        <SinglePageIcon />
                                                        <Typography className='list-text'>Single Pager Website*</Typography>
                                                    </li>
                                                </ul>
                                            </Box>
                                            <Divider className='divider' />
                                            <Box className="social-media mb-3">
                                                <Typography className='header-title' style={{ marginBottom: '0px' }}>Social Media Profile Creation</Typography>
                                                <Typography className='header-subtitle'>(Profile creation with 24 templatized posts annually)</Typography>
                                                <ul className='package-list' >
                                                    <li style={{ alignItems: 'center', gap: '2px', marginBottom: '10px' }}>
                                                        <InstaIcon />
                                                        <Typography className='list-text'>Instagram</Typography>
                                                    </li>
                                                    <li style={{ alignItems: 'center', gap: '2px', marginBottom: '10px' }}>
                                                        <FacebookIcon />
                                                        <Typography className='list-text'>Facebook</Typography>
                                                    </li>
                                                    <li style={{ alignItems: 'center', gap: '2px', marginBottom: '10px' }}>
                                                        <LinkedinIcon />
                                                        <Typography className='list-text'>LinkedIn</Typography>
                                                    </li>

                                                </ul>
                                            </Box>
                                            <Typography className="caption">*T&C Apply</Typography>
                                        </Box>
                                        <Box className="center-message">
                                            <Typography variant="h6" className="message-text">
                                                Your essential Package Launching Soon!!
                                            </Typography>
                                        </Box>
                                    </Box>


                                </Grid>

                            </Grid>



                            <Typography className='font-skmodernist fs-18 text-gray'
                                onClick={handleSubTermsCondition}
                            >
                                <span className='text-primary-2' style={{cursor: 'pointer'}}>*Click here</span> to view the Terms & Conditions
                            </Typography>
                            <div style={{ marginTop: !isTabletOrMobile ? '100px' : '100px' }}>
                                <Box className="text-center mb-4">
                                    <Box sx={[stickyButtonStyle, {boxShadow: '0 -4px 5px rgba(0, 0, 0, 0.2)'}]}>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                            sx={{ borderRadius: '6px', textTransform: 'capitalize', width: '200px' }}
                                            onClick={toggleCallBackDrawer}
                                        >
                                            Get started
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                            {/* <Grid container spacing={3} justifyContent="center">
                              
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        className='subscription-custom-card'

                                    >
                                        <CardContent className='card-content'>
                                            <Card className="inner-card">
                                                <Typography className='font-skmodernist fs-20 text-white'>
                                                    Basic
                                                </Typography>
                                                <CheckCircleOutline color="primary" />
                                            </Card>
                                            <Typography variant="body2" color="text.secondary">
                                                Our basic free package
                                            </Typography>
                                            <ul>
                                                {[
                                                    "Marketplace Listing",
                                                    "Requirement posting",
                                                    "Unlimited Connects",
                                                    "Unlimited Inquiries",
                                                    "Digital Business Card",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            borderColor: 'primary.main',
                                            boxShadow: 6,
                                            '&:hover': {
                                                boxShadow: 10,
                                            },
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container alignItems="center" justifyContent="space-between">
                                                <Typography variant="h6" component="div" color="primary">
                                                    eStarter
                                                </Typography>
                                            </Grid>
                                            <Typography variant="body2" color="text.secondary">
                                                <del>₹2,500</del> Now FREE!!<br />Come Experience elynker
                                            </Typography>
                                            <ul>
                                                {[
                                                    "All benefits from Basic",
                                                    "Digital Catalog",
                                                    "WhatsApp Business Profile",
                                                    "Google Business Profile",
                                                    "Single Pager Website",
                                                    "Social Media profile creation (Instagram, Facebook)",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                sx={{ mt: 2, backgroundColor: 'primary.main' }}
                                            >
                                                Get Now
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            p: 2,
                                            borderColor: 'grey.300',
                                            boxShadow: 1,
                                            opacity: 0.5,
                                            position: 'relative',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: 'primary.main',
                                                color: 'white',
                                                p: 1,
                                                borderRadius: 2,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Your essential Package Launching Soon!!
                                        </Typography>
                                        <CardContent>
                                            <Typography variant="h6" component="div" color="primary">
                                                essential
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Coming Soon!!
                                            </Typography>
                                            <ul>
                                                {[
                                                    "All services from eStarter",
                                                    "Dedicated Helpline",
                                                    "Advisory Services",
                                                    "Email Marketing",
                                                    "Social Media Management (Instagram, Facebook, LinkedIn)",
                                                ].map((benefit, index) => (
                                                    <li key={index}>
                                                        <Typography variant="body2">{benefit}</Typography>
                                                    </li>
                                                ))}
                                            </ul>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid> */}


                        </form>

                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};

SubscriptionnForm.propTypes = {
    userData: PropTypes.object,
    pushMessage: PropTypes.func,
    postEnquiry: PropTypes.func,
    enquiry: PropTypes.object,
    getEnquiryById: PropTypes.func,
    resetTemplateData: PropTypes.func,
    setTemplateRender: PropTypes.func,
    isLoading: PropTypes.bool
}

const mapStateToProps = (state) => {
    const { userData } = state.signin;
    const {
        enquiry,
    } = state.b2bVendorlisting;
    const { isLoading } = state.root;
    return {
        userData,
        enquiry,
        isLoading,
    }
}

export default connect(mapStateToProps, { postEnquiry, getEnquiryById, pushMessage, resetTemplateData, setTemplateRender })(SubscriptionnForm);
