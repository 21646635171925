/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  Avatar,
  CardMedia,
  Box,
  Stack,
  Modal,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  // InputLabel,
  // InputAdornment,
} from '@mui/material';
import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as BackArrow } from '../../assets/back-arrow.svg';
import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import Card from '@mui/material/Card';
// import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
// import CircularProgress from '@mui/material/CircularProgress';
import GradientCircularProgress from '../../components/ProgressCircle/ProgressCircle';
// import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { ReactComponent as ProfileViewsIcon } from '../../assets/profile-views-icon.svg';
import { ReactComponent as ProfileImageEditIcon } from '../../assets/profile-edit-icon.svg';
import { ReactComponent as StarRatedIcon } from '../../assets/star-rated-icon.svg';
// import { ReactComponent as ConnectionReqIcon } from '../../assets/connection-request-icon.svg';
// import { ReactComponent as SearchAppearanceIcon } from '../../assets/search-appearance-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import Drawer from '@mui/material/Drawer';
// import { ReactComponent as DeliveryIcon } from '../../assets/delivery-icon.svg';
// import { ReactComponent as RatingIcon } from '../../assets/rating-icon.svg';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loading } from '../../store/actions/rootActions';
import {
  COMPANY_LOGO_BASE_URL,
  COVER_IMAGE_BASE_URL,
  UPLOAD_BASE_URL,
} from '../../utils/APIs/api';
import {
  resetProfileData,
  uploadCompanyLogoImage,
  uploadCoverImage,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import {
  getTopLevelProductCategoriesByUser,
  getUpdatedRegistrationData,
} from './redux/actions/user-profile-actions';
import Resizer from 'react-image-file-resizer';
import {
  getCurrentProfileProgress,
  getFLCurrentProfileProgress,
} from '../../utils/percent';
import {
  getAllProducts,
  resetOfferedServiceData,
} from '../OfferedServices/redux/actions/offered-services-form-action';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { downloadCanvasImage } from '../../utils/downloadCanvasImage';
import { resetUserData } from '../Otp/redux/actions/submit-code-action';
import { resetB2BStepData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { resetComapanyData } from '../CompanyDetails/redux/actions/company-details-form-action';
import { resetAddCatalogData } from '../AddCatalog/redux/actions/add-catalog-action';
import { resetMyCatalogData } from '../MyCatalog/redux/actions/mycatalog-action';
import { resetMyServiceData } from '../MyServices/redux/actions/mycatalog-action';
import { RWebShare } from 'react-web-share';
import {
  postBuisnessCard,
  resetUpdateProfileeData,
} from '../UpdateProfile/store/actions/profileActions';
import { resetFLAddBioData } from '../FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { resetFLProfileData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { resetHourlyRate } from '../FreeLancer/FL-SetRate/redux/actions/fl-set-rate-action';
import { resetFLResumeData } from '../FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { resetFLUploadServiceData } from '../FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { resetFLUProfRoleData } from '../FreeLancer/FLAddProfessionalRole/redux/actions/fl-add-profession-action';
import { resetFLAddServiceData } from '../FreeLancer/FLAddService/redux/actions/add-service-action';
import { resetLoginData } from '../Login/redux/actions/submit-mobileno-action';
import { DeleteConfirmationPopup } from '../../components/ActionPopup';
import { resetCompleteProfileData } from '../CompleteProfile/redux/actions/start-connecting-action';
import { resetFLStepData } from '../FreeLancer/FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { resetRequirementList } from '../ActiveRequirements/redux/actions/active-requirement-action';
import ShareBusinessCardModal from './ShareBusinessCardModal';
import { DIALOG_TITLE, ModalStyle, SHARE_VENDOR_DETAIL_URL } from '../../utils/constants';
import {
  resetSubscription,
  getAllSubscriptionServices,
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
  setCurrentSubscriptionDetail,
  clearSubscription,
  getAllSubscriptionList,
} from '../Subscription/redux/action/susbcription-action';
import { ConfirmationDialog } from '../../components/ActionPopup';
import moment from 'moment';
import DefaultLogo from '../../components/StyledAvatar/styledAvatar';
import Responsive from '../../utils/responsive';
import ProfileCardWrapper from './components/ProfileCardWrapper';
import { COPY_NEW_USER_DATA } from '../Otp/redux/types';
import { shareCardContent } from '../../utils/helper-funtions';
import { LoadingButton } from '@mui/lab';

const returnColorForStatus = (status) => {
  switch (status) {
    case 'pending':
      return '#F3B900';
    case 'approved':
      return '#00B257';
    case 'rejected':
      return '#f05d23';
  }
};
function toSentenceCase(str) {
  if (!str) return str; // Return if the string is empty or undefined
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
const theme = createTheme();
function getMinimumPrice(items) {
  console.log('===getMinimumPrice items', items);
  if (items.length === 0) {
    return null;
  }
  const minPrice = items.reduce(
    (min, item) => (item.budget < min ? item.budget : min),
    items[0].budget
  );
  console.log('===getMinimumPrice', minPrice);
  return minPrice?.toLocaleString();
}
var cardAsfile;
const ProfileForm = ({
  userData,
  uploadCompanyLogoImage,
  uploadCoverImage,
  pushMessage,
  getUpdatedRegistrationData,
  getTopLevelProductCategoriesByUser,
  businessDocuments,
  socialLinks,
  // topLevelAddedCategories,
  allProducts,
  getAllProducts,
  // freelancer_role,
  // freelancer_bio,
  resumeData,
  projects,
  allServices,
  certificates,
  resetUserData,
  resetB2BStepData,
  resetComapanyData,
  resetProfileData,
  resetAddCatalogData,
  resetMyCatalogData,
  resetMyServiceData,
  resetOfferedServiceData,
  resetUpdateProfileeData,
  resetFLAddBioData,
  resetFLProfileData,
  resetHourlyRate,
  resetFLResumeData,
  resetFLUploadServiceData,
  resetFLUProfRoleData,
  resetFLAddServiceData,
  resetLoginData,
  resetCompleteProfileData,
  resetFLStepData,
  resetRequirementList,
  postBuisnessCard,
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
  orderData,
  subscriptionDetail,
  premiumPlan,
  getAllSubscriptionServices,
  setCurrentSubscriptionDetail,
  resetSubscription,
  clearSubscription,
  getAllSubscriptionList,
  loading,
  subscription,
}) => {
  // console.log('===profile userData', userData.registration.rating);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cardShareImg, setCardShareImg] = useState();
  // const [payuUrl, setPayUUrl] = useState('#');
  const rWebRef = React.useRef(null);
  const getCardImage = async () => {
    try {
      const cardContent = document.querySelector('.e-business-card');
      console.log('=====cardContent', cardContent);
      if (!cardContent) {
        throw new Error("Element with class 'e-business-card' not found");
      }
      return await downloadCanvasImage(cardContent);
    } catch (error) {
      console.error('Error in getCardImage:', error);
    }
  };

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });

  const [openCallModal, setOpenCallModal] = useState(false);
  const handleOpenCallModal = () => setOpenCallModal(true);
  const handleCloseCallModal = () => setOpenCallModal(false);
  const [profileProgress, setProfileProgress] = useState(0);
  const companyLogoInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const [planData, setPlanData] = useState(null);
  const [userType, setUserType] = useState(
    userData?.registration?.registration_type || 1
  );
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setShowGenerate(true);
    setShareLoading(false);
  };
  // React.useEffect(() => {
  //   console.log('====cardShareImg', cardShareImg);
  //   if (rWebRef?.current && !isDrawerOpen) {
  //     rWebRef.current.click();
  //   }
  // }, [cardShareImg, isDrawerOpen]);

  const [openPopup, setOpenPopup] = React.useState(false);

  const [open, setOpen] = useState(false);

  const [cardImgPath, setCardImgPath] = useState();
  const [showGenerate, setShowGenerate] = useState(true);
  const [shareLoading, setShareLoading] = useState(false);
  const handleLogoButtonClick = () => {
    if (userData?.registration?.image_path) {
      setConfirmationDialogType('Logo');
      setOpenReupload(true);
    } else {
      companyLogoInputRef.current.click();
    }
  };
  const registrationData = userData.registration;
  const [ConfirmationDialogType, setConfirmationDialogType] =
    React.useState('Logo');
  const [openReupload, setOpenReupload] = useState(false);
  const handleCloseReupload = () => setOpenReupload(false);
  const handleLogoFileChange = async (e) => {
    const selectedFile = e.target.files;
    console.log(
      'Selected COMPNAY LOGO Image:',
      userData?.registration?.image_path
    );
    if (selectedFile) {
      if (selectedFile.length > 0) {
        if (
          selectedFile[0]?.type.includes('jpeg') ||
          selectedFile[0]?.type.includes('jpg') ||
          selectedFile[0]?.type.includes('png')
        ) {
          loading(true);
          let compressedImage = await compressImages(selectedFile);
          await uploadCompanyLogoImage(
            compressedImage,
            userData?.registration?.id,
            pushMessage,
            getUpdatedUserData
          );
          loading(false);
        } else {
          pushMessage('Please add Valid file type');
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCoverButtonClick = () => {
    if (userData?.registration?.image_path) {
      setConfirmationDialogType('Cover');
      setOpenReupload(true);
    } else {
      coverInputRef.current.click();
    }
  };
  const handleConfirmReupload = () => {
    ConfirmationDialogType === 'Logo'
      ? companyLogoInputRef.current.click()
      : coverInputRef.current.click();
  };
  const handleCoverFileChange = async (e) => {
    const selectedFile = e.target.files;
    // Handle the selected file as needed, e.g., upload to a server or display preview
    console.log('Selected COVER Image:', selectedFile);
    if (selectedFile) {
      if (selectedFile.length > 0) {
        if (
          selectedFile[0]?.type.includes('jpeg') ||
          selectedFile[0]?.type.includes('jpg') ||
          selectedFile[0]?.type.includes('png')
        ) {
          loading(true);
          let compressedImage = await compressImages(selectedFile, 1080, 1920);
          await uploadCoverImage(
            compressedImage,
            userData?.registration?.id,
            pushMessage,
            getUpdatedUserData
          );
          loading(false);
        } else {
          pushMessage('Please add Valid file type');
        }
      }
    }
  };

  const compressImages = (files, height = 500, width = 500, quality = 75) =>
    Promise.all(
      Array.from(files).map(
        (file) =>
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              height, // New width
              width, // New height
              'JPEG', // Format
              quality, // Quality
              0, // Rotation
              (uri) => {
                resolve(uri);
              },
              'file'
            );
          })
      )
    );

  const getUpdatedUserData = async () => {
    await getUpdatedRegistrationData(userData?.user?.id, userData, pushMessage);
  };
  // useEffect(() => {
  // 	getUpdatedUserData();
  // }, []);

  useEffect(() => {
    let deb = setTimeout(() => {
      if (userData)
        getSubscriptionDetails('registration_id', userData?.registration?.id);
      if (userData) getAllSubscriptionList(() => {}, loading);
      if (userData)
        getSubscriptionOrderBySearch(
          userData?.registration?.id,
          'registration_id'
        );
    }, 1000);

    return () => {
      clearTimeout(deb);
    };
  }, [userData]);

  useEffect(() => {
    let deb = setTimeout(() => {
      if (
        subscriptionDetail?.subscription_details_id &&
        subscription.length > 0
      );
      const planData = subscription.find(
        (sub) => sub.id === subscriptionDetail?.subscription_details_id
      );
      if (planData) {
        setPlanData(planData);
        getAllSubscriptionServices({
          id: subscriptionDetail?.subscription_details_id,
          name: planData?.name,
        });
      }
    }, 1000);

    return () => {
      clearTimeout(deb);
    };
  }, [subscriptionDetail, subscription]);

  useEffect(() => {
    let deb = setTimeout(() => {
      if (subscriptionDetail?.status === true && premiumPlan && planData) {
        const subPlanData = premiumPlan?.subs.find(
          (plan) => plan?.id === subscriptionDetail?.subscription_id
        );
        if (subPlanData) {
          console.log('subPlanData', subPlanData);
          let renewalDate = moment(subscriptionDetail?.valid_to).format(
            'DD MMMM, YYYY'
          );
          console.log('renewalDate', renewalDate);

          setCurrentSubscriptionDetail({
            renewalDate: renewalDate,
            plan: planData?.name,
            plan_type: subPlanData?.plan_type,
            amount: subPlanData?.price,
            status: subscriptionDetail?.status,
          });
        }
      }
    }, 1000);

    return () => {
      clearTimeout(deb);
    };
  }, [subscriptionDetail, premiumPlan, planData]);

  useEffect(() => {
    let deb = setTimeout(() => {
      if (userData) {
        // dispatch(getTopLevelProductCategoriesByUser(userData?.registration?.id, pushMessage));
        getAllProducts(userData?.registration?.id);
        // console.log("===userData789 788", allProducts, businessDocuments, socialLinks);
        // setProfileProgress(getCurrentProfileProgress());
        // let progress = getCurrentProfileProgress(
        // 	userData?.registration,
        // 	allProducts,
        // 	businessDocuments,
        // 	socialLinks
        // );
        // // console.log('===progress', progress);
        // setProfileProgress(Math.round(progress));
        if (userData?.registration?.registration_type == 2) {
          let progress = getCurrentProfileProgress(
            userData?.registration,
            allProducts,
            businessDocuments,
            socialLinks
          );
          setProfileProgress(Math.round(progress));
          // console.log('===progress', progress);
        }

        if (userData?.registration?.registration_type == 3) {
          let flProgress = getFLCurrentProfileProgress(
            userData?.registration,
            resumeData,
            projects,
            allServices,
            certificates
          );
          setProfileProgress(Math.round(flProgress));
          console.log('===flProgress', flProgress);
        }
      }
    }, 1000);

    return () => {
      clearTimeout(deb);
    };
  }, [resumeData, projects, allServices, certificates]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleLogout = () => {
    //clear reducer data
    resetUserData();
    resetB2BStepData();
    resetComapanyData();
    resetProfileData();
    resetAddCatalogData();
    resetMyCatalogData();
    resetMyServiceData();
    resetOfferedServiceData();
    resetUpdateProfileeData();
    resetFLAddBioData();
    resetFLProfileData();
    resetHourlyRate();
    resetFLResumeData();
    resetFLUploadServiceData();
    resetFLUProfRoleData();
    resetFLAddServiceData();
    resetLoginData();
    resetCompleteProfileData();
    resetFLStepData();
    resetRequirementList();
    resetSubscription();
    clearSubscription();
    history.replace('/login');
    localStorage.removeItem('user_loggedIn');
    localStorage.clear();
  };

  useEffect(() => {
    let deb = setTimeout(() => {
      if (userData) {
        setUserType(userData?.registration?.registration_type || 1);
        getTopLevelProductCategoriesByUser(
          userData?.registration?.id,
          pushMessage
        );
      }
    }, 1000);

    return () => {
      clearTimeout(deb);
    };
  }, [userData]);

  const Item = styled('div')({
    background: '#61A3F2',
    padding: '10px',
    margin: '10px',
    height: 'auto',
    borderRadius: '8px',
  });

  useEffect(() => {
    console.log('orderData', orderData);
    console.log('subscriptionDetail', subscriptionDetail);
    console.log('premiumPlan', premiumPlan);
  }, [orderData, subscriptionDetail, premiumPlan]);

  return (
    <ThemeProvider theme={theme}>
      <ConfirmationDialog
        open={openReupload}
        handleClose={handleCloseReupload}
        title="Elynker"
        content={`Are you sure you want to replace the Existing ${ConfirmationDialogType}?`}
        onConfirm={handleConfirmReupload}
        confirmText="Yes"
        cancelText="No"
      />
      <Container component="main" maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Dialog
            open={openCallModal}
            onClose={handleCloseCallModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="sm"
            fullWidth
          >
            {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
            <DialogTitle className="custom-border-header pb-0">
              <Box className="position-relative d-flex justify-content-between">
                <Typography
                  className="fs-22 fw-bold font-skmodernist"
                  id="alert-dialog-title"
                >
                  {DIALOG_TITLE}
                </Typography>
                <Box>
                  <CloseIcon
                    onClick={() => {
                      handleCloseCallModal();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please add product in your catalog.
              </DialogContentText>
            </DialogContent>

            <DialogActions className="modal-footer">
              <Button
                onClick={() => {
                  handleCloseCallModal();
                }}
                className="btn-save"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="sm"
            fullWidth
          >
            {/* <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle> */}
            <DialogTitle className="custom-border-header pb-0">
              <Box className="position-relative d-flex justify-content-between">
                <Typography
                  className="fs-22 fw-bold font-skmodernist"
                  id="alert-dialog-title"
                >
                  {DIALOG_TITLE}
                </Typography>
                <Box>
                  <CloseIcon
                    onClick={() => {
                      handleClose();
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
              </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Kindly upload your Profile and Cover images to create the
                digital catalog PDF
              </DialogContentText>
            </DialogContent>

            <DialogActions className="modal-footer">
              <Button
                onClick={() => {
                  handleClose();
                }}
                className="btn-save"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center pb-3">
              <ArrowBackIosIcon onClick={() => history.replace('/homepage')} />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>

            <div style={{ position: 'relative' }}>
              <div
                style={{
                  zIndex: 100,
                  position: 'absolute',
                  right: 5,
                  top: 15,
                }}
              >
                {userType === 2 && (
                  <Box>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={coverInputRef}
                      onChange={handleCoverFileChange}
                    />
                    <Tooltip title="Edit" style={{ cursor: 'pointer' }}>
                      <ProfileImageEditIcon onClick={handleCoverButtonClick} />
                    </Tooltip>
                  </Box>
                )}
              </div>
              {Responsive(
                <>
                  <CardMedia
                    className="profile-background-2"
                    image={
                      userData?.registration?.cover_image
                        ? COVER_IMAGE_BASE_URL +
                          userData?.registration?.cover_image
                        : require('../../assets/JobSeeker-Profile-CoverImage960x700px.png')
                    }
                  >
                    {!userData?.registration?.cover_image && (
                      <div className="profile-background-2-tint" />
                    )}
                    <div style={{ zIndex: 2, position: 'relative' }}>
                      <Card className="profile-main-card">
                        <CardContent className="profile-main-content">
                          <div className="profile-photo-main">
                            {!userData?.registration?.image_path && (
                              <div>
                                <input
                                  type="file"
                                  accept=".png,.jpeg,.jpg"
                                  style={{ display: 'none' }}
                                  ref={companyLogoInputRef}
                                  onChange={handleLogoFileChange}
                                />

                                <DefaultLogo
                                  name={
                                    userData?.registration?.company_name ||
                                    userData?.registration?.name
                                  }
                                  handleClick={handleLogoButtonClick}
                                />
                              </div>
                            )}
                            {userData?.registration?.image_path && (
                              <div className="profile-photo-inner">
                                <div
                                // style={{
                                //   backgroundImage: require('../../assets/profile-image.png'),
                                // }}
                                >
                                  <input
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    ref={companyLogoInputRef}
                                    onChange={handleLogoFileChange}
                                  />
                                  <Avatar
                                    className="profile-avatar-img"
                                    alt={
                                      userData?.user?.company_name ||
                                      'Profile Image'
                                    }
                                    src={
                                      userData?.registration?.image_path
                                        ? COMPANY_LOGO_BASE_URL +
                                          userData?.registration?.image_path
                                        : require('../../assets/profile-image.png')
                                    }
                                    style={{
                                      width: '120px',
                                      height: '120px',
                                      borderRadius: '50%',
                                      border: '1px solid #00000059',
                                      background: '#fff',
                                    }}
                                  />
                                </div>
                                <div
                                  className="profile-edit-icon"
                                  onClick={handleLogoButtonClick}
                                >
                                  <Tooltip title="Edit">
                                    <ProfileImageEditIcon />
                                  </Tooltip>

                                  {/* <img
                            src={require('../../assets/profile-edit-icon.png')}
                          ></img> */}
                                </div>
                              </div>
                            )}
                          </div>
                          <Typography
                            variant="h4"
                            className="m-top fs-18 text-black fw-bold mb-2 font-skmodernist"
                          >
                            {/* {userData?.registration?.company_name} */}
                            {userData?.registration?.registration_type == 2
                              ? userData?.registration?.company_name
                              : userData?.registration?.name}
                          </Typography>
                          <Stack
                            direction="row"
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: returnColorForStatus(
                                  registrationData?.status
                                ),
                                padding: 7,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 5,
                              }}
                            ></div>
                            <Typography
                              variant="h4"
                              className="fs-13 text-black fw-normal font-gotham"
                            >
                              {toSentenceCase(registrationData?.status)}{' '}
                            </Typography>
                          </Stack>
                          {userData?.registration?.aboutCompany && (
                            <Typography
                              variant="h5"
                              className="fs-13 text-secondary fw-normal mb-2 font-gotham"
                            >
                              {/* Provider of all types of glass and <br></br> lighting
                    fixtures. */}
                              {userData?.registration?.aboutCompany?.length > 80
                                ? userData?.registration?.aboutCompany?.substring(
                                    0,
                                    80
                                  ) + '...'
                                : userData?.registration?.aboutCompany}
                            </Typography>
                          )}

                          <Grid
                            container
                            spacing={2}
                            className="profile-stats-card"
                          >
                            <Grid item className="p-0" xs={6} md={6}>
                              <Item className="text-start">
                                <div className="text-end">
                                  <ProfileViewsIcon />
                                </div>
                                <Typography
                                  variant="h5"
                                  className="fs-26 text-white fw-normal font-skmodernist stats-number"
                                >
                                  {userData?.registration?.profile_views || 0}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className="fs-13 text-white fw-normal font-gotham stats-text"
                                >
                                  Profile Views
                                </Typography>
                              </Item>
                            </Grid>

                            <Grid item className="p-0" xs={6} md={6}>
                              <Item className="text-start">
                                <div className="text-end">
                                  <StarRatedIcon />
                                </div>
                                <Typography
                                  variant="h5"
                                  className="fs-26 text-white fw-normal font-skmodernist stats-number"
                                >
                                  {userData?.registration?.rating || '0.0'}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className="fs-13 text-white fw-normal font-gotham stats-text"
                                >
                                  Star Rated
                                </Typography>
                              </Item>
                            </Grid>
                            {/* <Grid item className="p-0" xs={6} md={6}>
                            <Item className="text-start">
                              <div className="text-end">
                                <ConnectionReqIcon />
                              </div>
                              <Typography
                                variant="h5"
                                className="fs-26 text-white fw-normal font-skmodernist stats-number"
                              >
                                23
                              </Typography>
                              <Typography
                                variant="h5"
                                className="fs-13 text-white fw-normal font-gotham stats-text"
                              >
                                Connection Request
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item className="p-0" xs={6} md={6}>
                            <Item className="text-start">
                              <div className="text-end">
                                <SearchAppearanceIcon />
                              </div>
                              <Typography
                                variant="h5"
                                className="fs-26 text-white fw-normal font-skmodernist stats-number"
                              >
                                62
                              </Typography>
                              <Typography
                                variant="h5"
                                className="fs-13 text-white fw-normal font-gotham stats-text"
                              >
                                Search Appearance
                              </Typography>
                            </Item>
                          </Grid> */}
                          </Grid>
                        </CardContent>
                      </Card>
                    </div>
                  </CardMedia>
                </>,
                <>
                  <Box className="profile-web-sec">
                    <CardMedia
                      className="profile-web-background"
                      image={
                        userData?.registration?.cover_image
                          ? COVER_IMAGE_BASE_URL +
                            userData?.registration?.cover_image
                          : require('../../assets/JobSeeker-Profile-CoverImage2264x700px.png')
                      }
                    >
                      {!userData?.registration?.cover_image && (
                        <div className="profile-web-background-tint" />
                      )}
                    </CardMedia>
                    <Box
                      className="d-flex position-relative"
                      sx={{ height: '100px', zIndex: 2, position: 'relative' }}
                    >
                      <div className="profile-photo-web-main">
                        {!userData?.registration?.image_path && (
                          <div>
                            <input
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              ref={companyLogoInputRef}
                              onChange={handleLogoFileChange}
                            />
                            <DefaultLogo
                              name={
                                userData?.registration?.company_name ||
                                userData?.registration?.name
                              }
                              handleClick={handleLogoButtonClick}
                              height={150}
                              width={150}
                              iconStyle={{ right: '3px', bottom: '5px' }}
                              iconHeight={42}
                              iconWidth={42}
                            />
                          </div>
                        )}
                        {userData?.registration?.image_path && (
                          <div
                            className="profile-photo-inner"
                            style={{ width: '150px', height: '150px' }}
                          >
                            <div
                            // style={{
                            //   backgroundImage: require('../../assets/profile-image.png'),
                            // }}
                            >
                              <input
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={companyLogoInputRef}
                                onChange={handleLogoFileChange}
                              />
                              <Avatar
                                className="profile-avatar-img"
                                alt={
                                  userData?.user?.company_name ||
                                  'Profile Image'
                                }
                                src={
                                  userData?.registration?.image_path
                                    ? COMPANY_LOGO_BASE_URL +
                                      userData?.registration?.image_path
                                    : require('../../assets/profile-image.png')
                                }
                                sx={{
                                  width: '150px !important',
                                  height: '150px !important',
                                  borderRadius: '50%',
                                  border: '1px solid #00000059',
                                  background: '#fff',
                                  objectFit: 'contain',
                                }}
                              />
                            </div>
                            <div
                              className="profile-edit-icon"
                              onClick={handleLogoButtonClick}
                              style={{ bottom: '5px', right: '3px' }}
                            >
                              <Tooltip title="Edit">
                                <ProfileImageEditIcon />
                              </Tooltip>

                              {/* <img
                            src={require('../../assets/profile-edit-icon.png')}
                          ></img> */}
                            </div>
                          </div>
                        )}
                      </div>

                      <Box
                        className="d-flex justify-content-between align-items-start"
                        sx={{
                          paddingLeft: '45px',
                          paddingTop: '25px',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            gap: '20px',
                            alignItems: 'end',
                          }}
                        >
                          <Typography
                            variant="h4"
                            className="fs-18 text-black fw-bold font-skmodernist"
                          >
                            {/* {userData?.registration?.company_name} */}
                            {userData?.registration?.registration_type == 2
                              ? userData?.registration?.company_name
                              : userData?.registration?.name}
                          </Typography>
                          <Stack
                            direction="row"
                            sx={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: returnColorForStatus(
                                  registrationData?.status
                                ),
                                padding: 5,
                                borderRadius: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginRight: 5,
                              }}
                            ></div>
                            <Typography
                              variant="h4"
                              className="fs-13 text-black fw-normal font-gotham"
                            >
                              {toSentenceCase(registrationData?.status)}{' '}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box className="d-flex" sx={{ gap: '20px' }}>
                          {userType !== 3 && (
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn-web-next"
                              sx={{
                                padding: '15px 16px 17px 16px !important',
                                fontSize: '13px !important',
                              }}
                              onClick={() => {
                                if (allProducts?.length > 0) {
                                  // history.push('/product-catalog-pdf');
                                  if (
                                    !userData?.registration?.cover_image ||
                                    !userData?.registration?.image_path
                                  ) {
                                    setOpen(true);
                                  } else {
                                    window.open(
                                      '/product-catalog-pdf',
                                      '_blank'
                                    );
                                  }
                                } else {
                                  handleOpenCallModal();
                                }
                              }}
                            >
                              Generate Digital Catalog
                            </Button>
                          )}
                          {/* {userType == 2 && (
                            <>
                              <Button
                                type="submit"
                                variant="contained"
                                className="btn-web-next"
                                onClick={handleOpenModal}
                                sx={{
                                  padding: '15px 16px 17px 16px !important',
                                  fontSize: '13px !important',
                                }}
                              >
                                Share your E-Business Card
                              </Button>
                            </>
                          )} */}

                          <Button
                            type="button"
                            variant="contained"
                            className="logout-btn"
                            sx={{ width: '100px' }}
                            onClick={() => setOpenPopup(true)}
                          >
                            Logout
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </div>
            {cardShareImg && (
              <RWebShare
                data={{
                  text: '',
                  url: cardShareImg,
                  title: 'Share',
                }}
                onClick={() => {
                  // getCardImage();
                  // postBuisnessCard()
                }}
              >
                <Button ref={rWebRef} />
              </RWebShare>
            )}

            <form className="profile-margin-top">
              {isTabletOrMobile && (
                <>
                  {userType == 2 && (
                    <>
                      <Typography className="fs-16 font-skmodernist text-darkgrey fw-bold mb-3">
                        Please complete your profile to add your details.
                      </Typography>

                      <Grid container rowSpacing={1} columnSpacing={3} mb={2}>
                        <Grid item xs={12} md={6} alignItems={'flex-end'}>
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            className="mb-2 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                            onClick={toggleDrawer}
                            sx={{
                              borderRadius: '6px',
                              textTransform: 'capitalize',
                            }}
                          >
                            Share your E-Business Card
                          </Button>
                        </Grid>

                        {userType !== 3 && (
                          <Grid item xs={12} md={6}>
                            {/* <Typography className='fs-10'>( Please add product in your catalog. )</Typography> */}
                            <Button
                              type="button"
                              fullWidth
                              variant="contained"
                              className="mb-2 py-3 primary-color-1 fs-15 font-skmodernist fw-bold testtt"
                              onClick={() => {
                                if (allProducts?.length > 0) {
                                  if (
                                    !userData?.registration?.cover_image ||
                                    !userData?.registration?.image_path
                                  ) {
                                    // pushMessage("Please Add Profile and cover image to download the PDF");
                                    setOpen(true);
                                  } else {
                                    window.open(
                                      '/product-catalog-pdf',
                                      '_blank'
                                    );
                                  }
                                } else {
                                  handleOpenCallModal();
                                }
                              }}
                              sx={{
                                borderRadius: '6px',
                                textTransform: 'capitalize',
                              }}
                            >
                              Generate Digital Catalog
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}

              <ShareBusinessCardModal
                open={openModal}
                onClose={handleCloseModal}
                userData={userData}
                getMinimumPrice={getMinimumPrice}
                allProducts={allProducts}
              />

              <Drawer
                xs={12}
                anchor="bottom"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{
                  '& .MuiDrawer-paper': {
                    height: 'auto',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  },
                }}
              >
                <div className="p-3">
                  <div className="d-flex justify-content-between mb-3">
                    <Typography
                      variant="h5"
                      align="left"
                      alignItems="center"
                      className="fs-18 mb-2 fw-bold font-skmodernist "
                      gutterBottom
                    >
                      Share your E-Business Card
                    </Typography>
                    <CloseIcon onClick={toggleDrawer} />
                  </div>

                  <Card className="e-business-card mb-3">
                    <CardContent className="e-business-content">
                      <div className="d-flex">
                        <div>
                          {!userData?.registration?.image_path ? (
                            <DefaultLogo
                              name={
                                userData?.registration?.company_name ||
                                userData?.registration?.name
                              }
                              handleClick={handleLogoButtonClick}
                              height={100}
                              width={100}
                              showEdit={false}
                              iconStyle={{ right: '3px', bottom: '5px' }}
                              iconHeight={42}
                              iconWidth={42}
                            />
                          ) : (
                            <img
                              src={
                                userData?.registration?.image_path
                                  ? COMPANY_LOGO_BASE_URL +
                                    userData?.registration?.image_path
                                  : require('../../assets/profile-image.png')
                              }
                              width={'96px'}
                              height={'96px'}
                              style={{ borderRadius: 100 }}
                            ></img>
                          )}
                        </div>
                        <div className="ms-3">
                          <Typography
                            variant="h5"
                            className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                            component="div"
                          >
                            {userData?.user?.name}
                          </Typography>
                          {/* <Typography
                            variant="body2"
                            className="fs-14 text-white mb-3 font-gotham"
                          >
                            {userData?.registration?.freelancer_bio}
                          </Typography> */}

                          <div className="d-flex align-items-center">
                            <div className="d-flex me-4 mb-2">
                              {/* <DeliveryIcon className="delivery-icon" /> */}
                              <Typography
                                variant="body2"
                                className="fs-13 text-white fw-normal font-skmodernist"
                              >
                                {/* {allProducts?.length} deliveries */}
                                {userData?.registration?.company_name} <br />
                                {userData?.user?.email} <br />
                                {userData?.user?.country_code}{' '}
                                {userData?.user?.mobile_number}
                              </Typography>
                            </div>
                          </div>
                          <div className="d-flex mb-2">
                            <RatingIcon className="rating-icon" />
                            <Typography
                              variant="body2"
                              className="fs-13 text-white  fw-normal font-skmodernist"
                            >
                              {userData?.registration?.rating}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <Divider
                        component="div"
                        className="divider my-2"
                        role="presentation"
                      ></Divider>
                      <div className="d-flex justify-content-between align-items-center">
                        <ElynkerLogoComponent>
                          <ElynkerLogo width={'54px'} height={'16px'} />
                        </ElynkerLogoComponent>
                        {/* {getMinimumPrice(allProducts) && (
													<div>
														<Typography
															variant="body2"
															className="fs-12 text-white fw-normal font-skmodernist"
														>
															Starting at <br />
															<span className="text-primary-2 fw-bold fs-15">
																₹{getMinimumPrice(allProducts)}/
															</span>
															Products
														</Typography>
													</div>
												)} */}
                      </div>
                    </CardContent>
                  </Card>
                {showGenerate ? (
                  <LoadingButton
                  loadingPosition="start"
                  loadingIndicator={<CircularProgress style={{'color': 'white'}} size={16} />}
                  loading={shareLoading} 
                  onClick={async () => {
                    setShareLoading(true);
                    cardAsfile = await getCardImage();
                    await postBuisnessCard(
                      cardAsfile,
                      userData?.registration?.id,
                      (res) => {
                        console.log(
                          'postBuisnessCard cardAsfile',
                          cardAsfile
                        );
                        const cardShareUrl =
                          UPLOAD_BASE_URL + '/' + res?.data?.imagePath;
                        setCardShareImg(cardShareUrl);
                        setCardImgPath(res?.data?.imagePath);
                        setShareLoading(false);
                        setShowGenerate(false);

                      },
                      (err) => {
                        console.log('error call back', err);
                        setCardShareImg('');
                        setShareLoading(false);
                      }
                    );
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                  sx={{ borderRadius: '6px', textTransform: 'capitalize', color: 'white !important' }}
                >
                  Generate your E-Business Card
                </LoadingButton>
                ) : (
                  <LoadingButton
                    onClick={async () => {
                      shareCardContent(
                        'Share',
                        cardAsfile,
                        cardImgPath,
                        SHARE_VENDOR_DETAIL_URL+userData?.registration?.uuid
                      );
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                    className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                    sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
                  >
                    Share your E-Business Card
                  </LoadingButton>
                )}
                </div>
              </Drawer>
              <Grid container rowSpacing={3} columnSpacing={4}>
                <Grid item xs={12} md={6}>
                  {isDesktopOrLaptop && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        className="profile-stats-card"
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '8px',
                          padding: '10px',
                          marginBottom: '30px !important',
                        }}
                      >
                        <Grid item className="p-0" xs={6} md={6}>
                          <Item className="text-start">
                            <div className="text-end">
                              <ProfileViewsIcon />
                            </div>
                            <Typography
                              variant="h5"
                              className="fs-26 text-white fw-normal font-skmodernist stats-number"
                            >
                              {userData?.registration?.profile_views || 0}
                            </Typography>
                            <Typography
                              variant="h5"
                              className="fs-13 text-white fw-normal font-gotham stats-text"
                            >
                              Profile Views
                            </Typography>
                          </Item>
                        </Grid>
                        <Grid item className="p-0" xs={6} md={6}>
                          <Item className="text-start">
                            <div className="text-end">
                              <StarRatedIcon />
                            </div>
                            <Typography
                              variant="h5"
                              className="fs-26 text-white fw-normal font-skmodernist stats-number"
                            >
                              {userData?.registration?.rating || '0.0'}
                            </Typography>
                            <Typography
                              variant="h5"
                              className="fs-13 text-white fw-normal font-gotham stats-text"
                            >
                              Star Rated
                            </Typography>
                          </Item>
                        </Grid>

                        {/* <Grid item className="p-0" xs={6} md={6}>
                            <Item className="text-start">
                              <div className="text-end">
                                <ConnectionReqIcon />
                              </div>
                              <Typography
                                variant="h5"
                                className="fs-26 text-white fw-normal font-skmodernist stats-number"
                              >
                                23
                              </Typography>
                              <Typography
                                variant="h5"
                                className="fs-13 text-white fw-normal font-gotham stats-text"
                              >
                                Connection Request
                              </Typography>
                            </Item>
                          </Grid>
                          <Grid item className="p-0" xs={6} md={6}>
                            <Item className="text-start">
                              <div className="text-end">
                                <SearchAppearanceIcon />
                              </div>
                              <Typography
                                variant="h5"
                                className="fs-26 text-white fw-normal font-skmodernist stats-number"
                              >
                                62
                              </Typography>
                              <Typography
                                variant="h5"
                                className="fs-13 text-white fw-normal font-gotham stats-text"
                              >
                                Search Appearance
                              </Typography>
                            </Item>
                          </Grid> */}
                      </Grid>
                    </>
                  )}

                  <Box>
                    {(userData?.registration?.registration_type === 2 ||
                      userData?.registration?.registration_type === 3) && (
                      <Card className="complete-profile-card">
                        <CardContent className="complete-profile-content">
                          {/* {profileProgress !== 100 && ( */}
                          <div
                            style={{
                              position: 'relative',
                              width: 90,
                              height: 90,
                            }}
                          >
                            <GradientCircularProgress
                              value={profileProgress}
                              size={90}
                            />
                            <Typography
                              variant="caption"
                              className="progress-percentage fs-16 text-darkgrey fw-bold font-skmodernist"
                            >
                              {`${profileProgress}%`}
                            </Typography>
                          </div>
                          {/* )} */}
                          <div className="ms-4">
                            <Typography
                              variant="body2"
                              className="fs-16 text-darkgrey fw-bold mb-2 font-skmodernist"
                            >
                              {profileProgress !== 100
                                ? 'Complete Your Profile'
                                : 'Profile Completed'}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-grey fw-normal mb-2 font-gotham"
                            >
                              Completed profiles rank higher than other profiles
                              on the search page.
                            </Typography>
                            <Button
                              variant="contained"
                              className="complete-profile-btn"
                              onClick={() => {
                                if (userType == 2) {
                                  // && profileProgress !== 100
                                  dispatch({ type: COPY_NEW_USER_DATA });
                                  history.push('/update-profile');
                                } else if (userType == 3) {
                                  // && profileProgress !== 100
                                  dispatch({ type: COPY_NEW_USER_DATA });
                                  history.push('/fl-update-profile');
                                } else {
                                  pushMessage(
                                    'You do not have access to profile'
                                  );
                                }
                              }}
                            >
                              {profileProgress === 100
                                ? 'Manage Profile'
                                : 'Complete your Profile'}
                            </Button>
                          </div>
                        </CardContent>
                      </Card>
                    )}
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <ProfileCardWrapper>
                    {userData?.registration?.registration_type === 2 && (
                      <Box>
                        <Card className="free-tier-card mb-3">
                          <CardContent className="free-tier-content">
                            <Typography
                              variant="h5"
                              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                              component="div"
                            >
                              {subscriptionDetail?.status === true &&
                              orderData?.slice(-1)[0]?.orders_status ===
                                'captured'
                                ? planData?.name
                                : 'Free Plan'}
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-white mb-3 font-gotham"
                            >
                              Subscribe to elynker premium and grow <br></br>{' '}
                              your business with ease!
                            </Typography>
                                <Button
                              variant="contained"
                              className="profile-card-btn"
                              onClick={() => {
                                history.push("/subscription")
                                // if (
                                //   subscriptionDetail?.status === true &&
                                //   orderData?.slice(-1)[0]?.orders_status ===
                                //     'captured' &&
                                //   premiumPlan
                                // ) {
                                //   history.push('/view-subscription');
                                // } else {
                                //   history.push('/subscription');
                                // }
                              }}
                            >
                              {/* {subscriptionDetail?.status === true &&
                              orderData?.slice(-1)[0]?.orders_status ===
                                'captured' &&
                              premiumPlan
                                ? 'View Details'
                                : 'Subscribe Now'} */}
                                Subscribe Now
                            </Button>
                          </CardContent>
                        </Card>

                        <Card className="my-catalog-card mb-3">
                          <CardContent className="my-catalog-content">
                            <div></div>
                            <Typography
                              variant="h5"
                              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                              component="div"
                            >
                              My Catalog
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-white mb-3 font-gotham"
                            >
                              Add and Edit your complete list of products{' '}
                              <br></br> for businesses to discover!
                            </Typography>
                            <Button
                              variant="contained"
                              className="profile-card-btn"
                              onClick={() => history.push('/my-catalog')}
                            >
                              Manage Catalog
                            </Button>
                          </CardContent>
                        </Card>

                        <Card className="my-requirements-card mb-4">
                          <CardContent className="my-requirements-content">
                            <Typography
                              variant="h5"
                              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                              component="div"
                            >
                              My Requirements
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-white mb-3 font-gotham"
                            >
                              Create and manage requirements
                            </Typography>
                            <Button
                              variant="contained"
                              className="profile-card-btn mt-2"
                              onClick={() =>
                                history.push('/active-requirements')
                              }
                            >
                              Manage Requirements
                            </Button>
                          </CardContent>
                        </Card>

                        {/* <Card className="my-requirements-card mb-4">
                          <CardContent className="my-requirements-content">
                            <Typography
                              variant="h5"
                              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                              component="div"
                            >
                              Job Posting
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-white mb-3 font-gotham"
                            >
                              Create and manage job posting
                            </Typography>
                            <Button
                              variant="contained"
                              className="profile-card-btn mt-2"
                              onClick={() => history.push('/job-posted')}
                            >
                              Manage Job posting
                            </Button>
                          </CardContent>
                        </Card> */}

                        {isTabletOrMobile && (
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            className="mb-4 logout-btn"
                            onClick={() => setOpenPopup(true)}
                          >
                            Logout
                          </Button>
                        )}
                      </Box>
                    )}
                    {userData?.registration?.registration_type === 3 && (
                      <Box>
                        <Card className="my-catalog-card mb-3 mt-0">
                          <CardContent className="my-catalog-content">
                            <div></div>
                            <Typography
                              variant="h5"
                              className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                              component="div"
                            >
                              My Services
                            </Typography>
                            <Typography
                              variant="body2"
                              className="fs-14 text-white mb-3 font-gotham"
                            >
                              Add and Edit your complete list of services{' '}
                              <br></br> for businesses to discover!
                            </Typography>
                            <Button
                              variant="contained"
                              className="profile-card-btn"
                              onClick={() => history.push('/my-catalog')}
                            >
                              Manage Services
                            </Button>
                          </CardContent>
                        </Card>

                        {/* <Card className="free-tier-card mb-3">
                        <CardContent className="free-tier-content">
                          <Typography
                            variant="h5"
                            className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                            component="div"
                          >
                            {subscriptionDetail?.status === true &&
                            orderData?.slice(-1)[0]?.orders_status ===
                              'captured'
                              ? planData?.name
                              : 'Free Plan'}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="fs-14 text-white mb-3 font-gotham"
                          >
                            Subscribe to elynker premium and grow <br></br> your
                            business with ease!
                          </Typography>
                          <Button
                            variant="contained"
                            className="profile-card-btn"
                            onClick={() => {
                              if (
                                subscriptionDetail?.status === true &&
                                orderData?.slice(-1)[0]?.orders_status ===
                                  'captured' &&
                                premiumPlan
                              ) {
                                // history.push('/subscription');
                                history.push('/view-subscription');
                              } else {
                                history.push('/subscription');
                              }
                            }}
                          >
                            {subscriptionDetail?.status === true &&
                            orderData?.slice(-1)[0]?.orders_status ===
                              'captured' &&
                            premiumPlan
                              ? 'View Details'
                              : 'Subscribe Now'}
                          </Button>
                        </CardContent>
                      </Card> */}

                        {isTabletOrMobile && (
                          <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            className="mb-4 logout-btn"
                            onClick={() => setOpenPopup(true)}
                          >
                            Logout
                          </Button>
                        )}
                      </Box>
                    )}
                  </ProfileCardWrapper>
                </Grid>
              </Grid>
              <Box>
                <Typography className="fs-14 font-gotham my-4 text-black text-center">
                  Ver. {process.env.REACT_APP_APP_VERSION}
                </Typography>
              </Box>
            </form>
          </Grid>
        </Grid>
        <DeleteConfirmationPopup
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onConfirm={handleLogout}
          message={'Are you sure you want to logout?'}
        />
      </Container>
    </ThemeProvider>
  );
};

ProfileForm.propTypes = {
  uploadCoverImage: PropTypes.func,
  uploadCompanyLogoImage: PropTypes.func,
  userData: PropTypes.object,
  newSelectedCompanyLogo: PropTypes.object,
  newSelectedCoverPic: PropTypes.object,
  pushMessage: PropTypes.func,
  getUpdatedRegistrationData: PropTypes.func,
  getTopLevelProductCategoriesByUser: PropTypes.func,
  businessDocuments: PropTypes.array,
  socialLinks: PropTypes.array,
  topLevelAddedCategories: PropTypes.array,
  allProducts: PropTypes.array,
  getAllProducts: PropTypes.func,
  // freelancer_role: PropTypes.string,
  // freelancer_bio: PropTypes.string,
  resumeData: PropTypes.array,
  projects: PropTypes.array,
  allServices: PropTypes.array,
  certificates: PropTypes.array,
  resetUserData: PropTypes.func,
  resetB2BStepData: PropTypes.func,
  resetComapanyData: PropTypes.func,
  resetProfileData: PropTypes.func,
  resetAddCatalogData: PropTypes.func,
  resetMyCatalogData: PropTypes.func,
  resetMyServiceData: PropTypes.func,
  resetOfferedServiceData: PropTypes.func,
  resetUpdateProfileeData: PropTypes.func,
  resetFLAddBioData: PropTypes.func,
  resetFLProfileData: PropTypes.func,
  resetHourlyRate: PropTypes.func,
  resetFLResumeData: PropTypes.func,
  resetFLUploadServiceData: PropTypes.func,
  resetFLUProfRoleData: PropTypes.func,
  resetFLAddServiceData: PropTypes.func,
  resetLoginData: PropTypes.func,
  resetCompleteProfileData: PropTypes.func,
  resetFLStepData: PropTypes.func,
  resetRequirementList: PropTypes.func,
  postBuisnessCard: PropTypes.func,
  getSubscriptionDetails: PropTypes.func,
  getSubscriptionOrderBySearch: PropTypes.func,
  orderData: PropTypes.any,
  subscriptionDetail: PropTypes.any,
  premiumPlan: PropTypes.any,
  getAllSubscriptionServices: PropTypes.func,
  setCurrentSubscriptionDetail: PropTypes.func,
  resetSubscription: PropTypes.func,
  clearSubscription: PropTypes.func,
  getAllSubscriptionList: PropTypes.func,
  loading: PropTypes.func,
  subscription: PropTypes.array,
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  const { businessDocuments } = state.b2bOnboardStep;
  const { topLevelAddedCategories } = state.userProfile;
  const { socialLinks } = state.profileDetails;
  const { allProducts } = state.offeredServices;
  // const {} = state.userProfile;
  // const { freelancer_role, freelancer_bio } = userData.registration;
  const { resumeData } = state.flUploadResume;
  const { projects } = state.flAddBio;
  const { allServices } = state.flUploadService;
  const { certificates } = state.flProfileDetails;
  const { country_code } = state.mobile;
  const { country_code_name } = state.mobile;
  const { mobile_number } = state.mobile;
  const { subscription, orderData, subscriptionDetail, premiumPlan } =
    state.Subscription;

  // console.log('====registration profile', userData.registration);
  return {
    userData,
    businessDocuments,
    topLevelAddedCategories,
    socialLinks,
    allProducts,
    // freelancer_role,
    // freelancer_bio,
    resumeData,
    projects,
    allServices,
    certificates,
    country_code,
    country_code_name,
    mobile_number,
    orderData,
    subscriptionDetail,
    premiumPlan,
    subscription,
  };
};

export default connect(mapStateToProps, {
  loading,
  uploadCompanyLogoImage,
  uploadCoverImage,
  pushMessage,
  getUpdatedRegistrationData,
  getTopLevelProductCategoriesByUser,
  getAllProducts,
  resetUserData,
  resetB2BStepData,
  resetComapanyData,
  resetProfileData,
  resetAddCatalogData,
  resetMyCatalogData,
  resetMyServiceData,
  resetOfferedServiceData,
  resetUpdateProfileeData,
  resetFLAddBioData,
  resetFLProfileData,
  resetHourlyRate,
  resetFLResumeData,
  resetFLUploadServiceData,
  resetFLUProfRoleData,
  resetFLAddServiceData,
  resetLoginData,
  resetCompleteProfileData,
  resetFLStepData,
  resetRequirementList,
  postBuisnessCard,
  getSubscriptionDetails,
  getSubscriptionOrderBySearch,
  getAllSubscriptionServices,
  setCurrentSubscriptionDetail,
  resetSubscription,
  clearSubscription,
  getAllSubscriptionList,
})(ProfileForm);
