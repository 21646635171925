import {
  SET_ALL_VENDORS,
  SET_ENQUIRY,
  SET_VENDORS_DETAILS,
  SET_VENDOR_CITY,
  SET_FILTER_VCITY,
  SAVE_FL_DETAILS,
  SET_FILTER_RATING,
  SET_FILTER_CATEGORY,
  SET_FILTER_CITY,
  SET_FILTER_NEARME,
  SET_FILTER_SORT,
  RESET_CITY_FILTER,
  RESET_ALL_VENDOR_DATA,
  MARK_NEAR_BY_CLICK,
  SET_WA_ENQUIRY,
  SET_WA_CATE_ENQUIRY
} from '../types';

const INITIAL_STATE = {
  vendors: [],
  vendorsDetails: [],
  enquiry: null,
  filterVCity: [],
  allVCity: [],
  flDetails: [],
  saveFilterRating: [],
  saveFilterCategory: [],
  saveFilterCity: [],
  saveFilterNearMe: '',
  saveFilterSort: {},
  markNearByClick: false,
  waEnquiry: null,
  waCateCall: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };
    case SET_ENQUIRY: {
      const newState = {
        ...state,
        enquiry: action.payload,
      };
      return newState;
    }
    case SET_VENDORS_DETAILS: {
      console.log('===SET_VENDORS_DETAILS action', action);
      const newState = {
        ...state,
        vendorsDetails: action.payload,
      };
      console.log('===SET_VENDORS_DETAILS newState', newState);
      return newState;
    }
    case SET_VENDOR_CITY:
      return {
        ...state,
        allVCity: action.payload,
      };
    case SET_FILTER_VCITY:
      return {
        ...state,
        filterVCity: action.payload,
      };
    case SAVE_FL_DETAILS:
      return {
        ...state,
        flDetails: action.payload,
      };
    case SET_FILTER_RATING:
      return {
        ...state,
        saveFilterRating: action.payload,
      };
    case SET_FILTER_CATEGORY:
      return {
        ...state,
        saveFilterCategory: action.payload,
      };
    case SET_FILTER_CITY:
      return {
        ...state,
        saveFilterCity: action.payload,
      };
    case SET_FILTER_NEARME:
      return {
        ...state,
        saveFilterNearMe: action.payload,
      };
    case SET_FILTER_SORT:
      return {
        ...state,
        saveFilterSort: action.payload,
      };
    case RESET_CITY_FILTER:
      return {
        ...state,
        saveFilterCity: [],
      };
    case RESET_ALL_VENDOR_DATA:
      return INITIAL_STATE;

    case MARK_NEAR_BY_CLICK:
      return {
        ...state,
        markNearByClick: action.payload,
      };
    case SET_WA_ENQUIRY:
      return {
        ...state,
        waEnquiry: action.payload,
      }
    case SET_WA_CATE_ENQUIRY:
      return {
        ...state,
        waCateCall: action.payload,
      }
    default:
      return state;
  }
};
