import React from 'react';
// import { Container, Grid, Typography, TextField, FormControlLabel, Checkbox, Button, Link } from '@mui/material';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  OutlinedInput,
  Typography,
  Autocomplete,
} from '@mui/material';
import '../../index.css';
import UserLogo from '../../assets/user-icon.svg';
import EmailLogo from '../../assets/email-icon.svg';
import ColorToggleButton from '../../components/ToggleButton/ToggleButton';
import PropTypes from 'prop-types';
const customBlueColor = '#416AD4';
import { FormHelperText } from '@mui/material';
function WebCompleteProfile({
    formData,
    errors,
    handleSubmit,
    isSeller,
    handleCheckChange,
    toggleData,
    handleToggle,
    selIndex,
    isFromHomeScreen,
    handleNameChange,
    mapOtions,
    handleEmailChange,
    locSearchVal,
    onLocationChange,
    onLocationInputChange,
    locationAutoCompleteOpen,
    onLocationAutoCompleteOpen,
    onLocationAutoCompleteClose,
    getAddress2DefaultVal,
    getLocationString,
    searchLocInpRef,
    email
}) {
  console.log('WebCompleteProfile formdata', formData);
  return (
    <Container maxWidth="xl" sx={{ padding: '0px !important' }}>
      <Grid container className="root" style={{ height: '100vh' }}>
        {' '}
        {/* Apply class name from CSS */}
        {/* The image half */}
        <Grid item md={7} lg={7} className="bgImage">
          {' '}
          {/* Apply class name from CSS */}
        </Grid>
        {/* The content half */}
        <Grid
          item
          md={5}
          lg={5}
          xs={12}
          className="bgMain"
          sx={{ margin: 'auto' }}
        >
          {' '}
          {/* Apply class name from CSS */}
          <div className="login">
            {/* Demo content */}
            <Container cla>
              <Grid
                justifyContent="center"
                display={'flex'}
                alignContent={'center'}
              >
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <Typography
                    variant="h4"
                    align="left"
                    alignItems="center"
                    className="fs-34 mb-2 fw-bold text-black font-skmodernist"
                    gutterBottom
                  >
                    Complete your Profile
                  </Typography>

                  <Typography
                    variant="body1"
                    align="left"
                    className="fs-15 text-darkgrey fw-normal font-gotham"
                    gutterBottom
                  >
                    The more you share, the better we can serve you
                  </Typography>

                  <form
                    style={{ marginTop: '50px' }}
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className=" mb-3">
                      <InputLabel
                        htmlFor="inputMobile"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Name
                      </InputLabel>
                      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <OutlinedInput
                          id="inputMobile"
                          // label="Mobile Number"
                          type="text"
                          placeholder="Enter your name"
                          autoFocus
                          fullWidth
                          disabled={isFromHomeScreen && name ? true : false}
                          value={formData?.name}
                          onChange={handleNameChange}
                          inputProps={{
                            maxLength: 50,
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img
                                  src={UserLogo}
                                  alt="user-logo"
                                  className="user-logo"
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.name}
                      </FormHelperText>
                    </div>

                    <div className=" mb-3">
                      <InputLabel
                        htmlFor="inputEmail"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Email ID
                      </InputLabel>
                      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                        <OutlinedInput
                          id="inputEmail"
                          // label="Mobile Number"
                          type="text"
                          placeholder="Enter your email id"
                          fullWidth
                          disabled={isFromHomeScreen && email ? true : false}
                          value={formData?.email}
                          inputProps={{
                            maxLength: 200,
                            autoCapitalize: 'none',
                          }}
                          onChange={handleEmailChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                              >
                                <img
                                  src={EmailLogo}
                                  alt="email-logo"
                                  className="email-logo"
                                />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.email}
                      </FormHelperText>
                    </div>

                    <div className=" mb-3">
                      <InputLabel
                        htmlFor="inputLocation"
                        className="text-start fs-14 text-black font-skmodernist"
                      >
                        Location
                      </InputLabel>
                      <Autocomplete
                        disablePortal
                        ref={searchLocInpRef}
                        id={'SearchLocation'}
                        options={mapOtions}
                        fullWidth
                        // value={formData?.city}
                        inputValue={locSearchVal}
                        onInputChange={onLocationInputChange}
                        onChange={onLocationChange}
                        open={locationAutoCompleteOpen}
                        onOpen={onLocationAutoCompleteOpen}
                        onClose={onLocationAutoCompleteClose}
                        defaultValue={getAddress2DefaultVal()}
                        getOptionLabel={getLocationString}
                        isOptionEqualToValue={(option, value) =>
                          option?.properties?.name === value?.properties?.name
                        }
                        sx={{
                          '.MuiAutocomplete-input': {
                            paddingLeft: '14px !important',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            placeholder={
                              formData?.city ? formData?.city : 'Search City'
                            }
                            inputProps={{
                              ...params.inputProps,
                              maxLength: 200,
                            }}
                          />
                        )}
                      />
                      <FormHelperText
                        sx={{ color: 'red' }}
                        id="email-helper-text"
                      >
                        {errors.city}
                      </FormHelperText>
                    </div>

                    <FormControlLabel
                      className="d-flex align-items-center"
                      control={
                        <Checkbox
                          id="customCheck1"
                          className="py-0"
                          checked={isSeller}
                          onChange={handleCheckChange}
                          sx={{
                            color: customBlueColor,
                            borderWidth: 1,
                            '& .MuiSvgIcon-root': {
                              borderWidth: 1,
                              borderRadius: 4,
                            },
                            '&.Mui-checked': {
                              color: customBlueColor,
                              borderRadius: 15,
                              borderWidth: 2,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography className="text-start fs-13 font-gotham text-grey line-height">
                          Become a seller
                        </Typography>
                      }
                    />

                    {isSeller && (
                      <>
                        <InputLabel
                          htmlFor="inputLocation"
                          className="text-start fs-15 text-black font-skmodernist mt-3 mb-2"
                        >
                          Who are you?
                        </InputLabel>
                        <ColorToggleButton
                          className="font-skmodernist"
                          data={toggleData}
                          callback={(arg) => {
                            handleToggle(arg);
                          }}
                          selIndex={selIndex}
                        ></ColorToggleButton>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className="mt-4 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
                          sx={{
                            borderRadius: '6px',
                            textTransform: 'capitalize',
                          }}
                        >
                          Get started
                        </Button>
                      </>
                    )}
                    {!isSeller && (
                      <>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                          sx={{
                            borderRadius: '6px',
                            textTransform: 'capitalize',
                          }}
                        >
                          Get started
                        </Button>
                      </>
                    )}
                  </form>
                </Grid>
              </Grid>
            </Container>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
WebCompleteProfile.propTypes = {
    formData: PropTypes.object,
    errors: PropTypes.object,
    handleSubmit: PropTypes.func,
    isSeller: PropTypes.bool,
    handleCheckChange: PropTypes.func,
    toggleData: PropTypes.func,
    handleToggle: PropTypes.func,
    selIndex: PropTypes.number,
    isFromHomeScreen: PropTypes.bool,
    handleNameChange: PropTypes.func,
    handleEmailChange: PropTypes.func,
    mapOtions: PropTypes.any,
    locSearchVal: PropTypes.any,
    onLocationChange: PropTypes.func,
    onLocationInputChange: PropTypes.func,
    searchLocInpRef: PropTypes.any,
    locationAutoCompleteOpen: PropTypes.bool,
    onLocationAutoCompleteOpen: PropTypes.func,
    onLocationAutoCompleteClose: PropTypes.func,
    getAddress2DefaultVal: PropTypes.func,
    getLocationString: PropTypes.func,
    email: PropTypes.string
}
export default WebCompleteProfile;
