/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	Backdrop,
	CircularProgress,
	Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ElynkerLogo } from '../../../assets/elynker-logo.svg';
// import Divider from '@mui/material/Divider';
import AddBtnIcon from '../../../assets/add-btn-icon.svg';
import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { loading } from '../../../store/actions/rootActions';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { getAllService } from './redux/actions/fl-upload-service-action';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { resetFLAddServiceData } from '../FLAddService/redux/actions/add-service-action';
import { useMediaQuery } from 'react-responsive';

const theme = createTheme();
const categoryText = 'Upload Service Catalog';

const FLUploadServiceCatalogForm = ({
	onNextHandler,
	onBackHandler,
	loading,
	updateUserData,
	userData,
	isLoading,
	getAllService,
	allServices,
	resetFLAddServiceData,
}) => {
	let history = useHistory();

	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	useEffect(() => {
		resetFLAddServiceData();
		const fetchData = async () => {
			await getAllService(userData?.registration?.id);
		};
		loading(true);
		fetchData();
		loading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function nextSuccessCallback() {
		//clear data
		loading(false);
		onNextHandler();
	}

	function backSuccessCallback() {
		loading(false);
		onBackHandler();
	}

	const submitUploadService = async () => {
		loading(true);
		let newUserData = {
			active_steps: 5,
			steps_completed: false,
		};
		console.log('==userData+newUserData', newUserData);
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken,
			nextSuccessCallback
		);
		loading(false);
		onNextHandler();
	};

	const handleBackButton = async () => {
		loading(true);
		let newUserData = {
			active_steps: 2,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken,
			backSuccessCallback
		);
		loading(false);
	};

	const handleAddService = () => {
		history.push('/fl-add-service');
	};

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 5,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						{/* <div className='my-3 ms-2'>
                            <ElynkerLogo width={'100px'} height={'30px'} />
                        </div>
                        <Divider component="div" className="main-divider mb-3" role="presentation" /> */}
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
							style={{ whiteSpace: 'pre-line' }}
						>
							{categoryText}
						</Typography>

						<Typography
							variant="body1"
							align="left"
							className="fs-14 text-grey fw-normal font-gotham"
							gutterBottom
						>
							What service categories do you provide ?
						</Typography>

						<form
							style={{ marginTop: '30px' }}
							onSubmit={(e) => {
								e.preventDefault();
								submitUploadService();
							}}
						>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<div className="d-flex justify-content-between">
									<InputLabel
										htmlFor="inputproduct"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Add Service Catalog
									</InputLabel>
									{allServices?.length > 0 && (
										<Typography
											variant="body1"
											align="left"
											className="fs-14 text-primary fw-normal font-gotham mb-0"
											gutterBottom
										>
											<Link
												to={{
													pathname: '/my-catalog',
													state: { userType: 'Freelancer' },
												}}
												// href="/my-catalog"
												// color="inherit"
												style={{
													textDecoration: 'none',
													fontFamily: 'Sk-Modernist',
												}}
											>
												View Catalog ({allServices.length})
											</Link>
										</Typography>
									)}
								</div>
								<Button
									type="button"
									fullWidth
									variant="outlined"
									className="add-button mt-2 fs-15 font-skmodernist fw-bold"
									sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									onClick={handleAddService}
								>
									<img
										src={AddBtnIcon}
										alt="add-btn-icon"
										className="user-logo"
									/>
								</Button>
							</Grid>

							<div style={{ marginTop: '100px' }}>
								{isDesktopOrLaptop && (
									<>
										<Box className="d-flex justify-content-between align-items-center my-4">
											<Button
												onClick={handleBackButton}
												variant="text"
												type="button"
												className="btn-web-back"
											>
												Back
											</Button>
											<Button
												onClick={handleSkipButton}
												variant="text"
												type="button"
												className="btn-web-back"
											>
												Skip for Now
											</Button>
											{/* ) : ( */}
											<Button
												type="submit"
												variant="contained"
												className="btn-web-next"
											>
												Next Step
											</Button>
										</Box>
									</>
								)}

								{isTabletOrMobile && (
									<>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
											sx={{
												borderRadius: '6px',
												textTransform: 'capitalize',
											}}
										>
											Next Step
										</Button>
										{/* Show this button on view catalog screen  */}
										<div style={{display: "flex", justifyContent: "space-between"}}>
										<Button
											variant="text"
											type="button"
											fullWidth
											className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
											onClick={handleBackButton}
										>
											Back
										</Button>
										<Button
											onClick={handleSkipButton}
											variant="text"
											type="button"
											fullWidth
											className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
										>
											Skip for Now
										</Button>
										</div>
									</>
								)}
							</div>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FLUploadServiceCatalogForm.propTypes = {
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	onNextHandler: PropTypes.func,
	onBackHandler: PropTypes.func,
	userData: PropTypes.object,
	updateUserData: PropTypes.func,
	getAllService: PropTypes.func,
	allServices: PropTypes.array,
	resetFLAddServiceData: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { allServices } = state.flUploadService;

	// console.log("State :", state);
	return {
		isLoading,
		userData,
		allServices,
	};
};
export default connect(mapStateToProps, {
	loading,
	updateUserData,
	getAllService,
	resetFLAddServiceData,
})(FLUploadServiceCatalogForm);

