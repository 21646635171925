import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '../index.css';
// import Root from '../pages/Root';
import Login from '../pages/Login/Login';
import { connect } from 'react-redux';
// import Profile from '../pages/Profile';
// import ErrorPage from '../pages/ErrorPage';
import CompleteProfileForm from '../pages/CompleteProfile/CompleteProfile';
import PropTypes from 'prop-types';
import OtpForm from '../pages/Otp/Otp';
// import SellServicesForm from '../pages/SellServices/SellServices';
import OnboardingForm from '../pages/Onboarding/Onboarding';
import CompanyDetailsForm from '../pages/CompanyDetails/CompanyDetails';
import OfferedServicesForm from '../pages/OfferedServices/OfferedServices';
import ProfileDetailsForm from '../pages/ProfileDetails/ProfileDetails';
import CongratulationsForm from '../pages/Congratulations/Congratulations';
import FLOnboardingForm from '../pages/FreeLancer/FL-Onboarding/FL-Onboarding';
import FLAddBioForm from '../pages/FreeLancer/FL-AddBio/FL-AddBio';
import FLSetRateForm from '../pages/FreeLancer/FL-SetRate/FL-SetRate';
import AddCatalogForm from '../pages/AddCatalog/Add-Catalog';
import B2BOnboardingSteps from '../pages/B2BOnboardingSteps/B2BOnboardingSteps';
import HomepageForm from '../pages/Homepage/Homepage';
import MyCatalogForm from '../pages/MyCatalog/MyCatalog';
// import BuisnessQrShareForm from '../pages/BuisnessCardShareForm';
import FLProfileDetailsForm from '../pages/FreeLancer/FL-Profile-Details/FL-Profile-Details';
import B2BBusinessForm from '../pages/B2BBusiness/B2B-Business';
import B2BCategoryForm from '../pages/B2BCategory/B2B-Category';
import UpdateProfileForm from '../pages/UpdateProfile/UpdateProfile';
import ProfileForm from '../pages/Profile/Profile';
import FLUpdateProfileForm from '../pages/FlUpdateProfile/flUpdateProfile';
import FLUploadResumeForm from '../pages/FreeLancer/FL-UploadResume/FL-UploadResume';
// import FLAddProfessionalRoleForm from '../pages/FreeLancer/FLAddProfessionalRole/FL-AddProfessionalRole';
import FLAddService from '../pages/FreeLancer/FLAddService/FL-Add-Service';
// import FLUploadServiceCatalogForm from '../pages/FreeLancer/FL-UploadServiceCatalog/FL-UploadServiceCatalog';
// import FLMyServicesForm from '../pages/MyServices/FL-My-Services';
import FLOnboardingSteps from '../pages/FreeLancer/FLOnboardingSteps/FLOnboardingSteps';
import FLFreelanceForm from '../pages/FLFreelance/FL-Freelance';
import FLFreelanceCategoryForm from '../pages/FLFreelanceCategory/FL-Freelance-Category';
import B2BDetailForm from '../pages/B2BDetail/B2B-Detail';
import FreelancerDetailForm from '../pages/FreelancerDetail/Freelancer-Detail';
import B2CProfileForm from '../pages/B2CProfile/B2CProfile';
import B2CEditProfileForm from '../pages/B2CProfile/B2CEditProfile';
// import B2BRequirementDetailsForm from '../pages/B2BRequirement/B2BRequirementDetails';
import B2BRequirementProductCategoryForm from '../pages/B2BRequirement/B2BSubmitRequirement/B2BRequirementProductCategory';
// import B2BRequirementServiceCategoryForm from '../pages/B2BRequirement/B2BRequirementServiceCategory';
import B2BRequirementVerifyForm from '../pages/B2BRequirement/B2BVeriify/B2BRequirementVerify';
import SuccessfullForm from '../pages/Successful/Successful';
import SubscriptionForm from '../pages/Subscription/Subscription';
// import ProductToolsForm from '../pages/ProductTools/ProductTools';
import ElynkerServicesForm from '../pages/ElynkerServices/ElynkerServices';
// import WebLogin from '../pages/Login/WebLogin';
// import WebOtp from '../pages/Otp/WebOtp';
import ActiveRequirementsForm from '../pages/ActiveRequirements/ActiveRequirements';
// import ApplicationsForm from '../pages/Applications/Applications';
// import WebCompleteProfile from '../pages/CompleteProfile/WebCompleteProfile ';
// import WebOnboarding from '../pages/Onboarding/WebOnboarding';
import SearchForm from '../pages/Search/Search';
// import WebHomepageForm from '../pages/Homepage/WebHomepage';
// import B2BRequirementAdditionalDetailsForm from '../pages/B2BRequirement/B2BRequirementAdditionalDetails';
// import WebB2BCategoryForm from '../pages/B2BCategory/Web-B2B-Category';
import TemplatePages from '../pages/Templates/TemplatePages';
// import TermsForm from '../pages/Terms/TermsForm';
// import PrivacyForm from '../pages/Terms/PrivacyForm';
import CreateJobRequirementForm from '../pages/CreateJobRequirement/CreateJobRequirement';
// import JobPostedForm from '../pages/JobPosted/JobPosted';
// import JobSubscriptionForm from '../pages/JobSubscription/JobSubscription';
// import GBPLandingForm from '../pages/GBPLanding/GBPLanding';
// import WhatsappLandingForm from '../pages/WhatsappLanding/WhatsappLanding';
// import RecruitmentLandingForm from '../pages/RecruitmentLanding/RecruitementLanding';
// import JobSearchForm from '../pages/JobSearch/JobSearch';
import JobApplicationsForm from '../pages/JobApplications/JobApplications';
import JobListingForm from '../pages/JobListing/JobListing';
import JobPostApplyForm from '../pages/JobPostApply/JobPostApply';
import JobPostDetailForm from '../pages/JobPostDetail/JobPostDetail';
// import CreditVASLandingForm from '../pages/CreditVASLanding/CreditVASLanding';
import PersonalLoanLandingForm from '../pages/PersonalLoanLanding/PersonalLoanLanding';
import ProductCatalogForm from '../pages/ProductCatalog/ProductCatalog';
// import PaymentDetailsForm from '../pages/PaymentDetails/PaymentDetails';
import FailedForm from '../pages/Failed/Failed';
import ProcessingForm from '../pages/Processing/Processing';
import ViewSubscriptionForm from '../pages/ViewSubscription/ViewSubscription';
import RequestCallbackSuccessfullForm from '../pages/Successful/RequestCallbackSuccessful';
import ProductCatalogFrontForm from '../pages/ProductCatalog/ProductCatalogFront';
import ProductCatalogListForm from '../pages/ProductCatalog/ProductCatalogList';
import ProductCatalogDetailForm from '../pages/ProductCatalog/ProductCatalogDetail';
import ProductCatalogPdf from '../pages/ProductCatalog/ProductCatalogPdf';
import SubscriptionnForm from '../pages/Subscription/Subscriptionn';
// import WebJobListingForm from '../pages/JobListing/WebJobListing';
// import PaymentHandler from './PaymentHandler';

const Router = ({ userData }) => {
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          userData ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
  PrivateRoute.propTypes = {
    children: PropTypes.element,
  };

  return (
    <BrowserRouter>
      <Switch>
        {/* `<Route path="/login">
                    {!props.user ? (
                        <Login />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/'
                            }}
                        />
                    )}
                </Route>` */}
        <Route exact path="/">
          {userData ? (
            userData?.registration?.registration_type === 2 ? (
              userData?.registration?.steps_completed == true ? (
                <Redirect from="/" to="/homepage" />
              ) : (
                <Redirect from="/" to="/b2bonboarding-steps" />
              )
            ) : userData?.registration?.registration_type === 3 ? (
              userData?.registration?.steps_completed == true ? (
                <Redirect from="/" to="/homepage" />
              ) : (
                <Redirect from="/" to="/fl-onboarding-steps" />
              )
            ) : (
              <Redirect from="/" to="/homepage" />
            )
          ) : (
            <Redirect from="/" to="/homepage" />
          )}
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        {/* <PrivateRoute path="/profile">
                    <Profile />
                </PrivateRoute> */}

        <Route exact path="/otp">
          <OtpForm />
        </Route>
        <Route exact path="/complete-profile">
          <CompleteProfileForm />
        </Route>
        {/* <Route exact path="/sell-services">
					<SellServicesForm />
				</Route> */}
        <PrivateRoute exact path="/onboarding">
          <OnboardingForm />
        </PrivateRoute>
        <PrivateRoute exact path="/b2bonboarding-steps">
          <B2BOnboardingSteps />
        </PrivateRoute>
        <PrivateRoute exact path="/companydetails">
          <CompanyDetailsForm />
        </PrivateRoute>
        <PrivateRoute exact path="/offered-services">
          <OfferedServicesForm />
        </PrivateRoute>
        <PrivateRoute exact path="/profile-details">
          <ProfileDetailsForm />
        </PrivateRoute>
        <PrivateRoute exact path="/congratulations">
          <CongratulationsForm />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-onboarding">
          <FLOnboardingForm />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-upload-resume">
          <FLUploadResumeForm />
        </PrivateRoute>
        {/* <Route exact path="/fl-add-professional-role">
					<FLAddProfessionalRoleForm />
				</Route> */}
        <PrivateRoute exact path="/fl-add-bio">
          <FLAddBioForm />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-add-service">
          <FLAddService />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-onboarding-steps">
          <FLOnboardingSteps />
        </PrivateRoute>
        {/* <Route exact path="/fl-upload-service-catalog">
					<FLUploadServiceCatalogForm />
				</Route> */}
        {/* <Route exact path="/fl-my-services">
					<FLMyServicesForm />
				</Route> */}
        <PrivateRoute exact path="/fl-set-rate">
          <FLSetRateForm />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-profile-details">
          <FLProfileDetailsForm />
        </PrivateRoute>
        <PrivateRoute exact path="/add-catalog">
          <AddCatalogForm />
        </PrivateRoute>
        <Route exact path="/homepage">
          <HomepageForm />
        </Route>
        <PrivateRoute exact path="/my-catalog">
          <MyCatalogForm />
        </PrivateRoute>
        <Route exact path="/b2b-business">
          <B2BBusinessForm />
        </Route>
        <Route exact path="/b2b-category">
          <B2BCategoryForm />
        </Route>
        <PrivateRoute exact path="/update-profile">
          <UpdateProfileForm />
        </PrivateRoute>
        <PrivateRoute exact path="/fl-update-profile">
          <FLUpdateProfileForm />
        </PrivateRoute>
        <PrivateRoute exact path="/profile">
          <ProfileForm />
        </PrivateRoute>
        <Route exact path="/fl-freelance">
          <FLFreelanceForm />
        </Route>
        <Route exact path="/fl-freelance-category">
          <FLFreelanceCategoryForm />
        </Route>
        <Route exact path="/b2b-detail/:vId">
          <B2BDetailForm />
        </Route>
        <Route exact path="/freelancer-detail/:vendorId">
          <FreelancerDetailForm />
        </Route>
        <PrivateRoute exact path="/b2c-profile">
          <B2CProfileForm />
        </PrivateRoute>
        <PrivateRoute exact path="/b2c-edit-profile">
          <B2CEditProfileForm />
        </PrivateRoute>
        {/* <Route exact path="/b2b-requirement-details">
					<B2BRequirementDetailsForm />
				</Route> */}
        <PrivateRoute exact path="/b2b-requirement-product-category">
          <B2BRequirementProductCategoryForm />
        </PrivateRoute>
        {/* <Route exact path="/b2b-requirement-service-category">
					<B2BRequirementServiceCategoryForm />
				</Route> */}
        <PrivateRoute exact path="/b2b-requirement-verify">
          <B2BRequirementVerifyForm />
        </PrivateRoute>
        {/* <Route exact path="/b2b-requirement-additional-details">
					<B2BRequirementAdditionalDetailsForm />
				</Route> */}
        <PrivateRoute exact path="/successful">
          <SuccessfullForm />
        </PrivateRoute>
        {/* <Route exact path="/buisnessQrShare">
					<BuisnessQrShareForm />
				</Route> */}
        <PrivateRoute exact path="/subscriptionn">
          <SubscriptionForm />
        </PrivateRoute>

        <Route exact path="/subscription">
          <SubscriptionnForm />
        </Route>
        {/* <Route exact path="/product-tools">
					<ProductToolsForm />
				</Route> */}
        <Route exact path="/elynker-services">
          <ElynkerServicesForm />
        </Route>
        <PrivateRoute exact path="/active-requirements">
          <ActiveRequirementsForm />
        </PrivateRoute>
        {/* <Route exact path="/applications">
					<ApplicationsForm />
				</Route> */}
        {/* <Route exact path="/template-page">
					<TemplatePages />
				</Route> */}
        {/* <Route exact path="/terms">
					<TermsForm />
				</Route> */}
        {/* <Route exact path="/privacy">
					<PrivacyForm />
				</Route> */}
        <PrivateRoute exact path="/create-job-requirement">
          <CreateJobRequirementForm />
        </PrivateRoute>
        {/* <Route exact path="/job-subscription">
					<JobSubscriptionForm />
				</Route> */}
        {/* <PrivateRoute exact path="/job-posted">
          <JobPostedForm />
        </PrivateRoute> */}
        {/* <Route exact path="/gbp-landing">
					<GBPLandingForm />
				</Route> */}
        {/* <Route exact path="/whatsapp-landing">
					<WhatsappLandingForm />
				</Route> */}
        {/* <Route exact path="/recruitment-landing">
					<RecruitmentLandingForm />
				</Route> */}
        {/* <Route exact path="/job-search">
					<JobSearchForm />
				</Route> */}
        <Route exact path="/job-applications">
          <JobApplicationsForm />
        </Route>
        <Route exact path="/job-listing">
          <JobListingForm />
        </Route>
        <PrivateRoute exact path="/job-apply">
          <JobPostApplyForm />
        </PrivateRoute>
        <Route exact path="/job-detail">
          <JobPostDetailForm />
        </Route>
        {/* <Route exact path="/credit-vas-landing">
					<CreditVASLandingForm />
				</Route> */}
        <Route exact path="/personal-loan-landing">
          <PersonalLoanLandingForm />
        </Route>
        <PrivateRoute exact path="/product-catalog">
          <ProductCatalogForm />
        </PrivateRoute>
        {/* <Route exact path="/payment-details">
					<PaymentDetailsForm />
				</Route> */}
        <PrivateRoute exact path="/failed">
          <FailedForm />
        </PrivateRoute>
        <PrivateRoute exact path="/processing">
          <ProcessingForm />
        </PrivateRoute>
        <PrivateRoute exact path="/view-subscription">
          <ViewSubscriptionForm />
        </PrivateRoute>
        <PrivateRoute exact path="/product-catalog-front">
          <ProductCatalogFrontForm />
        </PrivateRoute>
        <PrivateRoute exact path="/product-catalog-list">
          <ProductCatalogListForm />
        </PrivateRoute>
        <PrivateRoute exact path="/product-catalog-detail">
          <ProductCatalogDetailForm />
        </PrivateRoute>
        <PrivateRoute exact path="/product-catalog-pdf">
          <ProductCatalogPdf />
        </PrivateRoute>

        {/* Web Login Page (All Web Login routes needs to be removed later on) */}

        {/* <Route path="/web-login">
					<WebLogin />
				</Route> */}

        {/* <Route path="/web-otp">
					<WebOtp />
				</Route> */}

        {/* <Route path="/web-complete-profile">
					<WebCompleteProfile />
				</Route> */}

        {/* <Route path="/web-onboarding">
					<WebOnboarding />
				</Route> */}

        <Route path="/search">
          <SearchForm />
        </Route>

        {/* <Route exact path="/web-job-listing">
          <WebJobListingForm />
        </Route> */}

        {/* <Route exact path="/web-homepage">
					<WebHomepageForm />
				</Route> */}

        {/* <Route exact path="/web-b2b-category">
					<WebB2BCategoryForm />
				</Route> */}

        <Route exact path="/req-successful">
          <RequestCallbackSuccessfullForm />
        </Route>
        <Route path="/:name">
          <TemplatePages />
        </Route>
        {/* <PrivateRoute path="/">
                    <Root />
                </PrivateRoute> */}
        {/* <Route
					path="/payment/handler"
					render={(props) => (
						<PaymentHandler
							{...props}
							endpoint="http://yourdomain.com/payment/status"
						/>
					)}
				/> */}

        <Route path="*">
          <HomepageForm />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
Router.defaultProps = {
  userData: null,
};

Router.propTypes = {
  userData: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

const mapStateToProps = (state) => {
  const { userData } = state.signin;
  return { userData };
};

export default connect(mapStateToProps, {})(Router);
