import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
// import './complete-profile.css';
import '../../index.css';
import PropTypes from 'prop-types';
import {
	Container,
	Grid,
	Typography,
	Button,
	// FormControlLabel,
	// FormLabel,
	Backdrop,
	CircularProgress,
	Box,
	TextareaAutosize,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import Divider from '@mui/material/Divider';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { connect } from 'react-redux';
import {
	setAboutCompany,
	setCompanyName,
	setAdditioanlDetails1,
	setAdditioanlDetails2,
	setAdditioanlDetails3,
} from './redux/actions/company-details-form-action';
import {
	submitBusinessDocuments,
	getExistingBusinessDocuments,
	updateUserData,
} from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { setBusinessType } from '../OfferedServices/redux/actions/offered-services-form-action';
import { loading } from '../../store/actions/rootActions';
import INBussinessDocumentForm from '../../components/BussinessDocumentsForm/IN/IN-BussinessDocumentForm';
import {
	AUSTRALIA,
	INDIA,
} from '../../components/BussinessDocumentsForm/constant';
import { FormHelperText } from '@mui/material';

import AUBussinessDocumentForm from '../../components/BussinessDocumentsForm/AU/AU-BussinessDocumentForm';
import { hasAnyErrorPresent } from '../../utils/validation';
import useValidation from '../../hooks/useValidation';
import { useMediaQuery } from 'react-responsive';
import { stickyButtonStyle } from '../../utils/sx-styles';
import {
	handleErrorsAndScroll,
	scrollToInput,
} from '../../utils/helper-funtions';

const theme = createTheme();
// const additionalDetailArray = (type) => {
//   const buisness = [
//     'Minimum order quantity',
//     'Average lead time',
//     'Warranty information',
//   ];
//   const services = [
//     'Service area',
//     'Typical project duration',
//     'Payment terms',
//   ];

//   return type === '1' ? buisness : services;
// };
const CompanyDetailsForm = ({
	onNextHandler,
	// onBackHandler,
	companyName,
	setCompanyName,
	userData,
	submitBusinessDocuments,
	getExistingBusinessDocuments,
	updateUserData,
	// businessType,
	// setBusinessType,
	loading,
	isLoading,
	country_code_name,
	aboutCompany,
	setAboutCompany,
	additionalDetails1,
	additionalDetails2,
	additionalDetails3,
	// gst,
	hasGST,
	gstReason,
	setAdditioanlDetails1,
	setAdditioanlDetails2,
	setAdditioanlDetails3,
}) => {
	const [businessDoc, setBusinessDocuments] = useState(null);
	const { errors, validateInput, setErrors } = useValidation();
	const [count, setCount] = useState(aboutCompany?.length || 0);
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	const onChangeCompanyName = (e) => {
		const input = e?.target?.value;
		console.log('onChangeCompanyName', input);
		const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
		if (regex.test(input) || input === '') {
			validateInput('company_name', input);
			setCompanyName(input);
		}
	};
	const onPastCompanyName = (event) => {
		const input = event.clipboardData.getData('Text');
		const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
		console.log('Pasted content:', event.clipboardData.getData('Text'));
		if (regex.test(input) || input === '') {
			validateInput('company_name', input);
			setCompanyName(input);
		}
		event.preventDefault();
	};
	const onChangeAboutCompany = (e) => {
		const input = e.target.value;
		const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
		if (regex.test(input) && input.length <= 5000) {
			validateInput('about_company', input);
			setAboutCompany(input);
			setCount(input.length);
		}
	};

	const onPastAboutCompany = (event) => {
		const input = event.clipboardData.getData('Text');
		const newInput = aboutCompany + input;
		console.log('Pasted content:', input);
		const regex = /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
		if (regex.test(newInput) && newInput.length <= 5000) {
			validateInput('about_company', newInput);
			setAboutCompany(newInput);
			setCount(newInput.length);
		}
		event.preventDefault();
	};
	const onChangeAdditionalDetails = (e) => {
		const input = e.target;
		switch (input.id) {
			case 'additionalDetails1':
				setAdditioanlDetails1(input?.value);
				break;
			case 'additionalDetails2':
				setAdditioanlDetails2(input?.value);
				break;
			case 'additionalDetails3':
				setAdditioanlDetails3(input?.value);
				break;
		}
		console.log('onChangeAdditionalDetails', input.id);
	};

	// const handleBTypeChange = (event) => {
	//   setBusinessType(event.target.value);
	// };
	useEffect(() => {
		const {
			company_name,
			additional_detail1,
			additional_detail2,
			additional_detail3,
			about_company,
		} = userData?.registration || {};

		if (company_name) {
			setCompanyName(company_name);
		}

		if (about_company) {
			setCompanyName(about_company);
		}

		if (additional_detail1) {
			setAdditioanlDetails1(additional_detail1);
		}

		if (additional_detail2) {
			setAdditioanlDetails2(additional_detail2);
		}

		if (additional_detail3) {
			setAdditioanlDetails3(additional_detail3);
		}
	}, [userData?.registration]);
	useEffect(() => {
		console.log('Is loading userData ', userData);
		if (userData?.registration?.id && country_code_name) {
			getExistingBusinessDocuments(
				userData?.registration?.id,
				country_code_name
			);
		}
	}, [userData?.registration?.id, country_code_name]);

	const submitCompanyDetails = async () => {
		// console.log('submitCompanyDetails,errors', errors, !companyName);

		if (!companyName) {
			validateInput('company_name', '');
			scrollToInput('company_name');
		} else if (!aboutCompany) {
			validateInput('about_company', '');
			scrollToInput('about_company');
		} else if (hasGST == 0 && gstReason == '0') {
			validateInput('gst_reason', '');
			scrollToInput('gst_reason');
		}
		// else if (hasGST == 1 && !gst) {
		//   validateInput('gst', '');
		// }
		else if (!hasAnyErrorPresent(errors)) {
			loading(true);
			await submitBusinessDocuments(
				userData?.registration?.id,
				userData?.accessToken,
				businessDoc
			);
			let newUserData = {
				active_steps: 1,
				steps_completed: false,
				about_company: aboutCompany,
				company_name: companyName,
				additional_detail1: additionalDetails1,
				additional_detail2: additionalDetails2,
				additional_detail3: additionalDetails3,
			};
			console.log('upate newUserData', newUserData);

			await updateUserData(
				userData,
				newUserData,
				userData?.registration?.id,
				userData?.accessToken
			);

			onNextHandler();
			loading(false);
		} else {
			handleErrorsAndScroll(errors);
		}
	};

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 1,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	// const handleBackButton = async (e) => {
	//   e.preventDefault();
	//   loading(true);
	//   let newUserData = {
	//     active_steps: 0,
	//     steps_completed: false,
	//   };
	//   await updateUserData(
	//     userData,
	//     newUserData,
	//     userData?.registration?.id,
	//     userData?.accessToken,
	//     onBackHandler
	//   );
	//   loading(false);
	// };

	const renderBusinessDocumentForm = () => {
		switch (userData?.user?.country_code) {
			case INDIA.countryCodeNumber:
				return (
					<INBussinessDocumentForm
						// setCompletedFields={setCompletedFields}

						validateInput={validateInput}
						errors={errors}
						setErrors={setErrors}
						setBusinessDocuments={setBusinessDocuments}
					/>
				);
			case AUSTRALIA.countryCodeNumber:
				return (
					<AUBussinessDocumentForm
						// setCompletedFields={setCompletedFields}
						setBusinessDocuments={setBusinessDocuments}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: '110vh' }}
				>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
						>
							A complete profile attracts more clients by showcasing your
							expertise
						</Typography>
						{/* <Typography
              variant="body1"
              align="left"
              className="fs-14 text-secondary fw-normal font-gotham"
              gutterBottom
            >
              How can a profile help me stand out?
            </Typography> */}
						{/* <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal mt-3 font-manrope"
              gutterBottom
            >
              Hi{' '}
              <span style={{ color: '#864FFD' }}>{userData?.user?.name},</span>{' '}
              continue completing or creating your profile
            </Typography> */}
						<form
							onSubmit={(e) => {
								e.preventDefault();
								submitCompanyDetails();
							}}
							style={{ marginTop: '50px' }}
						>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<InputLabel
									htmlFor="inputCompanyName"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Company Name: <span className="text-mandatory">*</span>
								</InputLabel>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="company_name"
										// label="Mobile Number"
										type="text"
										placeholder="Enter your company's name"
										autoFocus
										fullWidth
										margin="none"
										error={errors.company_name}
										value={companyName}
										onChange={onChangeCompanyName}
										inputProps={{
											maxLength: 100,
										}}
										onPaste={onPastCompanyName}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.company_name}
								</FormHelperText>
							</Grid>

							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<div className="d-flex justify-content-between">
									<InputLabel
										htmlFor="inputAboutCompany"
										className="text-start fs-14 text-black font-skmodernist"
									>
										About Company:<span className="text-mandatory">*</span>
									</InputLabel>
									<Typography
										variant="body1"
										align="left"
										className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
										gutterBottom
									>
										{5000 - count} Characters Left
									</Typography>
								</div>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<TextareaAutosize
										id="about_company"
										placeholder="Write about your company"
										minRows={3} // You can adjust the number of rows as needed
										maxRows={3} // You can adjust the number of rows as needed
										onChange={onChangeAboutCompany}
										inputprops={{
											maxLength: 5000,
										}}
										value={aboutCompany}
										onPaste={onPastAboutCompany}
										// defaultValue={singleProduct?.description || ''}
									/>
								</FormControl>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.about_company}
								</FormHelperText>
							</Grid>
							{/* <FormControl fullWidth>
                <FormLabel
                  id="business-type-radio"
                  className="fs-15 fw-normal font-skmodernist text-black"
                >
                  Business Type:
                </FormLabel>
                <Divider
                  component="div"
                  className="divider mt-3 mb-1"
                  role="presentation"
                ></Divider>
                <RadioGroup
                  row
                  aria-labelledby="business-type-radio"
                  name="business-type-radio"
                  defaultValue={'1'}
                  value={businessType}
                  onChange={handleBTypeChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Product"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Service"
                  />
                </RadioGroup> */}
							{/* <Divider
                  component="div"
                  className="divider mb-3 mt-1"
                  role="presentation"
                ></Divider>
              </FormControl> */}
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<InputLabel
									htmlFor="inputAboutCompany"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Key details about your business:
								</InputLabel>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="additionalDetails1"
										// label="Mobile Number"
										type="text"
										// placeholder={additionalDetailArray(businessType)[0]}
										fullWidth
										margin="none"
										className=" mb-3"
										value={additionalDetails1}
										onChange={onChangeAdditionalDetails}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</FormControl>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="additionalDetails2"
										// label="Mobile Number"
										type="text"
										// placeholder={additionalDetailArray(businessType)[1]}
										fullWidth
										className=" mb-3"
										margin="none"
										value={additionalDetails2}
										onChange={onChangeAdditionalDetails}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</FormControl>
								<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
									<OutlinedInput
										id="additionalDetails3"
										// label="Mobile Number"
										type="text"
										// placeholder={additionalDetailArray(businessType)[2]}
										fullWidth
										margin="none"
										className=" mb-3"
										value={additionalDetails3}
										onChange={onChangeAdditionalDetails}
										inputProps={{
											maxLength: 100,
										}}
									/>
								</FormControl>
							</Grid>

							{renderBusinessDocumentForm()}

							{/* <Typography
                variant="body1"
                align="left"
                className="fs-14 text-primary fw-normal font-gotham"
                gutterBottom
              >
                Why this is important
              </Typography> */}

							<Typography
								variant="body1"
								align="left"
								className="fs-16 mt-3 text-black fw-normal font-skmodernist"
								gutterBottom
							>
								Frequently Asked Questions
							</Typography>

							<div className="custom-accordion">
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1-content"
										id="panel1-header"
										className="fs-15 fontskmodernist text-darkgrey"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="Can I add GST later?" />
									</AccordionSummary>
									<AccordionDetails>
										Yes, you can add your GST number later. You can edit your
										profile information anytime after creating your account.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										className="fs-15 fontskmodernist"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="What if I don't have a GSTIN (Goods and Service Tax Identification Number)?" />
									</AccordionSummary>
									<AccordionDetails>
										{
											"You don't have to provide a GST number if you don't have one. Select 'No'"
										}
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										className="fs-15 fontskmodernist"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="What are the benefits of having a complete profile?" />
									</AccordionSummary>
									<AccordionDetails>
										<Typography
											variant="body1"
											align="left"
											className="fs-14 fw-normal font-gotham"
											gutterBottom
										>
											complete profile helps you stand out to potential clients
											in several ways:
										</Typography>
										<Typography
											variant="body1"
											align="left"
											className="fs-14 fw-normal font-gotham"
											gutterBottom
										>
											- A complete profile with your company name, description,
											and contact information makes it easier for customers to
											find you.
										</Typography>
										<Typography
											variant="body1"
											align="left"
											className="fs-14 fw-normal font-gotham"
											gutterBottom
										>
											- Detailed information about your business builds trust
											with potential clients.
										</Typography>
										<Typography
											variant="body1"
											align="left"
											className="fs-14 fw-normal font-gotham"
											gutterBottom
										>
											- A well-defined profile allows you to showcase your
											expertise and attract clients who are a good fit for your
											services.
										</Typography>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										className="fs-15 fontskmodernist"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="What kind of information should I include in the 'About Company' section?" />
									</AccordionSummary>
									<AccordionDetails>
										Briefly describe your companys mission, values, and what
										makes you unique. Highlight your areas of expertise and the
										types of clients you serve. Include your years of experience
										and any relevant certifications or awards.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										className="fs-15 fontskmodernist"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="How long does it take to complete my profile?" />
									</AccordionSummary>
									<AccordionDetails>
										Completing your profile typically takes just a few minutes.
										The follow-up page focuses on essential information, and you
										can add more details later.
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										className="fs-15 fontskmodernist"
									>
										<ListItemIcon className="align-items-center">
											<CircleIcon
												style={{
													color: '#1843A0',
													width: '7px',
													height: '7px',
												}}
											/>
										</ListItemIcon>
										<ListItemText primary="Can I go back and edit my profile information later?" />
									</AccordionSummary>
									<AccordionDetails>
										{
											"Absolutely! You can edit your profile information at any time. It's important to keep your profile updated to reflect any changes in your business"
										}
										.
									</AccordionDetails>
								</Accordion>
								<div
									className="margin"
									style={{ marginBottom: isTabletOrMobile ? 125 : 0 }}
								></div>
							</div>

							{isTabletOrMobile && (
								<Box sx={{ width: '100%' }}>
									<Box sx={[stickyButtonStyle]}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
											sx={[
												{
													borderRadius: '6px',
													textTransform: 'capitalize',
												},
											]}
										>
											Next Step
										</Button>
										<Button
											onClick={handleSkipButton}
											variant="text"
											type="button"
											fullWidth
											className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
										>
											Skip for Now
										</Button>
									</Box>
								</Box>
							)}

							{isDesktopOrLaptop && (
								<>
									<Box className="d-flex justify-content-between align-items-center my-4">
										<Button
											onClick={handleSkipButton}
											variant="text"
											type="button"
											className="btn-web-back"
										>
											Skip for Now
										</Button>
										<Button
											type="submit"
											variant="contained"
											className="btn-web-next"
										>
											Next Step
										</Button>
									</Box>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

CompanyDetailsForm.propTypes = {
	onNextHandler: PropTypes.func,
	onBackHandler: PropTypes.func,
	companyName: PropTypes.string,
	setCompanyName: PropTypes.func,
	userData: PropTypes.object,
	mobile_number: PropTypes.string,
	country_code: PropTypes.string,
	submitBusinessDocuments: PropTypes.func,
	getExistingBusinessDocuments: PropTypes.func,
	businessDocuments: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
	updateUserData: PropTypes.func,
	businessType: PropTypes.string,
	setBusinessType: PropTypes.func,
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	country_code_name: PropTypes.string,
	aboutCompany: PropTypes.string,
	setAboutCompany: PropTypes.func,
	gstReason: PropTypes.string,
	cin: PropTypes.string,
	gst: PropTypes.string,
	pan: PropTypes.string,
	hasGST: PropTypes.string,
	additionalDetails1: PropTypes.string,
	additionalDetails2: PropTypes.string,
	additionalDetails3: PropTypes.string,
	setAdditioanlDetails1: PropTypes.func,
	setAdditioanlDetails2: PropTypes.func,
	setAdditioanlDetails3: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;

	const {
		aboutCompany,
		companyName,
		additionalDetails1,
		additionalDetails2,
		additionalDetails3,
	} = state.companyDetails;
	console.log('state.companyDetails1234;', state.companyDetails);

	// console.log(
	//   '===state.companyDetails567',
	//   JSON.stringify(state.companyDetails)
	// );
	const { mobile_number, country_code, country_code_name } = state.mobile;
	const { businessDocuments } = state.b2bOnboardStep;
	const { gstReason, cin, gst, pan, hasGST } = state.INBusinessDoc;
	const { businessType } = state.offeredServices;
	const { isLoading } = state.root;

	// const {
	//   company_name,
	//   additional_detail1,
	//   additional_detail2,
	//   additional_detail3,
	//   about_company,
	// } = userData.registration;

	return {
		userData,
		companyName,
		mobile_number,
		country_code,
		businessDocuments,
		businessType,
		isLoading,
		country_code_name,
		aboutCompany,
		additionalDetails1,
		additionalDetails2,
		additionalDetails3,
		gstReason,
		cin,
		gst,
		pan,
		hasGST,
	};
};

export default connect(mapStateToProps, {
	setCompanyName,
	submitBusinessDocuments,
	getExistingBusinessDocuments,
	updateUserData,
	setBusinessType,
	loading,
	setAboutCompany,
	setAdditioanlDetails1,
	setAdditioanlDetails2,
	setAdditioanlDetails3,
})(CompanyDetailsForm);

