import React, { useRef, useEffect, useState } from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	Box,
	CircularProgress,
	Backdrop,
	FormHelperText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddBtnIcon from '../../../assets/add-btn-icon.svg';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as ResumeIcon } from '../../../assets/resume-icon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/cat-delete-icon.svg';
import {
	uploadResumeData,
	getResumeData,
	deleteResume,
} from './redux/actions/fl-upload-resume-action';
import { loading } from '../../../store/actions/rootActions';
import { func, object, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { RESUME_BASE_URL } from '../../../utils/APIs/api';
import { updateUserData } from '../FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { formatedDate } from '../../../utils/constants';
import { pushMessage } from '../../../components/MessageBox/redux/actions/message-box-action';
import { compressFile, isImage } from '../../../utils/compressFile';
import { useMediaQuery } from 'react-responsive';
import useValidation from '../../../hooks/useValidation';
import { ConfirmationDialog } from '../../../components/ActionPopup';
import { stickyButtonStyle } from '../../../utils/sx-styles';

const theme = createTheme();
const categoryText = 'How would you Like to Tell us About Yourself?';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   pt: 2,
//   px: 4,
//   pb: 3,
// };

const FLUploadResumeForm = ({
	loading,
	isLoading,
	uploadResumeData,
	getResumeData,
	userData,
	resumeData,
	deleteResume,
	onNextHandler,
	// onBackHandler,
	updateUserData,
	pushMessage,
	// resume
}) => {
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
	const { validateInput, errors } = useValidation();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [openReupload, setOpenReupload] = useState(false);
	const handleOpenReupload = () => setOpenReupload(true);
	const handleCloseReupload = () => setOpenReupload(false);

	const [doc, setDoc] = useState();

	function successCallback() {
		//clear data
		getResumeData(userData?.registration?.id);
	}

	function errorCallback(err) {
		// const type = 'error';
		console.log('errorCallback', err);
		pushMessage(err);
		// pushMessage(err, type);
	}

	function onDeleteSuccess(res) {
		pushMessage(res?.success);
	}

	async function onNextClick() {
		loading(true);
		let newUserData = {
			active_steps: 1,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData?.registration?.id,
			userData?.accessToken
		);
		loading(false);
		onNextHandler();
	}

	const handleResumeUploadFile = async (e) => {
		const selectedFiles = e.target.files;

		if (selectedFiles.length === 0) {
			return;
		}

		const file = selectedFiles[0];
		const fileType = file.type;

		// Check file type
		const allowedTypes = [
			'application/pdf',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		];
		if (!allowedTypes.includes(fileType)) {
			pushMessage('Selected file is not a PDF or DOCX.');
			return;
		}
		if (file.size > 2000000) {
			validateInput('flResumeUpload', file.size);
			return;
		} else {
			validateInput('flResumeUpload', 0);
		}

		let compressedFile;
		if (isImage(file)) {
			compressedFile = compressFile(selectedFiles);
		} else {
			compressedFile = file;
		}

		loading(true);
		await uploadResumeData(
			compressedFile,
			userData?.registration?.id,
			successCallback,
			errorCallback
		);
		loading(false);
		console.log('Resume File:', selectedFiles);
		e.target.value = null;
	};
	const handleResumeUploadClick = () => {
		if (resumeData && resumeData.length >= 1) {
			// show alert message
			handleOpenReupload();
		} else {
			resumeUploadRef.current.click();
		}
	};

	const resumeUploadRef = useRef(null);

	useEffect(() => {
		console.log('User data : ' + userData?.id);
		getResumeData(userData?.registration?.id);
	}, []);
	const handleConfirmDelete = () => {
		handleClose();
		deleteResume(doc?.id, onDeleteSuccess, () =>
			console.log('Please try again, Error while deleting Resume.')
		);
	};
	const handleConfirmReupload = () => {
		handleCloseReupload();
		resumeUploadRef.current.click();
	};

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 1,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<ConfirmationDialog
					open={open}
					handleClose={handleClose}
					title="Elynker"
					content="Are you sure you want to delete the Resume?"
					onConfirm={handleConfirmDelete}
					confirmText="Yes"
					cancelText="No"
				/>
				<ConfirmationDialog
					open={openReupload}
					handleClose={handleCloseReupload}
					title="Elynker"
					content="Are you sure you want to replace the Existing Resume?"
					onConfirm={handleConfirmReupload}
					confirmText="Yes"
					cancelText="No"
				/>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
							style={{ whiteSpace: 'pre-line' }}
						>
							{categoryText}
						</Typography>

						<Typography
							variant="body1"
							align="left"
							className="fs-14 text-grey fw-normal font-gotham"
							gutterBottom
						>
							We need to get a sense of your education, experience and skills.
							It&apos;s quickest to import your information -- you can edit it
							before your profile goes live.
						</Typography>

						<form
							style={{ marginTop: '20px' }}
							onSubmit={(e) => {
								e.preventDefault();
								onNextClick();
							}}
						>
							<Grid md={5} lg={5} xs={12} className=" mb-3">
								<input
									type="file"
									style={{ display: 'none' }}
									ref={resumeUploadRef}
									onChange={handleResumeUploadFile}
									id="file"
									accept=".doc,.docx,.pdf"
								/>
								<Button
									type="button"
									fullWidth
									variant="outlined"
									className="add-button mt-2 fs-15 font-skmodernist fw-bold"
									sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									onClick={handleResumeUploadClick}
								>
									<img
										src={AddBtnIcon}
										alt="add-btn-icon"
										className="user-logo"
									/>
									<Typography className="ms-2 text-primary-1">
										Upload Your Resume
									</Typography>
								</Button>

								<Typography class="text-muted fs-12">
									(PDF or DOCX files only, maximum size: 2 MB)
								</Typography>
								<FormHelperText
									sx={{ color: 'red' }}
									id="resume-upload-helper-text"
								>
									{errors?.flResumeUpload}
								</FormHelperText>
							</Grid>

							{resumeData &&
								resumeData?.map((item, index) => {
									const formatDate = formatedDate(item?.createdAt);
									const filename = item?.freelancer_resume?.replace(
										/^.*[\\/]/,
										''
									);
									return (
										<Grid
											md={5}
											lg={5}
											xs={12}
											className="d-flex justify-content-between align-items-center my-2"
											key={index}
										>
											<div>
												<div className="d-flex align-items-center">
													<div>
														<ResumeIcon style={{ width: '20px' }} />
													</div>
													<Typography
														variant="body1"
														align="left"
														className="fs-14 text-black fw-normal mb-0 ms-3 font-skmodernist"
														gutterBottom
														sx={{
															wordBreak: 'break-all',
														}}
													>
														{filename}
													</Typography>
												</div>
												<div style={{ marginLeft: '36px' }}>
													<Typography
														variant="body1"
														align="left"
														className="fs-13 text-grey fw-normal mb-0 ms-0 font-gotham"
														gutterBottom
													>
														Uploaded on: {formatDate}
													</Typography>
												</div>
											</div>
											<div className="d-flex">
												<IconButton
													className="cat-edit-icon"
													onClick={() => {
														saveAs(
															RESUME_BASE_URL + item?.freelancer_resume,
															item?.freelancer_resume
														);
														// if(item?.freelancer_resume.includes('.pdf')){
														//     downloadPDF(item);
														// }else{
														//     saveAs(RESUME_BASE_URL + item?.freelancer_resume, item?.freelancer_resume)
														// }
													}}
												>
													<DownloadIcon />
												</IconButton>
												<IconButton
													className="cat-delete-icon"
													onClick={() => {
														setDoc(item);
														handleOpen();
													}}
												>
													<DeleteIcon />
												</IconButton>
											</div>
										</Grid>
									);
								})}

							<div style={{ marginTop: '100px' }}>
								{isTabletOrMobile && (
									<>
										<Box sx={{ width: '100%' }}>
											<Box sx={[stickyButtonStyle]}>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
													sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
												// onClick={() => {}}
												>
													Next Step
												</Button>
												<Button
													onClick={handleSkipButton}
													variant="text"
													type="button"
													fullWidth
													className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
												>
													Skip for Now
												</Button>
											</Box>
										</Box>

									</>
								)}

								{isDesktopOrLaptop && (
									<>
										<Box className="d-flex justify-content-between align-items-center my-4">
											<Button
												onClick={handleSkipButton}
												variant="text"
												type="button"
												className="btn-web-back"
											>
												Skip for Now
											</Button>
											<Button
												type="submit"
												variant="contained"
												className="btn-web-next"
											>
												Next Step
											</Button>
										</Box>
									</>
								)}
							</div>
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};

FLUploadResumeForm.propTypes = {
	isLoading: bool,
	loading: func,
	resume: array,
	resumeData: array,
	getResumeData: func,
	uploadResumeData: func,
	userData: object,
	deleteResume: func,
	onNextHandler: func,
	// onBackHandler: func,
	updateUserData: func,
	pushMessage: func,
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const { isLoading } = state.root;
	const { resume, resumeData } = state.flUploadResume;
	return {
		isLoading,
		resumeData,
		resume,
		userData,
	};
};

export default connect(mapStateToProps, {
	getResumeData,
	uploadResumeData,
	loading,
	deleteResume,
	updateUserData,
	pushMessage,
})(FLUploadResumeForm);

