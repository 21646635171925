import React from 'react';
import '../../../common/fontsize.css';
import '../../../index.css';
import UserLogo from '../../../assets/user-icon.svg';
import CheckSquareLogo from '../../../assets/check-square-icon.svg';
import ShieldLogo from '../../../assets/shield-check-icon.svg';
import { Container, Grid, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PropTypes from 'prop-types';
import FLWebOnboarding from './FLWebOnboarding';
import Responsive from '../../../utils/responsive';

const theme = createTheme();
// const customBlueColor = '#416AD4';
const userText = "Build your profile ";
const checkSquareText = "Get verified";
const shieldText = "Personalise";

const FLOnboardingForm = ({ userData }) => {

    const history = useHistory();
    // const [isTerms, setIsTerms] = useState(false);

    // const handleCheckChange = (e) => {
    //     setIsTerms(e.target.checked);
    // };
    const onSubmit = (e) => {
        e.preventDefault();
        history.replace('fl-onboarding-steps');
    }

    return (
        <>
        {
          Responsive(
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '85vh' }}>
                    <Grid item xs={12}>
                        <div className="">
                            <Typography
                                variant="h4"
                                align="left"
                                alignItems="center"
                                className="fs-26 mb-1  fw-bold text-black font-skmodernist"
                                gutterBottom>
                                Hey <span style={{ color: '#864FFD' }}>{userData?.user?.name},</span> ready to get
                            </Typography>
                            <Typography variant="h4"
                                align="left"
                                alignItems="center"
                                className="fs-26 mb-2 fw-bold text-black font-skmodernist"
                                gutterBottom>
                                more customers?
                            </Typography>
                        </div>

                        <form style={{ marginTop: '45px' }} onSubmit={onSubmit}>
                            <div className="d-flex align-items-center">
                                <img
                                    src={UserLogo}
                                    alt="user-logo" width={24} height={24}
                                    className="user-logo me-3 mb-2"
                                />
                                <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                    {userText}
                                </Typography>
                            </div>
                            <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>
                            <div className="d-flex align-items-center">
                                <img
                                    src={CheckSquareLogo}
                                    alt="check-square-logo" width={24} height={24}
                                    className="check-square-logo me-3 mb-2"
                                />
                                <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                    {checkSquareText}
                                </Typography>
                            </div>
                            <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>
                            <div className="d-flex align-items-center">
                                <img
                                    src={ShieldLogo}
                                    alt="shield-logo" width={24} height={24}
                                    className="shield-logo me-3 mb-2"
                                />
                                <Typography className="text-start fs-14 font-gotham text-grey line-height" style={{ whiteSpace: 'pre-line' }}>
                                    {shieldText}
                                </Typography>
                            </div>
                            <hr style={{ borderTop: '1px solid #DEDEDE', opacity: '1' }}></hr>
                            <Typography className="fs-14 font-gotham text-grey mb-2">Create a personalized service page that reflects your brand. Come back to edit anytime</Typography>
                            {/* <Typography className="fs-14 font-gotham text-primary mb-2">Save as you go.</Typography> */}
                            <Typography className="fs-14 text-grey fw-normal mb-3 font-gotham">Stand out to potential clients with a complete profile</Typography>

                            {/* <Box>
                                <Typography className="text-start fs-13 font-gotham text-grey line-height">
                                    By signing up, you agree to{' '}
                                    <span className="fw-bold">Terms</span>. See how
                                    we use your data in our{' '}
                                    <span className="fw-bold">Privacy Policy</span>.
                                </Typography>
                            </Box> */}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
                                Get Started
                            </Button>

                        </form>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
         ,
         <FLWebOnboarding userData={userData} onSubmit={onSubmit} />
       )
     }
   </>
    );
};

FLOnboardingForm.propTypes = {
    userData: PropTypes.object,

};

const mapStateToProps = (state) => {
    const { userData } = state.signin;
    console.log("====state global", state.flProfileDetails);
    return {
        userData,
    };
};
export default connect(mapStateToProps, {})(FLOnboardingForm);
