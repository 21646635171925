import React, { useState, useEffect } from 'react';
// import { Container, Grid, Typography, TextField, FormControlLabel, Checkbox, Button, Link } from '@mui/material';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	InputAdornment,
	InputLabel,
	Typography,
} from '@mui/material';
import '../../index.css';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-web-logo.svg';
// import { ReactComponent as CallLogo } from '../../assets/mobile-icon.svg';
import {
	MuiTelInput,
	// matchIsValidTel
} from 'mui-tel-input';
import { connect } from 'react-redux';
// import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import { saveMobileNo, saveCountryCode, saveCountryCodeName, resetLoginData } from "./redux/actions/submit-mobileno-action"
import { maxMobileLengthObj } from '../../utils/countryCode';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import Checkbox from '@mui/material/Checkbox';

import PropTypes from 'prop-types';
import saveMobileNo, {
	getTemplateDataPage,
	resetLoginData,
	saveCountryCode,
	saveCountryCodeName,
} from './redux/actions/submit-mobileno-action';
import { resetTemplateData, setTemplateRender } from '../Templates/redux/action.js/template-action';
import {
	PRIV_POL_SLUG,
	PRIVACY_POLICY,
	T_AND_C_SLUG,
	TERMS,
} from '../../utils/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function WebLogin({
	mobile_number,
	country_code,
	country_code_name,
	// saveCountryCode,
	// saveCountryCodeName,
	// saveMobileNo,
	// pushMessage,
	errorMsg,
	handleChange,
	handleSubmit,
	mobileNumber,
	getTemplateDataPage,
	setTemplateRender,
	all_template,
	handlePrivacyPolicyCheckbox,
	privacyPolicyChecked,
	resetTemplateData,
	isLoading
}) {
	console.log('mobile_number is', mobile_number);
	// const [, setMobileNumber] = useState(''); // 8793655054
	const [mobileNumberLength, setMobileNumberLength] = useState(10);
	// const [error, setError] = useState();
	const history = useHistory();

	// const handleSubmit = (e) => {handleSubmit(e)}

	// const handleChange = (newValue, info) => {
	//     saveCountryCode(`+${info.countryCallingCode}`);
	//     if (info.countryCode) {
	//         saveCountryCodeName(info.countryCode);
	//     }
	//     if (info?.nationalNumber?.length > mobileNumberLength) {
	//         pushMessage(`Only ${mobileNumberLength} digits are allowed.`);
	//         return;
	//     }
	//     saveMobileNo(info.nationalNumber);
	//     setMobileNumber(info.nationalNumber);

	// };

	useEffect(() => {
		const numberLen = maxMobileLengthObj(country_code).mobileNumberLength;
		setMobileNumberLength(numberLen || 10);
	}, [country_code]);

	useEffect(() => {
		console.log(mobile_number);
		console.log(country_code);
		console.log(country_code_name);
	}, [mobile_number, country_code, country_code_name]);

	// const handleSave = () => {
	//     console.log("Mobile_no: ", typeof mobile_number)
	//     if (mobile_number === "") {
	//         setError("Please enter your mobile number.")
	//     }
	//     else if (mobile_number.length < 10) {
	//         // history.replace("/login");
	//         setError("Please enter 10 digit number")
	//     }
	//     else {
	//         history.push("/otp");
	//     }
	// }

	useEffect(() => {
		getTemplateDataPage();
	}, []);

	useEffect(() => {
		console.log('all_template :', all_template);
	}, []);

	const handleTerms = () => {
		setTemplateRender(TERMS);
		history.push(`/${T_AND_C_SLUG}`);
		resetTemplateData();
	};

	const handlePrivacy = () => {
		setTemplateRender(PRIVACY_POLICY);
		history.push(`/${PRIV_POL_SLUG}`);
		resetTemplateData();
	};

	return (
		<Container maxWidth="xl" className="p-0">
			{isLoading && (
				<Backdrop
					sx={{
						color: '#fff',
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			<Grid container className="root" style={{ height: '100vh' }}>
				{' '}
				{/* Apply class name from CSS */}
				{/* The image half */}
				<Grid item md={7} lg={7} className="bgImage">
					{' '}
					{/* Apply class name from CSS */}
				</Grid>
				{/* The content half */}
				<Grid
					item
					md={5}
					lg={5}
					xs={12}
					className="bgMain"
					sx={{ margin: 'auto' }}
				>
					{' '}
					{/* Apply class name from CSS */}
					<div className="login">
						{/* Demo content */}
						<Container>

							<Grid
								justifyContent="center"
								display={'flex'}
								alignContent={'center'}
							>
								<Grid item xs={12} md={9} lg={8} xl={8}>
									<div className="d-flex align-items-end mb-2">
										<Typography
											variant="h4"
											align="left"
											alignItems="center"
											className="fs-34 mb-0 me-2 fw-bold text-black font-skmodernist"
											gutterBottom
										>
											Welcome to
										</Typography>
										<ElynkerLogo />
									</div>

									<Typography
										variant="body1"
										align="left"
										className="fs-14 text-grey fw-normal font-gotham"
										gutterBottom
									>
										Login to get started
									</Typography>
									<form
										style={{ marginTop: '30px' }}
										onSubmit={(e) => handleSubmit(e)}
									>
										<InputLabel
											htmlFor="inputMobile"
											className="text-start fs-14 text-black fw-normal font-skmodernist"
										>
											Mobile Number
										</InputLabel>
										<div className="position-relative mb-2">
											<MuiTelInput
												forceCallingCode
												errorMsg
												focusOnSelectCountry
												color={'error'}
												helperText={errorMsg}
												// disableFormatting
												onlyCountries={['IN', 'AU']}
												defaultCountry={country_code_name}
												fullWidth
												sx={{ my: 1 }}
												value={mobileNumber}
												onChange={handleChange}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<div>
																{mobile_number.length}/{mobileNumberLength}
															</div>
														</InputAdornment>
													),
												}}
											/>
										</div>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'start',
												marginTop: 3,
											}}
										>
											<Checkbox
												checked={privacyPolicyChecked}
												onChange={handlePrivacyPolicyCheckbox}
												className='p-0'
												inputProps={{ 'aria-label': 'controlled' }}
											/>

											<Typography className="mt-1 mb-3 fs-13 text-grey text-center font-gotham">
												By signing up, you agree to{' '}
												<Button className="fs-12 p-0"
													sx={{
														textTransform: 'capitalize !important',
														padding: '0px !important',
														minWidth: '0px !important',
													}}
													onClick={handleTerms}>
													Terms.
												</Button>{' '}
												See how we use your data in our{' '}
												<Button className="fs-12 p-0"
													sx={{
														textTransform: 'capitalize !important',
														padding: '0px !important',
														minWidth: '0px !important',
													}}
													onClick={handlePrivacy}>
													Privacy Policy.
												</Button>
											</Typography>
										</Box>

										<Box className="text-end">
											<Button
												type="submit"
												fullWidth
												variant="contained"
												className="mt-3 py-2 primary-color-1 fs-15 font-skmodernist fw-bold"
												sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
											>
												Login
											</Button>
										</Box>
									</form>
								</Grid>
							</Grid>
						</Container>
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}

WebLogin.propTypes = {
	mobile_number: PropTypes.string,
	country_code: PropTypes.string,
	country_code_name: PropTypes.string,
	saveCountryCode: PropTypes.string,
	saveCountryCodeName: PropTypes.string,
	saveMobileNo: PropTypes.string,
	pushMessage: PropTypes.string,
	errorMsg: PropTypes.string,
	handleSubmit: PropTypes.func,
	handleChange: PropTypes.func,
	mobileNumber: PropTypes.string,
	getTemplateDataPage: PropTypes.func,
	setTemplateRender: PropTypes.func,
	all_template: PropTypes.object,
	handlePrivacyPolicyCheckbox: PropTypes.func,
	privacyPolicyChecked: PropTypes.any,
	setPrivacyPolicyChecked: PropTypes.func,
	resetTemplateData: PropTypes.func,
	isLoading:PropTypes.bool
};

const mapStatetoProps = (state) => {
	return {
		mobile_number: state.mobile.mobile_number,
		country_code: state.mobile.country_code,
		loading: state.root.isLoading,
		error: state.mobile.error,
		country_code_name: state.mobile.country_code_name,
		all_template: state.mobile.all_template,
	};
};

export default connect(mapStatetoProps, {
	saveMobileNo,
	saveCountryCode,
	saveCountryCodeName,
	resetLoginData,
	pushMessage,
	getTemplateDataPage,
	setTemplateRender,
	resetTemplateData
})(WebLogin);

