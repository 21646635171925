/* eslint-disable no-mixed-spaces-and-tabs */

import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
	Container,
	Grid,
	Typography,
	Divider,
	Button,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-icon-black.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ReactComponent as PremiumCard } from '../../assets/premium-bg-card.svg';
import { ReactComponent as FreeCard } from '../../assets/free-bg-card.svg';
import { ReactComponent as TickCircleIcon } from '../../assets/tick-circle-icon.svg';
import { ReactComponent as CrossCircleIcon } from '../../assets/cross-circle-icon.svg';
import { loading } from '../../store/actions/rootActions';
// import { ReactComponent as StarVectorIcon } from '../../assets/star-vector-icon.svg';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { ReactComponent as CheckCircleIcon } from '../../assets/check-circle-purple-icon.svg';
import { connect } from 'react-redux';
import {
	getAllSubscriptionList,
	getAllSubscriptionServices,
	clearSubscription,
} from './redux/action/susbcription-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
// import { ReactComponent as RadioButtonUncheckedIcon } from '../../assets/cross-circle-icon.svg';
import responsive from '../../utils/responsive';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const theme = createTheme();
function capitalizeFirstCharacter(str) {
	if (str.length === 0) {
		return str;
	}
	return str.charAt(0).toUpperCase() + str.slice(1);
}

const SubscriptionForm = ({
	getAllSubscriptionList,
	// getSingleSubscription,
	getAllSubscriptionServices,
	single_subscription_services,
	clearSubscription,
	// subscription,
	single_subscription,
	isLoading,
	loading,
}) => {
	const history = useHistory();

	React.useEffect(() => {
		clearSubscription();

		getAllSubscriptionList((r) => {
			r.forEach((element) => {
				getAllSubscriptionServices(element);
			});
		}, loading);
	}, [getAllSubscriptionList, getAllSubscriptionServices, clearSubscription]);

	const [allSingleServicesList, saveAllSingleServicesList] = useState([]);
	const [selected, setSelected] = useState('monthly');
	const [selectedPlan, setSelectedPlan] = useState('monthly');

	React.useEffect(() => {
		saveAllSingleServicesList(single_subscription_services);
	}, [single_subscription_services]);

	React.useEffect(() => {
		saveAllSingleServicesList(
			single_subscription_services.filter((r) => r[selected] === true)
		);
	}, [selected, single_subscription_services]);

	React.useEffect(() => {
		if (single_subscription && single_subscription?.length > 0) {
			let selecPlan = single_subscription?.filter(
				(p) => p?.plan_type === 'monthly'
			)[0];
			setSelectedPlan(selecPlan);
		}
	}, [single_subscription]);

	const handleSelect = (plan, planObj) => {
		setSelected(plan);
		setSelectedPlan(planObj);
	};

	return (
		<ThemeProvider theme={theme}>
			{isLoading && (
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			<Container component="main" maxWidth="xl">
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: 'auto' }}
				>
					<Grid item xs={12}>
						{/* <div>
							<AppBar position="static">
								<Toolbar>
									<IconButton
										size="large"
										edge="start"
										color="inherit"
										aria-label="menu"
										sx={{ mr: 2 }}
									>
										<MenuIcon />
									</IconButton>
									<div style={{ flexGrow: 1 }}>
										<ElynkerLogoComponent>
											<ElynkerLogo width={'100px'} height={'30px'} />
										</ElynkerLogoComponent>
									</div>
									{
										<div>
											<IconButton
												size="medium"
												aria-label="user icon"
												aria-controls="menu-appbar"
												aria-haspopup="true"
												color="inherit"
											>
												<SearchIcon />
											</IconButton>
										</div>
									}
								</Toolbar>
							</AppBar>
						</div> */}
						<div className="my-3 ms-2 d-flex align-items-center">
							<ArrowBackIosIcon onClick={() => {
								history.goBack()
							}} />
							<div style={{display:"flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
							<ElynkerLogoComponent>
								<ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
							</ElynkerLogoComponent>
								{/* <div>
									<IconButton
										size="medium"
										aria-label="user icon"
										aria-controls="menu-appbar"
										aria-haspopup="true"
										color="inherit"
										onClick={() => {
										//   setShowSearch((val) => !val);
										history.push('/search');
										}}
									>
										<SearchIcon />
									</IconButton>
								</div> */}
							</div>
						</div>
						<Divider
							component="div"
							className="main-divider mt-2 mb-3"
							role="presentation"
						/>

						<form style={{ marginTop: '0px' }}>
							{responsive(
								<Card className="subscription-card mb-3">
									<CardContent className="subscription-content">
										<Typography
											variant="h5"
											className="fs-16 text-offwhite mb-2 fw-bold font-skmodernist subscription-text"
											component="div"
										>
											Drive your <br></br>
											Growth with elynker
										</Typography>

										{/* <Typography
											variant="h5"
											className="fs-14 text-white mb-2 fw-normal font-gotham"
											component="div"
										>
											Plans starting from <br></br>
											<span className="fs-14 text-white fw-bold font-gotham">
												3833
											</span>
											/month.
										</Typography>

										<Typography className="fs-11 text-white fw-normal font-gotham">
											T&C apply
										</Typography> */}
									</CardContent>
								</Card>,
								<Card className="subscription-web-card mb-3">
									<CardContent className="subscription-web-content">
										<Typography
											variant="h5"
											className=" text-offwhite mb-2 fw-bold font-skmodernist subscription-web-text"
											component="div"
										>
											Drive your Growth with elynker
										</Typography>

										{/* <Typography
											variant="h5"
											className="fs-16 text-white mb-2 fw-normal font-gotham"
											component="div"
										>
											Plans starting from
											<span className="fs-16 text-white fw-bold font-gotham">
												3833
											</span>
											/month.
										</Typography>

										<Typography className="fs-13 text-white fw-normal font-gotham">
											T&C apply
										</Typography> */}
									</CardContent>
								</Card>
							)}

							<Grid container rowSpacing={3} columnSpacing={3}>
								<Grid item xs={12} md={4}>
									<Box className="">
										{responsive(
											<Typography
												variant="h4"
												align="left"
												alignItems="center"
												className="fs-20 mb-4 fw-bold font-skmodernist"
												gutterBottom
												style={{ whiteSpace: 'pre-line' }}
											>
												Select Premium Pack
											</Typography>,
											<Typography
												variant="h4"
												align="left"
												alignItems="center"
												className="fs-34 mb-4 fw-bold font-skmodernist"
												gutterBottom
												style={{ whiteSpace: 'pre-line' }}
											>
												Select Premium Pack
											</Typography>
										)}

										<Box className="cardsContainer">
											{single_subscription &&
												single_subscription?.map((plan) => (
													<>
														<Card
															key={plan?.id}
															className={`card ${
																selected === plan?.plan_type ? 'selected' : ''
															}`}
															onClick={() => {
																console.log('plan', plan);
																handleSelect(plan?.plan_type, plan);
															}}
														>
															<CardContent className="p-0">
																<Typography className="fs-16 fw-bold font-skmodernist text-darkgrey">
																	{capitalizeFirstCharacter(plan?.plan_type)}
																</Typography>
																<Typography className="fs-25 fw-bold font-skmodernist text-darkgrey">
																	₹{plan?.total_amount}{' '}
																	{/* <span className="fs-12 fw-normal font-skmodernist text-darkgrey">
																		(Incl. GST)
																	</span> */}
																</Typography>
																<Typography className="fs-12 fw-normal font-skmodernist text-darkgrey mb-4 ms-3">
																	(Incl. GST)
																</Typography>
																{!!Number(plan?.discount) && (
																	<Typography className="annual-discount">
																		{plan?.discount}% OFF
																	</Typography>
																)}
																{selected === plan?.plan_type && (
																	<CheckCircleIcon
																		className="checkIcon"
																		color="primary"
																	/>
																)}
															</CardContent>
														</Card>
													</>
												))}
										</Box>

										<Button
											type="button"
											fullWidth
											variant="contained"
											className="mt-4 mb-3 btn-subscribe"
											onClick={() => {
												console.log('selectedPlan', selectedPlan);
												if (selectedPlan) {
													history.replace('/processing', {
														selectedPlan: selectedPlan,
													});
												}
											}}
										>
											Pay Now
										</Button>
									</Box>
								</Grid>
								<Grid item xs={12} md={8}>
									<Box className="subcription-available-plans mb-3 mt-3">
										<Box className="plans-header">
											<Grid container spacing={3}>
												<Grid item xs={6}>
													<Typography variant="h6"></Typography>
												</Grid>
												<Grid item xs={3} className="ps-2">
													<FreeCard className="free-card" />
												</Grid>
												<Grid item xs={3} className="ps-2">
													<PremiumCard className="premium-card" />
												</Grid>
											</Grid>
										</Box>
										<Card
											variant="outlined"
											sx={{ transform: 'translateY(-3px) !important' }}
										>
											<CardContent className="plans-content">
												{allSingleServicesList &&
													allSingleServicesList.map((feature, index) => (
														<Grid
															key={index}
															container
															spacing={3}
															alignItems="center"
															className="mb-3"
														>
															<Grid item xs={6}>
																<Typography className="fs-14">
																	{feature.name}
																</Typography>
															</Grid>
															{/* <Grid item xs={3} className="free-field">
                                                    {feature.free ? (
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <CrossCircleIcon />
                                                        </Box>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={3} className="text-center premium-field">
                                                    {feature.premium ? (
                                                        <Box display="flex" alignItems="center" justifyContent="center">
                                                            <TickCircleIcon />
                                                        </Box>
                                                    ) : null}
                                                </Grid> */}
															<Grid
																item
																xs={3}
																className="free-field"
																textAlign="center"
															>
																{feature.free === true ? (
																	<TickCircleIcon color="success" />
																) : feature.free === false ||
																  feature?.free === undefined ? (
																	<CrossCircleIcon color="error" />
																) : (
																	<Typography className="fs-10 text-uppercase">
																		{feature.free}
																	</Typography>
																)}
															</Grid>
															<Grid
																item
																xs={3}
																className="premium-field"
																textAlign="center"
															>
																{feature.premium === true ? (
																	<TickCircleIcon color="success" />
																) : feature.premium === false ? (
																	<CrossCircleIcon color="error" />
																) : (
																	<Typography className="fs-10 text-uppercase">
																		{feature.premium}
																	</Typography>
																)}
															</Grid>
														</Grid>
													))}
											</CardContent>
										</Card>
									</Box>
								</Grid>
							</Grid>

							{/* <Box>
                <Card className="subscription-plan">
                  <Card className="card">
                    <CardContent className="p-0  mt-1">
                      <div className="star-vector-img">
                        <StarVectorIcon />
                      </div>
                      <div className="d-flex d-flex justify-content-between align-items-center mb-1">
                        <Typography
                          variant="body2"
                          className="fs-14 text-darkgrey fw-bold mb-0 font-gotham"
                        >
                          1 Month
                        </Typography>

                        <Typography
                          variant="body2"
                          className="fs-26 text-secondary fw-bold mb-0 font-skmodernist"
                        >
                          ₹4999
                          <span className="fs-14 text-secondary fw-normal mb-0 font-skmodernist">
                            +GST
                          </span>
                        </Typography>
                      </div>
                      <Typography
                        variant="body2"
                        className="fs-13 fw-normal text-gray font-gotham"
                      >
                        Talk to unlimited buyers - Respond to <br></br>
                        requirements posted by buyers Discover <br></br>
                        buyers by city and talk to them Silver <br></br>
                        member tag
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </Box> */}
						</form>
					</Grid>
				</Grid>
			</Container>
		</ThemeProvider>
	);
};
SubscriptionForm.propTypes = {
	subscription: PropTypes.any,
	getAllSubscriptionList: PropTypes.func,
	getSingleSubscription: PropTypes.func,
	single_subscription: PropTypes.any,
	single_subscription_services: PropTypes.any,
	clearSubscription: PropTypes.func,
	getAllSubscriptionServices: PropTypes.func,
	isLoading: PropTypes.bool,
	loading: PropTypes.any,
};
const mapStateToProps = ({ Subscription, root }) => {
	return {
		subscription: Subscription.subscription,
		single_subscription: Subscription.single_subscription,
		single_subscription_services: Subscription?.single_subscription_services,
		isLoading: root.isLoading,
	};
};
export default connect(mapStateToProps, {
	getAllSubscriptionList,
	clearSubscription,
	getAllSubscriptionServices,
	loading,
})(SubscriptionForm);

