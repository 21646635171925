import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// Common Reducers
import rootReducer from './rootReducer';
import messageBoxReducer from '../../components/MessageBox/redux/reducers/message-box-reducer';
//
import authReducer from './authReducer';
import submitMobileNo from '../../pages/Login/redux/reducer/submit-mobileno-reducer';
import signIn from '../../pages/Otp/redux/reducer/submit-code-reducer';
import signUp from '../../pages/CompleteProfile/redux/reducer/start-connecting-reducer';

// B2B REDUCERS
import b2bOnboardStepReducer from '../../pages/B2BOnboardingSteps/redux/reducers/b2b-onboarding-steps-reducer';
import companyDetailReducer from '../../pages/CompanyDetails/redux/reducers/company-details-form-reducer';
import offeredServicesReducer from '../../pages/OfferedServices/redux/reducers/offered-services-form-reducer';
import catalogProduct from '../../pages/AddCatalog/redux/reducer/add-catalog-reducer';
import myCatalogProduct from '../../pages/MyCatalog/redux/reducers/mycatalog-reducer';
//

// Country-wise Business Documents Reducers
import inBussinessDocumentFormReducer from '../../components/BussinessDocumentsForm/IN/redux/reducers/in-bussiness-document-form-reducer';
import auBussinessDocumentFormReducer from '../../components/BussinessDocumentsForm/AU/redux/reducers/au-bussiness-document-form-reducer';
//

import profileDetailsReducer from '../../pages/ProfileDetails/redux/reducers/profile-details-form-reducer';
import UpdateProfileReducer from '../../pages/UpdateProfile/store/reducer';
import userProfileReducer from '../../pages/Profile/redux/reducers/user-profile-reducer';

import flOnboardStepReducer from '../../pages/FreeLancer/FLOnboardingSteps/redux/reducers/fl-onboarding-steps-reducer';
import flUploadResumeReducer from '../../pages/FreeLancer/FL-UploadResume/redux/reducer/fl-upload-resume-reducer';
import flAddProffessionalRoleReducer from '../../pages/FreeLancer/FLAddProfessionalRole/redux/reducer/fl-add-profession-reducer';
import flAddBioReducer from '../../pages/FreeLancer/FL-AddBio/redux/reducer/fl-add-bio-reducer';
import flProfileDetailsReducer from '../../pages/FreeLancer/FL-Profile-Details/redux/reducer/fl-profile-details-reducer';
import flHourlyRateReducer from '../../pages/FreeLancer/FL-SetRate/redux/reducer/fl-set-rate-reducer';
import flUploadService from '../../pages/FreeLancer/FL-UploadServiceCatalog/redux/reducer/fl-upload-service-reducer';
import flAddService from '../../pages/FreeLancer/FLAddService/redux/reducer/add-service-reducer';
import b2cProfileReducer from '../../pages/B2CProfile/redux/reducer/b2c-profile-reducer';

import b2bVendorListing from '../../pages/B2BCategory/redux/reducers/b2b-category-reducer';
import b2bDetailsReducer from '../../pages/B2BDetail/redux/reducer/b2bDetailReducer';
import b2bBusinessReducer from '../../pages/B2BBusiness/redux/reducer/b2b-business-reducer';
import homepageReducer from '../../pages/Homepage/redux/reducer/homepage-reducer';
import templatePageReducer from '../../pages/Templates/redux/reducer.js/template-reducer';

import b2bRequirement from '../../pages/B2BRequirement/B2BSubmitRequirement/redux/reducer/b2b-submit-requirement-reducer';
import b2bRequirementList from '../../pages/ActiveRequirements/redux/reducer/action-requirement-reducer';
import solrSearch from '../../pages/Search/redux/reducer/search-reducer';
import homepageToolsReducer from '../../pages/Homepage/redux/reducer/homepage-tools-reducer';
import createJobReducer from '../../pages/CreateJobRequirement/redux/reducer/create-job-reducer';
import jobListReducer from '../../pages/JobPosted/redux/reducer/create-jobposted-reducer';
import jobSearchListReducer from "../../pages/JobListing/redux/reducer/job-listing-reducer";
import elynkerToolsReducer from '../../pages/ElynkerServices/redux/reducer/elynker-tools-reducer';
import creditVasReducer from '../../pages/CreditVASLanding/redux/reducer/credit-landing-reducer';
import personalServiceReducer from '../../pages/PersonalLoanLanding/redux/reducer/personal-service-reducer';
import jobDetailReducer from "../../pages/JobPostDetail/redux/reducer/job-post-details-reducer";
import jobApplyReducer from "../../pages/JobPostApply/redux/reducer/job-post-apply-reducer";
import jobApplicationReducer from '../../pages/JobApplications/redux/reducer/job-application-reducer';
import SubscriptionReducer from '../../pages/Subscription/redux/reducer/subscription-reducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    root: rootReducer,
    auth: authReducer,
    mobile: submitMobileNo,
    signin: signIn,
    signup: signUp,
    companyDetails: companyDetailReducer,
    offeredServices: offeredServicesReducer,
    b2bOnboardStep: b2bOnboardStepReducer,
    b2bDetails: b2bDetailsReducer,
    product: catalogProduct,
    mycatalog: myCatalogProduct,
    profileDetails: profileDetailsReducer,
    flOnboardingStep: flOnboardStepReducer,
    flUploadResume: flUploadResumeReducer,
    flAddProfRole: flAddProffessionalRoleReducer,
    flAddBio: flAddBioReducer,
    flHourlyRate: flHourlyRateReducer,
    flProfileDetails: flProfileDetailsReducer,
    flUploadService: flUploadService,
    messageBox: messageBoxReducer,

    // Country wise BusinessDocuments
    INBusinessDoc: inBussinessDocumentFormReducer,
    AUBusinessDoc: auBussinessDocumentFormReducer,
    //

    // User Profile
    userProfile: userProfileReducer,
    updatedProfile: UpdateProfileReducer,
    //
    flService: flAddService,
    // B2C Profile
    b2cData: b2cProfileReducer,
    b2bVendorlisting: b2bVendorListing,
    b2bBusinessReducer: b2bBusinessReducer,
    homepage: homepageReducer,
    b2bRequirement: b2bRequirement,
    b2bRequirementList: b2bRequirementList,
    template: templatePageReducer,
    solrSearch: solrSearch,
    homepageTools: homepageToolsReducer,
    createJobReducer: createJobReducer,
    jobListReducer: jobListReducer,
    jobSearchList: jobSearchListReducer,
    elynkerTools: elynkerToolsReducer,
    jobDetail : jobDetailReducer,
    jobPostApply : jobApplyReducer,
    creditVas: creditVasReducer,
    personalService: personalServiceReducer,
    Subscription: SubscriptionReducer,
    jobApplication: jobApplicationReducer
  });

export default createRootReducer;
