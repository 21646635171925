/*eslint no-empty: "error"*/
import { loading } from '../../../../store/actions/rootActions';
import {
	getVenderListApi,
	postEnquiryApi,
	getEnquiryApi,
	getVendorDetailsApi,
	postMembersContactedApi,
	getFreelancerVendorDetailsApi,
} from '../../../../utils/APIs/endpoints';
import { STATUS_CODE_200 } from '../../../../utils/constants';
import {
	SET_ALL_VENDORS,
	SET_VENDORS_DETAILS,
	SET_ENQUIRY,
	SET_VENDOR_CITY,
	SET_FILTER_VCITY,
	SAVE_FL_DETAILS,
	SET_FILTER_RATING,
	SET_FILTER_CATEGORY,
	SET_FILTER_CITY,
	SET_FILTER_NEARME,
	SET_FILTER_SORT,
	RESET_CITY_FILTER,
	RESET_ALL_VENDOR_DATA,
	MARK_NEAR_BY_CLICK,
} from '../types';

export const getVendorList = (type, parentID, params) => async (dispatch) => {
	try {
		const response = await getVenderListApi(type, parentID, params);
		if (response?.status === STATUS_CODE_200) {
			dispatch({ type: SET_ALL_VENDORS, payload: response?.data?.data });
			//dispatch vendors city
			const filtered = response?.data?.cities?.filter((e) => e !== null);
			if (params == null || params == '') {
				dispatch({ type: SET_VENDOR_CITY, payload: filtered });
			}
		} else {
			dispatch({ type: SET_ALL_VENDORS, payload: null });
		}
	} catch (error) {
		dispatch({ type: SET_ALL_VENDORS, payload: [] });
	}
};
export const getVendorDetails = (reg_detail) => async (dispatch) => {
	try {
		const response = await getVendorDetailsApi(reg_detail);
		if (response) {
			dispatch({ type: SET_VENDORS_DETAILS, payload: response });
		} else {
			dispatch({ type: SET_VENDORS_DETAILS, payload: null });
		}
		dispatch(loading(false));
	} catch (error) {
		dispatch({ type: SET_VENDORS_DETAILS, payload: [] });
		dispatch(loading(false));
	}
};
export const getFreelancerVendorDetails = (reg_detail) => async (dispatch) => {
	try {
		const response = await getFreelancerVendorDetailsApi(reg_detail);
		if (response) {
			dispatch({ type: SET_VENDORS_DETAILS, payload: response });
		} else {
			dispatch({ type: SET_VENDORS_DETAILS, payload: null });
		}
	} catch (error) {
		dispatch({ type: SET_VENDORS_DETAILS, payload: [] });
	}
};
export const postMemberContacted =
	(registrationId, member_phone) => async () => {
		try {
			const response = await postMembersContactedApi(
				registrationId,
				member_phone
			);
			if (response) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			// empty
		}
	};

export const postEnquiry = (id, payload) => async (dispatch) => {
	try {
		dispatch(loading(true));
		const enquiry = await postEnquiryApi(id, payload);
		dispatch({ type: SET_ENQUIRY, payload: { isTrue: true, ...enquiry } });
		return enquiry;
	} catch (error) {
		dispatch({ type: SET_ENQUIRY, payload: [] });
		return false;
	} finally {
		dispatch(loading(false));
	}
};
export const getEnquiryById = (id, type) => async (dispatch) => {
	try {
		const enquiry = await getEnquiryApi(id, type);
		dispatch({ type: SET_ENQUIRY, payload: { ...enquiry[0] } });
		return enquiry;
	} catch (error) {
		dispatch({ type: SET_ENQUIRY, payload: [] });
		return error;
	}
};

export const setAllVCity = (city) => async (dispatch) => {
	dispatch({ type: SET_VENDOR_CITY, payload: city });
};

export const setFilterVCity = (city) => async (dispatch) => {
	dispatch({ type: SET_FILTER_VCITY, payload: city });
};
export const saveFLDetails = (Details) => async (dispatch) => {
	dispatch({ type: SAVE_FL_DETAILS, payload: Details });
};

export const setFilterRating = (data) => async (dispatch) => {
	dispatch({ type: SET_FILTER_RATING, payload: data });
};

export const setFilterCategory = (data) => async (dispatch) => {
	dispatch({ type: SET_FILTER_CATEGORY, payload: data });
};

export const setVendorFilterCity = (data) => async (dispatch) => {
	dispatch({ type: SET_FILTER_CITY, payload: data });
};

export const setFilterNearMe = (data) => async (dispatch) => {
	dispatch({ type: SET_FILTER_NEARME, payload: data });
};

export const setFilterSort = (data) => async (dispatch) => {
	dispatch({ type: SET_FILTER_SORT, payload: data });
};

export const resetVendorFilterCity = () => async (dispatch) => {
	dispatch({ type: RESET_CITY_FILTER });
};
export const resetAllVendorData = () => async (dispatch) => {
	dispatch({ type: RESET_ALL_VENDOR_DATA });
};

export const setNearByClickState = (data) => async (dispatch) => {
	dispatch({ type: MARK_NEAR_BY_CLICK, payload: data });
};
