import {
  getApiTrendingProductCategory,
  postApiTrendingProductCategory,
  getApiTrendingJobCategory,
  postApiTrendingJobCategory,
  getVendorSearchAPI,
} from '../../../../utils/APIs/endpoints';
// import { solrSearchData } from "../../../../utils/constants";
import {
  GET_SEARCH_VENDORS,
  SEARCH_TEXT,
  RESET_SEARCH_DATA,
  SAVE_TREDNING_PRODUCT_CATEGORIES,
  SAVE_TREDNING_JOB_CATEGORIES,
} from '../types';

export const searchVendors = (text) => async (dispatch) => {
  try {
    const response = await getVendorSearchAPI(text);
    if (response?.status === 200) {
      // dispatch({ type: GET_SEARCH_VENDORS, payload: response?.data?.data });
      let vendors,
        jobs = [];
      if (response?.data?.vendors) {
        vendors = response?.data?.vendors;
      }
      if (response?.data?.jobs) {
        jobs = response?.data?.jobs;
      }
      let newData = [...vendors, ...jobs];
      dispatch({ type: GET_SEARCH_VENDORS, payload: newData });
    } else {
      dispatch({ type: GET_SEARCH_VENDORS, payload: null });
    }
  } catch (error) {
    dispatch({ type: GET_SEARCH_VENDORS, payload: [] });
  }
};

export const getTredningProductsCategory = (text) => async (dispatch) => {
  try {
    const response = await getApiTrendingProductCategory(text);
    console.log('getTredningProducts response', response.data);
    if (response?.status === 200) {
      dispatch({
        type: SAVE_TREDNING_PRODUCT_CATEGORIES,
        payload: response?.data.data,
      });
    } else {
      dispatch({ type: SAVE_TREDNING_PRODUCT_CATEGORIES, payload: null });
    }
  } catch (error) {
    console.log('getTredningProducts error', error);
    dispatch({ type: SAVE_TREDNING_PRODUCT_CATEGORIES, payload: [] });
  }
};

export const postTredningProductsCategory = (registrationId) => async () => {
  try {
    await postApiTrendingProductCategory(registrationId);
    // console.log('getTredningProducts response', response.data);
    // if (response?.status === 200) {
    //   // dispatch({
    //   //   type: SAVE_TREDNING_PRODUCT_CATEGORIES,
    //   //   payload: response?.data.data,
    //   // });
    // } else {
    //   dispatch({ type: SAVE_TREDNING_PRODUCT_CATEGORIES, payload: null });
    // }
  } catch (error) {
    console.log('getTredningProducts error', error);
    // dispatch({ type: SAVE_TREDNING_PRODUCT_CATEGORIES, payload: [] });
  }
};

export const getTredningJobCategory = (text) => async (dispatch) => {
  try {
    const response = await getApiTrendingJobCategory(text);
    // console.log('getTredningJobCategory response', response.data);
    if (response?.status === 200) {
      dispatch({
        type: SAVE_TREDNING_JOB_CATEGORIES,
        payload: response?.data.data,
      });
    } else {
      dispatch({ type: SAVE_TREDNING_JOB_CATEGORIES, payload: null });
    }
  } catch (error) {
    console.log('getTredningJobCategory error', error);
    dispatch({ type: SAVE_TREDNING_JOB_CATEGORIES, payload: [] });
  }
};

export const postTredningJobCategory = (jobId) => async () => {
  try {
    await postApiTrendingJobCategory(jobId);
    // console.log('postTredningJobCategory response', response.data);
    // if (response?.status === 200) {
    // } else {
    //   // dispatch({ type: SAVE_TREDNING_JOB_CATEGORIES, payload: null });
    // }
  } catch (error) {
    console.log('postTredningJobCategory error', error);
    // dispatch({ type: SAVE_TREDNING_JOB_CATEGORIES, payload: [] });
  }
};

export const saveSearchText = (text) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_TEXT, payload: text });
  } catch (err) {
    dispatch({ type: SEARCH_TEXT, payload: '' });
  }
};

export const resetSearchData = () => async (dispatch) => {
  dispatch({ type: RESET_SEARCH_DATA });
};

export const resetSearchVendors = () => async (dispatch) => {
  dispatch({ type: GET_SEARCH_VENDORS, payload: [] });
};
