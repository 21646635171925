/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint react/prop-types: 0 */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useMemo } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Backdrop,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextareaAutosize,
  FormHelperText,
  Autocomplete,
  Tabs,
  Tab,
  Drawer,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Divider from '@mui/material/Divider';
import { func, object, bool, array, any, string } from 'prop-types';
// import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { loading } from '../../store/actions/rootActions';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { ReactComponent as DownloadIcon } from '../../assets/download-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import { ReactComponent as ResumeIcon } from '../../assets/resume-icon.svg';
import { ReactComponent as AddCertificationIcon } from '../../assets/add-certification-icon.svg';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import {
  uploadResumeData,
  getResumeData,
  deleteResume,
} from '../FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { connect, useDispatch } from 'react-redux';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import {
  DATE_MAX_YEAR,
  DATE_MIN_YEAR,
  formatedDate,
} from '../../utils/constants';
import { POSTOFFICE_PINCODE, RESUME_BASE_URL } from '../../utils/APIs/api';
import useValidation from '../../hooks/useValidation';
import {
  uploadBannerData,
  deleteBanner,
} from '../FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import moment from 'moment';
import { BANNER_BASE_URL } from '../../utils/APIs/api';
import {
  setProfileDate,
  setProfileEducation,
  addCertificateData,
  setProfileLanguage,
  updateCertificateData,
  deleteCertificateData,
  getMasterEducationDetails,
  getMasterLanguageDetails,
  setAddress1,
  setAddress2,
  setCity,
  setState,
  setAreaName,
  setPincode,
} from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
// import { getRegistrationData } from '../../utils/APIs/endpoints';
import {
  DELETED_PROJECT_DATA,
  REMOVE_BANNER_DATA,
  SAVE_PROJECT_DATA,
} from '../FreeLancer/FL-AddBio/redux/types';
import { putUpdateUser } from '../../utils/APIs/endpoints';
import {
  FRL_DELETED_CERTIFICATE_DATA,
  FRL_Delete_CERTIFICATE_DATA,
  FRL_SET_CERTIFICATE_DATA,
  FRL_UPDATE_CERTIFICATE_DATA,
} from '../FreeLancer/FL-Profile-Details/redux/types';
import {
  DELETE_RESUME,
  STORE_DELETED_RESUME,
  UPDATE_UPLOADED_RESUME,
} from '../FreeLancer/FL-UploadResume/redux/types';
import {
  RESET_UPDATED_NEW_DATA,
  UPDATE_SIGNIN_DATA,
  UPDATE_USER_DETAILS,
} from '../Otp/redux/types';
import { getFRLProfileData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { getCertificateData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { compressFile } from '../../utils/compressFile';
import {
  putUpdateAboutCompanyDetails,
  getUpdateAboutCompanyDetails,
} from '../UpdateProfile/store/actions/profileActions';
import { putUpdateUserDetails } from '../UpdateProfile/store/actions/profileActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { styled } from '@mui/material/styles';
import { MuiChipsInput } from 'mui-chips-input';
import { getFreelancerVendorDetails } from '../B2BCategory/redux/actions/b2b-category-action';
import Responsive from '../../utils/responsive';
import AddCertificationModal from './AddCertificationModal';
import MobileNoWidget from './MobileNoWidget';
import {
  getUpdatedRegistrationData,
  getUserRegistrationData,
} from '../Profile/redux/actions/user-profile-actions';
import { setWhatsappNumber } from '../ProfileDetails/redux/actions/profile-details-form-action';
import axios from 'axios';
import { stickyButtonStyle } from '../../utils/sx-styles';
import { useMediaQuery } from 'react-responsive';
import { ConfirmationDialog } from '../../components/ActionPopup';

const theme = createTheme();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const FlUpdateProfile = ({ isLoading }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className="b2b-detail-tabpanel"
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ py: 3, px: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // console.log("JobID data on details :", job);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        {isLoading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  dispatch({ type: RESET_UPDATED_NEW_DATA });
                  history.goBack();
                }}
              />
              <ElynkerLogoComponent>
                <ElynkerLogo className="ms-3" width={'100px'} height={'30px'} />
              </ElynkerLogoComponent>
            </div>
            <Divider
              component="div"
              className="main-divider mb-3"
              role="presentation"
            />
            <Typography
              variant="h4"
              align="left"
              alignItems="center"
              className="fs-20 mt-4 mb-2 fw-bold font-skmodernist"
              gutterBottom
            >
              Complete your Profile
            </Typography>

            <Typography
              variant="body1"
              align="left"
              className="fs-14 text-grey fw-normal font-gotham"
              gutterBottom
            >
              Better we know you better we can serve
            </Typography>
            <Divider
              component="div"
              className="main-divider mt-3"
              role="presentation"
            />
            <form
              style={{ marginTop: '0px' }}
              onSubmit={(e) => {
                e.preventDefault();
                // saveProfileDetails();
              }}
            >
              {Responsive(
                <>
                  <div className="custom-accordion pro-details-accordion">
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 font-skmodernist text-black fw-bold"
                      >
                        Professional Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <ProfessionalDetailsComponent />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <Divider
                    component="div"
                    className="main-divider my-1"
                    role="presentation"
                  />
                  <div className="custom-accordion pro-details-accordion">
                    <Accordion defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="fs-15 font-skmodernist text-black fw-bold"
                      >
                        Personal Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <PersonalDetailsComponent />
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <Divider
                    component="div"
                    className="main-divider my-1"
                    role="presentation"
                  />
                </>,
                <Box sx={{ width: '100%' }}>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="secondary tabs example"
                    className="b2b-detail-tabs"
                  >
                    <Tab
                      label="Professional Details"
                      className="fs-15 font-skmodernist text-capitalize"
                      {...a11yProps(0)}
                    />
                    {/* <Tab label="Catalog"  className="fs-15 font-skmodernist text-capitalize" {...a11yProps(1)} /> */}

                    <Tab
                      label={
                        <React.Fragment>
                          <span className="fs-15 font-skmodernist text-capitalize b2b-detail-tabs-title">
                            Personal Details
                          </span>
                        </React.Fragment>
                      }
                      {...a11yProps(1)}
                    />
                  </Tabs>

                  <TabPanel value={value} index={0}>
                    <ProfessionalDetailsComponent />
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    <PersonalDetailsComponent />
                  </TabPanel>
                </Box>
              )}

              <ConSaveProfileDetailsWidget />
            </form>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

FlUpdateProfile.propTypes = {
  isLoading: bool,
  loading: func,
};
const mapStateToProps = (state) => {
  console.log('===global state', state);
  const { isLoading } = state.root;
  return {
    isLoading,
  };
};

export default connect(mapStateToProps, {})(FlUpdateProfile);

// Components for tab

const ProfessionalDetailsComponent = () => {
  return (
    <>
      <Grid container spacing={2} columnSpacing={10} className="">
        <ConResumeUploadWidget />
      </Grid>

      <Grid container spacing={2} columnSpacing={10} className="">
        <ConProfessionalRoleWidget />

        <ConAboutYouWidget />
      </Grid>

      <Grid container spacing={2} columnSpacing={10} className="">
        <ConProjectSelectWidget />
      </Grid>

      <Grid container spacing={2} columnSpacing={10} className="">
        <ConProjectAccordionWidget />
      </Grid>
    </>
  );
};

const ResumeUploadWidget = ({
  resume,
  pushMessage,
  // resumeData,
  // deletedResumeData
}) => {
  const dispatch = useDispatch();
  const { validateInput, errors } = useValidation();
  const resumeUploadRef = useRef(null);
  const [doc, setDoc] = useState();
  const [resDelOpen, setresDelOpen] = useState(false);
  const handleResumeOpen = () => setresDelOpen(true);
  const handleResumeClose = () => setresDelOpen(false);
  const [openReupload, setOpenReupload] = useState(false);
  const handleOpenReupload = () => setOpenReupload(true);
  const handleCloseReupload = () => setOpenReupload(false);

  const handleClose = () => {
    setresDelOpen(false);
  };

  const handleResumeUploadFile = async (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile?.type.includes('.document') ||
      selectedFile?.type.includes('pdf') ||
      selectedFile?.type.includes('msword')
    ) {
      if (selectedFile?.size > 2000000) {
        validateInput('flResumeUpload', selectedFile?.size);
        return;
      } else {
        validateInput('flResumeUpload', 0);
      }
      compressFile(e.target.files);
      selectedFile.isLocal = true;
      console.log('=====selectedFile', selectedFile);
      dispatch({ type: UPDATE_UPLOADED_RESUME, payload: selectedFile });
      e.target.value = null;
    } else {
      pushMessage('Please add Valid file type');
      e.preventDefault();
      e.target.value = null;
    }
  };

  const handleResumeUploadClick = () => {
    if (resume && resume.length >= 1) {
      // show alert message
      handleOpenReupload();
    } else {
      resumeUploadRef.current.click();
    }
  };
  const handleConfirmReupload = () => {
    handleCloseReupload();
    resumeUploadRef.current.click();
  };
  const handleConfirmDelete = () => {
    handleResumeClose();
    dispatch({ type: DELETE_RESUME, payload: doc.name });
    dispatch({ type: STORE_DELETED_RESUME, payload: doc });
  };
  return (
    <>
      <ConfirmationDialog
        open={openReupload}
        handleClose={handleCloseReupload}
        title="Elynker"
        content="Are you sure you want to replace the Existing Resume?"
        onConfirm={handleConfirmReupload}
        confirmText="Yes"
        cancelText="No"
      />
      <ConfirmationDialog
        open={resDelOpen}
        handleClose={handleResumeClose}
        title="Elynker"
        content={`Are you sure you want to delete the Resume?`}
        onConfirm={handleConfirmDelete}
        confirmText="Yes"
        cancelText="No"
      />
      <Grid item md={6} lg={6} xs={12}>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={resumeUploadRef}
          onChange={handleResumeUploadFile}
          id="file"
          accept=".doc,.docx,.pdf"
        />
        <Button
          type="button"
          fullWidth
          variant="outlined"
          className="add-button mt-2 fs-15 font-skmodernist fw-bold"
          sx={{
            borderRadius: '6px',
            textTransform: 'capitalize',
          }}
          onClick={handleResumeUploadClick}
        >
          <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
          <Typography className="ms-2 text-primary-1">
            Upload Your Resume
          </Typography>
        </Button>
        <Typography class="text-muted fs-12">
          (PDF or DOCX files only, maximum size: 2 MB)
        </Typography>
        <FormHelperText sx={{ color: 'red' }} id="resume-upload-helper-text">
          {errors?.flResumeUpload}
        </FormHelperText>

        {resume &&
          resume.map((item, index) => {
            const formatDate = formatedDate(item.createdAt);
            console.log('resume data:', item);

            return (
              <div
                className="d-flex justify-content-between align-items-center my-2"
                key={index}
              >
                <div className="d-flex align-items-center">
                  <ResumeIcon />
                  <div>
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-14 text-black fw-normal mb-0 ms-3 font-skmodernist"
                      gutterBottom
                    >
                      {item?.name && item?.name?.length > 20
                        ? item?.name?.slice(0, 20) + '...'
                        : item?.name ||
                            (item?.freelancer_resume &&
                              item?.freelancer_resume?.length > 20)
                          ? item?.freelancer_resume?.slice(0, 20) + '...'
                          : item?.freelancer_resume}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-13 text-grey fw-normal mb-0 ms-3 font-gotham"
                      gutterBottom
                    >
                      Uploaded on: {formatDate}
                    </Typography>
                  </div>
                </div>
                <div className="d-flex">
                  <IconButton
                    className="cat-edit-icon"
                    onClick={() => {
                      saveAs(
                        RESUME_BASE_URL + item?.freelancer_resume,
                        item?.freelancer_resume
                      );
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    className="cat-delete-icon"
                    onClick={() => {
                      setDoc(item);
                      handleResumeOpen();
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
      </Grid>
    </>
  );
};

const ProfessionalRoleWidget = ({ freelancer_role }) => {
  const dispatchRL = useDispatch();
  const { validateInput, errors } = useValidation();
  const onChangeRole = (e) => {
    const input = e.target.value;
    // const regex = /^[a-zA-Z\s]*$/;
    // if (regex.test(input)) {
    // setProfessionalRole(input)
    // setRole(input);
    validateInput('flRole', input);
    dispatchRL({
      type: UPDATE_SIGNIN_DATA,
      payload: { freelancer_role: input },
    });
    // }
    // setCompanyName(input);
  };
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputName"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Your Professional Role
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <OutlinedInput
          id="inputName"
          type="text"
          placeholder="Enter your role"
          // autoFocus
          fullWidth
          margin="none"
          inputProps={{
            maxLength: 50,
          }}
          value={freelancer_role}
          onChange={onChangeRole}
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.flRole}
      </FormHelperText>
    </Grid>
  );
};

const AboutYouWidget = ({ freelancer_bio }) => {
  const [count, setCount] = useState(freelancer_bio?.length | 0);
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();
  const onChangeBio = (e) => {
    if (e.target.value.length > 5000) {
      pushMessage('The number of characters exceeded.');
      return;
    } else {
      let bio = e.target.value;
      setCount(bio.length);
      validateInput('about', bio);
      dispatch({ type: UPDATE_SIGNIN_DATA, payload: { freelancer_bio: bio } });
      // setAddBio(bio);
    }
  };
  return (
    <Grid item md={6} lg={6} xs={12}>
      <div className="d-flex justify-content-between">
        <InputLabel
          htmlFor="inputCompanyName"
          className="text-start fs-14 text-black font-skmodernist"
        >
          About you
        </InputLabel>

        <Typography
          variant="body1"
          align="left"
          className="fs-12 text-lightgrey fw-normal mb-0 font-gotham"
          gutterBottom
        >
          {5000 - count} Characters Left
        </Typography>
      </div>

      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <TextareaAutosize
          id="inputAdditionalInfo"
          placeholder="Enter Bio"
          minRows={3} // You can adjust the number of rows as needed
          maxRows={2} // You can adjust the number of rows as needed
          maxLength={5000}
          onChange={onChangeBio}
          value={freelancer_bio}
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.about}
      </FormHelperText>
    </Grid>
  );
};

const ProjectSelectWidget = ({ pushMessage }) => {
  const bannerUploadRef = useRef(null);
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();

  const handleProjectUploadClick = () => {
    bannerUploadRef.current.click();
  };
  const handleProjectUploadFile = async (e) => {
    let selectedFile = e?.target?.files[0];
    if (
      selectedFile?.type.includes('.document') ||
      selectedFile?.type.includes('pdf') ||
      selectedFile?.type.includes('msword') ||
      selectedFile?.type.includes('jpeg') ||
      selectedFile?.type.includes('jpg') ||
      selectedFile?.type.includes('png')
    ) {
      if (selectedFile?.size > 2000000) {
        validateInput('flWorkUpload', selectedFile?.size);
        return;
      } else {
        validateInput('flWorkUpload', 0);
      }
      if (selectedFile) {
        selectedFile.isLocal = true;
        dispatch({ type: SAVE_PROJECT_DATA, payload: selectedFile });
        console.log('Resume File:', selectedFile);
      }
    } else {
      pushMessage('Please add Valid file type');
    }
  };
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputCompanyName"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Upload your work
      </InputLabel>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={bannerUploadRef}
        onChange={handleProjectUploadFile}
        id="file"
        accept=".doc,.docx,.pdf,.png,.jpeg,.jpg"
      />
      <Button
        type="button"
        fullWidth
        variant="outlined"
        className="add-button mt-2 fs-15 font-skmodernist fw-bold"
        sx={{
          borderRadius: '6px',
          textTransform: 'capitalize',
        }}
        onClick={handleProjectUploadClick}
      >
        <img src={AddBtnIcon} alt="add-btn-icon" className="user-logo" />
        <Typography className="ms-2 text-primary-1">Upload</Typography>
      </Button>
      <Typography class="text-muted fs-12">
        (PDF or DOCX or JPEG files only, maximum size: 2 MB)
      </Typography>
      <FormHelperText sx={{ color: 'red' }} id="work-upload-helper-text">
        {errors?.flWorkUpload}
      </FormHelperText>
    </Grid>
  );
};

const ProjectAccordionWidget = ({ projects }) => {
  const dispatch = useDispatch();
  const [projDelDoc, setDelProjDoc] = useState();
  const [projDelOpen, setprojDelOpen] = useState(false);
  const hanProjecDelOpen = () => setprojDelOpen(true);
  const hanProjecDelClose = () => setprojDelOpen(false);
  const handleConfirmDelete = () => {
    hanProjecDelClose();
    console.log('=====projDelDoc', projDelDoc);
    dispatch({
      type: DELETED_PROJECT_DATA,
      payload: projDelDoc,
    });
    dispatch({
      type: REMOVE_BANNER_DATA,
      payload: projDelDoc.banner_image,
    });
    // Additional deletion logic if needed
  };
  return (
    <>
      <ConfirmationDialog
        open={projDelOpen}
        handleClose={hanProjecDelClose}
        title="Elynker"
        content="Are you sure you want to delete the Project?"
        onConfirm={handleConfirmDelete}
        confirmText="Yes"
        cancelText="No"
      />
      <Grid
        item
        md={6}
        lg={6}
        xs={12}
        className="custom-accordion product-accordion mt-0"
      >
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="fs-15 font-skmodernist text-black fw-bold"
          >
            Projects
          </AccordionSummary>
          <AccordionDetails>
            {projects &&
              projects?.map((item, index) => {
                console.log('projects === : ' + item);
                const formatedDate = moment(item?.createdAt).format(
                  'DD-MM-YYYY'
                );
                console.log('Formatted date : ' + formatedDate);
                return (
                  <div
                    className="d-flex justify-content-between align-items-center my-2"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div>
                        <Typography
                          variant="body1"
                          align="left"
                          className="fs-14 text-black fw-normal mb-0 font-skmodernist"
                          style={{ maxWidth: '100px' }}
                          gutterBottom
                        >
                          {item?.banner_name || item?.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          align="left"
                          className="fs-13 text-grey fw-normal mb-0 font-gotham"
                          gutterBottom
                        >
                          Uploaded on: {formatedDate}
                        </Typography>
                      </div>
                    </div>
                    <div className="d-flex">
                      <IconButton
                        className="cat-edit-icon"
                        onClick={() => {
                          saveAs(
                            BANNER_BASE_URL + item?.banner_image,
                            item?.banner_image
                          );
                        }}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        className="cat-delete-icon"
                        onClick={() => {
                          setDelProjDoc(item);
                          hanProjecDelOpen();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                );
              })}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

const PersonalDetailsComponent = () => {
  // useEffect(() => {
  //   const email = userData?.user?.email;
  //   setFormData(email);
  // }, []);

  // useEffect(() => {
  //   getMasterLanguageDetails();
  // }, [getMasterLanguageDetails]);

  // useEffect(() => {
  //   console.log('=========DOB VALUE==========', dob);
  // }, [dob]);
  // useEffect(() => {
  //   console.log('=========CERTIFICATE VALUE==========', certificates);
  // }, [certificates]);

  // useEffect(() => {
  //   getMasterEducationDetails();
  // }, [getMasterEducationDetails]);

  // const getEducationStatusObject = () => {
  //   let statusObj = null;
  //   if (educationOptions?.length > 0) {
  //     statusObj = educationOptions?.find((item) =>
  //       item?.value?.toLowerCase().includes(education?.toLowerCase())
  //     );
  //   }
  //   // const valuesArray = Object.values(educationOptions);
  //   // console.log("ValuesArray data @@@@: ", valuesArray);

  //   // console.log("statusObj_data :", statusObj);
  //   if (statusObj) {
  //     return statusObj;
  //   } else {
  //     return null;
  //   }
  // };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={10} className="">
        <ConnectedNameWidget />

        <MobileNoWidget />

        <ConEmailIDWidget />

        {/* <FreelancerCategoryWidget /> */}

        <ConDateOfBirthWidget />

        <ConWhatsAppNumberWidget />

        <ConEducationWidget />

        <ConLanguageWidget />

        <ConSkillsWidget />
      </Grid>
      {/* <ConAddCertificateWidget /> */}
      <ConnectedAddressWidget />

      <ConCertificateAccordion />
    </>
  );
};

const NameWidget = ({ name }) => {
  const dispatch = useDispatch();
  const { validateInput, errors } = useValidation();
  const handleChange = (e) => {
    const { value, id } = e.target;
    console.log('====handleChange', value, id);
    if (id === 'name') {
      validateInput('name', value);
      dispatch({ type: UPDATE_SIGNIN_DATA, payload: { name: value } });
    } else if (id === 'email') {
      validateInput('email', value);
      dispatch({ type: UPDATE_SIGNIN_DATA, payload: { email: value } });
      // setFormData({
      //   email: value,
      // });
    }
  };
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputName"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Name
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <OutlinedInput
          id="name"
          type="text"
          placeholder="Enter your name"
          // autoFocus
          fullWidth
          value={name}
          onChange={handleChange}
          margin="none"
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.name}
      </FormHelperText>
    </Grid>
  );
};

const EmailIDWidget = ({ email }) => {
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value, id } = e.target;
    console.log('====handleChange', value, id);
    if (id === 'name') {
      validateInput('name', value);
      dispatch({ type: UPDATE_SIGNIN_DATA, payload: { name: value } });
    } else if (id === 'email') {
      validateInput('email', value);
      dispatch({ type: UPDATE_USER_DETAILS, payload: { email: value } });
      // setFormData({
      //   email: value,
      // });
    }
  };
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputEmail"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Email ID
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <OutlinedInput
          id="email"
          type="text"
          placeholder="Enter your email id"
          fullWidth
          value={email}
          onChange={handleChange}
          margin="none"
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.email}
      </FormHelperText>
    </Grid>
  );
};

const FreelancerCategoryWidget = () => {
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputCompanyName"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Freelance Category
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <MultiSelect
          fullWidth
          productCategory={[{ id: 1, title: 'Test' }]}
          selectedOptions={[{ id: 1, title: 'Test' }]}
          placeholder1={''}
          placeholder2={''}
          maxSelection={3}
          readOnly={true}
          disabled={true}
        />
      </FormControl>
    </Grid>
  );
};

const DateOfBirthWidget = ({ dob }) => {
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="dateOfBirth"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Date of Birth
      </InputLabel>
      <LocalizationProvider className="test-0" dateAdapter={AdapterMoment}>
        <DemoContainer className="test-1" components={['DatePicker']}>
          <DatePicker
            className="test-2"
            format="DD-MM-YYYY"
            renderInput={(params) => <TextField {...params} fullWidth />}
            value={moment(dob)}
            onChange={(newValue) => {
              validateInput('dob', newValue);
              dispatch({
                type: UPDATE_SIGNIN_DATA,
                payload: { dob: newValue },
              });
            }}
            maxDate={moment().subtract(DATE_MAX_YEAR, 'years')}
            minDate={moment().subtract(DATE_MIN_YEAR, 'years')}
            disableFuture
          />
        </DemoContainer>
      </LocalizationProvider>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.dob}
      </FormHelperText>
    </Grid>
  );
};

const WhatsAppNumberWidget = ({
  whatsapp,
  mobile_number,
  setWhatsappNumber,
  userData,
  userDataNew,
}) => {
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();
  const isExistingMobileasWhatsapp =
    userData?.user?.mobile_number === userDataNew?.registration?.whatsapp_number
      ? true
      : false;
  const [isNotExistingMobile, setisNotExistingMobile] = React.useState(
    isExistingMobileasWhatsapp
  );
  const hanldleIsExistingMobile = () => {
    setWhatsappNumber(userData.user?.mobile_number);
    setisNotExistingMobile((prev) => !prev);
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: { whatsapp_number: userData.user?.mobile_number },
    });
  };
  const handleWhatsappChange = (e) => {
    setisNotExistingMobile(false);
    const input = e.target.value;
    const regex = /^[0-9]{0,10}$/;
    if (regex.test(input)) {
      setWhatsappNumber(input);
      dispatch({
        type: UPDATE_SIGNIN_DATA,
        payload: { whatsapp_number: input },
      });
    }
    // validateInput("whatsapp", input)
  };

  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputWhatsppNumber"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Add WhatsApp number
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <OutlinedInput
          id="inputWhatsppNumber"
          type="number"
          placeholder="Add WhatsApp number"
          onKeyDown={(evt) =>
            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
          }
          inputProps={{
            maxLength: 10,
          }}
          fullWidth
          margin="none"
          value={userDataNew?.registration?.whatsapp_number || ''}
          onChange={handleWhatsappChange}
        />
        <Box display="flex" flexDirection="row" alignItems="center">
          <Checkbox
            edge="start" // Aligning checkbox to the left
            onChange={hanldleIsExistingMobile}
            checked={isNotExistingMobile}
            inputProps={{
              'aria-labelledby': 'checkbox-list-secondary',
            }}
            sx={{
              color: '#D4D4D4',
              '&.Mui-checked': {
                color: '#416AD4',
              },
            }}
          />
          <Typography
            sx={{
              color: '#7c7d7d',
              mr: 1,
              fontSize: theme.typography.fontSize,
            }}
          >
            Same as Registered number
          </Typography>
        </Box>
      </FormControl>

      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors.whatsapp}
      </FormHelperText>
    </Grid>
  );
};

const EducationWidget = ({
  select_education,
  education,
  getMasterEducationDetails,
  setProfileEducation,
}) => {
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();

  useEffect(() => {
    getMasterEducationDetails();
    // getEducationStatusObject();
  }, []);

  const educationOptions = useMemo(() => {
    return select_education || [];
  }, [select_education]);
  console.log('eduationOptions: ', educationOptions);
  const onEducationChange = (e, value) => {
    validateInput('flEducation', value?.value);
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: { education: value?.value },
    });
    setProfileEducation(value);
  };

  const getEducationStatusObject = () => {
    let statusObj = null;
    if (educationOptions?.length > 0) {
      statusObj = educationOptions?.find((item) =>
        item?.value?.toLowerCase().includes(education?.toLowerCase())
      );
    }
    // const valuesArray = Object.values(educationOptions);
    // console.log("ValuesArray data @@@@: ", valuesArray);

    // console.log("statusObj_data :", statusObj);
    if (statusObj) {
      return statusObj;
    } else {
      return null;
    }
  };

  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputEducation"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Education
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <CustomDropdown
          id="inputEducation"
          // disabled={userSelect === "view"}
          value={getEducationStatusObject() || {}}
          options={educationOptions}
          inputPlaceholder="Select Education"
          onChange={onEducationChange}
          labelKey="value"
          optionKey="id"
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.flEducation}
      </FormHelperText>
    </Grid>
  );
};

const LanguageWidget = ({
  select_language,
  language,
  getMasterLanguageDetails,
}) => {
  const dispatch = useDispatch();
  const { validateInput, errors } = useValidation();

  // console.log("languageOptions @@@ :", languageOptions);
  useEffect(() => {
    getMasterLanguageDetails();
  }, []);
  const languageOptions = select_language || [];
  const handleLangChange = (e, newChips) => {
    console.log('=handleLangChange');
    console.log('newChips', newChips);
    if (newChips.length > 3) {
      pushMessage('You can add upto 3 languages.');
      return;
    }

    let langStr = '';

    newChips.forEach((itm, idx) => {
      if (idx === newChips?.length - 1) {
        langStr += itm.value;
      } else {
        langStr += itm.value + ',';
      }
    });
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: { language: langStr || '' },
    });
    // setProfileLanguage(newChips)
  };

  const getLanguageArr = useMemo(() => {
    let valueArr = [];
    console.log(
      'getLanguageArr,languageOptions,language',
      languageOptions,
      language
    );
    if (language && languageOptions?.length > 0) {
      let langArr = language.split(',');

      langArr.forEach((itm) => {
        let langObj = languageOptions?.find((itmm) => itmm.value === itm);
        if (langObj) {
          valueArr.push(langObj);
        }
      });
    }
    console.log('getLanguageArr', valueArr);
    return valueArr;
  }, [language, languageOptions]);
  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputCIN"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Languages
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={languageOptions}
          value={getLanguageArr}
          onChange={handleLangChange}
          disableCloseOnSelect
          getOptionLabel={(option) => option?.value}
          isOptionEqualToValue={(option, value) => {
            return option === value;
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.value}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={language?.length > 0 ? '' : 'Languages'}
            />
          )}
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.language}
      </FormHelperText>
    </Grid>
  );
};

const SkillsWidget = ({ freelancer_skills }) => {
  const MuiChipsInputStyled = styled(MuiChipsInput)`
    & input {
      color: red;
    }
    &.MuiOutlinedInput-input {
      padding: 0px !important;
    }
  `;
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();
  const [chips, setChips] = React.useState(freelancer_skills || []);
  function handleSelecetedTags(items) {
    if (items.length >= 6) {
      pushMessage('You can add upto 5 Skills.');
      return;
    }
    console.log('Selected Language :', items);
    setChips(items);
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: { freelancer_skills: items },
    });
  }

  return (
    <Grid item md={6} lg={6} xs={12}>
      <InputLabel
        htmlFor="inputEducation"
        className="text-start fs-14 text-black font-skmodernist"
      >
        Skills<span className="text-mandatory">*</span>
      </InputLabel>
      <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
        <MuiChipsInputStyled
          className="MuiChipsInputCustom"
          value={chips}
          onChange={handleSelecetedTags}
        />
      </FormControl>
      <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
        {errors?.flEducation}
      </FormHelperText>
    </Grid>
  );
};

const AddCertificateWidget = () => {
  return (
    <></>
    // <Grid item md={6} lg={6} xs={12}>
    //   {Responsive(
    //     <Button
    //       className="d-flex p-0 align-items-center"
    //       onClick={toggleDrawer}
    //     >
    //       <AddCertificationIcon />
    //       <Typography
    //         variant="body1"
    //         align="left"
    //         className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
    //         gutterBottom
    //       >
    //         Add Certification
    //       </Typography>
    //     </Button>,
    //     <Button
    //       className="d-flex p-0 align-items-center"

    //       onClick={handleOpenModal}
    //     >
    //       <AddCertificationIcon />
    //       <Typography
    //         variant="body1"
    //         align="left"
    //         className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
    //         gutterBottom
    //       >
    //         Add Certification
    //       </Typography>
    //     </Button>
    //   )

    //   }
    //   <Divider
    //     component="div"
    //     className="main-divider my-3"
    //     role="presentation"
    //   />
    // </Grid>
  );
};

const CertificateAccordion = ({ certificates, userData }) => {
  const dispatch = useDispatch();
  const [doc, setDoc] = useState();
  const { validateInput, errors } = useValidation();
  const [isEditing, setIsEditing] = useState(false);
  const [certName, setCertName] = useState('');
  const [certDesc, setCertDesc] = useState('');
  const [certDate, setCertDate] = useState(null); //moment()
  const [certDelDoc, setCertelDoc] = useState();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [certDelOpen, setcertDelOpen] = useState(false);
  const hanCertdelOpen = () => setcertDelOpen(true);
  const hanCertdelClose = () => setcertDelOpen(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setIsEditing(false);
    setCertDate(null);
    setCertDesc('');
    setCertName('');
  };
  const [openModal, setOpenModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const handleOpenModal = () => {
    if (!isEditing) {
      setCertName('');
      setCertDesc('');
      setCertDate(null);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCertNameChange = (e) => {
    const input = e.target.value;
    setCertName(input);
  };
  const handleCertDescChange = (e) => {
    const input = e.target.value;
    setCertDesc(input);
  };
  const saveCertificate = () => {
    console.log('saveCertificate isEditing', isEditing);
    const isLocal = true;
    const payload = {
      name: certName,
      description: certDesc,
      issued_on: certDate?.format('YYYY-MM-DD').toString(),
      registrationId: userData?.registration?.id,
      id: doc?.id,
      isLocal,
    };
    if (isEditing) {
      dispatch({
        type: FRL_UPDATE_CERTIFICATE_DATA,
        payload: { ...payload, isLocal: false },
      });
      setIsEditing(false);
      setCertDate(null);
      setCertName('');
      setCertDesc('');
    } else {
      dispatch({
        type: FRL_SET_CERTIFICATE_DATA,
        // payload: { ...payload, isLocal: false },
        payload,
      });
      setIsEditing(false);
      setCertDate(null);
      setCertName('');
      setCertDesc('');
    }

    setIsDrawerOpen(false);
    setOpenModal(false);
  };
  const handleConfirmDelete = () => {
    console.log('===certDelDoc', certDelDoc);
    hanCertdelClose();
    dispatch({
      type: FRL_DELETED_CERTIFICATE_DATA,
      payload: certDelDoc,
    });
    dispatch({
      type: FRL_Delete_CERTIFICATE_DATA,
      payload: certDelDoc.id,
    });
  };
  return (
    <>
      <Drawer
        xs={12}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            height: 'auto',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
        }}
      >
        <div className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <Typography
              variant="h5"
              align="left"
              alignItems="center"
              className="fs-18 mb-2 fw-bold font-skmodernist "
              gutterBottom
            >
              {isEditing ? 'Edit' : 'Add'} Certification
            </Typography>
            <CloseIcon onClick={toggleDrawer} />
          </div>
          <div className="mb-3">
            <InputLabel
              htmlFor="inputEducation"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Name <span className="text-mandatory">*</span>
            </InputLabel>
            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <OutlinedInput
                id="inputEducation"
                // label="Mobile Number"
                type="text"
                placeholder="Enter name"
                autoFocus
                fullWidth
                margin="none"
                value={certName}
                onChange={handleCertNameChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
            </FormControl>
            <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
              {errors.flAddress2}
            </FormHelperText>
          </div>
          <div className=" mb-3">
            <InputLabel
              htmlFor="inputCompanyName"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Description
            </InputLabel>

            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
              <TextareaAutosize
                id="inputAdditionalInfo"
                placeholder="Enter description"
                minRows={3} // You can adjust the number of rows as needed
                maxRows={2} // You can adjust the number of rows as needed
                value={certDesc}
                onChange={handleCertDescChange}
                maxLength={500}
              />
            </FormControl>
          </div>
          <div className="mb-5">
            <InputLabel
              htmlFor="dateOfBirth"
              className="text-start fs-14 text-black font-skmodernist"
            >
              Issued on
            </InputLabel>
            <LocalizationProvider
              className="test-0"
              dateAdapter={AdapterMoment}
            >
              <DemoContainer className="test-1" components={['DatePicker']} />
              <DatePicker
                className="test-2"
                format="DD-MM-YYYY"
                renderInput={(params) => <TextField {...params} fullWidth />}
                value={certDate}
                onChange={(newValue) => setCertDate(newValue)}
                disableFuture
              />
            </LocalizationProvider>
          </div>
          <Button
            type="button"
            fullWidth
            variant="contained"
            className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
            sx={{
              borderRadius: '6px',
              textTransform: 'capitalize',
            }}
            onClick={saveCertificate}
          >
            Save
          </Button>
        </div>
      </Drawer>
      <AddCertificationModal
        open={openModal}
        onClose={handleCloseModal}
        certName={certName}
        handleCertNameChange={handleCertNameChange}
        certDesc={certDesc}
        handleCertDescChange={handleCertDescChange}
        certDate={certDate}
        setCertDate={setCertDate}
        saveCertificate={saveCertificate}
        isEditing={isEditing}
      />
      <ConfirmationDialog
        open={certDelOpen}
        handleClose={hanCertdelClose}
        title="Elynker"
        content="Are you sure you want to delete the certificate?"
        onConfirm={handleConfirmDelete}
        confirmText="Yes"
        cancelText="No"
      />
      <Grid container rowSpacing={0} columnSpacing={10} className="">
        <Grid item md={6} lg={6} xs={12}>
          {Responsive(
            <Button
              className="d-flex p-0 align-items-center"
              onClick={toggleDrawer}
            >
              <AddCertificationIcon />
              <Typography
                variant="body1"
                align="left"
                className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
                gutterBottom
              >
                Add Certification
              </Typography>
            </Button>,
            <Button
              className="d-flex p-0 align-items-center"
              onClick={handleOpenModal}
            >
              <AddCertificationIcon />
              <Typography
                variant="body1"
                align="left"
                className="fs-14 text-primary fw-normal font-gotham ms-3 mb-0 text-capitalize"
                gutterBottom
              >
                Add Certification
              </Typography>
            </Button>
          )}
          <Divider
            component="div"
            className="main-divider my-3"
            role="presentation"
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={0}
        columnSpacing={10}
        className=""
        sx={{
          marginBottom: isTabletOrMobile ? 15 : 0,
        }}
      >
        <Grid
          item
          md={6}
          lg={6}
          xs={12}
          className="custom-accordion product-accordion"
        >
          <Accordion
            defaultExpanded={
              certificates && certificates.length > 0 ? true : false
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="fs-15 font-skmodernist text-black fw-bold"
            >
              Certification
            </AccordionSummary>
            <AccordionDetails>
              {certificates &&
                certificates.map((item, index) => {
                  const issueDate = formatedDate(item?.issued_on);
                  // console.log("=====certificates", item);
                  return (
                    <div className="mb-3" key={index}>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                          <Typography className="fs-15 fw-normal font-skmodernist text-darkgrey">
                            {item?.name}
                          </Typography>
                        </div>
                        <div className="d-flex">
                          <IconButton
                            className="cat-edit-icon"
                            onClick={() => {
                              console.log('===edit certifcate item', item);
                              setDoc(item);
                              setIsEditing(true);
                              setCertName(item?.name);
                              setCertDesc(item?.description);
                              setCertDate(moment(item?.issued_on));
                              if (isTabletOrMobile) {
                                setIsDrawerOpen(true);
                              } else {
                                handleOpenModal(true);
                              }
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            className="cat-delete-icon"
                            onClick={() => {
                              console.log('====deleted resume', item);
                              setCertelDoc(item);
                              hanCertdelOpen();
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                      <Typography
                        variant="body1"
                        align="left"
                        className="fs-14 text-grey fw-normal font-gotham mb-2"
                        gutterBottom
                      >
                        {item?.description}
                      </Typography>
                      <Typography className="fs-14 font-gotham text-primary mb-2">
                        Issued on: {issueDate}{' '}
                      </Typography>
                    </div>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

const SaveProfileDetailsWidget = ({
  // userDataNew,
  userData,
  userDataNew,
  certificates,
  resume,
  dob,
  name,
  education,
  // hourly_rate,
  freelancer_bio,
  freelancer_role,
  language,
  email,
  putUpdateUserDetails,
  putUpdateAboutCompanyDetails,
  projects,
  uploadBannerData,
  deletedProjectFile,
  deletedCertificates,
  addCertificateData,
  updateCertificateData,
  deletedResumeData,
  // getUserRegistrationData,
  pushMessage,
  getUpdatedRegistrationData,
  deleteCertificateData,
}) => {
  const history = useHistory();
  const { validateInput, errors } = useValidation();
  const dispatch = useDispatch();
  const regDetails = userDataNew?.registration;
  const chips = userDataNew?.registration?.freelancer_skills || [];

  const uploadItemsToDb = async (items, uploadFunction) => {
    console.log('===uploadItemsToDb items', items);
    if (items && items.length > 0) {
      for (const item of items) {
        // console.log("===uploadItemsToDb loop items", item);
        if (item?.isLocal) {
          {
            console.log('===uploadItemsToDb item', item);
            await uploadFunction(item);
          }
        }
      }
    }
  };
  function successCallback() {}

  function errorCallback() {
    const type = 'error';
    pushMessage('Please try again, Error while uploading resume.', type);
  }
  const uploadResumeToDb = (selectedFile) => {
    uploadResumeData(
      selectedFile,
      userData?.registration?.id,
      successCallback,
      errorCallback
    );
  };

  const uploadSingleProjectToDb = (project) => {
    console.log('====uploadSingleProjectToDb', project);
    const type = 'project-upload';
    uploadBannerData(
      project,
      project?.name,
      userData?.registration?.id,
      successCallback,
      errorCallback,
      type
    );
  };
  const putUpdateUserDetail = async (newUserData) => {
    await putUpdateUserDetails(userData?.user?.id, newUserData, userData);
  };

  const putUpdateDetails = (regDetails) => {
    console.log('===putUpdateDetails', regDetails);
    putUpdateAboutCompanyDetails(userData?.registration?.id, regDetails);
  };

  const saveSingleCertificateToDb = (certificate) => {
    console.log('====saveSingleCertificateToDb', certificate);
    const { name, description, issued_on } = certificate;
    addCertificateData(
      name,
      description,
      issued_on,
      userData?.registration?.id,
      successCallback,
      errorCallback
    );
  };
  function onDeleteSuccess(res) {
    console.log('===onDeleteSuccess', res);
    if (res.success) pushMessage(res?.success);
    else pushMessage(res?.message);
  }

  const deleteItems = async (items, deleteFunction, onSuccess, onFailure) => {
    console.log('====deleteItems', items);
    if (items && items.length > 0) {
      for (const item of items) {
        if (!item?.isLocal)
          await deleteFunction(item?.id, onSuccess, onFailure);
      }
    }
  };

  const updateSingleCertificateToDb = (certificate) => {
    console.log('====updateSingleCertificateToDb', certificate);
    const { id, name, description, issued_on } = certificate;
    updateCertificateData(
      id,
      name,
      description,
      issued_on,
      userData?.registration?.id,
      successCallback,
      errorCallback
    );
  };

  const saveProfileDetails = async () => {
    console.log(
      '===saveProfileDetails errors',
      name,
      freelancer_role,
      email,
      education,
      dob,
      freelancer_bio,
      regDetails
    );
    // console.log('===userData regDetails', regDetails);
    // console.log('===userData regDetails errors', errors);
    if (freelancer_role === '') {
      validateInput('flRole', '');
    } else if (name === '') {
      validateInput('name', '');
    } else if (email === '') {
      validateInput('email', '');
    } else if (education === '') {
      validateInput('flEducation', '');
    } else if (dob === '') {
      validateInput('dob', '');
    } else if (freelancer_bio === '') {
      validateInput('bio', '');
    } else if (!regDetails.pincode) {
      validateInput('pincode', '');
    } else {
      loading(true);
      try {
        console.log('====regDetails flupdateprofile', regDetails);
        console.log('====certificates uploadItemsToDb', certificates);
        const updateCertData = certificates.filter(
          (certData) => certData?.isLocal === false
        );
        await Promise.all([
          uploadItemsToDb([{ ...regDetails, isLocal: true }], putUpdateDetails),
          uploadItemsToDb({ email: email, isLocal: true }, putUpdateUserDetail),
          uploadItemsToDb(
            { freelancer_skills: chips, isLocal: true },
            putUpdateUserDetail
          ),
          uploadItemsToDb(resume, uploadResumeToDb),
          uploadItemsToDb(projects, uploadSingleProjectToDb),
          uploadItemsToDb(certificates, saveSingleCertificateToDb),
          uploadItemsToDb(
            updateCertData?.map((cer) => ({ ...cer, isLocal: true })),
            updateSingleCertificateToDb
          ),
        ]);
      } catch (error) {
        console.log('error', error);
      }
      await deleteItems(deletedProjectFile, deleteBanner, onDeleteSuccess, () =>
        console.log('Please try again, Error while deleting project.')
      );
      await deleteItems(
        deletedCertificates,
        deleteCertificateData,
        onDeleteSuccess,
        () => console.log('Please try again, Error while deleting Resume.')
      );
      // dispatch({ type: CLEAR_Delete_CERTIFICATE_DATA })
      await deleteItems(deletedResumeData, deleteResume, onDeleteSuccess, () =>
        console.log('Please try again, Error while deleting Resume.')
      );
      await getUpdatedRegistrationData(
        userData?.user?.id,
        userData,
        pushMessage
      );
      dispatch({ type: RESET_UPDATED_NEW_DATA });
      loading(false);
      history.goBack();
    }
  };
  return (
    <>
      {Responsive(
        <Box sx={{ width: '100%' }}>
          <Box sx={[stickyButtonStyle]}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold mb-4"
              sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
              onClick={saveProfileDetails}
            >
              Save Profile Details
            </Button>
          </Box>
        </Box>,
        <Box className="d-flex justify-content-end mt-4">
          <Button
            type="button"
            variant="contained"
            className="btn-web-next"
            onClick={saveProfileDetails}
          >
            Save Profile Details
          </Button>
        </Box>
      )}
    </>
  );
};

const AddressWidget = ({
  address1,
  address2,
  city,
  state,
  pincode,
  setAddress1,
  setAddress2,
  setCity,
  setState,
  userData,
}) => {
  const [mapOtions, setMapOtions] = useState([]);
  const [searchLoad, setSearchLoad] = useState(false);
  const [locSearchVal, setLocSearchVal] = useState(pincode?.toString() || '');
  const { validateInput, errors } = useValidation();
  const [defaultPincodeVal, setDefaultPincodeVal] = useState({ pincode: [''] });

  const dispatch = useDispatch();
  const searchLocInpRef = useRef(null);
  const [open, setOpen] = useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const handleAddress1Change = (e) => {
    const input = e.target.value;
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: {
        address1: input,
      },
    });
    setAddress1(input);
    validateInput('flAddress1', input);
  };
  useEffect(() => {
    const getData = setTimeout(async () => {
      if (locSearchVal && searchLoad) {
        try {
          const response = await axios.get(
            // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
            POSTOFFICE_PINCODE + locSearchVal
          );
          if (response.status === 200) {
            if (response?.data?.status_code == 200) {
              console.log('SEARCH RESULT', response.data);
              setMapOtions(response.data?.data);
              setSearchLoad(false);
            } else {
              setMapOtions([]);
              setSearchLoad(false);
            }
          } else {
            pushMessage('No City Found!!');
          }
        } catch (err) {
          console.log('');
        }
      }
    }, 250);
    return () => clearTimeout(getData);
  }, [locSearchVal]);

  const getInitialPincodeData = async (pincode) => {
    try {
      const response = await axios.get(
        // `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
        POSTOFFICE_PINCODE + pincode
      );
      if (response.status === 200) {
        if (response?.data?.status_code == 200) {
          console.log('SEARCH RESULT', response.data);
          setMapOtions(response.data?.data);
          setSearchLoad(false);
          return response.data?.data;
        } else {
          setMapOtions([]);
          setSearchLoad(false);
          return [];
        }
      } else {
        pushMessage('No City Found!!');
        return [];
      }
    } catch (err) {
      console.log('');
      return [];
    }
  };

  function setDefaultAddressValues() {
    dispatch({
      type: UPDATE_SIGNIN_DATA,
      payload: {
        address2: userData?.registration?.address2,
        city: userData?.registration?.city,
        state: userData?.registration?.state,
        pincode: userData?.registration?.pincode,
      },
    });
    setDefaultPincodeVal({ pincode: [userData?.registration?.pincode] });
    // setCity(userData?.registration?.city);
    // setState(userData?.registration?.state);
    // setPincode(userData?.registration);
  }

  useEffect(() => {
    async function getPincodeData(pincode) {
      try {
        const pincodeArr = await getInitialPincodeData(pincode);
        console.log('pincodeArr', pincodeArr);
        if (pincodeArr.length > 0) {
          const picodeDataExac = pincodeArr.find(
            (pin) =>
              pin.city[0] === userData?.registration?.city &&
              pin.statename[0] === userData?.registration?.state &&
              pin.pincode[0] === userData?.registration?.pincode &&
              pin.areaname[0] === userData?.registration?.address2
          );
          console.log('picodeDataExac', picodeDataExac);

          if (picodeDataExac) {
            dispatch({
              type: UPDATE_SIGNIN_DATA,
              payload: {
                address2: picodeDataExac?.areaname[0]?.toString(),
                city: picodeDataExac?.city[0]?.toString(),
                state: picodeDataExac?.statename[0]?.toString(),
                pincode: picodeDataExac?.pincode[0],
              },
            });
            setDefaultPincodeVal(picodeDataExac);
            // setCity(picodeDataExac?.city[0]?.toString());
            // setState(picodeDataExac?.statename[0]?.toString());
            // setPincode(picodeDataExac);
          } else {
            setDefaultAddressValues();
          }
        } else {
          setDefaultAddressValues();
        }
      } catch (error) {
        setDefaultAddressValues();
      }
    }
    let pinDeb = setTimeout(() => {
      if (userData?.registration?.pincode) {
        getPincodeData(userData?.registration?.pincode);
        setLocSearchVal(userData?.registration?.pincode);
      }
    }, 1000);

    return () => clearTimeout(pinDeb);
  }, [userData?.registration?.pincode]);

  return (
    <Grid container rowSpacing={0} columnSpacing={10} className="">
      <Grid item md={6} lg={6} xs={12} className="mb-1">
        <InputLabel
          htmlFor="inputCompanyAddress"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Address <span className="text-mandatory">*</span>
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <OutlinedInput
            id="inputCompanyAddress"
            // label="Mobile Number"
            type="text"
            placeholder="Building/Street"
            // required
            inputProps={{
              maxLength: 200,
            }}
            fullWidth
            margin="none"
            value={address1}
            onChange={handleAddress1Change}
          />
        </FormControl>
        <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
          {errors.flAddress1}
        </FormHelperText>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <InputLabel
          htmlFor="inputLocation"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Enter your Pincode
          <span className="text-mandatory">*</span>
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <Autocomplete
            disablePortal
            ref={searchLocInpRef}
            id={'SearchLocation'}
            options={mapOtions}
            fullWidth
            value={defaultPincodeVal}
            onInputChange={(e, value) => {
              const numericValue = value.replace(/[^0-9]/g, '');
              validateInput('pincode', numericValue);
              setLocSearchVal(numericValue);
              setSearchLoad(true);
            }}
            onChange={(e, value) => {
              console.log('Selected value ', value);
              if (e?.target?.value?.length == 0) {
                setAddress2('');
                setCity('');
                setState('');
                setMapOtions([]);
                setDefaultPincodeVal(null);
                setPincode(null);
              } else {
                setLocSearchVal(value);
                validateInput('pincode', value?.pincode[0]?.toString());
                setCity(value?.city[0]?.toString());
                setState(value?.statename[0]?.toString());
                setDefaultPincodeVal(value);
                setPincode(value);
                dispatch({
                  type: UPDATE_SIGNIN_DATA,
                  payload: {
                    address2: value?.areaname[0]?.toString(),
                    city: value?.city[0]?.toString(),
                    state: value?.statename[0]?.toString(),
                    pincode: value?.pincode[0]?.toString(),
                  },
                });
                setSearchLoad(false);
              }
            }}
            open={open}
            onOpen={openPopper}
            onClose={closePopper}
            getOptionLabel={(option) => option?.pincode[0]?.toString()}
            isOptionEqualToValue={(option, value) =>
              option['pincode'] === value['pincode']
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.areaname[0]} - {option.city[0]} - {option.pincode}
              </Box>
            )}
            sx={{
              '.MuiAutocomplete-input': {
                paddingLeft: '14px !important',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder={'eg. 400070'}
                inputProps={{
                  ...params.inputProps,
                  maxLength: 6,
                  inputMode: 'numeric',
                  onKeyDown: (e) => {
                    if (
                      !/^[0-9]$/.test(e.key) &&
                      e.key !== 'Backspace' &&
                      e.key !== 'Delete'
                    ) {
                      e.preventDefault();
                    }
                  },
                }}
              />
            )}
            noOptionsText={'Please enter valid Pincode'}
          />
        </FormControl>
        <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
          {errors.pincode}
        </FormHelperText>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <InputLabel
          htmlFor="inputCompanyAddress"
          className="text-start fs-14 text-black font-skmodernist"
        >
          Town/Area
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <OutlinedInput
            id="inputCompanyAddress"
            // label="Mobile Number"
            type="text"
            placeholder="Town/Area"
            // required
            inputProps={{
              maxLength: 200,
            }}
            fullWidth
            margin="none"
            value={address2}
            disabled
          />
        </FormControl>
        <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
          {errors.address}
        </FormHelperText>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <InputLabel
          htmlFor="inputCompanyAddress"
          className="text-start fs-14 text-black font-skmodernist"
        >
          City/District
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <OutlinedInput
            id="inputCompanyAddress"
            // label="Mobile Number"
            type="text"
            placeholder="City/District"
            // required
            inputProps={{
              maxLength: 200,
            }}
            fullWidth
            margin="none"
            value={city}
            disabled
          />
        </FormControl>
        <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
          {errors.address}
        </FormHelperText>
      </Grid>
      <Grid item md={6} lg={6} xs={12}>
        <InputLabel
          htmlFor="inputCompanyAddress"
          className="text-start fs-14 text-black font-skmodernist"
        >
          State
        </InputLabel>
        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
          <OutlinedInput
            id="inputCompanyAddress"
            // label="Mobile Number"
            type="text"
            placeholder="State"
            // required
            inputProps={{
              maxLength: 200,
            }}
            fullWidth
            margin="none"
            value={state}
            disabled
          />
        </FormControl>
        <FormHelperText sx={{ color: 'red' }} id="email-helper-text">
          {errors.address}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

const mapStateToPropsName = (state) => {
  console.log('===Name state', state);
  //regDetails
  const { name } = state?.signin?.userDataNew?.registration || { name: '' };
  return {
    name,
  };
};
const ConnectedNameWidget = connect(mapStateToPropsName, {})(NameWidget);

const mapStateToPropsPRole = (state) => {
  console.log('===PRole state', state);
  //regDetails
  const { freelancer_role } = state?.signin?.userDataNew?.registration || {};
  return {
    freelancer_role,
  };
};
const ConProfessionalRoleWidget = connect(
  mapStateToPropsPRole,
  {}
)(ProfessionalRoleWidget);

const mapStateToPropsAboutYou = (state) => {
  console.log('===ProfessionalRoleWidget state', state);
  //regDetails
  const { freelancer_bio } = state?.signin?.userDataNew?.registration || {
    freelancer_role: '',
  };
  return {
    freelancer_bio,
  };
};
const ConAboutYouWidget = connect(mapStateToPropsAboutYou, {})(AboutYouWidget);

const mapStateToPropsResumeUploadWidget = (state) => {
  console.log('===ResumeUploadWidget state', state);
  //regDetails
  const { resume, resumeData, deletedResumeData } = state?.flUploadResume || {
    resume: null,
    resumeData: null,
    deletedResumeData: null,
  };
  return {
    resume,
    resumeData,
    deletedResumeData,
  };
};
const ConResumeUploadWidget = connect(mapStateToPropsResumeUploadWidget, {
  getResumeData,
  uploadResumeData,
  deleteResume,
  pushMessage,
})(ResumeUploadWidget);

const mapStateToPropsProjectSelectWidget = (state) => {
  console.log('===ProjectSelectWidget state', state);
  //regDetails
  const { resume, resumeData, deletedResumeData } = state?.flUploadResume || {};
  return {
    resume,
    resumeData,
    deletedResumeData,
  };
};
const ConProjectSelectWidget = connect(mapStateToPropsProjectSelectWidget, {
  getResumeData,
  uploadResumeData,
  deleteResume,
  pushMessage,
})(ProjectSelectWidget);

const mapStateToPropsProjectAccordionWidget = (state) => {
  console.log('===ProjectAccordionWidget state', state);

  const { projects, deletedProjectFile } = state?.flAddBio || {};
  return {
    projects,
    deletedProjectFile,
  };
};
const ConProjectAccordionWidget = connect(
  mapStateToPropsProjectAccordionWidget,
  {}
)(ProjectAccordionWidget);

const mapStateToPropsEmailIDWidget = (state) => {
  console.log('===EmailIDWidget state', state);
  const { email } = state?.signin?.userDataNew?.user || { email: '' };
  return {
    email,
  };
};
const ConEmailIDWidget = connect(
  mapStateToPropsEmailIDWidget,
  {}
)(EmailIDWidget);

const mapStateToPropsDateOfBirthWidget = (state) => {
  console.log('===DateOfBirthWidget state', state);
  const { dob } = state?.signin?.userDataNew?.registration || {};
  return {
    dob,
  };
};
const ConDateOfBirthWidget = connect(
  mapStateToPropsDateOfBirthWidget,
  {}
)(DateOfBirthWidget);

const mapStateToPropsEducationWidget = (state) => {
  console.log('===EducationWidget state', state);
  const { select_education } = state?.flProfileDetails || {};
  const { education } = state?.signin?.userDataNew?.registration || {
    education: null,
  };
  return {
    select_education,
    education,
  };
};
const ConEducationWidget = connect(mapStateToPropsEducationWidget, {
  getMasterEducationDetails,
  setProfileEducation,
})(EducationWidget);

const mapStateToPropsLanguageWidget = (state) => {
  console.log('===LanguageWidget state', state);
  const { select_language } = state.flProfileDetails || {};
  const { language } = state?.signin?.userDataNew?.registration || {
    language: null,
  };
  return {
    select_language,
    language,
  };
};
const ConLanguageWidget = connect(mapStateToPropsLanguageWidget, {
  getMasterLanguageDetails,
})(LanguageWidget);

const mapStateToPropsSkillsWidget = (state) => {
  console.log('===SkillsWidget state', state);
  const { userDataNew } = state.signin || {};
  const userData = userDataNew;
  const { freelancer_skills } = state?.signin?.userDataNew?.registration || {
    freelancer_skills: null,
  };
  return {
    userData,
    freelancer_skills,
  };
};
const ConSkillsWidget = connect(mapStateToPropsSkillsWidget, {})(SkillsWidget);

const mapStateToPropsAddCertificateWidget = (state) => {
  console.log('===AddCertificateWidget state', state);
  const { userDataNew } = state?.signin || {};
  const userData = userDataNew;

  const {
    certificates,
    // deletedCertificates
  } = state.flProfileDetails;
  return {
    // userData,
    certificates,
    // deletedCertificates
  };
};
const ConAddCertificateWidget = connect(
  mapStateToPropsAddCertificateWidget,
  {}
)(AddCertificateWidget);

const mapStateToPropsCertificateAccordion = (state) => {
  console.log('===CertificateAccordion state', state);
  const { userDataNew } = state?.signin || {};
  const userData = userDataNew;

  const {
    certificates,
    // deletedCertificates
  } = state.flProfileDetails;
  return {
    userData,
    certificates,
    // deletedCertificates
  };
};
const ConCertificateAccordion = connect(
  mapStateToPropsCertificateAccordion,
  {}
)(CertificateAccordion);

const mapStateToPropsSaveProfileDetailsWidget = (state) => {
  console.log('===SaveProfileDetailsWidget state', state);
  const { userDataNew } = state?.signin || {};
  const userData = userDataNew;
  const { certificates, deletedCertificates, pincode } =
    state?.flProfileDetails || {};
  const { resume, deletedResumeData } = state?.flUploadResume || {};
  const {
    dob,
    name,
    education,
    hourly_rate,
    freelancer_bio,
    freelancer_role,
    language,
  } = userDataNew?.registration || {};
  const { email } = state?.signin?.userDataNew?.user || {};
  const { projects, deletedProjectFile } = state.flAddBio;
  return {
    userDataNew,
    userData,
    certificates,
    resume,
    dob,
    name,
    education,
    hourly_rate,
    freelancer_bio,
    freelancer_role,
    language,
    email,
    projects,
    deletedProjectFile,
    deletedCertificates,
    deletedResumeData,
    pincode,
  };
};
const ConSaveProfileDetailsWidget = connect(
  mapStateToPropsSaveProfileDetailsWidget,
  {
    putUpdateUserDetails,
    putUpdateAboutCompanyDetails,
    uploadBannerData,
    addCertificateData,
    updateCertificateData,
    getUserRegistrationData,
    pushMessage,
    deleteCertificateData,
    getUpdatedRegistrationData,
  }
)(SaveProfileDetailsWidget);

const mapStateToPropsWhatsAppNumberWidget = (state) => {
  console.log('===WhatsAppNumberWidget state', state);
  const { userDataNew, userData } = state.signin || {};
  const { whatsapp } = state.profileDetails;
  return {
    userDataNew,
    userData,
    whatsapp,
  };
};
const ConWhatsAppNumberWidget = connect(mapStateToPropsWhatsAppNumberWidget, {
  setWhatsappNumber,
})(WhatsAppNumberWidget);

const mapStateToPropsAddress = (states) => {
  console.log('===Address state', states);
  //regDetails
  const { name, address1, address2, city, state, areaname, pincode } = states
    ?.signin?.userDataNew?.registration || { name: '' };
  const userData = states.signin?.userData;
  console.log('piincode from userDataNew', pincode);

  return {
    name,
    address1,
    address2,
    city,
    state,
    areaname,
    pincode,
    userData,
  };
};
const ConnectedAddressWidget = React.memo(
  connect(mapStateToPropsAddress, {
    setAddress1,
    setAddress2,
    setCity,
    setState,
    setAreaName,
    setPincode,
  })(AddressWidget)
);
