/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import '../../common/fontsize.css';
// import './complete-profile.css';
import '../../index.css';
import PropTypes from 'prop-types';
const indianPincodeRegex = /^[1-9][0-9]{5}$/;

import {
	Container,
	Grid,
	Typography,
	Button,
	Autocomplete,
	TextField,
	Backdrop,
	CircularProgress,
	FormHelperText,
	Box,
	Checkbox,
} from '@mui/material';
import {
	alpha,
	createTheme,
	getContrastRatio,
	ThemeProvider,
} from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { DeleteConfirmationPopup } from '../../components/ActionPopup';
import FormControl from '@mui/material/FormControl';
// import Divider from '@mui/material/Divider';
import AddBtnIcon from '../../assets/add-btn-icon.svg';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
// import LocationLogo from '../../assets/location-icon.svg';
import { connect } from 'react-redux';
import {
	getSocialLinks,
	postSocialLinks,
	setAddress1,
	setAddress2,
	setCity,
	setCompanyLogo,
	// setLocationCoords,
	setSelectedCoverImage,
	setWhatsappNumber,
	setSocialLinks,
	setSocialData,
	setState,
	uploadCompanyLogoImage,
	uploadCoverImage,
	getCityMaster,
	setSocialName,
	getStateMaster,
	getSocialMediaMasterData,
	putSocialLinks,
	setAreaName,
	setPincode,
} from './redux/actions/profile-details-form-action';
// import LocationSearch from '../../components/LocationSearch/LocationSearch';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import GooglePlacesAutocomplete, {
//   geocodeByLatLng,
// } from 'react-google-places-autocomplete';
import { updateUserData } from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../assets/cat-edit-icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/cat-delete-icon.svg';
import { URL_REGEX } from './redux/types';
import CloseIcon from '@mui/icons-material/Close';
import ImageDialog from '../../components/ImageOverlay';

import {
	COMPANY_LOGO_BASE_URL,
	COVER_IMAGE_BASE_URL,
	POSTOFFICE_PINCODE,
} from '../../utils/APIs/api';
// import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';

import { loading } from '../../store/actions/rootActions';
import axios from 'axios';
import {
	handleErrorsAndScroll,
	handleLocationPermission,
	scrollToInput,
} from '../../utils/helper-funtions';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import SocialLinks from '../../components/SocialLinks/SocialLinks';
import Resizer from 'react-image-file-resizer';
import { calculateFilledPercentage } from '../../utils/percent';
import useValidation from '../../hooks/useValidation';
import { isObject } from 'lodash';
import { hasAnyErrorPresent } from '../../utils/validation';
import { useMediaQuery } from 'react-responsive';
import DefaultLogo from '../../components/StyledAvatar/styledAvatar';
import { stickyButtonStyle } from '../../utils/sx-styles';

const violetBase = '#7F00FF';
const violetMain = alpha(violetBase, 0.7);
const COUNTRY = 'IN';
const LIMIT = '10';
const LANG = 'en';
const MAPBOX_ACC =
	'pk.eyJ1IjoiYW1pdHZlcm1hMTI2NzkiLCJhIjoiY2xzZWRyMXQwMTRvNDJ2bDV5OWRrbTU2ciJ9.OY1IY9uFWThEyVWyWlci7g';

const theme = createTheme({
	palette: {
		violet: {
			main: violetMain,
			light: alpha(violetBase, 0.5),
			dark: alpha(violetBase, 0.9),
			contrastText:
				getContrastRatio(violetMain, '#fff') > 4.5 ? '#fff' : '#111',
		},
	},
});

const categoryText =
	'A few last details, then you can\ncheck and publish your profile';

const ProfileDetailsForm = ({
	whatsapp,
	onNextHandler,
	setWhatsappNumber,
	onBackHandler,
	companyLogo,
	setCompanyLogo,
	setAddress1,
	setAddress2,
	city,
	states,
	address1,
	address2,
	userData,
	uploadCoverImage,
	selectedCoverImage,
	setSelectedCoverImage,
	uploadCompanyLogoImage,
	updateUserData,
	setCity,
	allCity,
	allState,
	setState,
	// locationCoords,
	// setLocationCoords,
	socialData,
	socialLinks,
	setSocialLinks,
	setSocialData,
	getSocialLinks,
	postSocialLinks,
	getCityMaster,
	setSocialName,
	socialName,
	isLoading,
	loading,
	getStateMaster,
	pushMessage,
	getSocialMediaMasterData,
	socialMediaMaster,
	putSocialLinks,
	company_name,
	setAreaName,
	areaname,
	// pincode,
	setPincode,
}) => {
	const mobile_number = userData.user?.mobile_number;
	const isExistingMobileasWhatsapp = whatsapp === mobile_number ? true : false;
	const [socialUrlValidateError, setSocialUrlValidateError] = useState('');
	const companyLogoInputRef = useRef(null);
	const coverInputRef = useRef(null);
	const { errors, validateInput } = useValidation();
	const [openPopup, setOpenPopup] = React.useState(false);
	const [isReplaceType, setIsReplaceType] = React.useState(false);
	const [imgDeleteType, setImageDeleteType] = React.useState();
	const [isNotExistingMobile, setisNotExistingMobile] = React.useState(
		isExistingMobileasWhatsapp
	);
	const [defaultPincodeVal, setDefaultPincodeVal] = useState({ pincode: [''] });
	//isReplace
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)' });
	// const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
	// const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
	// const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	// const [socialLinkData] = useState([
	//   { value: 'Facebook', label: 'Facebook' },
	//   { value: 'Instagram', label: 'Instagram' },
	//   { value: 'Linkedin', label: 'Linkedin' },
	//   { value: 'Twitter', label: 'Twitter' },
	// ]);
	const [mapOtions, setMapOtions] = useState([]);
	const [searchLoad, setSearchLoad] = useState(false);
	const [locSearchVal, setLocSearchVal] = useState('');
	const [open, setOpen] = useState(false);
	const closePopper = () => setOpen(false);
	const openPopper = () => setOpen(true);

	const searchLocInpRef = useRef(null);

	// const [mapboxValue, setMapboxValue] = useState('');
	const [newSocialLinkData, setNewSocialLinkData] = useState([]);
	const hanldleIsExistingMobile = () => {
		if (!isNotExistingMobile) {
			validateInput('whatsapp', userData.user?.mobile_number);
			setWhatsappNumber(userData.user?.mobile_number);
		} else {
			setWhatsappNumber("");
		}
		setisNotExistingMobile((prev) => !prev);
	};
	const handleLogoButtonClick = () => {
		if (companyLogo) {
			setIsReplaceType('companyLogo');
			setOpenPopup(true);
		} else {
			companyLogoInputRef.current.click();
			setOpenPopup(false);
		}
	};

	const handleLogoFileChange = async (e) => {
		const selectedFile = e?.target?.files;
		console.log('Selected file data on logo :', selectedFile[0]);
		const selectedFileObj = Array.from(selectedFile)[0];
		if (
			selectedFileObj?.type.includes('jpeg') ||
			selectedFileObj?.type.includes('jpg') ||
			selectedFileObj?.type.includes('png')
		) {
			// Handle the selected file as needed, e.g., upload to a server or display preview
			validateInput('logoImage', [...selectedFile]);
			console.log('Selected COMPNAY LOGO Image:', selectedFile);
			let compressedImage = await compressImages(selectedFile);
			setCompanyLogo(compressedImage);
			e.target.value = null;
		} else {
			pushMessage('Please add Valid file type');
		}
	};

	//coverPhotos
	const handleAddress1Change = (e) => {
		const input = e.target.value;
		// const regex = /^[a-zA-Z\s]*$/;
		// if (regex.test(input)) {
		//   setAddress1(input);
		// }
		//
		validateInput('address', input);
		setAddress1(input);
	};
	React.useEffect(() => {
		setWhatsappNumber(
			Number(isNotExistingMobile ? userData.user?.mobile_number : whatsapp)
		);
	}, []);
	const handleWhatsappChange = (e) => {
		console.log('handleWhatsappChange');

		setisNotExistingMobile(false);
		const input = e.target.value;
		const regex = /^[0-9]*$/;
		if (regex.test(input) && input.length <= 10) {
			setWhatsappNumber(input);
		}
		validateInput('whatsapp', input);
	};
	//setWhatsappNumber
	const handleCoverButtonClick = () => {
		console.log('====selectedCoverImage', selectedCoverImage);
		if (selectedCoverImage && selectedCoverImage.length > 0) {
			setIsReplaceType('cover');
			setOpenPopup(true);
		} else {
			coverInputRef.current.click();
			setIsReplaceType();
			setOpenPopup(false);
		}
	};

	const handleCoverFileChange = async (e) => {
		const selectedFile = e.target.files;
		// Handle the selected file as needed, e.g., upload to a server or display preview
		console.log('Selected COVER Image:', selectedFile);
		const selectedFileObj = Array.from(selectedFile)[0];
		if (
			selectedFileObj?.type.includes('jpeg') ||
			selectedFileObj?.type.includes('jpg') ||
			selectedFileObj?.type.includes('png')
		) {
			// Handle the selected file as needed, e.g., upload to a server or display preview
			validateInput('logoImage', [...selectedFile]);
			console.log('Selected COMPNAY cover Image:', selectedFile);
			let compressedImage = await compressImages(selectedFile, 1080, 1920);
			setSelectedCoverImage(compressedImage);
			e.target.value = null;
		} else {
			pushMessage('Please add Valid file type');
		}
	};

	const compressImages = (files, height = 500, width = 500, quality = 75) => {
		return Promise.all(
			Array.from(files).map((file) => {
				return new Promise((resolve) => {
					Resizer.imageFileResizer(
						file,
						height, // New width
						width, // New height
						'JPEG', // Format
						quality, // Quality
						0, // Rotation
						(uri) => {
							resolve(uri);
						},
						'file'
					);
				});
			})
		);
	};

	function isDuplicateUrl(arr, newObj) {
		return arr.some((item) => item.social_url === newObj.social_url);
	}

	const handleAddSocialLinkBtn = () => {
		if (
			socialData?.social_name &&
			socialData?.social_url &&
			socialData?.socialmedia_id &&
			socialData?.registrationId
		) {
			let socialArr = [...socialLinks]; //all previus objects
			const socialObj = { ...socialData }; //contain single added link
			// id: 1,
			console.log('===socialArr,socialObj', socialArr, socialObj);
			socialObj.social_name = socialData.social_name;
			if (socialObj.id) {
				socialObj.changed = true;
			} else {
				socialObj.changed = false;
			}
			if (
				socialData?.social_url?.includes('https://') ||
				socialData?.social_url?.includes('http://')
			) {
				socialObj.social_url = socialData?.social_url;
			} else {
				socialObj.social_url = 'https://' + socialData?.social_url;
			}
			console.log('Social Object', socialObj);
			// Check if the URL already exists in the array
			if (isDuplicateUrl(socialArr, socialObj)) {
				pushMessage(
					'This social URL already exists. Please choose a different one.'
				);
				console.log(
					'This social URL already exists. Please choose a different one.'
				);
			} else {
				// If URL is unique, add the new social link

				socialArr.push(socialObj);
				setSocialLinks(socialArr);
				setSocialUrlValidateError('');
				setSocialData({ social_name: '', social_url: '' });
				setSocialName('');
				console.log('New social link added successfully!');
			}
		} else {
			setSocialUrlValidateError('Social Name and Link is required');
		}
	};

	const submitProfileDetails = async (e) => {
		e.preventDefault();
		// console.log('===errors', errors);
		// if (!companyLogo && !userData?.registration?.image_path) {
		//   // validateInput('logoImage', []);
		// }
		if (!address1) {
			validateInput('address', '');
			scrollToInput('address');
		} else if (!defaultPincodeVal || !defaultPincodeVal?.pincode[0]) {
			validateInput('pincode', '');
		} else if (!address2) {
			validateInput('location', null);
			scrollToInput('location');
		} else if (whatsapp !== "" && whatsapp !== 0 && String(whatsapp)?.length < 10) {
			validateInput('whatsapp', whatsapp?.length);
			scrollToInput('whatsapp');
		} else if (!hasAnyErrorPresent(errors)) {
			let newUserData = {
				active_steps: 2,
				steps_completed: false,
				address1: address1,
				address2: areaname,
				city: city,
				state: states,
				pincode: defaultPincodeVal?.pincode[0]?.toString(),
				// latitude: `${locationCoords?.latitude}`,
				// longitude: `${locationCoords?.longitude}`,
			};
			if(String(whatsapp)?.length == 10){
				newUserData = {...newUserData, whatsapp_number: whatsapp};
			}

			console.log('===new user data', newUserData);
			loading(true);
			// Upload profile image
			if (companyLogo) {
				if (companyLogo.length > 0) {
					if (companyLogo[0].name) loading(true);
					await uploadCompanyLogoImage(
						companyLogo,
						userData.registration.id,
						pushMessage
					);
					loading(false);
				}
			}

			if (selectedCoverImage) {
				if (selectedCoverImage.length > 0) {
					if (selectedCoverImage[0].name) {
						loading(true);
						await uploadCoverImage(
							selectedCoverImage,
							userData.registration.id,
							pushMessage
						);
						loading(false);
					}
				}
			} else {
				// if (!userData?.registration?.cover_image) {
				//   alert('Add Cover image');
				//   return;
				// }
			}

			//upload cover pphoto

			await updateUserData(
				userData,
				newUserData,
				userData.registration.id,
				userData.accessToken
			);

			let postSocialLinksArr = [];
			for (const socialLink of socialLinks) {
				if (socialLink.id && socialLink.changed) {
					await putSocialLinks(socialLink.id, socialLink);
				} else {
					postSocialLinksArr.push(socialLink);
				}
			}
			console.log('postSocialLinksArr', postSocialLinksArr);
			if (postSocialLinksArr.length > 0) {
				await postSocialLinks(
					userData?.registration?.id,
					userData?.accessToken,
					postSocialLinksArr
				);
			}
			loading(false);
			onNextHandler();

			// history.replace('/congratulations');
		} else {
			handleErrorsAndScroll(errors);
		}
		return;
	};

	const handleBackButton = async () => {
		loading(true);

		let newUserData = {
			active_steps: 1,
			steps_completed: false,
		};
		console.log('===user data and new user data', userData, newUserData);
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken,
			onBackHandler
		);
		loading(false);
	};

	useEffect(() => {
		if (userData && userData?.accessToken) {
			getSocialLinks(userData?.registration?.id, userData?.accessToken);
			getSocialMediaMasterData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (socialLinks.length > 0) {
			let newFilteredSocialOptions = socialMediaMaster.filter((social) => {
				const idx = socialLinks.findIndex(
					(itm) =>
						itm.social_name.toLowerCase() === social.media_name.toLowerCase()
				);
				if (idx !== -1) {
					return false;
				} else {
					return true;
				}
			});
			setNewSocialLinkData(newFilteredSocialOptions);
		} else {
			setNewSocialLinkData(socialMediaMaster);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socialLinks, socialMediaMaster]);

	useEffect(() => {
		const setExistingCityandState = async () => {
			const cityVal = await userData?.registration?.city;
			if (cityVal) {
				let cityIdx;
				if (allCity && allCity.length > 0)
					cityIdx = allCity?.findIndex(
						(itm) => itm.name?.toLowerCase() === cityVal.toLowerCase()
					);
				if (cityIdx !== -1) {
					setCity(allCity[cityIdx]);
				}
			}

			const stateVal = await userData?.registration?.state;
			if (stateVal) {
				const stateIdx = allState.findIndex(
					(itm) => itm.name.toLowerCase() === stateVal.toLowerCase()
				);
				if (stateIdx !== -1) {
					setState(allState[stateIdx]);
				}
			}
		};
		setExistingCityandState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const getAddress2DefaultVal = () => {
	// 	const address2Val = pincode;
	// 	if (address2Val) {
	// 		return address2Val;
	// 	} else {
	// 		return null;
	// 	}
	// };

	useEffect(() => {
		const getData = setTimeout(async () => {
			if (locSearchVal && searchLoad) {
				try {
					const response = await axios.get(
						// `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
						POSTOFFICE_PINCODE + locSearchVal
					);
					if (response.status === 200) {
						if (response?.data?.status_code == 200) {
							console.log('SEARCH RESULT', response.data);
							setMapOtions(response.data?.data);
							setSearchLoad(false);
						} else {
							setMapOtions([]);
							setSearchLoad(false);
						}
					} else {
						pushMessage('No City Found!!');
						setSearchLoad(false);
					}
				} catch (err) {
					console.log('');
					setSearchLoad(false);
				}
			}
		}, 250);

		return () => clearTimeout(getData);
	}, [locSearchVal]);

	const handleDelete = (ind) => {
		if (imgDeleteType === 'cover') {
			console.log(ind);
			setSelectedCoverImage([]);
		} else {
			setCompanyLogo(null);
		}
		setIsReplaceType(null);
		setOpenPopup(false);
		setImageDeleteType(null);
	};
	const handleReplace = () => {
		if (isReplaceType === 'cover') {
			coverInputRef.current.click();
			setOpenPopup(false);
			setIsReplaceType(null);
		} else {
			companyLogoInputRef.current.click();
			setOpenPopup(false);
			setIsReplaceType(null);
		}
	};
	const getLocationString = (option) => {
		console.log('===getLocationString', option);
		const fullAddress = option?.properties?.full_address || '';
		const address = option?.properties?.address || '';
		const name = option?.properties?.name || '';
		const placeFormatted = option?.properties?.place_formatted || '';

		let locationString = '';

		if (fullAddress) {
			locationString = fullAddress;
		} else {
			locationString += address;
			locationString += name;
			locationString += placeFormatted;
		}

		return locationString.trim();
	};
	const [viewImagedialogOpen, setViewImagedialogOpen] = React.useState(false);
	const [selectedImage, setSelectedImage] = React.useState('');

	// Function to handle opening the dialog and setting the selected image
	const handleViewImageClick = (image) => {
		console.log('===handleViewImageClick', image);
		setSelectedImage(image);
		setViewImagedialogOpen(true);
	};

	// Function to handle closing the dialog
	const handleViewImageCloseDialog = () => {
		setViewImagedialogOpen(false);
		setSelectedImage('');
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (companyLogo && companyLogo.length > 0) {
			let imgArr = Array.from(companyLogo);
			if (!imgArr[0].size) {
				setCompanyLogo(null);
			}
		}
	}, [companyLogo]);

	useEffect(() => {
		if (selectedCoverImage && selectedCoverImage.length > 0) {
			let imgArr = Array.from(selectedCoverImage);
			if (!imgArr[0].size) {
				setSelectedCoverImage(null);
			}
		}
	}, [selectedCoverImage]);

	const getInitialPincodeData = async (pincode) => {
		try {
			const response = await axios.get(
				// `https://api.mapbox.com/search/searchbox/v1/forward?q=${locSearchVal}&language=${LANG}&limit=${LIMIT}&country=${COUNTRY}&access_token=${MAPBOX_ACC}`
				POSTOFFICE_PINCODE + pincode
			);
			if (response.status === 200) {
				if (response?.data?.status_code == 200) {
					console.log('SEARCH RESULT', response.data);
					setMapOtions(response.data?.data);
					setSearchLoad(false);
					return response.data?.data;
				} else {
					setMapOtions([]);
					setSearchLoad(false);
					return [];
				}
			} else {
				pushMessage('No City Found!!');
				return [];
			}
		} catch (err) {
			console.log('');
			return [];
		}
	};

	function setDefaultAddressValues() {
		setDefaultPincodeVal({ pincode: [userData?.registration?.pincode] });
	}

	useEffect(() => {
		async function getPincodeData(pincode) {
			try {
				const pincodeArr = await getInitialPincodeData(pincode);
				console.log('pincodeArr', pincodeArr);
				if (pincodeArr.length > 0) {
					const picodeDataExac = pincodeArr.find(
						(pin) =>
							pin.city[0] === userData?.registration?.city &&
							pin.statename[0] === userData?.registration?.state &&
							pin.pincode[0] === userData?.registration?.pincode
					);
					if (picodeDataExac) {
						setDefaultPincodeVal(picodeDataExac);
						setAddress2(picodeDataExac);
						setAreaName(picodeDataExac?.areaname[0]?.toString());
						setCity(picodeDataExac?.city[0]?.toString());
						setState(picodeDataExac?.statename[0]?.toString());
					} else {
						setDefaultAddressValues();
					}
				} else {
					setDefaultAddressValues();
				}
			} catch (error) {
				setDefaultAddressValues();
			}
		}
		let pinDeb = setTimeout(() => {
			if (userData?.registration?.pincode) {
				getPincodeData(userData?.registration?.pincode);
				setLocSearchVal(userData?.registration?.pincode);
			}
		}, 1000);

		return () => clearTimeout(pinDeb);
	}, [userData?.registration?.pincode]);
	const onInputChange = (e, value) => {
		const numericValue = value.replace(/[^0-9]/g, '');
		setLocSearchVal(numericValue);

		if (numericValue.length === 6) {
			validateInput('pincode', numericValue);
			setSearchLoad(true);
		} else {
			setSearchLoad(false);
		}
	};

	const onChange = (e, value) => {
		if (e?.target?.value?.length === 0) {
			setAreaName('');
			setCity('');
			setState('');
			setMapOtions([]);
			setPincode(null);
			setAddress2('');
			setDefaultPincodeVal(null);
			setSearchLoad(false);
		} else {
			const pincode = value?.pincode[0]?.toString();
			if (indianPincodeRegex.test(pincode)) {
				setAddress2(value);
				validateInput('pincode', pincode);
				setAreaName(value?.areaname[0]?.toString());
				setCity(value?.city[0]?.toString());
				setState(value?.statename[0]?.toString());
				setDefaultPincodeVal(value);
				setPincode(value);
			} else {
				console.log(
					'Invalid pincode. It should be a 6-digit number starting with a non-zero digit'
				);
			}
			setSearchLoad(false);
		}
	};

	async function handleSkipButton() {
		loading(true);
		let newUserData = {
			active_steps: 2,
			steps_completed: false,
		};
		await updateUserData(
			userData,
			newUserData,
			userData.registration.id,
			userData.accessToken
		);
		loading(false);
		onNextHandler();
	}

	return (
		<ThemeProvider theme={theme}>
			<Container component="main" maxWidth="lg" className="px-0">
				{isLoading && (
					<Backdrop
						sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					style={{ height: '90vh' }}
				>
					<Grid item xs={12}>
						<Typography
							variant="h4"
							align="left"
							alignItems="center"
							className="fs-22 mt-4 mb-2 fw-bold font-skmodernist"
							gutterBottom
							style={{ whiteSpace: 'pre-line' }}
						>
							{categoryText}
						</Typography>
						<Typography
							variant="body1"
							align="left"
							className="fs-14 text-grey fw-normal font-gotham"
							gutterBottom
						>
							A company logo helps you build trust with your clients. To keep
							things safe and simple, they&apos;ll pay you through us - which is
							why we need your personal information.
						</Typography>
						{/* e.preventDefault(); */}
						<form onSubmit={submitProfileDetails} style={{ marginTop: '40px' }}>
							<div className="mb-3">
								<InputLabel
									htmlFor="inputCIN"
									className="text-start fs-14 text-black font-skmodernist"
								>
									Upload Company Logo
									<br />
									<span className="text-muted fs-12">
										(JPEG or PNG files only)
									</span>
								</InputLabel>
								{companyLogo &&
								companyLogo?.length > 0 &&
								Object.keys(companyLogo)?.length > 0 ? (
									Array.from(companyLogo).map((img, imgIdx) => {
										if (img?.size)
											return (
												<div
													key={imgIdx}
													style={{
														position: 'relative',
														display: 'inline-block',
														marginRight: '10px',
													}}
												>
													<img
														key={imgIdx}
														onClick={() => {
															console.log('company==logo==img', img);
															handleViewImageClick(URL.createObjectURL(img));
														}}
														style={{
															display: img ? 'block' : 'none',
															objectFit: 'contain',
															width: '100px',
															height: '100px',
														}}
														width={'100px'}
														height={'100px'}
														src={URL.createObjectURL(img)}
													/>
													<IconButton
														style={{
															position: 'absolute',
															top: '4px',
															right: '-7px',
															color: '#727272',
															padding: '5px',
															borderRadius: '50%',
															backgroundColor: 'blue',
														}}
														aria-label="Delete Image"
														onClick={() => {
															setImageDeleteType('logo');
															setOpenPopup(true);
														}}
													>
														<CloseIcon
															style={{ color: 'white', width: 16, height: 16 }}
														/>
													</IconButton>
												</div>
											);
									})
								) : (
									<DefaultLogo
										name={company_name}
										handleClick={handleLogoButtonClick}
									/>
								)}
								<input
									type="file"
									accept=".png,.jpeg,.jpg"
									style={{ display: 'none' }}
									ref={companyLogoInputRef}
									onChange={handleLogoFileChange}
								/>
								<ImageDialog
									open={viewImagedialogOpen}
									onClose={handleViewImageCloseDialog}
									image={selectedImage}
								/>
								<input
									type="file"
									accept=".png,.jpeg,.jpg"
									style={{ display: 'none' }}
									ref={companyLogoInputRef}
									onChange={handleLogoFileChange}
								/>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.logoImage}
								</FormHelperText>
							</div>
							<div className=" mb-3">
								<InputLabel className="text-start fs-14 text-black font-skmodernist">
									Add Cover Photo
									<br />
									<span className="text-muted fs-12">
										(JPEG or PNG files only)
									</span>
								</InputLabel>
								{selectedCoverImage &&
									selectedCoverImage.map((img, imgIdx) => {
										if (img?.size)
											return (
												<div
													key={imgIdx}
													style={{
														position: 'relative',
														display: 'inline-block',
														marginRight: '10px',
													}}
												>
													<img
														style={{
															display: img ? 'block' : 'none',
															objectFit: 'contain',
															width: '100px',
															height: '100px',
														}}
														src={URL.createObjectURL(img)}
														alt={`Cover Image ${imgIdx}`}
														onClick={() =>
															handleViewImageClick(URL.createObjectURL(img))
														}
													/>
													{img && (
														<IconButton
															style={{
																position: 'absolute',
																top: '4px',
																right: '-7px',
																color: '#727272',
																padding: '5px',
																borderRadius: '50%',
																backgroundColor: 'blue',
															}}
															aria-label="Delete Image"
															onClick={() => {
																setImageDeleteType('cover');
																setOpenPopup(true);
															}}
														>
															<CloseIcon
																style={{
																	color: 'white',
																	width: 16,
																	height: 16,
																}}
															/>
														</IconButton>
													)}
												</div>
											);
									})}
								<input
									type="file"
									accept=".png,.jpeg,.jpg"
									style={{ display: 'none' }}
									ref={coverInputRef}
									onChange={handleCoverFileChange}
								/>
								<Button
									type="button"
									fullWidth
									variant="outlined"
									className="add-button mt-2 fs-15 font-skmodernist fw-bold"
									sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									onClick={handleCoverButtonClick}
								>
									<img
										src={AddBtnIcon}
										alt="add-btn-icon"
										className="user-logo"
									/>
								</Button>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.coverPhoto}
								</FormHelperText>
							</div>
							<Grid
								container
								rowSpacing={1}
								columnSpacing={2}
								alignItems="flex-start"
							>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputCompanyAddress"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Add WhatsApp number
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputCompanyAddress"
											type="text"
											placeholder="Add WhatsApp number"
											onKeyDown={(evt) =>
												['e', 'E', '+', '-'].includes(evt.key) &&
												evt.preventDefault()
											}
											inputProps={{
												maxLength: 10,
											}}
											fullWidth
											margin="none"
											value={whatsapp || ''}
											onChange={handleWhatsappChange}
										/>
										<Box display="flex" flexDirection="row" alignItems="center">
											<Checkbox
												edge="start" // Aligning checkbox to the left
												onChange={hanldleIsExistingMobile}
												checked={isNotExistingMobile}
												inputProps={{
													'aria-labelledby': 'checkbox-list-secondary',
												}}
												sx={{
													color: '#D4D4D4',
													'&.Mui-checked': {
														color: '#416AD4',
													},
												}}
											/>
											<Typography
												sx={{
													color: '#7c7d7d',
													mr: 1,
													fontSize: theme.typography.fontSize,
												}}
											>
												Same as Registered number
											</Typography>
										</Box>
									</FormControl>

									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{errors.whatsapp}
									</FormHelperText>
								</Grid>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputCompanyAddress"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Company Address<span className="text-mandatory">*</span>
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputCompanyAddress"
											// label="Mobile Number"
											type="text"
											placeholder="Building/Street"
											// required
											inputProps={{
												maxLength: 200,
											}}
											fullWidth
											margin="none"
											value={address1}
											onChange={handleAddress1Change}
										/>
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{errors.address}
									</FormHelperText>
								</Grid>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputLocation"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Enter your Pincode
										<span className="text-mandatory">*</span>
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<Autocomplete
											disablePortal
											ref={searchLocInpRef}
											id={'SearchLocation'}
											options={mapOtions}
											fullWidth
											value={defaultPincodeVal}
											onInputChange={onInputChange}
											onChange={(e, value) => onChange(e, value)}
											open={open}
											onOpen={openPopper}
											onClose={closePopper}
											getOptionLabel={(option) =>
												option?.pincode[0]?.toString()
											}
											isOptionEqualToValue={(option, value) =>
												option['pincode'] === value['pincode']
											}
											renderOption={(props, option) => (
												<Box component="li" {...props}>
													{option.areaname[0]} - {option.city[0]} -{' '}
													{option.pincode}
												</Box>
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													variant="outlined"
													placeholder={'eg. 400070'}
													inputProps={{
														...params.inputProps,
														maxLength: 6,
														inputMode: 'numeric',
														onKeyDown: (e) => {
															if (
																!/^[0-9]$/.test(e.key) &&
																e.key !== 'Backspace' &&
																e.key !== 'Delete'
															) {
																e.preventDefault();
															}
														},
													}}
												/>
											)}
											noOptionsText={'Please enter valid Pincode'}
										/>
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{errors.pincode}
									</FormHelperText>
								</Grid>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputCompanyAddress"
										className="text-start fs-14 text-black font-skmodernist"
									>
										Town/Area
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputCompanyAddress"
											// label="Mobile Number"
											type="text"
											placeholder="Town/Area"
											// required
											inputProps={{
												maxLength: 200,
											}}
											fullWidth
											margin="none"
											value={
												(typeof areaname === 'object'
													? areaname?.name
													: areaname) || ''
											}
											disabled
										/>
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{/* {errors.address} */}
									</FormHelperText>
								</Grid>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputCompanyAddress"
										className="text-start fs-14 text-black font-skmodernist"
									>
										City/District
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputCompanyAddress"
											// label="Mobile Number"
											type="text"
											placeholder="City"
											// required
											inputProps={{
												maxLength: 200,
											}}
											fullWidth
											margin="none"
											value={
												(typeof city === 'object' ? city?.name : city) || ''
											}
											disabled
										/>
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{/* {errors.address} */}
									</FormHelperText>
								</Grid>
								<Grid item md={6} lg={6} xs={12}>
									<InputLabel
										htmlFor="inputCompanyAddress"
										className="text-start fs-14 text-black font-skmodernist"
									>
										State
									</InputLabel>
									<FormControl fullWidth sx={{ my: 1 }} variant="outlined">
										<OutlinedInput
											id="inputCompanyAddress"
											// label="Mobile Number"
											type="text"
											placeholder="State"
											// required
											inputProps={{
												maxLength: 200,
											}}
											fullWidth
											margin="none"
											value={
												(typeof states === 'object' ? states?.name : states) ||
												''
											}
											disabled
										/>
									</FormControl>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{/* {errors.address} */}
									</FormHelperText>
								</Grid>
							</Grid>
							<Box sx={{ marginBottom: isTabletOrMobile ? 20 : 0 }}>
								<SocialLinks
									socialLinks={socialLinks}
									setSocialLinks={setSocialLinks}
									socialName={socialName}
									newSocialLinkData={newSocialLinkData}
									socialData={socialData}
									setSocialName={setSocialName}
									setSocialData={setSocialData}
									socialUrlValidateError={socialUrlValidateError}
									setSocialUrlValidateError={setSocialUrlValidateError}
									handleAddSocialLinkBtn={handleAddSocialLinkBtn}
									registrationId={userData?.registration?.id}
									socialMediaMaster={socialMediaMaster}
								/>
								<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
									{errors.socialLink}
								</FormHelperText>
							</Box>
							{isTabletOrMobile && (
								<Box sx={{ width: '100%' }}>
									<Box sx={[stickyButtonStyle]}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
											sx={[
												{ borderRadius: '6px', textTransform: 'capitalize' },
											]}
										>
											Next Step
										</Button>
										<div style={{display: "flex", justifyContent: "space-between"}}>
											<Button
												onClick={handleBackButton}
												variant="text"
												type="button"
												fullWidth
												className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
											>
												Back
											</Button>
											<Button
												onClick={handleSkipButton}
												variant="text"
												type="button"
												fullWidth
												className="mt-2 mb-3  fs-15 text-capitalize fw-semibold text-black"
											>
												Skip for Now
											</Button>
										</div>
									</Box>
								</Box>
							)}

							{isDesktopOrLaptop && (
								<>
									<Box className="d-flex justify-content-between align-items-center my-4">
										<Button
											onClick={handleBackButton}
											variant="text"
											type="button"
											className="btn-web-back"
										>
											Back
										</Button>
										<Button
											onClick={handleSkipButton}
											variant="text"
											type="button"
											className="btn-web-back"
										>
											Skip for Now
										</Button>
										<Button
											type="submit"
											variant="contained"
											className="btn-web-next"
										>
											Next Step
										</Button>
									</Box>
								</>
							)}
						</form>
					</Grid>
				</Grid>
			</Container>
			<DeleteConfirmationPopup
				open={openPopup}
				onClose={() => {
					setOpenPopup(false);
					setIsReplaceType();
				}}
				onConfirm={isReplaceType ? handleReplace : handleDelete}
				message={`Are you sure you want to ${
					!isReplaceType ? 'delete' : 'replace'
				} photo?`}
			/>
		</ThemeProvider>
	);
};

const mapStateToProps = (state) => {
	const { userData } = state.signin;
	const company_name = userData.registration.company_name;
	const {
		companyLogo,
		address1,
		address2,
		// locationCoords,
		whatsapp,
		socialLinks,
		selectedCoverImage,
		city,
		states,
		allCity,
		allState,
		socialData,
		socialName,
		socialMediaMaster,
		areaname,
		pincode,
	} = state.profileDetails;
	const { isLoading } = state.root;

	return {
		userData,
		companyLogo,
		address1,
		address2,
		// locationCoords,
		socialLinks,
		selectedCoverImage,
		city,
		states,
		allCity,
		allState,
		socialData,
		socialName,
		isLoading,
		whatsapp,
		socialMediaMaster,
		company_name,
		areaname,
		pincode,
	};
};

export default connect(mapStateToProps, {
	setCompanyLogo,
	setAddress1,
	uploadCoverImage,
	setSelectedCoverImage,
	uploadCompanyLogoImage,
	updateUserData,
	setAddress2,
	setCity,
	setState,
	// setLocationCoords,
	getCityMaster,
	getStateMaster,
	setSocialData,
	setSocialLinks,
	getSocialLinks,
	postSocialLinks,
	setSocialName,
	loading,
	pushMessage,
	getSocialMediaMasterData,
	putSocialLinks,
	setWhatsappNumber,
	setAreaName,
	setPincode,
})(ProfileDetailsForm);

ProfileDetailsForm.propTypes = {
	onNextHandler: PropTypes.func,
	onBackHandler: PropTypes.func,
	companyLogo: PropTypes.array,
	address1: PropTypes.string,
	address2: PropTypes.object,
	// locationCoords: PropTypes.object,
	socialLinks: PropTypes.array,
	setCompanyLogo: PropTypes.func,
	setAddress1: PropTypes.func,
	userData: PropTypes.object,
	uploadCoverImage: PropTypes.func,
	selectedCoverImage: PropTypes.array,
	setSelectedCoverImage: PropTypes.func,
	uploadCompanyLogoImage: PropTypes.func,
	updateUserData: PropTypes.func,
	setAddress2: PropTypes.func,
	city: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	states: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	getCityMaster: PropTypes.func,
	getStateMaster: PropTypes.func,
	allCity: PropTypes.array,
	allState: PropTypes.array,
	setCity: PropTypes.func,
	setState: PropTypes.func,
	// setLocationCoords: PropTypes.func,
	socialData: PropTypes.object,
	setSocialData: PropTypes.func,
	setSocialLinks: PropTypes.func,
	getSocialLinks: PropTypes.func,
	postSocialLinks: PropTypes.func,
	setSocialName: PropTypes.func,
	socialName: PropTypes.object,
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	pushMessage: PropTypes.func,
	getSocialMediaMasterData: PropTypes.func,
	socialMediaMaster: PropTypes.array,
	putSocialLinks: PropTypes.func,
	whatsapp: PropTypes.string,
	setWhatsappNumber: PropTypes.func,
	company_name: PropTypes.string,
	setAreaName: PropTypes.func,
	areaname: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	pincode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	setPincode: PropTypes.func,
};

