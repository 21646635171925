import React, { useEffect } from 'react';
import './App.css';
import Router from './navigation/Router';
import { Provider } from 'react-redux';
import configureStore, { history } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { AuthProvider } from './providers/AuthProvider';
import { injectStore } from './utils/APIs/api';
import MessageBox from './components/MessageBox/MessageBox';
import ReactGA from 'react-ga4';
ReactGA.initialize(process.env.REACT_APP_GA_ID);

function App() {
  const { store, persistor } = configureStore();
  useEffect(() => {
    if(process.env.REACT_APP_ENV === "STAGING"){
      console.log = function () {};
      console.error = function () {};
      console.debug = function () {};
      console.info = function () {};
    }
  }, []);

  useEffect(() => {
    if (store) injectStore(store);
  }, [store]);

  // const isDesktopOrLaptop = useMediaQuery({
  //   query: '(min-width: 1024px)',
  // });
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <AuthProvider>
            <div className="App">
              <div aria-label="pwa-test" aria-hidden />
              <MessageBox />
              <Router />
            </div>
          </AuthProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
