import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import './Otp.css';
import '../../index.css';
import {
	Container,
	Grid,
	Typography,
	Button,
	// Link,
	CircularProgress,
	Backdrop,
	// Modal,
	// Box,
	// TextField,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { OtpInput } from '../../components/OtpBox'
import { MuiOtpInput } from 'mui-one-time-password-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	sendOTPForVerify,
	sendSigninData,
	setUserData,
} from './redux/actions/submit-code-action';
import {
	// ModalStyle, 
	OTP_MIN, OTP_SEC
} from '../../utils/constants';
import { FormHelperText } from '@mui/material';
import WebOtp from './WebOtp';


import {
	getExistingBusinessDocuments,
	setActiveStep,
	setStepperData,
} from '../B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
// import {
//   B2B_STEP_1_ACTIVE,
//   B2B_STEP_2_ACTIVE,
//   B2B_STEP_3_ACTIVE,
// } from '../B2BOnboardingSteps/constants';
import {
	setAddress1,
	setCity,
	setState,
	setAddress2,
	getSocialLinks,
	getSocialMediaMasterData,
} from '../ProfileDetails/redux/actions/profile-details-form-action';
import { loading } from '../../store/actions/rootActions';
import { sendMobForOTP } from '../Login/redux/actions/submit-mobileno-action';
import Responsive from '../../utils/responsive';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';

const theme = createTheme();

function OtpForm(props) {
	const {
		sendSigninData,
		// postLoginRouteName,
		// setActiveStep,
		// setStepperData,
		// setCity,
		// setState,
		// setAddress1,
		// setAddress2,
		// // setUserData,
		// userData,
		getExistingBusinessDocuments,
		loading,
		isLoading,
		country_code_name,
		getSocialLinks,
		getSocialMediaMasterData,
		enquiry,
		sendOTPForVerify,
		sendMobForOTP,
		pushMessage
	} = props;

	const [otp, setOtp] = useState('');
	const [otpError, setotpError] = useState('');

	const [minutes, setMinutes] = useState(OTP_MIN);
	const [seconds, setSeconds] = useState(OTP_SEC);

	// const [open, setOpen] = useState(false);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);

	function maskFirstFiveCharacters(str) {
		if (str.length <= 7) {
			// If the string length is 5 or less, just return all asterisks
			return '*'.repeat(str.length);
		} else {
			// Replace the first five characters with asterisks
			return '*****' + str.slice(7);
		}
	}

	const handleChange = (newValue) => {
		setotpError('');
		setOtp(newValue);
	};
	let history = useHistory();

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seconds]);

	const successCallback = (data) => {
		// delete otp info from localstorage
		if (data?.success == true) {
			submitOTPData();
		}
		// else {
		// 	handleOpen();
		// }
	};
	const errorCallback = (message) => {
		console.log("Invalid otp");
		setotpError("Please Enter Correct OTP.")
		if (message){
			pushMessage(message);
		}
		
		// handleOpen();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = {
			phone: props.mobile_number,
			userOtp: otp?.toString(),
		};
		sendOTPForVerify(data, successCallback, errorCallback);
	};

	const submitOTPData = async () => {
		if (otp.length < 6) {
			// alert('Please Enter Correct OTP.');
			setotpError('Please Enter Correct OTP.');
			return;
		}
		loading(true);
		let data = {
			mobile_number: props.mobile_number,
			country_code: props.country_code,
			otp: otp,
		};
		console.log('==submitOTPData');
		await sendSigninData(
			data,
			history,
			async (usrData) => {
				if (usrData) {
					await getExistingBusinessDocuments(
						usrData?.registration?.id,
						country_code_name
					);
					await getSocialLinks(usrData?.registration?.id, usrData?.accessToken);
					await getSocialMediaMasterData();
				}
			},
			enquiry
		);
		loading(false);
	};

	// useEffect(() => {
	//   async function getBusinessDoc() {
	//     await getExistingBusinessDocuments(
	//       userData?.registration?.id,
	//       country_code_name
	//     );
	//     await getSocialLinks(userData?.registration?.id, userData?.accessToken);
	//     await getSocialMediaMasterData();
	//   }
	//   if (userData) getBusinessDoc();
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [userData]);

	// useEffect(() => {
	//   async function getBusinessDoc() {
	//     await getExistingBusinessDocuments(
	//       userData?.registration?.id,
	//       userData?.accessToken
	//     );
	//   }
	//   if (userData && postLoginRouteName) {
	//     getBusinessDoc();
	//     history.push(`/${postLoginRouteName}`);
	//   }
	//   // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [userData, postLoginRouteName]);

	const successResendCallback = () => {
		loading(false);
	};
	const errorResendCallback = () => {
		loading(false);
	};

	const resendOTP = () => {
		// console.log("Resend button is clicked");
		setMinutes(OTP_MIN);
		setSeconds(OTP_SEC);

		const data = {
			phone: props.mobile_number,
		};

		sendMobForOTP(data, successResendCallback, errorResendCallback);
	};

	return (
		<>
			{Responsive(
				<ThemeProvider theme={theme}>
					<Container component="main" maxWidth="xs">
						{/* {isLoading && (<CircularLoading />)} */}

						{isLoading && (
							<Backdrop
								sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
								open={isLoading}
							>
								<CircularProgress color="inherit" />
							</Backdrop>
						)}
						{/* <Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={ModalStyle}>
						<Typography id="modal-modal-title" variant="h6" component="h2">
							Elynker
						</Typography>
						<Typography id="modal-modal-description" sx={{ mt: 2 }}>
							Invalid verification code provided, please try again.
						</Typography>
						<div style={{ float: 'right' }}>
							<Button
								onClick={() => {
									handleClose();
								}}
							>
								OK
							</Button>
						</div>
					</Box>
				</Modal> */}
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							style={{ height: '60vh' }}
						>
							<Grid item xs={12}>
								<div className="">
									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-26  fw-bold"
										gutterBottom
									>
										Verify your phone number
									</Typography>
									{/* <Typography

                variant="h4"
                align="left"
                alignItems="center"
                className="fs-26  fw-bold"
                gutterBottom
              >
                your number
              </Typography> */}
								</div>

								<Typography
									variant="body1"
									align="left"
									className="fs-14 text-grey fw-normal font-gotham"
									gutterBottom
								>
									A code has been sent to{' '}
									<span style={{ color: '#864FFD' }}>
										{props.country_code}{' '}
										{maskFirstFiveCharacters(props.mobile_number)}
									</span>{' '}
									, Enter it below to continue
								</Typography>
								<Typography className="fs-13 text-decoration-underline fw-normal font-gotham">
									<a href="\login" className="text-primary">
										Change Mobile Number
									</a>
								</Typography>

								<form
									style={{ marginTop: '60px' }}
									onSubmit={(e) => handleSubmit(e)}
								>
									<div>
										<MuiOtpInput
											id="otp-id"
											value={otp}
											autoFocus
											// type="number"
											itemType=""
											type="number"
											inputMode="numeric"
											length={6}
											gap={2}
											TextFieldsProps={{
												type: 'number',
												inputMode: 'numeric',
											}}
											validateChar={(val) => !isNaN(val)}
											onChange={handleChange}
											onKeyDown={(evt) =>
												['e', 'E', '+', '-'].includes(evt.key) &&
												evt.preventDefault()
											}
											// renderInput={(props) => (
											// 	<TextField
											// 	  {...props}
											// 	  type="tel" // Use 'tel' type for numeric input
											// 	  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restrict input to numbers
											// 	/>
											// )}
										/>
										{/* <TextField
                  value={otp}
                  label="OTP"
                  variant="outlined"
                  fullWidth
                  type="number"
                  inputProps={{ inputMode: 'numeric', maxLength: 6 }}
                  onChange={handleChange} /> */}
									</div>
									<FormHelperText sx={{ color: 'red' }} id="email-helper-text">
										{otpError}
									</FormHelperText>
									<div className="d-flex mt-4">
										{seconds ? (
											<Typography
												variant="body1"
												align="left"
												className="fs-14 text-grey fw-normal font-gotham"
												gutterBottom
											>
												Waiting for OTP:{' '}
												<span style={{ color: '#416AD4' }}>
													{minutes < 10 ? `0${minutes}` : minutes}:
													{seconds < 10 ? `0${seconds}` : seconds}
												</span>
											</Typography>
										) : (
											<Button
												sx={{ padding: "0px", textTransform: "capitalize" }}
												component="button"
												variant="body1"
												align="right"
												underline="none"
												onClick={resendOTP}
												className="fs-14 fw-normal font-gotham"
												gutterBottom
												style={{
													color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#416AD4',
												}}
												disabled={seconds > 0 || minutes > 0}
											>
												Resend OTP
											</Button>
										)}
									</div>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										className="mt-4 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
										sx={{ borderRadius: '6px', textTransform: 'capitalize' }}
									>
										Submit
									</Button>
								</form>
							</Grid>
						</Grid>
					</Container>
				</ThemeProvider>,
				<WebOtp
					resendOTP={resendOTP}
					submitOTPData={submitOTPData}
					successResendCallback={successResendCallback}
					errorResendCallback={errorResendCallback}
					handleSubmit={handleSubmit}
					errorCallback={errorCallback}
					successCallback={successCallback}
					handleChange={handleChange}
					maskFirstFiveCharacters={maskFirstFiveCharacters}
					otp={otp}
					otpError={otpError}
					minutes={minutes}
					seconds={seconds}
					open={open}
					// handleClose={handleClose}
				/>


			)}
		</>


	);
}

OtpForm.propTypes = {
	// Adjust the prop type based on your actual data type
	mobile_number: PropTypes.string,
	sendSigninData: PropTypes.func,
	country_code: PropTypes.string,
	setActiveStep: PropTypes.func,
	setStepperData: PropTypes.func,
	setCity: PropTypes.func,
	setState: PropTypes.func,
	setAddress1: PropTypes.func,
	setAddress2: PropTypes.func,
	setUserData: PropTypes.func,
	userData: PropTypes.object,
	postLoginRouteName: PropTypes.string,
	getExistingBusinessDocuments: PropTypes.func,
	loading: PropTypes.func,
	isLoading: PropTypes.bool,
	country_code_name: PropTypes.string,
	getSocialLinks: PropTypes.func,
	getSocialMediaMasterData: PropTypes.func,
	enquiry: PropTypes.object,
	sendOTPForVerify: PropTypes.func,
	sendMobForOTP: PropTypes.func,
	pushMessage: PropTypes.func
};

const mapStateToProps = (state) => {
	const { userData, postLoginRouteName } = state.signin;
	const { mobile_number, country_code, country_code_name } = state.mobile;
	const { isLoading } = state.root;
	const { enquiry } = state.b2bVendorlisting;

	return {
		mobile_number,
		country_code,
		userData,
		postLoginRouteName,
		isLoading,
		country_code_name,
		enquiry,
	};
};

export default connect(mapStateToProps, {
	sendSigninData,
	setStepperData,
	setActiveStep,
	setCity,
	setState,
	setAddress1,
	setAddress2,
	setUserData,
	getExistingBusinessDocuments,
	getSocialLinks,
	getSocialMediaMasterData,
	loading,
	sendOTPForVerify,
	sendMobForOTP,
	pushMessage
})(OtpForm);
