import { pushMessage } from '../../../../components/MessageBox/redux/actions/message-box-action';
import { loading } from '../../../../store/actions/rootActions';
import { getTemplatePage, postSendOTP } from '../../../../utils/APIs/endpoints';
import {
	SAVE_MOBILE_NO,
	// LOADING,
	SAVE_COUNTRY_CODE,
	SAVE_COUNTRY_CODE_NAME,
	RESET_LOGIN_DATA,
	GET_TEMPLATE_PAGE_DATA,
} from '../types';

// const verifyMobileRequest = (isLoading) => ({
//   type: LOADING,
//   payload: isLoading,
// });

const saveMobileNo = (mobile_no) => {
	return async (dispatch) => {
		try {
			// verifyMobileRequest(true);
			await dispatch({
				type: SAVE_MOBILE_NO,
				payload: mobile_no,
			});
			// verifyMobileRequest(false);
		} catch (error) {
			console.log('Error saving mobile_no', error);
		}
	};
};

export const saveCountryCode = (code) => {
	return async (dispatch) => {
		try {
			await dispatch({
				type: SAVE_COUNTRY_CODE,
				payload: code,
			});
		} catch (error) {
			console.log('Error saving country_code', error);
		}
	};
};

export const saveCountryCodeName = (code) => {
	return async (dispatch) => {
		try {
			await dispatch({
				type: SAVE_COUNTRY_CODE_NAME,
				payload: code,
			});
		} catch (error) {
			console.log('Error saving country_code_name', error);
		}
	};
};

export const resetLoginData = () =>
	function (dispatch) {
		dispatch({ type: RESET_LOGIN_DATA });
	};

export const getTemplateDataPage = () => {
	return async (dispatch) => {
		dispatch(loading(true));
		try {
			const response = await getTemplatePage();
			console.log('response_dtata', response);
			if (response.status === 200) {
				dispatch({ type: GET_TEMPLATE_PAGE_DATA, payload: response.data });
				dispatch(loading(false));
			} else {
				dispatch(loading(false));
			}
		} catch (error) {
			console.log('errors :', error);
			dispatch(loading(false));
		}
	};
};

export const sendMobForOTP = (
	mobileNo,
	successCallback = () => { },
	errorCallback = () => { }
) => {
	return async (dispatch) => {
		dispatch(loading(true));
		try {
			const response = await postSendOTP(mobileNo);
			if (response?.status === 200) {
				// let otpdata = response?.data?.data;
				// localStorage.setItem(mobileNo?.phone, JSON.stringify(otpdata));
				dispatch(pushMessage(response.data?.message));
				dispatch(loading(false));
				successCallback();
			} else {
				dispatch(loading(false));
				errorCallback();
			}
		} catch (error) {
			console.log("ERROR 400 ", error);

			if (error?.response?.status === 400) {
				errorCallback(error?.response?.data?.message)
			}
			else {
				errorCallback();
			}
			dispatch(loading(false));
		}
	};
};

export default saveMobileNo;


