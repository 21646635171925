/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
	Container,
	Grid,
	Typography,
	Divider,
	Button,
	ButtonGroup,
	TextareaAutosize,
	Radio,
	Avatar
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as FiltersIcon } from '../../assets/filters-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/clock-icon.svg';
import ProductIcon from '../../assets/product-image.png';
import DropdownIcon from '@mui/icons-material/KeyboardArrowDown';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import { loading } from '../../store/actions/rootActions';
import {
	getJobListByTitle,
	setFilterCategory,
	setFilterMinExp,
	setFilterCity,
	setFilterSort,
} from './redux/actions/job-listing-action';
import moment from 'moment';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { saveJobData } from '../JobPostDetail/redux/actions/job-post-details-action';
import {
  resetJobApplyData,
  setJobID
} from '../JobPostApply/redux/actions/job-post-apply-action';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { getEnquiryById, postEnquiry } from '../B2BCategory/redux/actions/b2b-category-action';
import { pushMessage } from '../../components/MessageBox/redux/actions/message-box-action';
import useValidation from '../../hooks/useValidation';
import DrawerForm from '../../components/DrawerForm';
import WebJobListingForm from './WebJobListing';
import Responsive from '../../utils/responsive';

const theme = createTheme();

const JobListingForm = ({
  loading,
  isLoading,
  saveFilterCategory,
  saveFilterMinExp,
  saveFilterCity,
  saveFilterSort,
  getJobListByTitle,
  searchJobText,
  jobs,
  allJMinExp,
  allJCity,
  allJCategory,
  setFilterCategory,
  setFilterMinExp,
  setFilterCity,
  setFilterSort,
  saveJobData,
  setJobID,
  userData,
  resetJobApplyData,
  postEnquiry,
  pushMessage,
  getEnquiryById
}) => {
  const history = useHistory();
  // Checkbox
  const [checked, setChecked] = useState([1]);
  const [selMinExp, setSelMinExp] = useState([]);
  const [selCategory, setSelCategory] = useState([]);
  const [selCity, setSelCity] = useState([]);
  const [selSort, setSelectedSort] = useState();
  const [searchText, setSearchText] = useState('');
  const [categoriesTree, setCategoriesTree] = useState([]);
  const { validateInput, errors } = useValidation();
  const { user } = userData || {};
  const { mobile_number = '', name = '', email = '' } = user || {};
  const location = useLocation();

  React.useEffect(() => {
    const states = location?.state;
    console.log('params in job list', states);
    if (states && states?.from === '/search') {
      const title = location?.state.data.title;
      setSelCategory([title]);
      setFilterCategory([title]);
    }
    return () => {
      setSelCategory([]);
      setFilterCategory([]);
    };
  }, []);
  const sortArr = [
    { label: 'Salary - Low to High', value: 'salary-asc' },
    { label: 'Salary - High to Low', value: 'salary-desc' },
    { label: 'Date Posted - Oldest to Newest', value: 'createdAt-asc' },
    { label: 'Date Posted - Newest to Oldest', value: 'createdAt-desc' },
  ];

  useEffect(() => {
    // params += "categoryId="+selectedVCategory?.id;
    if (saveFilterCategory?.length > 0) {
      setSelCategory(saveFilterCategory);
    }
    if (saveFilterMinExp?.length > 0) {
      setSelMinExp(saveFilterMinExp);
    }
    if (saveFilterCity?.length > 0) {
      setSelCity(saveFilterCity);
    }
    if (saveFilterSort && 'value' in saveFilterSort) {
      setSelectedSort(saveFilterSort);
    }
    var params = '';
    if (saveFilterMinExp?.length > 0) {
      for (let i = 0; i < saveFilterMinExp?.length; i++) {
        params += 'experiences=' + saveFilterMinExp[i];
        if (i !== saveFilterMinExp?.length - 1) {
          params += ',';
        }
      }
    }

    if (saveFilterCategory?.length > 0) {
      if (saveFilterMinExp?.length > 0) {
        params += '&';
      }
      for (let i = 0; i < saveFilterCategory?.length; i++) {
        params += 'categories=' + saveFilterCategory[i]?.id;
        if (i !== saveFilterCategory?.length - 1) {
          params += ',';
        }
      }
    }

    // call API
    if (saveFilterCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < saveFilterCity?.length; i++) {
        params += 'locations=' + saveFilterCity[i];
        if (i !== saveFilterCity?.length - 1) {
          params += ',';
        }
      }
    }

    if (saveFilterSort && 'value' in saveFilterSort) {
      if (params !== '') {
        params += ',';
      }
      if (saveFilterSort?.value == 'salary-asc') {
        params += 'sortBy=salary';
      } else if (saveFilterSort?.value == 'salary-desc') {
        params += 'sortBy=salary';
        params += 'sortOrder=desc';
      } else if (saveFilterSort?.value == 'createdAt-asc') {
        params += 'sortBy=createdAt';
      } else if (saveFilterSort?.value == 'createdAt-desc') {
        params += 'sortBy=createdAt';
        params += 'sortOrder=desc';
      }
    }

    console.log('JobList useEffect');
    const fetchData = async () => {
      // await getCityMaster();

      await getJobListByTitle(searchJobText, params);
    };
    loading(true);
    fetchData();
    loading(false);
  }, []);

  useEffect(() => {
    const regId = userData?.registration.id;
    getEnquiryById(regId, CALLBACK_TYPE_GENERIC);
    // if(enquiry)
    if (userData !== null && enquiry?.isTrue == true) {
      setIsCallBackDrawerOpen(true);
    } else{
      setIsCallBackDrawerOpen(false);
    } 
  }, []);

  function handleClick(event) {
    history.push('/homepage');
  }

  function setFilterParam() {
    var params = '';
    if (selMinExp?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selMinExp.length; i++) {
        params += 'experiences=' + selMinExp[i];
        if (i !== selMinExp.length - 1) {
          params += ',';
        }
      }
    }

    if (selCategory?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selCategory.length; i++) {
        params += 'categories=' + selCategory[i];
        if (i !== selCategory.length - 1) {
          params += ',';
        }
      }
    }

    if (selCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selCity.length; i++) {
        params += 'locations=' + selCity[i];
        if (i !== selCity.length - 1) {
          params += '&';
        }
      }
    }

    if (selSort !== undefined && selSort !== null) {
      if (params !== '') {
        params += '&';
      }

      if (selSort?.value == 'salary-asc') {
        params += 'sortBy=salary';
      } else if (selSort?.value == 'salary-desc') {
        params += 'sortBy=salary';
        params += '&sortOrder=desc';
      } else if (selSort?.value == 'createdAt-asc') {
        params += 'sortBy=createdAt';
      } else if (selSort?.value == 'createdAt-desc') {
        params += 'sortBy=createdAt';
        params += '&sortOrder=desc';
      }
    }

    return params;
  }

  // function setFilterParam() {
  // 	var params = {};
  // 	if (selMinExp?.length > 0) {

  //         params['experiences'] = selMinExp.toString()

  // 	}

  // 	if (selCategory?.length > 0) {

  //         params['categories'] = selCategory.toString();

  // 	}

  // 	if (selCity?.length > 0) {

  //         params['locations'] = selCity.toString();

  // 	}

  // 	if (selSort !== undefined && selSort !== null) {
  // 		if(selSort?.value == 'salary-asc'){
  //             params['sortBy'] = 'salary';
  //         } else if(selSort?.value == 'salary-desc'){
  //             params['sortBy'] = 'salary';
  //             params['sortOrder'] = 'desc';
  //         } else if(selSort?.value == 'createdAt-asc'){
  //             params['sortBy'] = 'createdAt';
  //         }else if(selSort?.value == 'createdAt-desc'){
  //             params['sortBy'] = 'createdAt';
  //             params['sortOrder'] = 'desc';
  //         }
  // 	}

  // 	return params;
  // }

  const handleExpLocAndCategoryFilter = () => {
    if (isFilterDrawerOpen) {
      toggleFilterDrawer();
    }
    if (isSortByDrawerOpen) {
      toggleSortByDrawer();
    }
    let rParams = setFilterParam();
    const fetchData = async () => {
      await getJobListByTitle(searchJobText, rParams);
    };
    // toggleFilterDrawer();
    loading(true);
    fetchData();
    loading(false);
  };

  const clearFilterData = () => {
    if (value == 0) {
      setSelCategory([]);
      setFilterCategory([]);
    }
    if (value == 1) {
      setSelCity([]);
      setFilterCity([]);
    }
    if (value == 2) {
      setSelMinExp([]);
      setFilterMinExp([]);
    }
  };

  const clearSortingData = () => {
    setSelectedSort([]);
    setFilterSort([]);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  // City toggle
  const handleCityToggle = (value) => () => {
    const currentIndex = selCity?.indexOf(value);
    const newChecked = [...selCity];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelCity(newChecked);
    setFilterCity(newChecked);
  };

  // category toggle
  const handleCategoryToggle = (value) => () => {
    const currentIndex = selCategory?.indexOf(value);
    const newChecked = [...selCategory];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log('newChecked123', newChecked);

    setSelCategory(newChecked);
    setFilterCategory(newChecked);
  };

  // min Exp toggle
  const handleMinExpToggle = (value) => () => {
    const currentIndex = selMinExp?.indexOf(value);
    const newChecked = [...selMinExp];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelMinExp(newChecked);
    setFilterMinExp(newChecked);
  };
  // Checkbox

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const toggleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const [isCallBackDrawerOpen, setIsCallBackDrawerOpen] = useState(false);
  const toggleCallBackDrawer = () => {
    setIsCallBackDrawerOpen(!isCallBackDrawerOpen);
  };

  const [isSortByDrawerOpen, setIsSortByDrawerOpen] = useState(false);
  const toggleSortByDrawer = () => {
    setIsSortByDrawerOpen(!isSortByDrawerOpen);
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  const handleSortChange = (value) => {
    console.log('values : ', value);
    setSelectedSort(value);
    setFilterSort(value);
  };

  // Filters section starts

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Filter section ends

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClick}
    >
      Elynker
    </Link>,
    // <Link
    //     underline="hover"
    //     key="2"
    //     className="fs-14 text-gray font-gotham"
    //     href="/"
    //     onClick={handleClick}
    // >
    //     Freelance
    // </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      Elynker Job Listing
    </Link>,
  ];

  const requestCallBackSubmit = async (formData) => {
    // const{email,name,mobile_number}=userData
    const registrationId = userData?.registration?.id;
    if (registrationId) {
      const { mobileNumber, comment, ...rest } = formData;
      const details = {
        ...rest,
        comments: comment,
        phone_number: mobileNumber,
        registrationId,
      };
      // const postResponse = await postEnquiry(registrationId, details);
      const postResponse = await postEnquiry(registrationId, details);
      // pushMessage(postResponse.message);
      pushMessage(
        'Your callback request has been accepted! Our team will reach out to you soon.'
      );
    }
    setIsCallBackDrawerOpen(false);
  };

  return (
    <>
      {Responsive(
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xl">
            <DrawerForm
              title="Request a Call Back"
              isOpen={isCallBackDrawerOpen}
              onClose={() => {
                setIsCallBackDrawerOpen(false);
              }}
              mobile_number={mobile_number}
              name={name}
              email={email}
              validateInput={validateInput}
              onSubmit={requestCallBackSubmit}
              errors={errors}
              BuisnessType={false}
            />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: 'auto' }}
            >
              <Grid item xs={12}>
                <div className="my-3 ms-2 d-flex align-items-center">
                  <ArrowBackIosIcon
                    onClick={() => {
                      history.replace('/homepage');
                    }}
                  />
                  <ElynkerLogoComponent>
                    <ElynkerLogo
                      className="ms-3"
                      width={'100px'}
                      height={'30px'}
                      onClick={() => {
                        history.push('/homepage');
                      }}
                    />
                  </ElynkerLogoComponent>
                </div>
                <Divider
                  component="div"
                  className="main-divider mb-3"
                  role="presentation"
                />

                <form style={{ marginTop: '0px' }}>
									<Card className="job-listing-card mb-3">
										<CardContent className="job-listing-content">
											<div className="job-listing-content-tint" />
											<div style={{ zIndex: 2, position: 'relative' }}>
												<Typography
													variant="h5"
													className="text-white mb-3 fw-bold font-skmodernist job-listing-text"
													component="div"
												>
													People have booked this <br></br> service on elynker
													with <br></br> 4.5 stars
												</Typography>
												<Button
													variant="contained"
													className="post-requirement-btn job-listing-button"
													onClick={toggleCallBackDrawer}
												>
													Request a Call Back
												</Button>
											</div>
										</CardContent>
									</Card>

                  {/* <Drawer

                                    xs={12}
                                    anchor="bottom"
                                    open={isCallBackDrawerOpen}
                                    onClose={toggleCallBackDrawer}
                                    sx={{ '& .MuiDrawer-paper': { height: 'auto', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' } }}
                                >
                                    <div className="p-3">
                                        <div className="d-flex justify-content-between mb-3">
                                            <Typography
                                                variant="h5"
                                                align="left"
                                                alignItems="center"
                                                className="fs-18 mb-2 fw-bold font-skmodernist "
                                                gutterBottom >
                                                Request a Call Back
                                            </Typography>
                                            <CloseIcon onClick={toggleCallBackDrawer} />
                                        </div>
    
    
                                        <div className="mb-3">
                                            <InputLabel
                                                htmlFor="inputCompanyName"
                                                className="text-start fs-14 text-black font-skmodernist">
                                                Name
                                            </InputLabel>
                                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
    
                                                <OutlinedInput
                                                    id="inputName"
                                                    // label="Mobile Number"
                                                    type="text"
                                                    placeholder="Enter name"
                                                    required
                                                    autoFocus
                                                    fullWidth
                                                    margin='none'
                                                />
                                            </FormControl>
                                        </div>
    
                                        <div className="mb-3">
                                            <InputLabel
                                                htmlFor="inputEmailID"
                                                className="text-start fs-14 text-black font-skmodernist">
                                                Email ID
                                            </InputLabel>
                                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
    
                                                <OutlinedInput
                                                    id="inputName"
                                                    // label="Mobile Number"
                                                    type="text"
                                                    placeholder="Enter email Id"
                                                    required
                                                    autoFocus
                                                    fullWidth
                                                    margin='none'
                                                />
                                            </FormControl>
                                        </div>
    
                                        <div className="mb-3">
                                            <InputLabel
                                                htmlFor="inputPhone"
                                                className="text-start fs-14 text-black font-skmodernist">
                                                Phone No.
                                            </InputLabel>
                                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
    
                                                <OutlinedInput
                                                    id="inputName"
                                                    // label="Mobile Number"
                                                    type="text"
                                                    placeholder="Enter phone no."
                                                    required
                                                    autoFocus
                                                    fullWidth
                                                    margin='none'
                                                />
                                            </FormControl>
                                        </div>
    
                                        <div className=" mb-3">
                                            <InputLabel
                                                htmlFor="inputCompanyName"
                                                className="text-start fs-14 text-black font-skmodernist">
                                                Comment
                                            </InputLabel>
                                            <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                                                <TextareaAutosize
                                                    id="inputComment"
                                                    placeholder="Enter comment"
                                                    minRows={3} // You can adjust the number of rows as needed
                                                    maxRows={2} // You can adjust the number of rows as needed
    
                                                />
                                            </FormControl>
    
    
                                        </div>
    
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            className="mb-3 py-3 primary-color-1 fs-15 font-skmodernist fw-bold"
                                            sx={{ borderRadius: '6px', textTransform: 'capitalize' }}>
    
                                            Submit
                                        </Button>
                                    </div>
                                </Drawer> */}

									<Breadcrumbs
										className="mb-3"
										separator={
											<NavigateNextIcon
												fontSize="small"
												className="text-gray"
											/>
										}
										aria-label="breadcrumb"
									>
										{breadcrumbs}
									</Breadcrumbs>

									<div className="my-4 b2b-filter-section">
										<Stack
											className="b2b-filter-slider"
											direction="row"
											spacing={1.5}
											useFlexGap
											flexWrap="nowrap"
											overflow={'auto'}
										>
											<Chip
												icon={<FiltersIcon />}
												label="Filters"
												clickable
												onClick={toggleFilterDrawer}
											/>

											<Chip
												label="Sort By"
												onDelete={(e) => {
													toggleSortByDrawer();
												}}
												deleteIcon={
													<DropdownIcon
														fontSize="medium"
														className="dropdown-icon"
													/>
												}
												onClick={toggleSortByDrawer}
												clickable
											/>
										</Stack>
									</div>
									{/* Filter drawer starts*/}
									<Drawer
										xs={12}
										anchor="bottom"
										open={isFilterDrawerOpen}
										onClose={toggleFilterDrawer}
										sx={{
											'& .MuiDrawer-paper': {
												height: 'auto',
												borderTopLeftRadius: '20px',
												borderTopRightRadius: '20px',
											},
										}}
									>
										<div className="p-3">
											<div className="d-flex justify-content-between mb-3">
												<Typography
													variant="h5"
													align="left"
													alignItems="center"
													className="fs-18 mb-2 fw-bold font-skmodernist "
													gutterBottom
												>
													Filters
												</Typography>
												<CloseIcon onClick={toggleFilterDrawer} />
											</div>

											<Box
												sx={{
													flexGrow: 1,
													bgcolor: 'background.paper',
													display: 'flex',
													height: 250,
													margin: '0 -16px',
													borderTop: '1px solid #D9D9D9',
													borderBottom: '1px solid #D9D9D9',
												}}
											>
												<Tabs
													orientation="vertical"
													variant="scrollable"
													value={value}
													onChange={handleChange}
													aria-label="Vertical tabs example"
													className="filter-tabs"
													sx={{
														borderRight: 1,
														borderColor: 'divider',
														background: '#F4F4F4',
														minWidth: '35%',
													}}
												>
													<Tab
														label="Job Categories"
														sx={{ borderBottom: '1px solid #E3E3E3' }}
														className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0 text-start"
														{...a11yProps(0)}
													/>
													<Tab
														label="Location"
														sx={{ borderBottom: '1px solid #E3E3E3' }}
														className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0"
														{...a11yProps(1)}
													/>
													<Tab
														label="Min. Experience"
														sx={{ borderBottom: '1px solid #E3E3E3' }}
														className="fs-15 fw-normal text-black font-skmodernist text-capitalize px-0"
														{...a11yProps(2)}
													/>
												</Tabs>
												<TabPanel
													value={value}
													index={0}
													className="filter-tabpanel"
												>
													<List
														class="filter-tabpanel-list"
														sx={{
															width: '100%',
															bgcolor: 'background.paper',
															padding: '0px',
														}}
													>
														{allJCategory?.map((jCat, index) => {
															const labelId = `checkbox-list-secondary-label-${index}`;
															return (
																<ListItem
																	key={index}
																	secondaryAction={
																		<Checkbox
																			edge="end"
																			onChange={handleCategoryToggle(jCat)}
																			checked={
																				selCategory?.indexOf(jCat) !== -1
																			}
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																			sx={{
																				color: '#D4D4D4',
																				'&.Mui-checked': {
																					color: '#416AD4',
																				},
																			}}
																		/>
																	}
																	disablePadding
																>
																	<ListItemButton sx={{ paddingLeft: '0px' }}>
																		<ListItemText
																			id={labelId}
																			className="fs-15 fw-normal text-black font-skmodernist"
																			primary={jCat}
																		/>
																	</ListItemButton>
																</ListItem>
															);
														})}
													</List>
												</TabPanel>
												<TabPanel
													value={value}
													index={1}
													className="filter-tabpanel"
												>
													<List
														class="filter-tabpanel-list"
														sx={{
															width: '100%',
															bgcolor: 'background.paper',
															padding: '0px',
														}}
													>
														{allJCity?.map((loc, index) => {
															const labelId = `checkbox-list-secondary-label-${index}`;
															return (
																<ListItem
																	key={index}
																	secondaryAction={
																		<Checkbox
																			edge="end"
																			onChange={handleCityToggle(loc)}
																			checked={selCity?.indexOf(loc) !== -1}
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																			sx={{
																				color: '#D4D4D4',
																				'&.Mui-checked': {
																					color: '#416AD4',
																				},
																			}}
																		/>
																	}
																	disablePadding
																>
																	<ListItemButton sx={{ paddingLeft: '0px' }}>
																		<ListItemText
																			id={labelId}
																			className="fs-15 fw-normal text-black font-skmodernist"
																			primary={loc}
																		/>
																	</ListItemButton>
																</ListItem>
															);
														})}
													</List>
												</TabPanel>
												<TabPanel
													value={value}
													index={2}
													className="filter-tabpanel"
												>
													<List
														class="filter-tabpanel-list"
														sx={{
															width: '100%',
															bgcolor: 'background.paper',
															padding: '0px',
														}}
													>
														{allJMinExp?.map((exp, index) => {
															const labelId = `checkbox-list-secondary-label-${index}`;
															return (
																<ListItem
																	key={index}
																	secondaryAction={
																		<Checkbox
																			edge="end"
																			onChange={handleMinExpToggle(exp)}
																			checked={selMinExp?.indexOf(exp) !== -1}
																			inputProps={{
																				'aria-labelledby': labelId,
																			}}
																			sx={{
																				color: '#D4D4D4',
																				'&.Mui-checked': {
																					color: '#416AD4',
																				},
																			}}
																		/>
																	}
																	disablePadding
																>
																	<ListItemButton sx={{ paddingLeft: '0px' }}>
																		<ListItemText
																			id={labelId}
																			className="fs-15 fw-normal text-black font-skmodernist"
																			primary={exp}
																		/>
																	</ListItemButton>
																</ListItem>
															);
														})}
													</List>
												</TabPanel>
											</Box>

											<ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
												<Button
													variant="contained"
													className="call-now-btn"
													onClick={clearFilterData}
												>
													Clear All
												</Button>
												<Button
													variant="contained"
													className="whatsapp-btn"
													onClick={handleExpLocAndCategoryFilter}
												>
													Apply
												</Button>
											</ButtonGroup>
										</div>
									</Drawer>
									{/* Filter drawer ends*/}

									<Drawer
										xs={12}
										anchor="bottom"
										open={isSortByDrawerOpen}
										onClose={toggleSortByDrawer}
										sx={{
											'& .MuiDrawer-paper': {
												height: 'auto',
												borderTopLeftRadius: '20px',
												borderTopRightRadius: '20px',
											},
										}}
									>
										<div className="p-3">
											<div className="d-flex justify-content-between mb-3">
												<Typography
													variant="h5"
													align="left"
													alignItems="center"
													className="fs-18 mb-2 fw-bold font-skmodernist "
													gutterBottom
												>
													Sort By
												</Typography>
												<CloseIcon onClick={toggleSortByDrawer} />
											</div>

											<List
												dense
												sx={{ width: '100%', bgcolor: 'background.paper' }}
											>
												{sortArr &&
													sortArr?.map((option, index) => {
														const labelId = `radio-list-secondary-label-${index}`;
														return (
															<ListItem
																key={index}
																secondaryAction={
																	<Radio
																		edge="end"
																		onChange={() => handleSortChange(option)}
																		checked={selSort?.label === option?.label}
																		inputProps={{
																			'aria-labelledby': labelId,
																		}}
																		sx={{
																			color: '#D4D4D4',
																			'&.Mui-checked': {
																				color: '#416AD4',
																			},
																		}}
																	/>
																}
																disablePadding
															>
																<ListItemButton sx={{ paddingLeft: '0px' }}>
																	<ListItemText
																		id={labelId}
																		className="fs-15 fw-normal text-black font-skmodernist"
																		primary={option?.label}
																	/>
																</ListItemButton>
															</ListItem>
														);
													})}
											</List>

											<ButtonGroup className="w-100 justify-content-between mt-3 mb-3">
												<Button
													variant="contained"
													className="call-now-btn"
													onClick={clearSortingData}
												>
													Clear All
												</Button>
												<Button
													variant="contained"
													className="whatsapp-btn"
													onClick={handleExpLocAndCategoryFilter}
												>
													Apply
												</Button>
											</ButtonGroup>
										</div>
									</Drawer>

									<Typography
										variant="h4"
										align="left"
										alignItems="center"
										className="fs-20 text-black mb-3 fw-bold font-skmodernist"
									>
										Wholesale and Suppliers
									</Typography>

									<List>
										{jobs &&
											jobs?.map((item, index) => {
												let last_update = moment(item?.updatedAt)?.fromNow();
												let categoriesToShow = item?.categories?.map((item) => {
													return item?.title;
												});
												console.log('JobList useEffect');
												return (
													<ListItem
														className="job-listing-item"
														key={index}
														onClick={() => {
															// e.preventDefault();
															history.push('/job-detail');
														}}
													>
														<Card className="card">
															<div>
																<div className="d-flex justify-content-between align-items-center mb-1">
																	<Typography
																		variant="h5"
																		className="fs-16 text-black fw-bold font-skmodernist"
																		component="div"
																	>
																		{item?.title}
																	</Typography>
																	<RightArrowIcon
																		onClick={() => {
																			saveJobData(item);
																			setJobID(item?.id);
																			history.push('/job-detail');
																		}}
																	/>
																</div>
																<Typography
																	variant="body2"
																	className="fs-13 text-grey mb-1 font-gotham"
																>
																	{categoriesToShow?.flat()}
																</Typography>
																<div className="d-flex align-items-center">
																	<IconButton className="p-0 pe-2">
																		<ClockIcon />
																	</IconButton>
																	<Typography
																		variant="h5"
																		className="fs-13 text-gray fw-normal  font-gotham"
																		component="div"
																	>
																		{last_update !== 'Invalid date'
																			? last_update
																			: ''}
																	</Typography>
																</div>
															</div>

															<Divider
																component="div"
																className="my-3"
																role="presentation"
															/>

															<div className="d-flex">
																<CardMedia
																	className="category-media"
																	image={
																		item?.registration?.image_path
																			? `${COMPANY_LOGO_BASE_URL}${item?.registration?.image_path}`
																			: require('../../assets/elynker_default_image.jpg')
																	}
																	sx={{
																		padding: '1em 1em 0 1em',
																		objectFit: 'contain',
																	}}
																></CardMedia>
																<CardContent className="content p-0">
																	<div className="d-flex justify-content-between align-items-start w-100">
																		<div>
																			<Typography
																				variant="h5"
																				className="fs-15 text-black mb-1  font-skmodernist"
																				component="div"
																			>
																				{item?.registration?.company_name}
																			</Typography>

																			<Typography
																				variant="body2"
																				className="fs-13 text-secondary mb-1 font-skmodernist"
																			>
																				{item?.job_location}
																			</Typography>
																		</div>

																		<div>
																			<Typography
																				variant="h5"
																				className="fs-13 text-darkgrey mb-0 font-skmodernist"
																			>
																				Upto
																			</Typography>

																			<Typography
																				variant="h5"
																				className="fs-15 text-secondary fw-bold mb-1 font-skmodernist"
																			>
																				₹ {item?.salary_offered}{' '}
																				<span className="fs-12 text-grey fw-normal">
																					/ Month
																				</span>
																			</Typography>
																		</div>
																	</div>
																</CardContent>
															</div>

															<Divider
																component="div"
																className="mt-2 mb-3"
																role="presentation"
															/>
															<CardContent className="p-0 d-flex justify-content-end">
																{item?.registration?.id !==
																userData?.registration?.id ? (
																	<Button
																		variant="contained"
																		className="apply-btn"
																		onClick={(e) => {
																			e.stopPropagation();
																			resetJobApplyData();
																			saveJobData(item);
																			setJobID(item?.id);
																			history.push('/job-apply');
																		}}
																	>
																		Apply
																	</Button>
																) : (
																	<div>This Job is posted by You.</div>
																)}
															</CardContent>
														</Card>
													</ListItem>
												);
											})}
									</List>
								</form>
							</Grid>
						</Grid>
					</Container>
				</ThemeProvider>,
				<WebJobListingForm
					isLoading={isLoading}
					errors={errors}
					jobs={jobs}
					saveJobData={saveJobData}
					setJobID={setJobID}
					userData={userData}
					resetJobApplyData={resetJobApplyData}
					sortArr={sortArr}
					handleClick={handleClick}
					allJCategory={allJCategory}
					selCategory={selCategory}
					handleCategoryToggle={handleCategoryToggle}
					allJCity={allJCity}
					selCity={selCity}
					allJMinExp={allJMinExp}
					handleMinExpToggle={handleMinExpToggle}
					selMinExp={selMinExp}
					handleCityToggle={handleCityToggle}
					getJobListByTitle={getJobListByTitle}
					searchJobText={searchJobText}
					setSelCategory={setSelCategory}
					setFilterCategory={setFilterCategory}
					setSelCity={setSelCity}
					setFilterCity={setFilterCity}
					setSelMinExp={setSelMinExp}
					setFilterMinExp={setFilterMinExp}
					setSelectedSort={setSelectedSort}
					setFilterSort={setFilterSort}
					selSort={selSort}
				/>
			)}
		</>
	);
};

JobListingForm.propTypes = {
	isLoading: PropTypes.bool,
	loading: PropTypes.func,
	saveFilterCategory: PropTypes.array,
	saveFilterMinExp: PropTypes.array,
	saveFilterCity: PropTypes.array,
	saveFilterSort: PropTypes.array,
	getJobListByTitle: PropTypes.func,
	searchJobText: PropTypes.string,
	jobs: PropTypes.array,
	allJMinExp: PropTypes.array,
	allJCategory: PropTypes.array,
	allJCity: PropTypes.array,
	setFilterCategory: PropTypes.func,
	setFilterMinExp: PropTypes.func,
	setFilterCity: PropTypes.func,
	setFilterSort: PropTypes.func,
	saveJobData: PropTypes.func,
	setJobID: PropTypes.func,
	userData: PropTypes.object,
	resetJobApplyData: PropTypes.func,
	postEnquiry: PropTypes.func,
	pushMessage: PropTypes.func,
	getEnquiryById: PropTypes.func
};

const mapStateToProps = (state) => {
	const { isLoading } = state.root;
	const { userData } = state.signin;

	const {
		searchJobText,
		jobs,
		jobsDetails,
		filterJCity,
		allJCity,
		saveFilterMinExp,
		saveFilterCategory,
		saveFilterCity,
		saveFilterSort,
		allJMinExp,
		allJCategory,
	} = state.jobSearchList;
	return {
		isLoading,
		searchJobText,
		jobs,
		jobsDetails,
		filterJCity,
		allJCity,
		saveFilterMinExp,
		saveFilterCategory,
		saveFilterCity,
		saveFilterSort,
		allJMinExp,
		allJCategory,
		userData,
	};
};
export default connect(mapStateToProps, {
	loading,
	getJobListByTitle,
	setFilterCategory,
	setFilterMinExp,
	setFilterCity,
	setFilterSort,
	saveJobData,
	setJobID,
	resetJobApplyData,
	postEnquiry,
	pushMessage,
	getEnquiryById
})(JobListingForm);
