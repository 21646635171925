import React, { useState } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
// import { ReactComponent as ProductCatalogLogo } from '../../assets/product-catalog-logo.svg';
import { ReactComponent as ProductCatalogDividerLeft } from '../../assets/product-catalog-divider-left.svg';
import { ReactComponent as ProductCatalogDividerRight } from '../../assets/product-catalog-divider-right.svg';
import { ReactComponent as ProductCatalogDivider } from '../../assets/product-catalog-divider.svg';
// import ProductCatalogBanner from '../../assets/product-catalog-front-banner.png';
// import RemoveIcon from '@mui/icons-material/Remove';
import { Container, Grid, Typography, Box, List, ListItem, ListItemText, Divider, Link } from '@mui/material';
import { Paper, Button, CardMedia } from '@mui/material';
import { IconButton } from '@mui/material';
import { ReactComponent as FacebookIcon } from '../../assets/facebook-cat-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter-cat-icon.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin-cat-icon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram-cat-icon.svg';
// import ProductImg from '../../assets/product-catalog-detail-img.png';
// import Responsive from '../../utils/responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { ReactComponent as ProductImg1 } from '../../assets/catalog-product-img-1.svg';
// import { ReactComponent as ProductImg2 } from '../../assets/catalog-product-img-2.svg';
// import { ReactComponent as ProductImg3 } from '../../assets/catalog-product-img-3.svg';
// import { ReactComponent as ProductImg4 } from '../../assets/catalog-product-img-4.svg';
// import { useMediaQuery } from 'react-responsive';
// import ProductImg5 from '../../assets/cat-product-1.png';
// import ProductImg6 from '../../assets/cat-product-2.png';
// import ProductImg7 from '../../assets/cat-product-3.png';
// import ProductImg8 from '../../assets/cat-product-4.png';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import getProductList from '../MyCatalog/redux/actions/mycatalog-action';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { COMPANY_LOGO_BASE_URL, COVER_IMAGE_BASE_URL, PRODUCT_IMAGE_BASE_URL } from '../../utils/APIs/api';
// import { useMediaQuery } from 'react-responsive';
import { Resolution, usePDF } from 'react-to-pdf';


const theme = createTheme();



const ProductCatalogPdf = ({ getProductList, userData, allProducts }) => {


    let chunkedArray;

    // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1024px)', });
    // const isTabletOnly = useMediaQuery({
    //     query: '(min-width: 600px) and (max-width: 1023px)',
    // });
    // const isMobileOnly = useMediaQuery({ query: '(max-width: 600px)' });

    function chunkArray(array, chunkSize) {
        let result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            // Slice the array into chunks and push to the result
            let chunk = array.slice(i, i + chunkSize);
            result.push(chunk);
        }
        return result;
    }


    useEffect(() => {
        getProductList(userData.registration.id);
    }, [userData]);

    useEffect(() => {
        console.log('All Products :', allProducts);
    }, [allProducts]);

    chunkedArray = chunkArray(allProducts, 4);
    console.log("Chunked Arrays : ", chunkedArray);

    const [hideEle, setHideEle] = useState(false);
    const { toPDF, targetRef } = usePDF({
        resolution: Resolution.NORMAL,
        canvas: {
            qualityRatio: 0.5
        },
        page: { margin: 5, format: 'A4' },
        filename: "Product-Catalog.pdf",
        overrides: {
            pdf: {
                compress: true
            },
            canvas: {
                useCORS: true,
            },
        },
    });

    function truncateText(text = '', length = 50) {
        if (text?.length <= length) {
            return text || '';
        } else if (text?.length == undefined) {
            return text;
        }
        return text?.substr(0, length) + '\u2026' || '';
    }


    return (
        <div ref={targetRef} style={{ width: 960, margin: 'auto' }}>
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="lg" sx={{ height: 1360, border: '1px solid #dcdcdc', marginBottom: '20px', position: 'relative' }}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: 'auto' }}
                    >
                        <Button
                            hidden={hideEle}
                            className='download-btn mt-3'
                            onClick={() => {
                                setHideEle(true);
                                setTimeout(() => {
                                    toPDF();
                                    setHideEle(false);
                                }, 3000);
                            }}
                        >
                            DownLoad PDF
                        </Button>
                        <Grid item xs={12}>
                            <>
                                <Box className="text-center" sx={{ margin: "40px 0 40px 0" }}>
                                    {/* <ProductCatalogLogo height={48} /> */}
                                    <img
                                        src={`${COMPANY_LOGO_BASE_URL}${userData?.registration?.image_path}`}
                                        alt={`${COMPANY_LOGO_BASE_URL}${userData?.registration?.image_path}`}
                                        height={100}
                                    />
                                </Box>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase" sx={{ fontSize: '60px', lineHeight: '60px' }}>Product</Typography>
                                <Typography className="sk-modernist fw-bold text-center text-uppercase text-primary-2" sx={{ fontSize: '85px', lineHeight: '100px', marginBottom: '10px' }}>Catalog</Typography>

                                <Box className="d-flex align-items-end justify-content-center" sx={{ gap: '24px', marginBottom: '54px' }}>
                                    <ProductCatalogDividerLeft />
                                    <Typography className="sk-modernist fw-bold " sx={{ fontSize: '18px', lineHeight: '10px' }}>{moment().format('DD/MM/YYYY')}</Typography>
                                    <ProductCatalogDividerRight />
                                </Box>

                                <Box className="text-center" sx={{ marginBottom: '52px' }}>
                                    {/* <img src={ProductCatalogBanner} width={700} height={600} /> */}
                                    <img
                                        src={`${COVER_IMAGE_BASE_URL}${userData?.registration?.cover_image}`}
                                        alt={`${COVER_IMAGE_BASE_URL}${userData?.registration?.cover_image}`}
                                        width={700}
                                        height={500}
                                        style={{objectFit: 'contain'}}
                                    />
                                </Box>

                                <Box className="text-center">
                                    <ProductCatalogDivider />

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '780px', margin: '0 auto' }}>
                                        <List sx={{ width: '100%', display: 'flex',flexDirection: 'column',gap: '12px' }}>
                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>

                                                {/* <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} /> */}

                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>ADDRESS</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            {userData?.registration?.address1},{' '}{userData?.registration?.address2},{' '}{userData?.registration?.city},{' '}{userData?.registration?.state}{' - '}{userData?.registration?.pincode}
                                                            
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <div style={{display: 'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                {/* <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} /> */}
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>CONTACT</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            {userData?.user?.country_code} {userData?.user?.mobile_number}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>

                                            <ListItem sx={{ alignItems: 'start', padding: '0px' }}>
                                                {/* <RemoveIcon sx={{ marginTop: '5px', marginRight: '10px', color: '#1843a0 ' }} /> */}
                                                <ListItemText
                                                    primary={<Typography variant="subtitle1" className='fs-20 font-skmodernist fw-bold'>EMAIL</Typography>}
                                                    secondary={
                                                        <Typography variant="body2" className='fs-17 font-gotham text-darkgrey'>
                                                            {userData?.user?.email}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                            </div>

                                            
                                        </List>
                                    </Box>

                                    <ProductCatalogDivider />
                                </Box>



                                <Box className="d-flex align-items-center py-3" sx={{
                                    gap: '24px',
                                    justifyContent: 'space-between',
                                    background: "#fff",
                                    maxWidth: "780px",
                                    margin: "0 60px",
                                    padding: "16px",
                                    position: 'absolute',
                                    bottom: '0px',
                                    width: '100%'


                                }}>
                                    <Link to="https://elynker.com/" className="fs-18 font-skmodernist" sx={{ color: '#000', textDecoration: 'none !important', lineHeight: '24px' }}>Powered by: www.elynker.com</Link>
                                    {/* <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography> */}
                                    <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '60%', marginTop: '4px' }} />
                                    <Typography className='fs-18 font-skmodernist'>1</Typography>
                                </Box>
                            </>


                        </Grid>
                    </Grid>
                </Container>

                {chunkedArray?.map((products, productIndex) => {
                    return (
                        <Container component="main" maxWidth="lg" sx={{ height: 1360, border: '1px solid #dcdcdc', marginBottom: '20px', position: 'relative' }} key={productIndex}>

                            <List className="product-catalog-list" >

                                {products?.map((product) => {
                                    return (
                                        <Paper className='paper' key={product.id} >
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>

                                                    <CardMedia className='media' component="div" >
                                                        {/* {lamp.imageUrl} */}
                                                        <img
                                                            src={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                            alt={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                            style={{ border: '1px solid #dcdcdc' }}
                                                            width={350}
                                                            height={274}
                                                        />
                                                    </CardMedia>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Grid direction="column" spacing={2}>
                                                        <Box className="content" >
                                                            <Typography className="fs-28 font-skmodernist fw-bold line-height-35 mb-3">
                                                                {truncateText(product?.title, 25)}
                                                            </Typography>
                                                            <Typography className="fs-17 font-gotham text-darkgrey mb-3">
                                                                {truncateText(product?.description, 150)}
                                                            </Typography>
                                                            <Button variant="contained" className='price-btn'>
                                                                ₹ {parseInt(product?.budget)}
                                                            </Button>
                                                        </Box>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )
                                })}
                            </List>
                            <Box className="d-flex align-items-center py-3" sx={{
                                gap: '24px',
                                justifyContent: 'space-between',
                                background: "#fff",
                                maxWidth: "780px",
                                margin: "0 60px",
                                padding: "16px",
                                position: 'absolute',
                                bottom: '0px',
                                width: '100%'


                            }}>
                                <Link to="https://elynker.com/" className="fs-18 font-skmodernist" sx={{ color: '#000', textDecoration: 'none !important', lineHeight: '24px' }}>Powered by: www.elynker.com</Link>
                                {/* <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography> */}
                                <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '60%', marginTop: '4px' }} />
                                <Typography className='fs-18 font-skmodernist'>{productIndex + 2}</Typography>
                            </Box>
                        </Container >)
                })}

                {/* {isTabletOnly && (<>
                        <List className="product-catalog-list">
                            {allProducts.map((product) => (
                                <Paper className='paper' key={product?.id} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>

                                            <CardMedia className='media' component="div">
                                                <img
                                                    src={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                    alt={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                    width={150}
                                                    height={130}
                                                />
                                            </CardMedia>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid direction="column" spacing={2}>
                                                <Box className="content" >
                                                    <Typography className="fs-28 font-skmodernist fw-bold line-height-20 mb-3">
                                                        {product?.title}
                                                    </Typography>
                                                    <Typography className="fs-17 font-gotham text-darkgrey mb-3">
                                                        {product?.description}
                                                    </Typography>
                                                    <Button variant="contained" className='price-btn'>
                                                        {product?.budget}
                                                    </Button>
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </List>
                        <Box className="d-flex align-items-center py-3" sx={{
                            gap: '24px', 
                            justifyContent: 'space-between',
                            background: "#fff",
                            maxWidth: "780px",
                            margin: "0 auto",
                            padding: "16px"
                        }}>
                            <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography>
                            <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '55%' }} />
                            <Typography className='fs-18 font-skmodernist'>02</Typography>
                        </Box>
                    </>)
                    }

                    {isMobileOnly && (<>
                        <List className="product-catalog-mobile-list">
                            {allProducts.map((product) => (
                                <Paper className='paper' key={product?.id} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={5}>

                                            <CardMedia className='media' component="div">
                                                <img
                                                    src={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                    alt={`${PRODUCT_IMAGE_BASE_URL}${product?.default_image}`}
                                                    width={150}
                                                    height={130}
                                                />
                                            </CardMedia>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Grid direction="column" spacing={2}>
                                                <Box className="content" >
                                                    <Typography className="fs-16 font-skmodernist fw-bold line-height-20 mb-2">
                                                        {product?.title}
                                                    </Typography>
                                                    <Typography className="fs-12 font-gotham text-darkgrey mb-2">
                                                        {product?.description}
                                                    </Typography>
                                                    <Button variant="contained" className='price-btn'>
                                                        {product?.budget}
                                                    </Button>
                                                </Box>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            ))}
                        </List>
                        <Box className="d-flex align-items-center py-3" sx={{
                            gap: '10px',
                            // left: 0,
                            justifyContent: 'space-between',
                            background: "#fff",
                            maxWidth: "400px",
                            margin: "0 auto",
                            padding: "16px"
                        }}>
                            <Typography className='fs-14  font-skmodernist'>Powered by: www.elynker.com</Typography>
                            <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '30%' }} />
                            <Typography className='fs-18 font-skmodernist'>02</Typography>
                        </Box>
                    </>)
                    } */}



                <Container component="main" maxWidth="lg" sx={{ height: 1360, border: '1px solid #dcdcdc', position: 'relative' }}>
                    <Box sx={{ width: '780px', margin: '60px auto 0px' }}>
                        {/* <img
                        src={ProductImg}
                        alt="Company Image"
                        style={{ width: '100%', marginBottom: '60px' }}
                    /> */}

                        {/* <Box sx={{ margin: '60px auto', width: '780px' }}>
                            <img
                                src={`${COVER_IMAGE_BASE_URL}${userData?.registration?.cover_image}`}
                                alt={`${COVER_IMAGE_BASE_URL}${userData?.registration?.cover_image}`}
                                style={{ width: '780px', height: '400px' }}
                            />
                        </Box> */}


                        <Typography className='fs-34 fs-m-20 font-skmodernist fw-bold line-height-20 mb-4'>
                            About Company
                        </Typography>
                        <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify '>
                            {userData?.registration?.about_company}
                        </Typography>
                        {/* <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&apos;s
                        standard dummy text ever since the 1500s, when an unknown printer took a galley
                    </Typography> */}

                        <Divider style={{ margin: '20px 0' }} />

                        <Typography className='fs-34 fs-m-20 font-skmodernist fw-bold line-height-20 mb-4'>
                            Key Details
                        </Typography>
                        <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                            {userData?.registration?.additional_detail1}
                        </Typography>
                        <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                            {userData?.registration?.additional_detail2}
                        </Typography>
                        <Typography className='fs-18 fs-m-14 font-gotham text-darkgrey mb-4 line-height-26 text-justify'>
                            {userData?.registration?.additional_detail3}
                        </Typography>

                        <Divider style={{ margin: '20px 0' }} />

                        <Box display="flex" justifyContent="start" alignItems="center" marginTop="20px" marginBottom="50px">
                            <IconButton className='ps-0' href="https://www.facebook.com">
                                <FacebookIcon />
                            </IconButton>
                            <IconButton color="primary" href="https://www.twitter.com">
                                <TwitterIcon />
                            </IconButton>
                            <IconButton color="primary" href="https://www.linkedin.com">
                                <LinkedInIcon />
                            </IconButton>
                            <IconButton color="primary" href="https://www.instagram.com">
                                <InstagramIcon />
                            </IconButton>
                        </Box>




                    </Box>

                    <Box className="d-flex align-items-center py-3" sx={{
                        gap: '24px',
                        justifyContent: 'space-between',
                        background: "#fff",
                        maxWidth: "780px",
                        margin: "0 60px",
                        padding: "16px",
                        position: 'absolute',
                        bottom: '0px',
                        width: '100%'


                    }}>
                        <Link to="https://elynker.com/" className="fs-18 font-skmodernist" sx={{ color: '#000', textDecoration: 'none !important', lineHeight: '24px' }}>Powered by: www.elynker.com</Link>
                        {/* <Typography className='fs-18  font-skmodernist'>Powered by: www.elynker.com</Typography> */}
                        <Divider sx={{ background: '#C8C8C8', opacity: '1', height: '2px', width: '60%', marginTop: '4px' }} />
                        <Typography className='fs-18 font-skmodernist'>{chunkedArray.length + 2}</Typography>
                    </Box>
                </Container>

            </ThemeProvider>
        </div>
    );
};


ProductCatalogPdf.propTypes = {
    allProducts: PropTypes.array,
    userData: PropTypes.object,
    getProductList: PropTypes.func,
};

const mapStateToProps = (state) => {
    const { userData } = state.signin;
    const { allProducts } = state.mycatalog;
    return {
        userData,
        allProducts,
    };
};

export default connect(mapStateToProps, { getProductList })(ProductCatalogPdf);