import {
  getParentCategory,
  getRegistrationData,
} from '../../../../utils/APIs/endpoints';
import { SAVE_SIGNIN_DATA, UPDATE_SIGNIN_DATA } from '../../../Otp/redux/types';
import {
  SET_NEW_COMPANY_LOGO,
  SET_NEW_COVER_PHOTO,
  SET_TOP_LEVEL_PRODUCT_CATEGORIES,
} from '../types';

export const setNewCoverPhoto = (newCover) =>
  function (dispatch) {
    dispatch({ type: SET_NEW_COVER_PHOTO, payload: newCover });
  };

export const setNewCompanyLogo = (newLogo) =>
  function (dispatch) {
    dispatch({ type: SET_NEW_COMPANY_LOGO, payload: newLogo });
  };

export const getUpdatedRegistrationData = (userId, olduserData, pushMessage) =>
  async function (dispatch) {
    try {
      if (userId && olduserData) {
        const response = await getRegistrationData(userId);
        if (response.status === 200) {
          let newUsrData = { ...olduserData };
          newUsrData.registration = response.data?.data;
          await dispatch({ type: SAVE_SIGNIN_DATA, payload: newUsrData });
          await dispatch({ type: UPDATE_SIGNIN_DATA, payload: {} });
        } else {
          pushMessage(response?.data?.message || 'Something Went Wroung');
        }
      } else {
        pushMessage('Registration Id is missing');
      }
    } catch (error) {
      console.log('Error fetching updated registration data', error);
      pushMessage('Error fetching updated registration data');
    }
  };

export const getTopLevelProductCategoriesByUser = (
  regId,
  pushMessage,
  successCallback = () => {},
  errorCallback = () => {}
) =>
  async function (dispatch) {
    try {
      const response = await getParentCategory(regId);
      if (response.status === 200) {
        if (
          Array.isArray(response.data?.data) &&
          response.data?.data?.length > 0
        ) {
          dispatch({
            type: SET_TOP_LEVEL_PRODUCT_CATEGORIES,
            payload: response.data.data,
          });
          successCallback(response.data.data);
        } else {
          // pushMessage('No Products Added.');
        }
      } else {
        pushMessage('Something went wrong.');
        errorCallback(response.data);
      }
    } catch (error) {
      console.log(
        '[user-profile-actions] getTopLevelProductCategoriesByUser Error',
        error
      );
      errorCallback();
    }
  };

  export const getUserRegistrationData = (userId, pushMessage) =>
  async function (dispatch) {
    try {
        const response = await getRegistrationData(userId);
        if (response.status === 200) {
          await dispatch({ type: SAVE_SIGNIN_DATA, payload: response?.data?.data });
          await dispatch({ type: UPDATE_SIGNIN_DATA, payload: null });
        } else {
          pushMessage(response?.data?.message || 'Something Went Wroung');
        }
    } catch (error) {
      console.log('Error fetching updated registration data', error);
      pushMessage('Error fetching updated registration data');
    }
  };