import React, { useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  // Autocomplete,
  // TextField,
  ListItemIcon,
  Radio,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-logo.svg';
// import { ReactComponent as SearchIcon } from '../../assets/search-purple-icon.svg';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu-black.svg';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as CallNowIcon } from '../../assets/call-now-icon.svg';
import { ReactComponent as WhatsappIcon } from '../../assets/whatsapp-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-arrow-icon.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { any, array, bool, func } from 'prop-types';
import moment from 'moment';
import { COMPANY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { loading } from '../../store/actions/rootActions';
import { DIALOG_VENDOR_DETAIL_SCRN__MSG, FREELANCER_ID } from '../../utils/constants';

const theme = createTheme();

// const WebFLCategoryForm = ({ nearRef, isLoading }) => {
const WebFLCategoryForm = ({
  nearRef,
  categoriesTree,
  selCategory,
  handleCategoryToggle,
  ratingArr,
  sortArr,
  handleRatingToggle,
  selRating,
  vendors,
  handleCallNow,
  handleWhatsapp,
  toggleCallBackDrawer,
  selectedVCategory,
  BuisnessType,
  // handleRatingAndCategoryFilter,
  clearFilter,
  handleTerms,
  handlePrivacy,
  openInNewTab,
  // handleShippingDelivery,
  // handleCancellationRefund,
  handleContactUs,
  handleClickHome,
  handleClickCategory,
  getFreelancerVendorDetails,
  selCity,
  // setSelCity,
  markNearByClick,
  nearByCity,
  // setSelRating,
  // setSelCategory,
  selSort,
  setSelectedSort,
  getVendorList,
  handleCityToggle,
  filterVCity,
  allVCity,
  setFilterVCity,
  clearSortData,
  clearAllCity,
  clearRatingData,
  pushMessage
}) => {
  // const onChangeApplyFilter = React.useCallback(() => {
  //   handleRatingAndCategoryFilter();
  // }, [selRating, selCategory, handleRatingAndCategoryFilter]);
  const history = useHistory();

  // function handleClick(event) {
  //   event.preventDefault();
  //   console.info('You clicked a breadcrumb.');
  // }

  // const [selSort, setSelectedSort] = useState();
  const handleSortChange = (value) => {
    console.log('values : ', value);
    setSelectedSort(value);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickHome}
    >
      Elynker
    </Link>,
    <Link
      underline="hover"
      key="2"
      className="fs-14 text-gray font-gotham"
      href="/"
      onClick={handleClickCategory}
    >
      {BuisnessType ? 'Business' : 'Freelancer'}
    </Link>,
    <Link
      underline="none"
      key="3"
      className="fs-14 text-breadcrumbs-active font-gotham"
    >
      {selectedVCategory?.title || ''}
    </Link>,
  ];

  useEffect(() => {
    let rParams = setFilterParam();
    console.log('Web params : ', rParams);
    const fetchData = async () => {
      await getVendorList(FREELANCER_ID, selectedVCategory?.id, rParams);
    };
    loading(true);
    fetchData();
    loading(false);
  }, [selSort, selRating, selCategory, selCity]);

  function setFilterParam() {
    var params = '';
    if (selRating?.length > 0) {
      for (let i = 0; i < selRating.length; i++) {
        params += 'rating=' + selRating[i].value;
        if (i !== selRating.length - 1) {
          params += '&';
        }
      }
    }

    if (selCategory?.length > 0) {
      if (selRating?.length > 0) {
        params += '&';
      }
      for (let i = 0; i < selCategory.length; i++) {
        params += 'categoryId=' + selCategory[i].id;
        if (i !== selCategory.length - 1) {
          params += '&';
        }
      }
    }

    if (selCity?.length > 0) {
      if (params !== '') {
        params += '&';
      }
      for (let i = 0; i < selCity.length; i++) {
        params += 'location=' + selCity[i];
        if (i !== selCity.length - 1) {
          params += '&';
        }
      }
    }

    if (selSort !== undefined && selSort !== null) {
      if (params !== '') {
        params += '&';
      }

      params += 'sortBy=' + selSort?.value;
    }

    if (markNearByClick) {
      if (nearByCity && nearByCity !== '') {
        if (params !== '') {
          params += '&';
        }
        params += 'location=' + nearByCity;
        nearRef.current.style.color = '#ff0000';
      }
    } else {
      nearRef.current.style.color = '#1843a0';
    }

    return params;
  }

  useEffect(() => {
    setFilterVCity(allVCity);
  }, [allVCity]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xl">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: 'auto' }}
        >
          <Grid item xs={12}>
            {/* <div>
              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <div style={{ flexGrow: 1 }}>
                    <ElynkerLogo width={'100px'} height={'30px'} />
                  </div>
                  {
                    <div>
                      <IconButton
                        size="medium"
                        aria-label="user icon"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => {
                          //   setShowSearch((val) => !val);
                          history.push('/search');
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </div>
                  }
                </Toolbar>
              </AppBar>
            </div> */}
            <div className="my-3 ms-2 d-flex align-items-center">
              <ArrowBackIosIcon
                onClick={() => {
                  history.goBack();
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <ElynkerLogoComponent>
                  <ElynkerLogo
                    className="ms-3"
                    width={'100px'}
                    height={'30px'}
                  />
                </ElynkerLogoComponent>
                <div>
                  {/* <IconButton
                    size="medium"
                    aria-label="user icon"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    color="inherit"
                    onClick={() => {
                      //   setShowSearch((val) => !val);
                      history.push('/search');
                    }}
                  >
                    <SearchIcon />
                  </IconButton> */}
                </div>
              </div>
            </div>
            <Divider
              component="div"
              className="main-divider mt-2 mb-3"
              role="presentation"
            />

            <form style={{ marginTop: '0px' }}>
              <Breadcrumbs
                className="mb-3"
                separator={
                  <NavigateNextIcon fontSize="small" className="text-gray" />
                }
                aria-label="breadcrumb"
              >
                {breadcrumbs}
              </Breadcrumbs>

              <Card className="category-web-card mb-3">
                <CardContent className="fl-category-web-content">
                  <div className="d-flex align-items-center flex-content">
                    <Typography
                      variant="h5"
                      className="fl-category-text text-offwhite fw-bold mb-3 fw-bold font-skmodernist"
                      component="div"
                      style={{ width: '50%' }}
                    >
                      {/* Connect with 4 Mn+{' '}
											{BuisnessType ? 'Businesses' : 'Services'} <br></br>{' '}
											Across India */}
                      Gain insights and advice from industry professionals who
                      understand your challenges!
                    </Typography>
                    <div className="">
                      <Button
                        variant="contained"
                        className="post-requirement-btn py-2 px-4"
                        onClick={toggleCallBackDrawer}
                      >
                        Request a Call Back
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Typography
                variant="h4"
                align="left"
                alignItems="center"
                className="fs-20 text-black mb-3 fw-bold font-skmodernist"
              >
                {BuisnessType ? 'Wholesale and Suppliers' : 'Category Name'}
              </Typography>

              <Grid container spacing={2} className="mb-4">
                <Grid item md={4} lg={4}>
                  <Box
                    sx={{
                      border: '1px solid #e3e3e3 !important',
                      borderRadius: '8px !important',
                      padding: '14px',
                    }}
                  >
                    <Box className="d-flex justify-content-between align-items-center">
                      <Typography className="fs-22 fw-bold font-skmodernsit ">
                        Filters
                      </Typography>
                      <Button onClick={clearFilter}>
                        <Typography
                          className="fs-15 fw-bold font-skmodernsit text-secondary"
                          onClick={() => {
                            clearFilter();
                            clearRatingData();
                            clearSortData();
                            clearAllCity();
                          }}
                        >
                          Clear All
                        </Typography>
                      </Button>
                    </Box>
                    <Divider
                      component="div"
                      className="my-3"
                      role="presentation"
                    />
                    <Box>
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Category
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {categoriesTree.map((category, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem
                              key={index}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleCategoryToggle(category)}
                                  checked={
                                    selCategory.findIndex(
                                      (x) => x.id === category.id
                                    ) !== -1
                                  } //selCategory.indexOf(category) !== -1
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                  sx={{
                                    color: '#D4D4D4',
                                    '&.Mui-checked': {
                                      color: '#416AD4',
                                    },
                                  }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton sx={{ paddingLeft: '0px' }}>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={category?.title}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Rating
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {ratingArr.map((rating, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton
                                sx={{ paddingLeft: '0px' }}
                                onClick={handleRatingToggle(rating)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    onChange={handleRatingToggle(rating)}
                                    // onClick={() =>
                                    //   handleRatingAndCategoryFilter()
                                    // }
                                    checked={
                                      selRating.findIndex(
                                        (x) => x.label === rating.label
                                      ) !== -1
                                    }
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={rating?.label}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        City
                      </Typography>
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {filterVCity &&
                          filterVCity?.map((city, index) => {
                            const labelId = `checkbox-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={handleCityToggle(city)}
                                >
                                  <ListItemIcon>
                                    <Checkbox
                                      edge="start"
                                      onChange={handleCityToggle(city)}
                                      checked={selCity.indexOf(city) !== -1}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-black font-skmodernist"
                                    primary={city}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                      {/* <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        City
                      </Typography>

                      <Autocomplete
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Search your city"
                            InputProps={{
                              startAdornment: (
                                <SearchIcon
                                  style={{ margin: '8px', width: '30px' }}
                                />
                              ),
                            }}
                          />
                        )}
                      />
                      <List
                        class="filter-web-list"
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          padding: '0px',
                        }}
                      >
                        {[
                          'Jaipur',
                          'Chandigarh',
                          'Delhi',
                          'Mumbai',
                          'Lucknow',
                          'Surat',
                        ].map((rating, index) => {
                          const labelId = `checkbox-list-secondary-label-${index}`;
                          return (
                            <ListItem key={index} disablePadding>
                              <ListItemButton sx={{ paddingLeft: '0px' }}>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    onChange={handleToggle(rating)}
                                    checked={checked.indexOf(rating) !== -1}
                                    inputProps={{
                                      'aria-labelledby': labelId,
                                    }}
                                    sx={{
                                      color: '#D4D4D4',
                                      '&.Mui-checked': {
                                        color: '#416AD4',
                                      },
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  className="fs-15 fw-normal text-black font-skmodernist"
                                  primary={rating}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List> */}

                      <Divider
                        component="div"
                        className="my-3"
                        role="presentation"
                      />
                      <Typography className="fs-18 fw-medium font-skmodernsit mb-2">
                        Sort By
                      </Typography>

                      <List
                        class="filter-web-sort-list"
                        dense
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                      >
                        {sortArr &&
                          sortArr?.map((option, index) => {
                            const labelId = `radio-list-secondary-label-${index}`;
                            return (
                              <ListItem key={index} disablePadding>
                                <ListItemButton
                                  sx={{ paddingLeft: '0px' }}
                                  onClick={() => handleSortChange(option)}
                                >
                                  <ListItemIcon>
                                    <Radio
                                      edge="end"
                                      onChange={() => handleSortChange(option)}
                                      checked={selSort?.label === option?.label} //selRating.findIndex(x => x.label === rating.label) !== -1
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                      sx={{
                                        color: '#D4D4D4',
                                        '&.Mui-checked': {
                                          color: '#416AD4',
                                        },
                                      }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    id={labelId}
                                    className="fs-15 fw-normal text-grey font-gotham"
                                    primary={option.label}
                                  />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Box>
                  </Box>
                </Grid>

                <Grid item md={8} lg={8}>
                  <div
                    className="mb-4 b2b-business"
                    style={{ display: 'none' }}
                  >
                    <Stack
                      direction="row"
                      spacing={1.5}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      {/* <Chip
												label="25 Mn+ Business"
												onClick={handleChipClick}
												onDelete={handleChipDelete}
												deleteIcon={<CrossIcon />}
											/>
											<Chip
												label="30 Cr Business Done"
												onClick={handleChipClick}
												onDelete={handleChipDelete}
												deleteIcon={<CrossIcon />}
											/>
											<Chip
												label="4.5 Star"
												onClick={handleChipClick}
												onDelete={handleChipDelete}
												deleteIcon={<CrossIcon />}
											/> */}
                      <Chip
                        ref={nearRef}
                        label="Near Me"
                        clickable
                        sx={{ display: 'none' }}
                      />
                    </Stack>
                  </div>
                  <List className="pt-0">
                    {vendors &&
                      vendors?.map((item, index) => {
                        let last_update = moment(item?.last_login)?.fromNow();
                        return (
                          <ListItem
                            className="b2b-list-item"
                            key={index}
                            style={{ cursor: 'pointer' }}
                          >
                            <Card
                              className="card"
                              onClick={() => {
                                getFreelancerVendorDetails(item.id);
                                if(item?.uuid){
                                  history.push(`/freelancer-detail/${item.uuid}`);
                                } else {
                                  pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                                }
                              }}
                            >
                              <div className="d-flex justify-content-between">
                                <CardMedia
                                  className="category-media"
                                  image={
                                    item?.image_path
                                      ? `${COMPANY_LOGO_BASE_URL}${item.image_path}`
                                      : // eslint-disable-next-line no-undef
                                        require('../../assets/elynker_default_image.jpg')
                                  }
                                  sx={{
                                    padding: '1em 1em 0 1em',
                                    objectFit: 'contain',
                                  }}
                                />
                                <CardContent className="content p-0">
                                  <div className="d-flex justify-content-between align-items-start w-100">
                                    <Typography
                                      variant="h5"
                                      className="fs-16 text-black mb-1 fw-bold font-skmodernist"
                                      component="div"
                                    >
                                      {item?.name || ''}
                                    </Typography>
                                    <div
                                      onClick={() => {
                                        getFreelancerVendorDetails(item.id);
                                        if(item?.uuid){
                                          history.push(
                                            `/freelancer-detail/${item.uuid}`
                                          );
                                        } else {
                                          pushMessage(DIALOG_VENDOR_DETAIL_SCRN__MSG);
                                        }
                                      }}
                                    >
                                      <RightArrowIcon />
                                    </div>
                                  </div>

                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-grey mb-1 font-gotham"
                                  >
                                    {item?.freelancer_role || ''}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-gotham"
                                  >
                                    Last seen{' '}
                                    {last_update !== 'Invalid date'
                                      ? last_update
                                      : ''}
                                  </Typography>
                                  <Box>
                                    <Rating
                                      name="read-only"
                                      value={item?.rating}
                                      size="small"
                                      precision={0.1}
                                      readOnly
                                    />
                                  </Box>
                                </CardContent>
                              </div>
                              <CardContent className="p-0 d-flex justify-content-between mt-1">
                                <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center mb-1">
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                    >
                                      Members
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className="fs-13 text-secondary mb-1 font-skmodernist"
                                    >
                                      {item?.member_count || 0}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="d-flex flex-column">
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                  >
                                    Services
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-secondary mb-1 font-skmodernist"
                                  >
                                    {item?.product_count || 0}
                                  </Typography>
                                </div>
                                <div className="d-flex flex-column">
                                  <Typography
                                    variant="body2"
                                    className="fs-13 text-darkgrey mb-1 font-skmodernist"
                                  >
                                    Starting at
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className="fs-15 fw-bold text-secondary mb-1 font-skmodernist"
                                  >
                                    ₹{item?.hourly_rate || 0}/{' '}
                                    <span className="fs-13 text-lightgrey fw-normal">
                                      Hour
                                    </span>
                                  </Typography>
                                </div>
                              </CardContent>
                              <Divider
                                component="div"
                                className="mt-2 mb-3"
                                role="presentation"
                              />
                              <CardContent className="p-0 d-flex justify-content-between">
                                <Button
                                  variant="contained"
                                  className="call-now-btn "
                                  onClick={(e) =>
                                    handleCallNow(
                                      e,
                                      item?.mobile_number,
                                      item?.country_code || '',
                                      item?.id
                                    )
                                  }
                                >
                                  <CallNowIcon className="me-2" />
                                  Call Now
                                </Button>
                                <Button
                                  variant="contained"
                                  className="whatsapp-btn "
                                  onClick={(e) =>
                                    handleWhatsapp(
                                      e,
                                      item?.whatsapp_number,
                                      item?.country_code || '',
                                      item?.id
                                    )
                                  }
                                >
                                  <WhatsappIcon className="me-2" />
                                  WhatsApp
                                </Button>
                              </CardContent>
                            </Card>
                          </ListItem>
                        );
                      })}
                  </List>
                </Grid>
              </Grid>
            </form>
            <div className="homepage-footer" style={{ marginTop: '100px' }}>
              <Grid
                container
                className="homepage-footer-content mb-4"
                sx={{
                  width: '70%',
                  margin: '0 auto',
                  justifyContent: 'space-evenly',
                }}
              >
                <Box sx={{ marginTop: '4px', marginBottom: '10px' }}>
                  <Typography
                    variant="body1"
                    className="fs-20 text-white font-skmodernist fw-bold mb-4"
                  >
                    Get In Touch
                  </Typography>

                  <Link
                    className="support-link"
                    // href="https://support.elynker.com"
                    underline="always"
                    color="#FFF"
                  >
                    {'support@elynker.com'}
                  </Link>
                  <Typography className="fs-14 font-gotham text-light-white mt-2">
                    +91 9820596537
                  </Typography>
                  <Typography className="fs-14 font-gotham text-light-white">
                    {'Remember us with ease -  '}
                    <Link
                      component="button"
                      underline="none"
                      variant="body2"
                      color="#FFF"
                      onClick={() => {
                        window.location.href = 'tel:+919820596537';
                      }}
                    >
                      9820 LYNKER
                    </Link>
                  </Typography>
                </Box>

                <Box className="usefullink-container">
                  <Typography
                    variant="body1"
                    className="fs-20 text-white font-skmodernist fw-bold mb-4"
                  >
                    Useful Links
                  </Typography>

                  <Box className="link-container"></Box>
                  {/* <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={handleShippingDelivery}
                      sx={{ cursor: 'pointer' }}
                    >
                      Shipping Delivery
                    </Typography>
                  </Box> */}
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={handlePrivacy}
                      sx={{ cursor: 'pointer' }}
                    >
                      Privacy Policy
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={handleTerms}
                      sx={{ cursor: 'pointer' }}
                    >
                      Terms of Use
                    </Typography>
                  </Box>
                  {/* <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={handleCancellationRefund}
                      sx={{ cursor: 'pointer' }}
                    >
                      Cancellation and Refund
                    </Typography>
                  </Box> */}
                  <Box>
                    <Typography
                      className="fs-14 font-gotham text-light-white mt-3 mb-1"
                      onClick={handleContactUs}
                      sx={{ cursor: 'pointer' }}
                    >
                      Contact Us
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Box className="d-flex justify-content-around">
                <Typography
                  className="fs-12 font-gotham text-light-white mb-4"
                  sx={{ marginTop: '30px', cursor: 'pointer' }}
                  onClick={() => openInNewTab('https://www.elgraces.com/')}
                >
                  © El Graces Aggregators Private Limited, 2024
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
WebFLCategoryForm.propTypes = {
  nearRef: any,
  isLoading: any,
  SearchComponent: any,
  setIsCallBackDrawerOpen: any,
  categoriesTree: any,
  selCategory: any,
  handleCategoryToggle: any,
  clearRatingData: any,
  handleRatingAndCategoryFilter: any,
  ratingArr: any,
  sortArr: any,
  handleRatingToggle: any,
  selRating: any,
  vendors: any,
  handleCallNow: any,
  handleWhatsapp: any,
  clearFilter: any,
  handleTerms: any,
  handlePrivacy: any,
  openInNewTab: func,
  toggleCallBackDrawer: any,
  selectedVCategory: any,
  BuisnessType: any,
  handleShippingDelivery: func,
  handleCancellationRefund: func,
  handleContactUs: func,
  handleClickHome: func,
  handleClickCategory: func,
  getFreelancerVendorDetails: func,
  selCity: any,
  setSelCity: func,
  markNearByClick: bool,
  nearByCity: any,
  setSelRating: any,
  setSelCategory: any,
  selSort: any,
  setSelectedSort: any,
  getVendorList: any,
  handleCityToggle: func,
  filterVCity: array,
  setFilterVCity: func,
  allVCity: array,
  clearSortData: func,
  clearAllCity: func,
  pushMessage: func
};
export default WebFLCategoryForm;
